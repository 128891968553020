import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { SelectionModel } from '@angular/cdk/collections';
import { Subscription, combineLatest, map } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EventWorkflowService } from '../../../services/event-workflow.service';
import { PageEvent } from '@angular/material/paginator';
import { OrganizationService } from 'src/app/@shared';

@Component({
  selector: 'app-add-workflow',
  templateUrl: './add-workflow.component.html',
  styleUrls: ['./add-workflow.component.scss'],
})
export class AddWorkflowComponent implements OnInit, OnDestroy {
  viewModelData: any = undefined;

  viewModel$ = combineLatest([
    this.ewService.Templates$!,
    this.ewService.isLoading$!,
    this.ewService.totalRecords$!,
    this.ewService.page$!,
  ]).pipe(
    map(([templates, isLoading, totalRecords, page]) => {
      if (templates.length == 0 && page.pageIndex > 0) {
        page.previousPageIndex = 0;
        page.pageIndex = 0;
        this.ewService.page(page);
        this.ewService.reload();
      }
      this.viewModelData = { templates, isLoading, totalRecords, page };
      this.getMasterTemplate();

      return { templates, isLoading, totalRecords, page };
    })
  );

  displayedColumns: string[] = [
    'select',
    'templateName',
    'templateGroup',
    'templateCategory',
  ];
  dataSource: any = [];
  showAddTemplateView = false;
  selection = new SelectionModel<any>(true, []);
  masterDataSource: any;
  subscription$ = new Subscription();
  allWorkflowGroups: any;
  allSelected: any;
  allTemplatesAdded: any = undefined;
  dataToFilterOut: any = null;

  @Output() templatesSelected = new EventEmitter<any>();

  constructor(
    public ewService: EventWorkflowService,
    public auth: AuthService,
    public dialogRef: MatDialogRef<AddWorkflowComponent>,
    public orgService: OrganizationService,
    public cdRef: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.dataToFilterOut = data.filterData;
  }

  ngOnInit(): void {
    this.getControlsRefData();
  }

  getMasterTemplate() {
    this.dataSource = this.viewModelData.templates?.map((d: any) => {
      return {
        id: d.Id,
        templateName: d.Name,
        eventType: d.WorkflowCategory ? d.WorkflowCategory : '-',
        channel: d.WorkflowGroup ? d.WorkflowGroup : '-',
        user: d.UserId,
      };
    });
    this.masterDataSource = this.dataSource;
  }

  onSearch(data: any) {
    const keyword = data.srcElement.value;

    // this.umService.search(event.target.value);
    this.dataSource = this.masterDataSource.filter((x: any) => {
      const keys = Object.keys(x);
      for (let key of keys) {
        if (x[key]?.toLowerCase()?.includes(keyword?.toLowerCase()?.trim())) {
          return true;
        }
      }
      return false;
    });
  }

  clearSearch() {
    // this.userManagementForm.controls.search.setValue('');
    // this.umService.search('');
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  toggleAllRows(e: any, dataSource: any) {
    this.allSelected = e.checked;
    if (e.checked == false) {
      this.selection.clear();
    } else {
      this.selection.select(...dataSource);
    }
  }

  selectionToggle(e: any, rowIndex: number, dataSource: any) {
    const u = dataSource[rowIndex];

    if (this.selection.isSelected(u)) {
      this.selection.deselect(u);
    } else {
      this.selection.select(u);
    }
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${
      row.position + 1
    }`;
  }

  onSave() {
    if (this.selection.selected.length > 0) {
      this.ewService.setLoadingSubjectInOfferPromoService(true);
    }

    // setTimeout(() => {
    this.addWorkflowTemplatesToAnEvent();
    // }, 2000);
  }

  addWorkflowTemplatesToAnEvent() {
    const tempSubs = new Subscription();
    for (let i = 0; i < this.selection.selected.length; i++) {
      const t = this.selection.selected[i];

      // If workflow already added, go to next iteration
      if ((this.dataToFilterOut as string[]).includes(t.Id)) {
        continue;
      }

      if (t && t.Id && t.Id?.trim()?.length > 0) {
        tempSubs.add(
          this.ewService
            .addWorkflow(
              t.Id,
              this.ewService.eventName,
              this.ewService.selectedEventId
            )
            .subscribe((resp: any) => {
              if (i == this.selection.selected.length - 1) {
                this.ewService.setLoadingSubjectInOfferPromoService(false);
                setTimeout(() => {
                  this.ewService.reload();
                  this.dialogRef.close();
                  tempSubs.unsubscribe();
                }, 300);
              }
            })
        );
      } else {
        if (i == this.selection.selected.length - 1) {
          this.ewService.setLoadingSubjectInOfferPromoService(false);
          setTimeout(() => {
            this.ewService.reload();
            this.dialogRef.close();
            tempSubs.unsubscribe();
          }, 300);
        }
      }
    }
  }

  getControlsRefData() {
    if (!this.ewService.workflowGroupsRefData) {
      this.subscription$.add(
        this.ewService
          .getRefDataForEditWorkflowControls()
          .subscribe((data: any) => {
            this.allWorkflowGroups = data.workflowGroups;
            this.ewService.workflowGroupsRefData = this.allWorkflowGroups;
          })
      );
    } else {
      this.allWorkflowGroups = this.ewService.workflowGroupsRefData;
    }
  }

  getCategoryNameFromId(id: string) {
    const i = this.allWorkflowGroups?.findIndex((g: any) => g?.Id == id);
    if (i > -1) {
      return this.allWorkflowGroups[i]?.Name;
    }
    return id;
  }

  onPage(e: PageEvent): void {
    this.selection.clear();
    this.ewService.page(e);
  }

  checkIfTemplateAlreadyAdded(row: any) {
    if (this.dataToFilterOut && this.dataToFilterOut.length > 0) {
      return this.dataToFilterOut.includes(row.Id);
    }
    return false;
  }

  checkIfAllTemplatesAlreadyAdded(users: any[]) {
    let templatesAdded;
    if (this.dataToFilterOut && this.dataToFilterOut.length > 0) {
      for (let i = 0; i < users.length; i++) {
        if (!this.checkIfTemplateAlreadyAdded(users[i])) {
          templatesAdded = false;
          return false;
        }
      }
      templatesAdded = true;
      return true;
    }
    templatesAdded = false;
    return false;
  }

  areAllTemplatesAdded(users: any) {
    if (this.allTemplatesAdded == undefined) {
      this.allTemplatesAdded = this.checkIfAllTemplatesAlreadyAdded(users);
      this.cdRef.detectChanges();
    }
    return this.allTemplatesAdded;
  }

  ngOnDestroy() {
    this.subscription$.unsubscribe();
  }
}
