import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Sort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest, map } from 'rxjs';
import { ConfirmDialogComponent, Logger, OrganizationService } from 'src/app/@shared';
import { DEFAULT_SNACKBAR_CONFIG } from 'src/app/@shared/constants/site.constants';
import { OfferAsset, ProductAssetsService, ProductAddAssetComponent, EventAssetsService } from '../..';
import { EventAddAssetComponent } from '../event-add-asset/event-add-asset.component';

const log = new Logger('ProductAssetListComponent');

@Component({
  selector: 'app-event-asset-list',
  templateUrl: './event-asset-list.component.html',
  styleUrls: ['./event-asset-list.component.scss']
})

export class EventAssetListComponent implements OnInit {
  private assetsUrl: string = '';
  viewModel$ = combineLatest([
    this.eventAssetService.isLoading$,
    this.eventAssetService.viewMode$,
    this.eventAssetService.eventId$,
    this.organizationService.assetsUrl$,
  ]).pipe(
    map(([ isLoading, viewMode, eventId, assetsUrl]) => {
        this.assetsUrl = assetsUrl;
      return { isLoading, viewMode, eventId, assetsUrl }
    }),
  );

  filtersForm = new FormGroup({
    search: new FormControl(),
  });
  
  @ViewChild('assetTable', { static: false }) assetTable: any;
  @ViewChild('assetCards', { static: false }) assetCards: any;
  public assetsList: any;

  constructor(private eventAssetService: EventAssetsService<OfferAsset>,
    private organizationService: OrganizationService,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private router: Router,
    private matSnackBar: MatSnackBar) { }

  ngOnInit(): void {
    log.debug('init');
    this.clearSearch();
    // get the event id from the parent route
    this.eventAssetService.eventId = this.route.snapshot.params['eventId'];
    this.eventAssetService.assets$.subscribe((data) => {
      this.assetsList = data;
    });
  }


  onSearch(event: any) {
    this.eventAssetService.search(event.target.value);
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(EventAddAssetComponent, {
      width: '80%',
      height: '90%',
      data: { eventId: this.route.snapshot.params['eventId'] }
    });
  }

  diassociateAssets() {
    let selectedRecords: any;
    if (this.assetTable) {
      selectedRecords = this.assetTable.getSelectedSectionRecords();
    } else if (this.assetCards) {
      selectedRecords = this.assetCards.getSelectedSectionRecords();
    }
    if (selectedRecords && selectedRecords.length > 0) {
      const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
        data: {
          title: 'Confirm Delete',
          message: `Do you want to remove from asset?`,
        },
        disableClose: true,
      });

      confirmDialog.afterClosed().subscribe(
        confirmResult => {
          if (confirmResult) {
            const assets = selectedRecords.map((x: any) => x.Detail.Id)
            this.eventAssetService.disassociateAssetToEvent(assets).subscribe({
              next: () => {
                this.matSnackBar.open(`Asset deleted`, 'OK', DEFAULT_SNACKBAR_CONFIG);
                if (this.assetTable) {
                  this.assetTable.clearSelection();
                } else if (this.assetCards) {
                  this.assetCards.clearSelection();
                }
                this.eventAssetService.reload();
                this.router.navigate([`../`, `event-assets`], { relativeTo: this.route });
              },
              error: (error) => {
                log.error('Error deleting asset from event', error);

                if (error.error.value) {
                  throw new Error(error.error.value);
                } else {
                  throw new Error(error.message);
                }
              }
            });
          }
        });
    }
  }

  cancel(): void {
    this.eventAssetService.reload();
    this.router.navigate([`../../`], { relativeTo: this.route });
  }
  clearSearch() {
    this.filtersForm.controls.search.setValue('');
    this.eventAssetService.search('');
  }

  toggleView(mode: string) {
    this.eventAssetService.toggleViewMode(mode);
  }

}
