<!-- page title -->
<h1>Event Management</h1>

<nav mat-tab-nav-bar [tabPanel]="tabPanel">
  <a mat-tab-link [routerLink]="['details']" routerLinkActive #details="routerLinkActive"
    [active]="details.isActive" data-testid="Details">Details
  </a>
  <a mat-tab-link *ngIf="eventId !=='0'" [routerLink]="['versions']" routerLinkActive #versions="routerLinkActive"
    [active]="versions.isActive">Versions
  </a>
  <a mat-tab-link *ngIf="eventId !=='0'" [routerLink]="['event-assets']" routerLinkActive #assetList="routerLinkActive"
    [active]="assetList.isActive">Asset List
  </a>
  <a mat-tab-link *ngIf="eventId !=='0'" [routerLink]="['workflow']" routerLinkActive #workflow="routerLinkActive"
    [active]="workflow.isActive">Workflow
  </a>
</nav>
<mat-tab-nav-panel #tabPanel>
  <router-outlet></router-outlet>
</mat-tab-nav-panel>
