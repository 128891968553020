import { SelectionModel } from '@angular/cdk/collections';
import {
  Component,
  Input,
  OnInit,
  OnDestroy,
  ViewChild,
  EventEmitter,
  Output,
} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Subscription, combineLatest, debounceTime, map } from 'rxjs';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from 'src/app/@shared';
import { AuthUser } from '../../../models/authuser.model';
import { AuthUserDomain } from '../../../models/authuser-domain.model';
import { AuthUserService } from '../../../services/authuser.service';

@Component({
  selector: 'app-users-table',
  templateUrl: './admin-users-table.component.html',
  styleUrls: ['./admin-users-table.component.scss'],
})
export class AdminUsersTableComponent<T extends AuthUser>
  implements OnInit, OnDestroy
{
  @Input() usersToFilterOut!: any[];

  viewModel$ = combineLatest([
    this.userService.AuthUsers$,
    this.userService.isLoading$,
    this.userService.totalRecords$,
    this.userService.page$,
  ]).pipe(
    map(([users, isLoading, totalRecords, page]) => {
      if (users.length == 0 && page.pageIndex > 0) {
        page.previousPageIndex = 0;
        page.pageIndex = 0;
        this.userService.page(page);
        this.userService.reload();
      }
      return { users, isLoading, totalRecords, page };
    })
  );

  @Input({ required: true }) displayedColumns!: string[];

  usersList: AuthUserDomain<AuthUser>[] = [];
  selection = new SelectionModel<AuthUserDomain<AuthUser>>(true, []);
  tableData: any;
  searchUserSubscription: Subscription | undefined;
  masterData: any;
  filteredData: any;
  searchDirty = false;
  usedForWorkflow = false;

  @ViewChild('paginator')
  paginator!: MatPaginator;

  @Output() checkboxChanges = new EventEmitter();
  @Output() userDelete = new EventEmitter();
  @Output() userActivationToggle = new EventEmitter();
  @Output() onAllUsersAdded = new EventEmitter();

  @Input() set addWorkflowUsers(val: boolean) {
    this.usedForWorkflow = true;
    this.userService.getOnlyActiveUsers();
  }

  constructor(
    public dialog: MatDialog,
    public userService: AuthUserService<T>
  ) {}

  ngOnInit() {
    this.userService.reload();
    this.userService.AuthUsers$.subscribe((data) => {
      this.usersList = data;
      this.selection.clear();
    });
  }

  syncIsActiveFromMasterData(data: any) {
    data = data?.map((d: any) => {
      if (!d.hasOwnProperty('isActive')) {
        let isActive = true;
        const i = this.masterData?.findIndex(
          (x: any) => x.user_id == d.user_id
        );
        if (i > -1) {
          isActive = this.masterData[i]?.isActive;
        }
        return { ...d, isActive };
      }
      return d;
    });
    return data;
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.usersList.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      this.checkboxChanges.emit([]);
      return;
    }
    this.checkboxChanges.emit(this.usersList);
    this.selection.select(...this.usersList);
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: AuthUserDomain<AuthUser>): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${
      row.Detail.FirstName + 1
    }`;
  }

  onPage(e: PageEvent): void {
    this.selection.clear();
    this.userService.page(e);
  }

  /** Simulates the deactivation behavior on front end */
  deactivateUserOnlyOnFrontEnd(user: any) {
    if (!this.filteredData) {
      const i = this.masterData?.findIndex(
        (d: any) => d.user_id == user.user_id
      );
      if (i > -1) {
        this.masterData[i].isActive = !this.masterData[i]?.isActive;
      }
    } else {
      const fi = this.filteredData?.findIndex(
        (d: any) => d.user_id == user.user_id
      );

      if (fi > -1) {
        this.filteredData[fi].isActive = !this.filteredData[fi]?.isActive;
      }
    }
  }

  /** Delete the user */
  deleteUser(user: any) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        message: 'Are you sure you want to delete this user?',
        confirmText: 'Yes',
        cancelText: 'No',
      },
    });
    dialogRef.afterClosed().subscribe((e) => {
      if (e == true) {
        this.userDelete.emit([user]);
      }
    });
  }

  /** Calls api to activate / deactivate user */
  activateUserToggle(userId: any, isActive: any) {
    let action = isActive == true ? 'de-activate' : 'activate';
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        message: `Are you sure you want to ${action} this user?`,
        confirmText: 'Yes',
        cancelText: 'No',
      },
    });
    dialogRef.afterClosed().subscribe((e) => {
      if (e == true) {
        this.userActivationToggle.emit({ userId, activate: !isActive });
      }
    });
  }

  /** Emit check box changes to parent */
  onCheckboxClick(row: any) {
    this.selection.toggle(row);
    this.checkboxChanges.emit(this.selection.selected);
  }

  public checkIfUserAlreadyAdded(row: any) {
    if (this.usersToFilterOut && this.usersToFilterOut.length > 0) {
      return this.usersToFilterOut.includes(row.DomainId);
    }
    return false;
  }

  allUsersAlreadyAdded(users: any[]) {
    if (this.usersToFilterOut && this.usersToFilterOut.length > 0) {
      for (let i = 0; i < users.length; i++) {
        if (!this.checkIfUserAlreadyAdded(users[i])) {
          this.onAllUsersAdded.emit(false);
          return false;
        }
      }
      this.onAllUsersAdded.emit(true);
      return true;
    }
    this.onAllUsersAdded.emit(false);
    return false;
  }

  ngOnDestroy(): void {
    this.userService.resetActiveUsersFlag();
    this.searchUserSubscription?.unsubscribe();
  }
}
