import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Sort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest, map, Observable } from 'rxjs';
import { Logger, OrganizationService } from 'src/app/@shared';
import * as dayjs from 'dayjs';
import { CouponAsset } from '../../models/coupon-asset.model';
import { CouponAssetsService } from '../../services/coupon-assets.service';

const log = new Logger('CouponAssetCardsComponent');

@Component({
  selector: 'app-coupon-asset-cards',
  templateUrl: './coupon-asset-cards.component.html',
  styleUrls: ['./coupon-asset-cards.component.scss']
})

export class CouponAssetCardsComponent implements OnInit {

  viewModel$ = combineLatest([
    this.couponAssetService.assets$,
    this.couponAssetService.isLoading$,
    this.couponAssetService.totalRecords$,
    this.couponAssetService.page$,
    this.couponAssetService.couponId$,
    this.organizationService.assetsUrl$
  ]).pipe(
    map(([assets, isLoading, totalRecords, page, couponId, assetsUrl]) => {
      if (assets.length == 0 && page.pageIndex > 0) {
        page.previousPageIndex = 0
        page.pageIndex = 0
        this.couponAssetService.page(page);
        this.couponAssetService.reload();
      }
      assets.forEach(asset => asset.DateAdded = dayjs(asset.DateAdded).format('MM/DD/YYYY'));
      return { assets, isLoading, totalRecords, page, couponId, assetsUrl }
    }),
  );
  assetsList: CouponAsset[] = [];
  selection = new SelectionModel<CouponAsset>(true, []);
  assetsUrl$: Observable<string> = this.organizationService.assetsUrl$;
  constructor(private couponAssetService: CouponAssetsService<CouponAsset>,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private router: Router,
    private matSnackBar: MatSnackBar, private organizationService: OrganizationService) { }

  ngOnInit(): void {
    log.debug('init');
    // get the coupon id from the parent route
    this.couponAssetService.couponId = this.route.snapshot.params['couponId'];
  }

  onSort(sortState: Sort): void {
    this.couponAssetService.sort(sortState);
  }

  onPage(pageEvent: PageEvent): void {
    this.selection.clear();
    this.couponAssetService.page(pageEvent);
  }

  onSearch(event: any) {
    this.couponAssetService.search(event.target.value);
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.assetsList.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.assetsList.forEach(row => this.selection.select(row));
  }

  public getSelectedSectionRecords() {
    return this.selection.selected;
  }

  public clearSelection() {
    this.selection.clear();
  }

  public getImageSrc(assetsUrl: any, assets: any) {
    let imagesrc = '';
    if (assets.Type === 'application/pdf') {
      imagesrc = './assets/images/pdf.png';
    } else if (assets.Type.indexOf('audio') > -1) {
      imagesrc = './assets/images/mp3.jpg';
    } else if (assets.Type.indexOf('video') > -1) {
      imagesrc = './assets/images/mp4.png';
    } else if (assets.Type.indexOf('text') > -1) {
      imagesrc = './assets/images/text.png';
    }
    else {
      imagesrc = assetsUrl + '/' + assets.Detail.FileName;
    }
    return imagesrc;
  }

}
