import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { IErrorService } from '@microsoft/applicationinsights-angularplugin-js';
import { ErrorService, Logger, NotificationService } from '.';

/**
 * Adds a default error handling to the entire client app
 */

@Injectable({
  providedIn: 'root',
})

export class GlobalErrorHandler implements IErrorService {

  // Error handling is important and needs to be loaded first.
  // Because of this we should manually inject the services with Injector.
  constructor(private injector: Injector) { }

  handleError(error: Error | HttpErrorResponse | any) {

    const log = new Logger('GlobalErrorHandler');
    const errorService = this.injector.get(ErrorService);
    const notificationService = this.injector.get(NotificationService);

    let message = '';
    let stackTrace = '';
   

    if (error instanceof HttpErrorResponse) {
      // Server Error
      message = errorService.getServerMessage(error);
      stackTrace = errorService.getServerStack(error);

      switch (true) {
        case (error.status === 0):
          notificationService.showError('No response from server');
          break;
        case (error.status === 400):
          notificationService.showError('Invalid request');
          break;
        case (error.status === 401):
          notificationService.showError('Unauthorized');
          break;
        case (error.status === 404):
          break;
        case (error.status >= 500):
          notificationService.showError(message);
          break;
        default:
          break;
      }

    } else {
      let errorMessage = error ? error.error : '';
      // Client Error
      message = errorService.getClientMessage(error);
      stackTrace = errorService.getClientStack(error);
      notificationService.showError(message, () => {
        if (errorMessage == 'login_required') {
          window.location.reload();
        }
      });
    }

    // Always log errors
    log.error(message, stackTrace);

  }



}
