import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { combineLatest, map, Observable, of, tap } from 'rxjs';
import { Logger, OrganizationService } from 'src/app/@shared';
import { OfferPromo, PromoDomain, OfferPromoService, Shopper, ShopperService, ShoppingStats } from '../..';
import { Status } from '../../models/offer-promo-status';
import { AuthorizeControlService } from 'pr1-ui-components';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

const log = new Logger('ShoppingStatsComponent');

@Component({
  selector: 'app-shopping-stats',
  templateUrl: './shopping-stats.component.html',
  styleUrls: ['./shopping-stats.component.scss'],
})
export class ShoppingStatsComponent<T extends Shopper> implements OnInit {
  shopStats$: Observable<ShoppingStats> = of({} as ShoppingStats);
  shopperId: string = '0';
  formGroup = new FormGroup({});

  constructor(
    private shopperService: ShopperService<T>,
    private router: Router,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.shopperId = this.route.snapshot.params['shopperId'];
    if (this.shopperId && this.shopperId !== '0') {
      this.shopStats$ = this.shopperService.getShoppingStats(this.shopperId).pipe(
        tap((shopper) => {
          this.formGroup.patchValue(shopper);
          this.formGroup.markAllAsTouched();
        })
      );
    }
  }

}
