import { Injectable } from '@angular/core';
import { EventType } from '..';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, combineLatest, debounceTime, distinctUntilChanged, map, Observable, shareReplay, switchMap, tap, } from 'rxjs';
import { environment } from 'src/environments/environment';
import { OrganizationService } from 'src/app/@shared';
import { v4 as uuidv4 } from 'uuid';
import { DEFAULT_PAGING } from 'src/app/@shared/constants/site.constants';

@Injectable({
  providedIn: 'root'
})

export class EventTypeService<T extends EventType> {

  // initialize behavior subjects
  private pageBehaviorSubject = new BehaviorSubject(DEFAULT_PAGING);
  private loadingBehaviorSubject = new BehaviorSubject<boolean>(false);
  private searchBehaviorSubject = new BehaviorSubject<string>('');
  private sortBehaviorSubject = new BehaviorSubject({ active: 'EventTypeName', direction: 'asc', });
  private reloadBehaviorSubject = new BehaviorSubject<string>('');

  // we do not wish to expose our behavior subjects.  create public observables
  public page$ = this.pageBehaviorSubject.asObservable();
  public search$ = this.searchBehaviorSubject.asObservable();
  public sort$ = this.sortBehaviorSubject.asObservable();
  public isLoading$ = this.loadingBehaviorSubject.asObservable();

  constructor(private httpClient: HttpClient, private organizationService: OrganizationService) { }

  // create the parameters observable that looks for changes in page, startDate, endDate, etc
  public params$ = combineLatest([
    this.pageBehaviorSubject, // add debounce if we need to wait for user input ex: .pipe(debounceTime(300)), 
    this.sortBehaviorSubject,
    this.searchBehaviorSubject.pipe(debounceTime(300)),
    this.reloadBehaviorSubject,
  ]).pipe(
    distinctUntilChanged((previous, current) => {
      // if the values coming down this pipe are the same, don't continue the pipe
      return JSON.stringify(previous) === JSON.stringify(current);
    }),
    map(([page, sort, search, reload]) => {

      // set the query string odata parameters
      let params: HttpParams = new HttpParams({
        fromObject: {
        // mode: viewMode,
        $skip: page.pageIndex * page.pageSize,
        $top: page.pageSize,
        $orderby: `${sort.active} ${sort.direction}`,
        $count: true,
      }
    });

      // if there is a search, add the search to the parameters
      if (search.length) {
        params = params.append('$search', `"${search}"`);
      }

      return params;
    })
  );

  // create the EventTypes observable that calls http get when any of our parameters change
  private eventTypesResponse$ = this.params$.pipe(
    tap(() => this.loadingBehaviorSubject.next(true)), // set isLoading to true
    switchMap((_params) =>
      this.httpClient.get(
        `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/EventTypes`,
        { params: _params })
    ),
    tap(() => this.loadingBehaviorSubject.next(false)), // set isLoading to false
    shareReplay(1) // make sure all subscriptions share the same http call (otherwise there will be a http call for each subscription)
  );

  // eventType listing
  public eventTypes$: Observable<T[]> = this.eventTypesResponse$.pipe(
    map((res: any) => res.value)
  );

  private eventTypesResponseList$ = this.params$.pipe(
    tap(() => this.loadingBehaviorSubject.next(true)), // set isLoading to true
    switchMap((_params) =>
      this.httpClient.get(
        `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/EventTypes/GetAllEventTypes`)
    ),
    tap(() => this.loadingBehaviorSubject.next(false)), // set isLoading to false
    shareReplay(1) // make sure all subscriptions share the same http call (otherwise there will be a http call for each subscription)
  );

  // eventType listing
  public eventTypesList$: Observable<T[]> = this.eventTypesResponseList$.pipe(
    map((res: any) => res.value)
  );

  private eventWorkflowResponseList$ = this.params$.pipe(
    tap(() => this.loadingBehaviorSubject.next(true)), // set isLoading to true
    switchMap((_params) =>
      this.httpClient.get(
        `https://pr1-std-workflow-dev-portal-api-app.azurewebsites.net/Private-Workflow/Standard/V1/MasterTemplate/GetMasterTemplate`)
    ),
    tap(() => this.loadingBehaviorSubject.next(false)), // set isLoading to false
    shareReplay(1) // make sure all subscriptions share the same http call (otherwise there will be a http call for each subscription)
  );

    // eventWorkflow listing
    public eventWorkflow$: Observable<T[]> = this.eventWorkflowResponseList$.pipe(
      map((res: any) => res.value)
    );

  // create the EventTypes observable that calls http get when any of our parameters change
  private allEventTypesResponse$ = this.params$.pipe(
    tap(() => this.loadingBehaviorSubject.next(true)), // set isLoading to true
    switchMap((_params) =>
      this.httpClient.get(`${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/EventTypes`)
    ),
    tap(() => this.loadingBehaviorSubject.next(false)), // set isLoading to false
    shareReplay(1) // make sure all subscriptions share the same http call (otherwise there will be a http call for each subscription)
  );

  // eventType listing
  public allEventTypes$: Observable<T[]> = this.allEventTypesResponse$.pipe(
    map((res: any) => {
      return res.value.sort((a: T, b: T) => a.EventTypeName.localeCompare(b.EventTypeName))
    })
  );

  // total number of eventType records based on filtering
  public totalRecords$: Observable<number> = this.eventTypesResponse$.pipe(
    map((res: any) => res['@odata.count'])
  );

  // set the current page
  page(page: any) {
    this.pageBehaviorSubject.next(page);
  }

  // gets an eventType by id
  getEventType(eventTypeId: string): Observable<T> {
    const url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/EventTypes/${eventTypeId}`
    return this.httpClient.get<T>(url);
  }

  // sets the sort property and order
  sort(sort: any) {
    this.sortBehaviorSubject.next(sort);
  }

  // sets the search phrase
  search(search: string) {
    const page = this.pageBehaviorSubject.value;
    page.pageIndex = 0;
    page.previousPageIndex = 0;
    this.searchBehaviorSubject.next(search);
    this.pageBehaviorSubject.next(page);
  }

  // reloads/refreshes the eventType listing
  reload() {
    // reload the EventType data
    this.reloadBehaviorSubject.next(uuidv4());
  }

  // up-serts EventTypes
  saveEventType(EventType: T) {
    let url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/EventTypes/`;
    if (!EventType.Id || EventType.Id === '0') {
      // create new record
      return this.httpClient.post(url, EventType);
    } else {
      // edit existing record
      url += `${EventType.Id}`;
      return this.httpClient.put(url, EventType);
    }
  }

  // deletes an EventType by id
  deleteEventType(id: string) {
    const url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/EventTypes/${id}`;
    return this.httpClient.delete(url);
  }

  deleteEventTypes(eventTypes: any) {
    const url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/EventTypes/DeleteEventTypes?${this.prepareStoreStringtoDelete(eventTypes)}`;
    return this.httpClient.post(url, eventTypes);
  }

  prepareStoreStringtoDelete(eventTypes: any) {
    let urlParams = '';
    if (eventTypes && eventTypes.length > 0) {
      for (let index = 0; index <= eventTypes.length - 1; index++) {
        urlParams += `eventIds=${eventTypes[index]?.Id}`;
        if (index != eventTypes.length - 1) {
          urlParams += '&'
        }
      }
    }
    return urlParams;
  }

  downloadEventTypesJSON() {
    const url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/EventTypes/EtlExport`;
    window.open(url,'_blank');
  }

  downloadEventTypesCSV() {
    const url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/EventTypes/Export`;
    window.open(url,'_blank');
  }
}
