<ng-container *ngIf="isLoading$ | async">
  <div class="overlay">
    <mat-progress-spinner
      class="spinner"
      color="'accent'"
      mode="indeterminate"
      value="50"
      diameter="50"
    >
    </mat-progress-spinner>
  </div>
</ng-container>
