<form *ngIf="productDomain$ | async as T; else loading" [formGroup]="formGroup" novalidate>
    <mat-card appearance="outlined">
        <mat-card-content fxLayout="column">
            <h2>{{formGroup.controls.Detail.controls.ProductName.value == '' ? "Product" : formGroup.controls.Detail.controls.ProductName.value}}</h2>
            <div formGroupName="Detail" fxLayout="row wrap" fxLayoutGap.gt-sm="16px" fxLayout.lt-md="column">
                <mat-form-field appearance="outline" fxFlex="32">
                    <mat-label>Product Name</mat-label>
                    <input [readonly]="!isAdmin()" matInput formControlName="ProductName" />
                    <mat-error *ngIf="formGroup.controls.Detail.controls.ProductName.status=='INVALID'">
                        {{formGroup.controls.Detail.controls.ProductName.errors!['maxlength'] && 'Maximum length can be' + formGroup.controls.Detail.controls.ProductName.errors!['maxlength']['requiredLength'] + '.'}}
                        {{formGroup.controls.Detail.controls.ProductName.errors!['pattern'] && 'ProductName may only contain alphanumeric and .,;-#&*\'/ characters.'}}
                    </mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline" fxFlex="32">
                    <mat-label>Client Key</mat-label>
                    <input [readonly]="!isAdmin()" matInput formControlName="ClientKey" />
                </mat-form-field>

                <mat-form-field appearance="outline" fxFlex="32" *ngIf="productId != '0'">
                    <mat-label>Id</mat-label>
                    <input [readonly]="!isAdmin()" matInput formControlName="Id" />
                </mat-form-field>
            </div>

            <div fxLayout="row wrap" fxLayoutGap.gt-sm="16px" fxLayout.lt-md="column">
                <mat-form-field appearance="outline" fxFlex="32">
                    <mat-label>Rank</mat-label>
                    <input [readonly]="!isAdmin()" matInput type="number" formControlName="Rank" />
                    <mat-error *ngIf="formGroup.controls.Rank.status=='INVALID'">
                        {{formGroup.controls.Rank.errors!['max'] && 'Maximum number can be '+formGroup.controls.Rank.errors!['max']['max'] + '. '}}
                        {{formGroup.controls.Rank.errors!['min'] && 'Minimum required number can be '+formGroup.controls.Rank.errors!['min']['min'] + '. '}}
                    </mat-error>
                </mat-form-field>
            </div>
        </mat-card-content>

        <mat-card-actions>
            <div fxLayout="row" [fxLayoutAlign]="productId != '0'?'space-between':'end'" class="mat-card-actions-details" [ngClass]="{'flex-row-reverse': isAdmin() == false}">
                <button mat-stroked-button type="reset" (click)="cancel()">
                    Cancel
                </button>
                <button *ngIf="isAdmin()" mat-raised-button type="button" (click)="save() " [disabled]="formGroup.invalid" color="accent">
                    Save
                </button>
            </div>
        </mat-card-actions>
    </mat-card>
</form>

<ng-template #loading>
    <mat-progress-bar mode="indeterminate" color="accent"></mat-progress-bar>
</ng-template>