import { Routes, Route } from '@angular/router';
import { ShellComponent } from '../';
import { PrAuthGuard } from 'src/app/@shared/auth-guard/pr.auth.guard';
/**
 * Provides helper methods to create routes.
 */
export class Shell {

  /**
   * Creates routes using the shell component and authentication.
   * @param routes The routes to add.
   * @return The new route using shell as the base.
   */
  static childRoutes(routes: Routes): Route {
    return {
      path: '',
      component: ShellComponent,
      children: routes,
      canActivate: [PrAuthGuard],
    };
  }

}
