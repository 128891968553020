import { Component, OnInit, NgZone } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProductBrand, ProductBrandService, ProductBrandDomain } from '../..';
import { Observable, of } from 'rxjs';
import { Validators, FormGroup, FormControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { Logger, ApiValidation, ConfirmDialogComponent } from 'src/app/@shared';
import { DEFAULT_SNACKBAR_CONFIG } from 'src/app/@shared/constants/site.constants';
import { DialogData, ErrorDialogService } from 'src/app/@shared/';

const log = new Logger('ProductBrandComponent');

@Component({
  selector: 'app-product-brand',
  templateUrl: './product-brand.component.html',
  styleUrls: ['./product-brand.component.scss']
})

export class ProductBrandComponent<TProductBrand extends ProductBrand> implements OnInit {

  brand$: Observable<ProductBrandDomain<TProductBrand>> = of({} as ProductBrandDomain<TProductBrand>);
  formGroup = new FormGroup({
    ParentProductBrandId: new FormControl<string | null>(null),
    ParentProductBrandName: new FormControl<string | null>(null),
    ChildProductBrandIds: new FormControl<string[]>([]),
    Detail: new FormGroup({
      ClientKey: new FormControl<string | null>('', [Validators.required, Validators.minLength(1), Validators.maxLength(100)]),
      Id: new FormControl<string>({value: '0', disabled: true}, { nonNullable: true }),
      ProductBrandName: new FormControl<string>('', [Validators.required, Validators.minLength(1), Validators.maxLength(100)]),
    })
  });
  brandId: string = '0';
  parentProductBrandId: string = '0';
  selectedHirerarchy: string = '1';
  productBrandName: string = '';
  parentProductBrandName = new FormControl<string | null>({ value: null, disabled: true });


  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private productBrandService: ProductBrandService<TProductBrand>,
    private matSnackBar: MatSnackBar,
    private dialog: MatDialog,
    private errorDialogService: ErrorDialogService,
    private zone: NgZone
  ) { }

  ngOnInit() {
    log.debug('init');

    // get the brand id from the parent route
    this.brandId = this.route.snapshot.params['brandId'];
    this.parentProductBrandId = this.route.snapshot.params['parentProductBrandId'];

    // if we are editing a record, get the record data
    if (this.brandId && this.brandId !== '0') {
      this.productBrandService.getProductBrand(this.brandId).subscribe(
        pBrandDomain => {          
          this.formGroup.patchValue(pBrandDomain.value[0]);
          this.parentProductBrandName.setValue(pBrandDomain.value[0].ParentProductBrandName);
          if (pBrandDomain.value[0].ParentProductBrandName) {
            this.selectedHirerarchy = '2';
          }
          this.formGroup.markAllAsTouched();
        }
      );
    } else if (this.parentProductBrandId && this.parentProductBrandId !== '0') {
      this.brandId = '0';
      this.productBrandService.getProductBrand(this.parentProductBrandId).subscribe(
        brand => {
          this.formGroup.patchValue({
            ParentProductBrandId: brand.value[0].Detail.Id,
            ParentProductBrandName: brand.value[0].Detail.ProductBrandName,
          });
        });
      this.selectedHirerarchy = '2';  // child button selected
    }

  }

  public onValChange(x: string) {
  }

  trimControlValues(formGroup: FormGroup): void {
    Object.keys(formGroup.controls).forEach((key: string) => {
      const abstractControl = formGroup.get(key);
      if (abstractControl instanceof FormGroup) {
        this.trimControlValues(abstractControl);
      } else {
        if (typeof abstractControl?.value == 'string') {
          abstractControl.setValue(abstractControl?.value.trim());
        }
      }
    })
  }
  
  save() {
    this.trimControlValues(this.formGroup);

    if (this.formGroup.valid) {

      this.productBrandService.saveProductBrand(this.formGroup.getRawValue() as ProductBrandDomain<TProductBrand>).subscribe({
        next: (response) => {
          this.matSnackBar.open(
            `${this.formGroup.controls.Detail.controls.ProductBrandName.value} saved`, 'OK', DEFAULT_SNACKBAR_CONFIG
          );
          this.productBrandService.reload();
          if (typeof this.parentProductBrandId != 'undefined' && this.parentProductBrandId) {
            this.router.navigate([`../../../`], { relativeTo: this.route });
          } else {
            this.router.navigate([`../../`], { relativeTo: this.route });
          }

        },
        error: (error) => {
          if (error.status === 500) {
            log.error('500 Error saving event', error);
          }
          if (error.status === 400) {
            const apiValidations: any = error.error;
            if (Array.isArray(apiValidations)) {
              apiValidations.forEach((validation: any) => {
                if (this.formGroup?.get(validation.PropertyName)) {
                  const control = this.formGroup?.get(validation.PropertyName);
                  if (control) {
                    control.markAsTouched();
                    control.setErrors({ invalid: validation.ErrorMessage });
                    this.matSnackBar.open(validation.ErrorMessage, 'Error', { verticalPosition: 'top', panelClass: ['snackbar-error'] });
                  }
                } else {
                  ///TODO: if we have cross field validation then show the validation error at the top of the screen
                  // if we have cross field validation then show the validation error at the top of the screen
                  // push general error messages to array this is displayed in a toast or dialog
                }
              });
            } else {
              this.matSnackBar.open(apiValidations, 'Error', { verticalPosition: 'top', panelClass: ['snackbar-error'] });
            }
          }
        }
      });
    }
  }

  cancel(): void {
    this.formGroup.reset();
    if (this.route.snapshot.params['parentProductBrandId'] != null) {
      this.router.navigate([`../../../`], { relativeTo: this.route });
    }
    else {
      this.router.navigate([`../../`], { relativeTo: this.route });
    }
  }

  deleteRecord() {
    const record = this.formGroup.getRawValue();
    if(record && record.ChildProductBrandIds && record.ChildProductBrandIds.length > 0){
      this.matSnackBar.open(`Cannot delete this brand record because it has children associated with it`, 'OK',  { verticalPosition: 'top', panelClass: ['snackbar-error'] })
      return;
    }
    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Confirm Delete',
        message: `Are you sure you wish to delete ${record.Detail.ProductBrandName}?`,
      },
      disableClose: true,
    });


    confirmDialog.afterClosed().subscribe(
      confirmResult => {
        if (confirmResult) {
          this.productBrandService.deleteProductBrand(record.Detail.Id).subscribe({
            next: () => {
              this.matSnackBar.open(`${record.Detail.ProductBrandName} deleted`, 'OK', DEFAULT_SNACKBAR_CONFIG);
              this.productBrandService.reload();
              this.router.navigate([`../../`], { relativeTo: this.route });
            },
            error: (error) => {
              log.error('Error deleting event', error);

              if (error.error.value) {
                throw new Error(error.error.value);
              } else {
                throw new Error(error.message);
              }
            }
          });
        }
      });
  }

}
