<ng-container *ngIf="viewModel$ | async as viewModel">
    <form [formGroup]="formGroup" (ngSubmit)="onSubmit()" novalidate>
        <h2 mat-dialog-title>Switch Organization</h2>
        <mat-dialog-content>
            <mat-form-field appearance="outline">
                <mat-label>Organization</mat-label>
                <mat-select formControlName="OrganizationId" data-testid="Organization Dropdown Arrow">
                    <mat-option *ngFor="let organization of viewModel.organizations" [value]="organization.id">
                        <span data-testid="Organization Types">{{organization.name}}</span>
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="formGroup.controls.OrganizationId.hasError('required')">
                    Organization is required
                </mat-error>
            </mat-form-field>
        </mat-dialog-content>
        <mat-dialog-actions align="end">
            <button mat-stroked-button [mat-dialog-close]="true" type="button" data-testid="Cancel">Cancel</button>
            <button mat-raised-button type="submit" [disabled]="formGroup.invalid" color="accent" data-testid="Select">Select</button>
            
        </mat-dialog-actions>
    </form>
</ng-container>
