import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Logger } from 'src/app/@shared';

const log = new Logger('EventComponent');

@Component({
  selector: 'app-event',
  templateUrl: './event.component.html',
  styleUrls: ['./event.component.scss']
})

export class EventComponent implements OnInit {

  eventId = '0';

  constructor(private route: ActivatedRoute,) { }

  ngOnInit(): void {
    log.debug('init');
    // get the event id from the route params
    this.route.params.subscribe(params => {
      this.eventId = params['eventId'];
    });
  }

}
