<form *ngIf="shopStats$ | async as shopStats; else loading" [formGroup]="formGroup" novalidate>
    <mat-card appearance="outlined">
        <h2 class="title">{{shopStats.FirstName}} {{shopStats.LastName}}</h2>
        <mat-card-content fxLayout="column">
            <div fxLayout="row">
                <mat-card appearance="outlined" class="statsData">
                    <mat-card-content>
                        <div class="grid-col-span-2 offer-name" fxLayout="row wrap" fxLayoutAlign="start center"
                            fxLayoutGap="45px">
                            <b>Coupons Clipped</b>
                            <b class="count"> {{shopStats.CouponsClipped}}</b>
                        </div>
                    </mat-card-content>
                </mat-card>
                <mat-card appearance="outlined" class="statsData">
                    <mat-card-content>
                        <div class="grid-col-span-2 offer-name" fxLayout="row wrap" fxLayoutAlign="start center"
                            fxLayoutGap="45px">
                            <b>Coupons Redeemed</b>
                            <b class="count"> {{shopStats.CouponsRedeemed}}</b>
                        </div>
                    </mat-card-content>
                </mat-card>
            </div>

            <div class="products">
                <mat-accordion class="OfferDetails" multi>
                    <mat-expansion-panel [expanded]="true">
                        <mat-expansion-panel-header>
                            <mat-panel-title><b>Products Purchased</b></mat-panel-title>
                            <mat-panel-description><b class="count">
                                    {{shopStats.ProductsPurchased}}</b></mat-panel-description>
                        </mat-expansion-panel-header>
                        <table>
                            <tr>
                                <td class="no-records">
                                    <h2>No Records Found</h2>
                                </td>
                            </tr>
                        </table>
                    </mat-expansion-panel>
                </mat-accordion>
            </div>


        </mat-card-content>
    </mat-card>
</form>

<ng-template #loading>
    <mat-progress-bar mode="indeterminate" color="accent"></mat-progress-bar>
</ng-template>
