import { Component, OnInit } from '@angular/core';
import { combineLatest, map } from 'rxjs';
import { OfferPromo } from '../../models/offer-promo.model';
import { PromoDomain } from '../../models/promo-domain.model';
import { OfferPromoService } from '../../services/offerpromo.service';
import { Logger, OrganizationService } from 'src/app/@shared';
import { SelectionModel } from '@angular/cdk/collections';
import { PageEvent } from '@angular/material/paginator';

const log = new Logger('CloneOffersListCardsComponent');

@Component({
  selector: 'app-clone-offers-list-cards',
  templateUrl: './clone-offers-list-cards.component.html',
  styleUrls: ['./clone-offers-list-cards.component.scss'],
})
export class CloneOffersListCardsComponent<
  TOfferPromo extends OfferPromo,
  TPromoDomain extends PromoDomain<OfferPromo>
> implements OnInit {
  assetsUrl: string = '';
  offerPromosList: any = [];
  selection = new SelectionModel<TPromoDomain>(true, []);

  viewModel$ = combineLatest([
    this.offerPromoService.cloneOfferPromos$,
    this.offerPromoService.cloneOffersTotalRecords$,
    this.offerPromoService.page$,
    this.organizationService.assetsUrl$,
  ]).pipe(
    map(([offers, totalRecords, page, assetsUrl]) => {
      this.offerPromosList = offers;
      this.assetsUrl = assetsUrl;
      return { offers, totalRecords, page, assetsUrl };
    })
  );

  constructor(
    private offerPromoService: OfferPromoService<TOfferPromo, TPromoDomain>,
    private organizationService: OrganizationService
  ) {}
  
  ngOnInit(): void {
    log.debug('init');
  }

  public getImageSrc(assets: any) {
    let imagesrc = '';
    if (assets.Type === 'application/pdf') {
      imagesrc = './assets/images/pdf.png';
    } else if (assets.Type.indexOf('audio') > -1) {
      imagesrc = './assets/images/mp3.jpg';
    } else if (assets.Type.indexOf('video') > -1) {
      imagesrc = './assets/images/mp4.png';
    } else if (assets.Type.indexOf('text') > -1) {
      imagesrc = './assets/images/text.png';
    } else {
      imagesrc = this.assetsUrl + '/' + assets.FileName;
    }
    return imagesrc;
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.offerPromosList.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.offerPromosList.forEach((row: any) => this.selection.select(row));
  }

  public getSelectedSectionRecords() {
    return this.selection.selected;
  }

  public clearSelection() {
    this.selection.clear();
  }

  onPage(pageEvent: PageEvent): void {
    this.selection.clear();
    this.offerPromoService.cloneOffersPage(pageEvent);
  }
}
