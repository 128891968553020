import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, catchError, combineLatest, debounceTime, distinctUntilChanged, map, Observable, shareReplay, switchMap, tap, } from 'rxjs';
import { environment } from 'src/environments/environment';
import { OrganizationService } from 'src/app/@shared';
import { v4 as uuidv4 } from 'uuid';
import * as dayjs from 'dayjs';
import { DEFAULT_PAGING } from 'src/app/@shared/constants/site.constants';
import { Filter } from 'src/app/@shared/models/filter.model';
import { OfferAsset } from '..';

const DEFAULT_DATE_RANGE = {
  startDate: dayjs().subtract(1, 'days').format('YYYY-MM-DD'),
  endDate: dayjs().add(5, 'months').format('YYYY-MM-DD'),
};

@Injectable({
  providedIn: 'root'
})

export class ProductAssetsService<T extends OfferAsset> {

  // initialize behavior subjects
  private dateRangeBehaviorSubject = new BehaviorSubject(DEFAULT_DATE_RANGE);
  private pageBehaviorSubject = new BehaviorSubject(DEFAULT_PAGING);
  private loadingBehaviorSubject = new BehaviorSubject<boolean>(false);
  private searchBehaviorSubject = new BehaviorSubject<string>('');
  private sortBehaviorSubject = new BehaviorSubject({ active: 'Rank', direction: 'asc', });
  private reloadBehaviorSubject = new BehaviorSubject<string>('');
  private filterBehaviorSubject = new BehaviorSubject<Filter[]>([]);
  private viewModeBehaviorSubject = new BehaviorSubject<string>('CARDS');
  private productIdBehaviorSubject = new BehaviorSubject<string>('');

  // we do not wish to expose our behavior subjects.  create public observables
  public dateRange$ = this.dateRangeBehaviorSubject.asObservable();
  public page$ = this.pageBehaviorSubject.asObservable();
  public search$ = this.searchBehaviorSubject.asObservable();
  public sort$ = this.sortBehaviorSubject.asObservable();
  public isLoading$ = this.loadingBehaviorSubject.asObservable();
  public filters$ = this.filterBehaviorSubject.asObservable();
  public viewMode$ = this.viewModeBehaviorSubject.asObservable();
  public productId$ = this.productIdBehaviorSubject.asObservable();

  constructor(private httpClient: HttpClient, private organizationService: OrganizationService) { }

  // create the parameters observable that looks for changes in page, startDate, endDate, etc
  public params$ = combineLatest([
    this.productIdBehaviorSubject,
    this.pageBehaviorSubject, // add debounce if we need to wait for user input ex: .pipe(debounceTime(300)), 
    this.sortBehaviorSubject,
    this.searchBehaviorSubject.pipe(debounceTime(300)),
    this.filterBehaviorSubject.pipe(debounceTime(50)),
    this.reloadBehaviorSubject,
  ]).pipe(
    distinctUntilChanged((previous, current) => {
      // if the values coming down this pipe are the same, don't continue the pipe
      return JSON.stringify(previous) === JSON.stringify(current);
    }),
    map(([productId, page, sort, search, reload]) => {

      // set the query string odata parameters
      let params: HttpParams = new HttpParams({
        fromObject: {
        productId: this.productIdBehaviorSubject.value,
        // mode: viewMode,
        $skip: page.pageIndex * page.pageSize,
        $top: page.pageSize,
        $orderby: `${sort.active} ${sort.direction}`,
        $expand: 'Detail',
        $count: true
      }
    });

      // if there is a search, add the search to the parameters
      if (search.length) {
        params = params.append('$search', `"${search}"`);
      }

      return params;
    })
  );

  set productId(productId: string) {
    this.productIdBehaviorSubject.next(productId);
  }

  //get the list of assets by productId
  private productAssetResponse$ = this.params$.pipe(
    tap(() => this.loadingBehaviorSubject.next(true)), // set isLoading to true
    switchMap((_params) =>
      this.httpClient.get(
        `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/AssetDomains/GetAssetsByProductId`,
        { params: _params })
    ),
    tap(() => this.loadingBehaviorSubject.next(false)), // set isLoading to false
    shareReplay(1) // make sure all subscriptions share the same http call (otherwise there will be a http call for each subscription)
  );

  //assets listing by productId
  public assets$: Observable<T[]> = this.productAssetResponse$.pipe(
    map((res: any) => res.value)
  );

  public totalRecords$: Observable<number> = this.productAssetResponse$.pipe(
    map((res: any) => res['@odata.count'])
  );

  associateAssetToProduct(assets: string[]) {
    let url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/ProductDomains/AssociateAssets?productId=${this.productIdBehaviorSubject.value}`;
    return this.httpClient.post(url, assets);
  }

  disassociateAssetToProduct(asset: any) {
    let url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/ProductDomains/DisAssociateAssets?productId=${this.productIdBehaviorSubject.value}`;
    return this.httpClient.post(url, asset);
  }

  saveRank(dataSave: OfferAsset) {
    const url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/AssetDomains/UpdateProductAssetRank`;
    return this.httpClient.post(url, dataSave);
  }

  // set the current page
  page(page: any) {
    this.pageBehaviorSubject.next(page);
  }

  toggleViewMode(mode: string) {
    this.viewModeBehaviorSubject.next(mode);
  }

  // sets the date range of the event listing
  dateRange(start?: string, end?: string) {
    if (start && end) {
      const range = { startDate: start, endDate: end };
      this.pageBehaviorSubject.next(DEFAULT_PAGING);

      this.dateRangeBehaviorSubject.next(range);
    }
  }


  // sets the sort property and order
  sort(sort: any) {
    this.sortBehaviorSubject.next(sort);
  }

  // sets the search phrase
  search(search: string) {
    const page = this.pageBehaviorSubject.value;
    page.pageIndex = 0;
    page.previousPageIndex = 0;
    this.searchBehaviorSubject.next(search);
    this.pageBehaviorSubject.next(page);
  }



  // reloads/refreshes the store listing
  reload() {
    // reload the Store data
    this.reloadBehaviorSubject.next(uuidv4());
  }

  // reset the current page
  resetpage() {
    const page = this.pageBehaviorSubject.value;
    page.pageIndex = 0;
    page.previousPageIndex = 0;
    this.pageBehaviorSubject.next(page);
  }

  getRecordsForFilters() {
    return this.httpClient.get(`${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/AssetDomains`);
  }

}
