 <ng-container *ngIf="viewModel$ | async as viewModel">
    <div class="bg-white">
    <form [formGroup]="filtersForm" novalidate>
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px" fxLayout.lt-md="column"
        fxLayoutAlign.lt-md="start start" fxLayoutGap.lt-md="4px">
        <h2>Add Assets</h2>
        <mat-form-field appearance="outline" class="mat-form-field-no-padding">
            <mat-label>Search Assets</mat-label>
            <mat-icon matPrefix>search</mat-icon>
            <input matInput formControlName="search" type="text" placeholder="Search" (input)="onSearch($event)" #searchInput />
            <button type="button" matSuffix mat-icon-button aria-label="Clear" *ngIf="searchInput.value" (click)="clearSearch()">
                <mat-icon>close</mat-icon>
            </button>
        </mat-form-field>
    </div>
    <div class="grid-container">

    <table mat-table [dataSource]="viewModel.allAssets" matSort (matSortChange)="onSort($event)" matSortDisableClear [matSortStart]="'asc'"  class="mat-elevation-z8">
          <!-- Checkbox Column -->
          <ng-container matColumnDef="select">
            <th class="no-ellipsis" mat-header-cell *matHeaderCellDef>
              <mat-checkbox (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()"
                [indeterminate]="selection.hasValue() && !isAllSelected()">
              </mat-checkbox>
            </th>
            <td class="no-ellipsis" mat-cell *matCellDef="let row">
              <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
                [checked]="selection.isSelected(row)">
              </mat-checkbox>
            </td>
          </ng-container>

        <!-- Id Column -->
        <!-- <ng-container matColumnDef="Id">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Id">
                Id
            </th>
            <td mat-cell *matCellDef="let record">{{ record.Detail.Id }}</td>
        </ng-container> -->

        <!-- AssetName Column -->
        <ng-container matColumnDef="AssetName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Name" [resizeColumn]="true">
                Asset Name
            </th>
            <td mat-cell *matCellDef="let record"> {{ record?.AssetName }}</td>
        </ng-container>

        <!-- Group Column -->
        <ng-container matColumnDef="Group">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Group" [resizeColumn]="true">
                Group
            </th>
            <td mat-cell *matCellDef="let record">{{ record?.AssetGroupName }}</td>
        </ng-container>

        <!-- DateAdded Column -->
        <ng-container matColumnDef="DateAdded">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by DateAdded" [resizeColumn]="true">
                Date Added
            </th>
            <td mat-cell *matCellDef="let record">{{ record?.DateAdded }}</td>
        </ng-container>

        <!-- Type Column -->
        <ng-container matColumnDef="Type">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Type" [resizeColumn]="true">
                Type
            </th>
            <td mat-cell *matCellDef="let record">{{ record?.Type }}</td>
        </ng-container>



         <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>

    <table>
        <tr *ngIf="!viewModel.allAssets.length">
            <td class="no-records" [attr.colspan]="displayedColumns.length">
                <h2>No Records Found</h2>
            </td>
        </tr>
    </table>
    </div>

    <!-- pager -->
    <mat-paginator [length]="viewModel.totalRecords" [pageSize]="viewModel.page.pageSize"
    [pageSizeOptions]="[5, 10, 20, 50, 100,500]" (page)="onPage($event)" [hidden]="!viewModel.allAssets.length"
    showFirstLastButtons [pageIndex]="viewModel.page.pageIndex">
</mat-paginator>


<mat-card-actions fxLayout="row" fxLayoutAlign="end">

    <div>
        <button mat-stroked-button type="button" (click)="closeDialog()">
            Cancel
        </button>
        <button mat-raised-button type="button" color="accent" (click)="saveData()">
            Select
        </button>
    </div>
</mat-card-actions>
</form>
</div>
</ng-container>
