import { Component, Inject, OnInit } from '@angular/core';
import { DialogData, Logger } from 'src/app/@shared';
import {
  Event,
  Division,
  OfferPromoService,
  OfferPromo,
  PromoDomain,
} from 'src/app/modules/standard/v1';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl } from '@angular/forms';
import { CloneType } from '../../models/clone-type.model';

const log = new Logger('CloneComponent');

@Component({
  selector: 'app-clone',
  templateUrl: './clone.component.html',
  styleUrls: ['./clone.component.scss'],
})
export class CloneComponent<
  TOfferPromo extends OfferPromo,
  TPromoDomain extends PromoDomain<OfferPromo>
> implements OnInit
{
  selectedCloneType = new FormControl(CloneType.EVENT);
  CloneType = CloneType;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private offerPromoService: OfferPromoService<TOfferPromo, TPromoDomain>
  ) {
    this.selectedCloneType.valueChanges.subscribe((res) => {
      res && this.offerPromoService.cloneEntity(res);
    });
  }

  ngOnInit(): void {
    log.debug('init');
  }
}
