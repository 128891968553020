import { Status } from "./offer-promo-status";

export interface OfferPromo {
    Name: string;
    Name_ToLower?: string;
    Headline?: string;
    Headline_ToLower?: string;
    BodyCopy?: string;
    BodyCopy_ToLower?: string;
    StartDate?: Date | string;
    Status?: Status;
    EndDate?: Date | string;
    Disclaimer?: string;
    Disclaimer_ToLower?: string;
    ClientComment?: string;
    ClientComment_ToLower?: string;
    TargetURL?: string;
    TargetURL_ToLower?: string;
    AdditionalText?: string[];
    AlternateText?: string;
    ClientKey: string;
    Id: string;
    OfferVariantName?: string;
    IsDeleted?: boolean;
    BadgeAssetId?: string;
    Rank: number;
}