import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { ActivatedRoute } from '@angular/router';
import { map, combineLatest, Observable } from 'rxjs';
import { Logger, OrganizationService } from 'src/app/@shared';
import { OfferProductService, Product, ProductDomain } from '../..';
import { AuthorizationService } from 'src/app/@shared/services/authorization.service';
import { CouponProductService } from '../../services/coupon-product.service';

const log = new Logger('CouponProductsCardsComponent');

@Component({
  selector: 'app-coupon-products-cards',
  templateUrl: './coupon-products-cards.component.html',
  styleUrls: ['./coupon-products-cards.component.scss'],
})

export class CouponProductsCardsComponent<T extends Product> implements OnInit, OnDestroy {
  private assetsUrl: string = '';
  viewModel$ = combineLatest([
    this.couponProductService.products$,
    this.couponProductService.isLoading$,
    this.couponProductService.totalRecords$,
    this.couponProductService.page$,
    this.organizationService.assetsUrl$,
  ]).pipe(
    map(([products, isLoading, totalRecords, page, assetsUrl]) => {
      if (products.length == 0 && page.pageIndex > 0) {
        this.couponProductService.resetpage();
        this.couponProductService.reload();
      }
      this.assetsUrl = assetsUrl;
      return { products, isLoading, totalRecords, page, assetsUrl }
    }),
  );
  productsList: ProductDomain<Product>[] = [];
  selection = new SelectionModel<ProductDomain<Product>>(true, []);
  constructor(private couponProductService: CouponProductService<T>,
    private route: ActivatedRoute, private organizationService: OrganizationService, private authorizationService: AuthorizationService){}

  ngOnInit(): void {
    log.debug('init');
    this.couponProductService.couponId = this.route.snapshot.params['couponId'];
    this.couponProductService.products$.subscribe((data) => {
      this.productsList = data;
    });

  }

  ngOnDestroy() { }

  onSort(sortState: Sort): void {
    this.couponProductService.sort(sortState);
  }

  onPage(pageEvent: PageEvent): void {
    this.selection.clear();
    this.couponProductService.page(pageEvent);
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.productsList.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.productsList.forEach(row => this.selection.select(row));
  }

  public getSelectedSectionRecords() {
    return this.selection.selected;
  }

  public clearSelection() {
    this.selection.clear();
  }

  public getImageSrc(assets: any) {
    let imagesrc = '';
    if (assets.Type === 'application/pdf') {
      imagesrc = './assets/images/pdf.png';
    } else if (assets.Type.indexOf('audio') > -1) {
      imagesrc = './assets/images/mp3.jpg';
    } else if (assets.Type.indexOf('video') > -1) {
      imagesrc = './assets/images/mp4.png';
    } else if (assets.Type.indexOf('text') > -1) {
      imagesrc = './assets/images/text.png';
    }
    else {
      imagesrc = this.assetsUrl + '/' + assets.FileName;
    }
    return imagesrc;
  }


  isAdmin(): boolean {
    const hasAccess = this.authorizationService.checkAccess('admin||offermang');
    return hasAccess;
  }
}
