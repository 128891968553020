import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Sort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest, map, Observable } from 'rxjs';
import { Logger, OrganizationService } from 'src/app/@shared';
import { EventAssetsService, OfferAsset } from 'src/app/modules/standard/v1';

const log = new Logger('OfferAssetCardsComponent');

@Component({
  selector: 'app-event-asset-cards',
  templateUrl: './event-asset-cards.component.html',
  styleUrls: ['./event-asset-cards.component.scss']
})

export class EventAssetCardsComponent implements OnInit {

  private assetsUrl: string = '';
  viewModel$ = combineLatest([
    this.eventAssetService.assets$,
    this.eventAssetService.isLoading$,
    this.eventAssetService.page$,
    this.eventAssetService.eventId$,
    this.organizationService.assetsUrl$,
    this.eventAssetService.totalRecords$
  ]).pipe(
    map(([assets, isLoading, page, eventId, assetsUrl, totalRecords]) => {
      if (assets.length == 0 && page.pageIndex > 0) {
        page.previousPageIndex = 0
        page.pageIndex = 0
        this.eventAssetService.page(page);
        this.eventAssetService.reload();
        }
        this.assetsUrl = assetsUrl;
      return { assets, isLoading, page, eventId, assetsUrl, totalRecords }
    }),
  );

  assetsList: OfferAsset[] = [];
  selection = new SelectionModel<OfferAsset>(true, []);

  constructor(private eventAssetService: EventAssetsService<OfferAsset>,
    private organizationService: OrganizationService,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private router: Router,
    private matSnackBar: MatSnackBar) { }

  ngOnInit(): void {
    log.debug('init');
    // get the event id from the parent route
    this.eventAssetService.eventId = this.route.snapshot.params['eventId'];
  }

  onSort(sortState: Sort): void {
    this.eventAssetService.sort(sortState);
    this.selection.clear();
  }

  onPage(pageEvent: PageEvent): void {
    this.selection.clear();
    this.eventAssetService.page(pageEvent);
  }

  onSearch(event: any) {
    this.eventAssetService.search(event.target.value);
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.assetsList.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.assetsList.forEach(row => this.selection.select(row));
  }

  public getSelectedSectionRecords() {
    return this.selection.selected;
  }

  public clearSelection() {
    this.selection.clear();
  }

  public getImageSrc(assets: any) {
    let imagesrc = '';
    if (assets.Type === 'application/pdf') {
      imagesrc = './assets/images/pdf.png';
    } else if (assets.Type.indexOf('audio') > -1) {
      imagesrc = './assets/images/mp3.jpg';
    } else if (assets.Type.indexOf('video') > -1) {
      imagesrc = './assets/images/mp4.png';
    } else if (assets.Type.indexOf('text') > -1) {
      imagesrc = './assets/images/text.png';
    }
    else {
      imagesrc = this.assetsUrl + '/' + assets.Detail.FileName;
    }
    return imagesrc;
  }

}
