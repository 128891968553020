import { Component, Inject, OnInit } from '@angular/core';
import { combineLatest, distinctUntilChanged, map, Subscription } from 'rxjs';
import { MediaChange, MediaObserver } from '@angular/flex-layout';
import { Logger } from 'src/app/@shared';
import { Sort } from '@angular/material/sort';
import { PageEvent } from '@angular/material/paginator';
import { SelectionModel } from '@angular/cdk/collections';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DEFAULT_SNACKBAR_CONFIG } from 'src/app/@shared/constants/site.constants';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Asset } from '../../models/asset.model';
import { AssetDomain } from '../../models/asset-domain.model';
import { Coupon } from '../../models/coupon.model';
import { AssetsService } from '../../services/assets.service';
import { CouponAsset } from '../../models/coupon-asset.model';
import { CouponAssetsService } from '../../services/coupon-assets.service';
import { CouponService } from '../../services/coupon.service';

const log = new Logger('CouponAddAssetComponent');

interface DialogData {
  couponId: string;
}

@Component({
  selector: 'app-coupon-add-asset',
  templateUrl: './coupon-add-asset.component.html',
  styleUrls: ['./coupon-add-asset.component.scss']
})

export class CouponAddAssetComponent<T extends Asset, TAsset extends AssetDomain, TCoupon extends Coupon,TCouponDomain> implements OnInit {

  viewModel$ = combineLatest([
    this.couponAssetService.withoutCouponAssets$,
    this.assetsService.isLoading$,
    this.couponAssetService.withoutCouponTotalRecords$,
    this.assetsService.page$,
  ]).pipe(
    map(([allAssetsForCoupons, isLoading, totalRecords, page]) => {
      this.assetsList = allAssetsForCoupons;
      return { allAssetsForCoupons, isLoading, totalRecords, page }
    }),
  );
  flexMediaWatcher!: Subscription;
  displayedColumns = ['select', 'AssetName', 'Group', 'DateAdded', 'Type'];
  assetsList: CouponAsset[] = [];
  selection = new SelectionModel<CouponAsset>(true, []);
  assetId: string = '0';
  couponId: string = '0';
  constructor(private mediaObserver: MediaObserver,
    private assetsService: AssetsService<Asset>,
    private couponAssetService: CouponAssetsService<CouponAsset>,
    private route: ActivatedRoute,
    public couponService: CouponService<TCoupon>,
    public dialogRef: MatDialogRef<CouponAddAssetComponent<Asset, AssetDomain, TCoupon, TCouponDomain>>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private matSnackBar: MatSnackBar,) { }

  ngOnInit(): void {
    log.debug('init');
    this.clearSearch();
    this.assetsService.resetpage();
    this.assetsService.reload();
    this.couponId = this.route.snapshot.params['couponId'] || this.data.couponId;
    this.assetId = this.route.snapshot.params['assetId'];
    const getAlias = (MediaChange: MediaChange[]) => {
      return MediaChange[0].mqAlias;
    };

    this.flexMediaWatcher = this.mediaObserver
      .asObservable()
      .pipe(
        distinctUntilChanged(
          (x: MediaChange[], y: MediaChange[]) => getAlias(x) === getAlias(y)
        ))
      .subscribe((change) => {
        if (change.some(x => x.mqAlias === 'xs')) {
          this.displayedColumns = ['select', 'AssetName'];
        }
        else if (change.some(x => x.mqAlias === 'sm')) {
          this.displayedColumns = ['select', 'AssetName', 'Type'];
        }
        else {
          this.displayedColumns = ['select', 'AssetName', 'Group', 'DateAdded', 'Type'];
        }
      });
  }

  filtersForm = new FormGroup({
    search: new FormControl(),
  });


  onSort(sortState: Sort): void {
    this.assetsService.sort(sortState);
    this.selection.clear();
  }

  closeDialog() {
    this.dialogRef.close();
  }

  onSearch(event: any) {
    this.couponAssetService.dialogSearch(event.target.value);
  }

  clearSearch() {
    this.filtersForm.controls.search.setValue('');
    this.couponAssetService.dialogSearch('');
  }


  saveData() {
    if (this.selection && this.selection.selected && this.selection.selected.length > 0) {
      let assets: any = this.selection.selected.map(x => x.Detail.Id);
      this.couponAssetService.associateAssetToCoupon(this.couponId, assets).subscribe({
        next: () => {
          this.matSnackBar.open(`Assets are added`, 'OK', DEFAULT_SNACKBAR_CONFIG);
          this.clearSearch();
          this.couponAssetService.resetpage();
          this.couponAssetService.reload();
          this.dialogRef.close(true);
        },
        error: (error) => {
          log.error('Error adding asset to coupon', error);

          if (error.error.value) {
            throw new Error(error.error.value);
          } else {
            throw new Error(error.message);
          }
        }
      });
    }
  }



  onPage(pageEvent: PageEvent): void {
    this.selection.clear();
    this.couponAssetService.dialogNotCouponAssetPage(pageEvent);
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.assetsList.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.assetsList.forEach(row => this.selection.select(row));
  }

  public getSelectedSectionRecords() {
    return this.selection.selected;
  }

  public clearSelection() {
    this.selection.clear();
  }

}
