<h1>Product Category</h1>

<form *ngIf="categoryDomain$ | async as T; else loading" [formGroup]="formGroup" novalidate>
    <mat-card appearance="outlined">
        <mat-card-content fxLayout="column">
            
            <h2>{{formGroup.controls.Detail.controls.ProductCategoryName.value == '' ? "Product Category" : formGroup.controls.Detail.controls.ProductCategoryName.value}}</h2>
            
            <div fxLayout="row wrap" fxLayoutGap.gt-sm="16px" fxLayout.lt-md="column" formGroupName="Detail">

                <mat-form-field appearance="outline" fxFlex="48">
                    <mat-label>Category Name</mat-label>
                    <input matInput type="text" formControlName="ProductCategoryName" />
                    <mat-error *ngIf="formGroup.controls.Detail.controls.ProductCategoryName.hasError('required')">
                        Category Name is required
                    </mat-error>
                    <mat-error *ngIf="formGroup.controls.Detail.controls.ProductCategoryName.hasError('invalid')">
                        {{formGroup.controls.Detail.controls.ProductCategoryName.getError('invalid')}}
                    </mat-error>
                    <mat-error *ngIf="formGroup.controls.Detail.controls.ProductCategoryName.status=='INVALID'">
                        {{formGroup.controls.Detail.controls.ProductCategoryName.errors!['maxlength'] && 'Maximum length can be' + formGroup.controls.Detail.controls.ProductCategoryName.errors!['maxlength']['requiredLength'] + '.'}}
                    </mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline" fxFlex="48" *ngIf="parentCategory" [formGroup]="formGroup">
                  <mat-label>Parent Category</mat-label>
                <mat-select formControlName="ParentCategory" [(value)]="parentCategory">
                    <mat-option *ngFor="let category of categories$" [value]="category.Id">
                      {{category.ProductCategoryName}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>

                <mat-form-field appearance="outline" fxFlex="48">
                    <mat-label>Client Key</mat-label>
                    <input matInput type="text" formControlName="ClientKey" />
                    <mat-error *ngIf="formGroup.controls.Detail.controls.ClientKey.hasError('required')">
                        Client key is required
                    </mat-error>
                    <mat-error *ngIf="formGroup.controls.Detail.controls.ClientKey.hasError('invalid')">
                        {{formGroup.controls.Detail.controls.ClientKey.getError('invalid')}}
                    </mat-error>
                    <mat-error *ngIf="formGroup.controls.Detail.controls.ClientKey.status=='INVALID'">
                        {{formGroup.controls.Detail.controls.ClientKey.errors!['maxlength'] && 'Maximum length can be' + formGroup.controls.Detail.controls.ClientKey.errors!['maxlength']['requiredLength'] + '.'}}
                    </mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline" fxFlex="48" *ngIf="categoryId != '0'">
                    <mat-label>Id</mat-label>
                    <input matInput formControlName="Id" readonly>
                </mat-form-field>

            </div>
        </mat-card-content>

        <mat-card-actions>
            <div fxLayout="row" [fxLayoutAlign]="categoryId != '0'?'space-between':'end'" class="mat-card-actions-details">
                <button mat-raised-button type="button" color="warn" (click)="deleteRecord()" *ngIf="categoryId != '0'">
                    Delete
                </button>
                <div>
                    <button mat-stroked-button type="reset" (click)="cancel()">
                        Cancel
                    </button>
                    <button mat-raised-button type="button" (click)="save()" [disabled]="formGroup.invalid" color="accent">
                        Save
                    </button>
                </div>
            </div>
        </mat-card-actions>

    </mat-card>
</form>

<ng-template #loading>
    <app-loader class="inline-loader" [isLoading]="true">Loading Event...</app-loader>
</ng-template>
