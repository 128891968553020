<form *ngIf="viewModel$ | async as viewModel; else loading" [formGroup]="formGroup" novalidate>
    <mat-card appearance="outlined" [class.mat-elevation-z0]="true">
        <mat-card-content fxLayout="column">

            <h2>{{formGroup.controls.VersionName.value == '' ? "Version name" : formGroup.controls.VersionName.value}}
                for {{viewModel.event.EventName}}</h2>

            <div fxLayout="row wrap" fxLayoutGap.gt-sm="16px" fxLayout.lt-md="column">

                <mat-form-field appearance="outline" fxFlex="auto">
                    <mat-label>Name</mat-label>
                    <input matInput type="text" formControlName="VersionName" required [matAutocomplete]="auto" data-testid="VersionName" />
                    <mat-autocomplete #auto="matAutocomplete">
                        <mat-option *ngFor="let versionName of filteredVersionNames$ | async" [value]="versionName">
                            {{versionName}}
                        </mat-option>
                    </mat-autocomplete>
                    <mat-error *ngIf="formGroup.controls.VersionName.hasError('required')" data-testid="VersionNameRequiredErrorMessage">
                        Name is required
                    </mat-error>
                    <mat-error *ngIf="formGroup.controls.VersionName.hasError('invalid')" data-testid="VersionNameInvalidErrorMessage">
                        {{formGroup.controls.VersionName.getError('invalid')}}
                    </mat-error>
                    <mat-error *ngIf="formGroup.controls.VersionName.status=='INVALID'" data-testid="VersionNameLengthErrorMessage">
                        {{formGroup.controls.VersionName.errors!['maxlength'] && 'Maximum length can be' + formGroup.controls.VersionName.errors!['maxlength']['requiredLength'] + '.'}}
                    </mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline" *ngIf="formGroup.controls.Id.value !== '0'" fxFlex="auto">
                    <mat-label>Id</mat-label>
                    <input matInput formControlName="Id" readonly data-testid="idField">
                </mat-form-field>

                <mat-form-field appearance="outline" fxFlex="auto" *ngIf="versionId!=='0'">
                    <mat-label>Client Key</mat-label>
                    <input matInput type="text" formControlName="ClientKey" readonly data-testid="ClientKey"/>
                    
                </mat-form-field>
                <mat-form-field appearance="outline" fxFlex="32">
                    <mat-label>Disclaimer</mat-label>
                    <input matInput type="text" formControlName="Disclaimer" data-testid="Disclaimer"/>
                </mat-form-field>

            </div>
        </mat-card-content>

        <mat-card-actions>
            <div fxLayout="row" [fxLayoutAlign]="versionId != '0'?'space-between':'end'" class="mat-card-actions-details">
                <button *ngIf="versionId !== '0'" mat-raised-button type="button" color="warn" (click)="deleteRecord()" data-testid="deleteButton">
                    Delete
                </button>
                <div>
                    <button mat-stroked-button type="reset" (click)="cancel()" data-testid="cancelButton">
                        Cancel
                    </button>
                    <button mat-raised-button type="button" (click)="save() " [disabled]="formGroup.invalid" color="accent" data-testid="saveButton">
                        Save
                    </button>
                </div>
            </div>
        </mat-card-actions>

    </mat-card>
</form>

<ng-template #loading>
    <mat-progress-bar mode="indeterminate" color="accent"></mat-progress-bar>
</ng-template>
