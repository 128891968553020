import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Logger, ThemeService, OrganizationService } from 'src/app/@shared';
import { DEFAULT_SNACKBAR_CONFIG } from 'src/app/@shared/constants/site.constants';
import { AuthService } from '@auth0/auth0-angular';
import { combineLatest, map } from 'rxjs';


const log = new Logger('HomeComponent');

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})

export class HomeComponent implements OnInit {

  viewModel$ = combineLatest([
    this.organizationService.organization$,
    this.authService.user$
  ]).pipe(
    map(([organization, user]) => { 
      return { organization, user } }),
  );

  profileJson: string = "";

  constructor(
    private httpClient: HttpClient,
    private matSnackBar: MatSnackBar,
    private themeService: ThemeService,
    private authService: AuthService,
    private organizationService: OrganizationService
  ) { }

  ngOnInit(): void {
    log.debug('init');
  }

  fireHttpError() {
    this.httpClient
      .get('https://httpstat.us/404?sleep=1000')
      .subscribe((json) => console.log(json));
  }

  fireLocalError() {
    throw new Error('This is an error message from the Home component');
  }

  fireSnackBar() {
    this.matSnackBar.open(
      'this is an example snack bar.  For example, record saved or record updated',
      'OK',
      DEFAULT_SNACKBAR_CONFIG
    );
  }

  toggleTheme() {
    this.themeService.toggleTheme();
  }
}
