
<form *ngIf="brand$ | async as TProductBrand; else loading" [formGroup]="formGroup">
  <mat-card appearance="outlined">
    <mat-card-content fxLayout="column">
      
      <h2>{{formGroup.controls.Detail.controls.ProductBrandName.value == '' ? "Brand" : 'Edit Brand '+ formGroup.controls.Detail.controls.ProductBrandName.value}}</h2>
      
      <div fxLayout="row wrap" fxLayoutGap.gt-sm="16px" fxLayout.lt-md="column" formGroupName="Detail">
        <mat-form-field appearance="outline" fxFlex="48">
          <mat-label>Brand Name</mat-label>
          <input matInput type="text" formControlName="ProductBrandName" required />
          <mat-error *ngIf="formGroup.controls.Detail.controls.ProductBrandName.hasError('required')">
            Brand Name is required
          </mat-error>
          <mat-error *ngIf="formGroup.controls.Detail.controls.ProductBrandName.status=='INVALID'">
            {{formGroup.controls.Detail.controls.ProductBrandName.getError('invalid')}}
            {{formGroup.controls.Detail.controls.ProductBrandName.errors!['maxlength'] && 'Maximum length can be '+formGroup.controls.Detail.controls.ProductBrandName.errors!['maxlength']['requiredLength']+'. '}}
          </mat-error>
        </mat-form-field>

        <!-- <div>
          <mat-button-toggle-group matInput #toggleBtn="matButtonToggleGroup" [value]="selectedHirerarchy"
            (change)="onValChange(toggleBtn.value)" [disabled]="true">
            <mat-button-toggle class="brandHierarchy-height" value="1">Parent Brand</mat-button-toggle>
            <mat-button-toggle class="brandHierarchy-height" value="2">Child Brand</mat-button-toggle>
          </mat-button-toggle-group>
        </div> -->

        <mat-form-field appearance="outline" fxFlex="48" *ngIf="brandId != '0'">
          <mat-label>Parent Brand Name</mat-label>
          <input matInput [formControl]="parentProductBrandName" readonly/>
        </mat-form-field>

        <mat-form-field appearance="outline" fxFlex="48" *ngIf="brandId != '0'">
          <mat-label>Id</mat-label>
          <input matInput formControlName="Id" readonly />
        </mat-form-field>

        <mat-form-field appearance="outline" fxFlex="48">
          <mat-label>Client Key</mat-label>
          <input matInput type="text" formControlName="ClientKey" required />
          <mat-error *ngIf="formGroup.controls.Detail.controls.ClientKey.hasError('required')">
            Client key is required
        </mat-error>
        <mat-error *ngIf="formGroup.controls.Detail.controls.ClientKey.status=='INVALID'">
            {{formGroup.controls.Detail.controls.ClientKey.getError('invalid')}}
            {{formGroup.controls.Detail.controls.ClientKey.errors!['maxlength'] && 'Maximum length can be '+formGroup.controls.Detail.controls.ClientKey.errors!['maxlength']['requiredLength']+'. '}}
        </mat-error>
        </mat-form-field>
      </div>
    </mat-card-content>

    <mat-card-actions>
      <div fxLayout="row" [fxLayoutAlign]="brandId != '0'?'space-between':'end'" class="mat-card-actions-details">
        <button mat-raised-button type="button" color="warn" (click)="deleteRecord()" *ngIf="brandId != '0'">Delete</button>
        <div>
          <button mat-stroked-button type="reset" (click)="cancel()">Cancel</button>
          <button mat-raised-button type="button" (click)="save() " [disabled]="formGroup.invalid"
            color="accent">Save</button>
        </div>
      </div>
    </mat-card-actions>

  </mat-card>
</form>

<ng-template #loading>
  <app-loader class="inline-loader" [isLoading]="true">Loading Brand...</app-loader>
</ng-template>
