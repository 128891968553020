import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { combineLatest, map } from 'rxjs';
import { OrganizationService } from '../..';

@Component({
  selector: 'app-organization-dialog',
  templateUrl: './organization-dialog.component.html',
  styleUrls: ['./organization-dialog.component.scss']
})

export class OrganizationDialogComponent implements OnInit {

  viewModel$ = combineLatest([
    this.organizationService.organizations$,
    this.organizationService.organization$
  ]).pipe(
    map(([organizations, organization]) => {
      this.formGroup.controls.OrganizationId.patchValue(organization?.id);
      this.formGroup.markAllAsTouched();
      return {
        organizations,
        organization
      }
    }),
  );

  formGroup = new FormGroup({
    OrganizationId: new FormControl<string | null | undefined>(null),
  });

  constructor(public matDialogRef: MatDialogRef<OrganizationDialogComponent>, private organizationService: OrganizationService) { }

  ngOnInit(): void {
  }

  onSubmit(): void {
    if (this.formGroup.valid) {
      if (this.formGroup.controls.OrganizationId.value) {
        this.organizationService.setOrganization(this.formGroup.controls.OrganizationId.value);
        // window.location.reload();
      }
      this.matDialogRef.close();
    }
  }

}
