import { Injectable } from '@angular/core';
import { ProductBrandDomain, ProductBrand } from '..';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, combineLatest, debounceTime, distinctUntilChanged, map, Observable, shareReplay, switchMap, tap, } from 'rxjs';
import { environment } from 'src/environments/environment';
import { OrganizationService } from 'src/app/@shared';
import { v4 as uuidv4 } from 'uuid';
import * as dayjs from 'dayjs';
import { DEFAULT_PAGING } from 'src/app/@shared/constants/site.constants';

const DEFAULT_DATE_RANGE = {
  startDate: dayjs().subtract(1, 'days').format('YYYY-MM-DD'),
  endDate: dayjs().add(5, 'months').format('YYYY-MM-DD'),
};

@Injectable({
  providedIn: 'root'
})

export class ProductBrandService<TProductBrand extends ProductBrand> {

  // initialize behavior subjects
  private dateRangeBehaviorSubject = new BehaviorSubject(DEFAULT_DATE_RANGE);
  private pageBehaviorSubject = new BehaviorSubject(DEFAULT_PAGING);
  private loadingBehaviorSubject = new BehaviorSubject<boolean>(false);
  private searchBehaviorSubject = new BehaviorSubject<string>('');
  private sortBehaviorSubject = new BehaviorSubject({ active: 'ProductBrandName', direction: 'asc', });
  private uploadBrandpageBehaviorSubject = new BehaviorSubject(DEFAULT_PAGING);
  private uploadBrandsearchBehaviorSubject = new BehaviorSubject<string>('');
  private uploadBrandsortBehaviorSubject = new BehaviorSubject({ active: 'ProductBrandName', direction: 'asc', });
  private reloadBehaviorSubject = new BehaviorSubject<string>('');

  // we do not wish to expose our behavior subjects.  create public observables
  public dateRange$ = this.dateRangeBehaviorSubject.asObservable();
  public page$ = this.pageBehaviorSubject.asObservable();
  public search$ = this.searchBehaviorSubject.asObservable();
  public sort$ = this.sortBehaviorSubject.asObservable();
  public uploadBrandpage$ = this.uploadBrandpageBehaviorSubject.asObservable();
  public uploadBrandsearch$ = this.uploadBrandsearchBehaviorSubject.asObservable();
  public uploadBrandsort$ = this.uploadBrandsortBehaviorSubject.asObservable();
  public isLoading$ = this.loadingBehaviorSubject.asObservable();

  constructor(private httpClient: HttpClient, private organizationService: OrganizationService) { }

  // create the parameters observable that looks for changes in page, startDate, endDate, etc
  public params$ = combineLatest([
    this.pageBehaviorSubject, // add debounce if we need to wait for user input ex: .pipe(debounceTime(300)), 
    this.sortBehaviorSubject,
    this.searchBehaviorSubject.pipe(debounceTime(300)),
    this.reloadBehaviorSubject,
  ]).pipe(
    distinctUntilChanged((previous, current) => {
      // if the values coming down this pipe are the same, don't continue the pipe
      return JSON.stringify(previous) === JSON.stringify(current);
    }),
    map(([page, sort, search, reload]) => {

      // set the query string odata parameters
      let params: HttpParams = new HttpParams({
        fromObject: {
        // mode: viewMode,
        $expand: 'Detail',
        $skip: page.pageIndex * page.pageSize,
        $top: page.pageSize,
        $orderby: `Detail/${sort.active} ${sort.direction}`,
        $count: true,
      }
    });

      // if there is a search, add the search to the parameters
      if (search.length) {
        params = params.append('$search', `"${search}"`);
      }

      return params;
    })
  );

  public uploaBrandParams$ = combineLatest([
    this.uploadBrandpageBehaviorSubject, // add debounce if we need to wait for user input ex: .pipe(debounceTime(300)), 
    this.uploadBrandsortBehaviorSubject,
    this.uploadBrandsearchBehaviorSubject.pipe(debounceTime(300)),
    this.reloadBehaviorSubject,
  ]).pipe(
    distinctUntilChanged((previous, current) => {
      // if the values coming down this pipe are the same, don't continue the pipe
      return JSON.stringify(previous) === JSON.stringify(current);
    }),
    map(([page, sort, search, reload]) => {

      // set the query string odata parameters
      let params: HttpParams = new HttpParams({
        fromObject: {
        // mode: viewMode,
        //$expand: 'Detail',
        $skip: page.pageIndex * page.pageSize,
        $top: page.pageSize,
        $orderby: `${sort.active} ${sort.direction}`,
        $count: true,
      }
    });

      // if there is a search, add the search to the parameters
      if (search.length) {
        params = params.append('$search', `"${search}"`);
      }

      return params;
    })
  );

  // create the productBrands observable that calls http get when any of our parameters change
  private productBrandsResponse$ = this.params$.pipe(
    tap(() => this.loadingBehaviorSubject.next(true)), // set isLoading to true
    switchMap((_params) =>
      this.httpClient.get(
        `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/ProductBrandDomains`,
        { params: _params })
    ),
    tap(() => this.loadingBehaviorSubject.next(false)), // set isLoading to false
    shareReplay(1) // make sure all subscriptions share the same http call (otherwise there will be a http call for each subscription)
  );

  // create the productBrands observable that calls http get when any of our parameters change
  private uploadproductBrandsResponse$ = this.uploaBrandParams$.pipe(
    tap(() => this.loadingBehaviorSubject.next(true)), // set isLoading to true
    switchMap((_params) =>
      this.httpClient.get(
        `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/ProductBrandDomains`,
        { params: _params })
    ),
    tap(() => this.loadingBehaviorSubject.next(false)), // set isLoading to false
    shareReplay(1) // make sure all subscriptions share the same http call (otherwise there will be a http call for each subscription)
  );

  // ProductBrand listing
  public productBrands$: Observable<ProductBrandDomain<TProductBrand>[]> = this.productBrandsResponse$.pipe(
    map((res: any) => res.value)
  );

   // ProductBrand listing
   public uploadproductBrands$: Observable<ProductBrandDomain<TProductBrand>[]> = this.uploadproductBrandsResponse$.pipe(
    map((res: any) => res.value)
  );


  // create the productBrands observable that calls http get when any of our parameters change
  private allProductBrandsResponse$ = this.uploaBrandParams$.pipe(
    tap(() => this.loadingBehaviorSubject.next(true)), // set isLoading to true
    switchMap((_params) =>
      this.httpClient.get(`${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/ProductBrands`,
        { params: _params })
    ),
    tap(() => this.loadingBehaviorSubject.next(false)), // set isLoading to false
    shareReplay(1) // make sure all subscriptions share the same http call (otherwise there will be a http call for each subscription)
  );

   // total number of ProductBrand records based on filtering
   public uploadBrandtotalRecords$: Observable<number> = this.allProductBrandsResponse$.pipe(
    map((res: any) => res['@odata.count'])
  );

  public allProductBrands$: Observable<TProductBrand[]> = this.allProductBrandsResponse$.pipe(
    map((res: any) => res.value)
  );

  // total number of ProductBrand records based on filtering
  public totalRecords$: Observable<number> = this.productBrandsResponse$.pipe(
    map((res: any) => res['@odata.count'])
  );

  // set the current page
  page(page: any) {
    this.pageBehaviorSubject.next(page);
  }

  // set the current page
  uploadBrandpage(page: any) {
    this.uploadBrandpageBehaviorSubject.next(page);
  }

  getLeafBrands(): Observable<TProductBrand[]> {
    const url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/ProductBrandDomains/GetLeafProductBrands`;
    return this.httpClient.get<TProductBrand[]>(url);
  }

  // gets an brand by id
  getProductBrand(brandId: string): Observable<any> {
    const url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/ProductBrandDomains/Get?$expand=Detail&key=${brandId}`;
    return this.httpClient.get(url);
  }

  // get child the brand listing
  getChildNodes(parentId: string): Observable<any> {
    const url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/ProductBrandDomains/GetByParentBrandId?$expand=Detail&parentBrandId=${parentId}`;
    return this.httpClient.get(url);
  }

  // sets the sort property and order
  sort(sort: any) {
    this.sortBehaviorSubject.next(sort);
  }

  // sets the sort property and order
  uploadBrandsort(sort: any) {
    this.uploadBrandsortBehaviorSubject.next(sort);
  }

  // sets the search phrase
  search(search: string) {
    const page = this.pageBehaviorSubject.value;
    page.pageIndex = 0;
    page.previousPageIndex = 0;
    this.searchBehaviorSubject.next(search);
    this.pageBehaviorSubject.next(page);
  }

  uploadBrandsearch(search: string) {
    const page = this.uploadBrandpageBehaviorSubject.value;
    page.pageIndex = 0;
    page.previousPageIndex = 0;
    this.uploadBrandsearchBehaviorSubject.next(search);
    this.uploadBrandpageBehaviorSubject.next(page);
  }

  // reloads/refreshes the brand listing
  reload() {
    // reload the ProductBrand data
    this.reloadBehaviorSubject.next(uuidv4());
  }

  // up-serts ProductBrands
  saveProductBrand(ProductBrand: ProductBrandDomain<TProductBrand>) {
    let url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/ProductBrandDomains/`;

    if (ProductBrand.Detail.Id === '0')
      ProductBrand.Detail.Id = "";

    //  let prodbrand = { Detail: { Id: ProductBrand.Id, ClientKey: ProductBrand.ClientKey, ProductBrandName: ProductBrand.ProductBrandName }, ParentProductBrandId: ProductBrand.ParentProductBrandId ? ProductBrand.ParentProductBrandId : "" };
    if (!ProductBrand.Detail.Id) {
      // create new record
      //   let parentProductBrandId = ProductBrand.ParentProductBrandId ? ProductBrand.ParentProductBrandId:"";
      //   url += `CreateProductBrandAndAssociateEdge?parentProductBrandId=${parentProductBrandId}`;
      return this.httpClient.post(url, ProductBrand);
    } else {
      // edit existing record
      // let prodbrand1 = { Id: ProductBrand.Id, ClientKey: ProductBrand.ClientKey, ProductBrandName: ProductBrand.ProductBrandName };
      url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/ProductBrandDomains/${ProductBrand.Detail.Id}`;
      return this.httpClient.put(url, ProductBrand);
    }
  }

  // deletes an ProductBrand by id
  deleteProductBrand(id: string) {
    const url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/ProductBrands/${id}`;
    return this.httpClient.delete(url);
  }

  downloadProductBrandsJSON() {
    const url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/ProductBrands/EtlExport`;
    window.open(url, '_blank');
  }

  downloadProductBrandsCSV() {
    const url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/ProductBrands/Export`;
    window.open(url, '_blank');
  }
}
