import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, of, tap } from 'rxjs';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { Logger, ConfirmDialogComponent } from 'src/app/@shared';
import { Store, StoreService } from '../..';
import { DEFAULT_SNACKBAR_CONFIG } from 'src/app/@shared/constants/site.constants';
import { BannersService } from '../../services/banners.service';
import { Banner } from '../../models/banner.model';
import { StoreDomain } from '../../models/store-domain.model';

const log = new Logger('StoreComponent');

@Component({
  selector: 'app-store',
  templateUrl: './store.component.html',
  styleUrls: ['./store.component.scss']
})

export class StoreComponent<T extends Store, TStoreDomain extends StoreDomain<Store>> implements OnInit {
  store$: Observable<StoreDomain<T>> = of({} as StoreDomain<T>);
  banners$: any[] = [] as any[];
  formGroup = new FormGroup({
    Detail: new FormGroup({
    ClientKey: new FormControl<string>('', [Validators.required, Validators.minLength(1), Validators.maxLength(100),]),
    Id: new FormControl({ value: '', disabled: true }, { nonNullable: true }),
    StoreName: new FormControl<string>('', [Validators.required, Validators.minLength(1), Validators.maxLength(100),]),
    Address1: new FormControl<string>('', [Validators.minLength(1), Validators.maxLength(255),]),
    Address2: new FormControl<string>('', [Validators.minLength(1), Validators.maxLength(255),]),
    BannerId : new FormControl<string>(''),
    City: new FormControl<string>('', [Validators.minLength(1), Validators.maxLength(100), Validators.pattern("[a-zA-Z0-9 ]*")]),
    State: new FormControl<string>('', [Validators.minLength(1), Validators.maxLength(25), Validators.pattern("[a-zA-Z0-9 ]*")]),
    PostalCode: new FormControl<string>('', [Validators.minLength(1), Validators.maxLength(100), Validators.pattern("[a-zA-Z0-9]*")]),
    }),
  });
  storeId: string = '0';

  constructor(private router: Router,
    private route: ActivatedRoute,
    private storeService: StoreService<T, TStoreDomain>,
    private matSnackBar: MatSnackBar,
    private bannersService: BannersService<Banner>,
    private dialog: MatDialog) { }

  ngOnInit(): void {
    log.debug('init');
    // get the store id from the parent route
    this.storeId = this.route.snapshot.params['storeId'];
    this.storeService.getBanners().subscribe({
      next: (data: any) => {
        this.banners$ = data.value;
      }
    });

    // if we are editing a record, get the record data
    if (this.storeId && this.storeId !== '0') {
      this.store$ = this.storeService.getStore(this.storeId).pipe(
        tap((store) => {
          this.formGroup.patchValue(store);
          this.formGroup.controls.Detail.controls.BannerId.patchValue(store.BannerId as string);
          this.formGroup.markAllAsTouched();
        })
      );
    }
  }

  trimControlValues(formGroup: FormGroup): void {
    Object.keys(formGroup.controls).forEach((key: string) => {
      const abstractControl = formGroup.get(key);
      if (abstractControl instanceof FormGroup) {
        this.trimControlValues(abstractControl);
      } else {
        if (typeof abstractControl?.value == 'string') {
          abstractControl.setValue(abstractControl?.value.trim());
        }
      }
    })
  }
  
  save() {
    this.trimControlValues(this.formGroup);
    if (this.formGroup.valid) {
      let request = this.formGroup.getRawValue() as StoreDomain<Store>;
      request.BannerId = this.formGroup.controls.Detail.controls.BannerId.value as string;
      delete request.Detail.BannerId;
      this.storeService.saveStore(request).subscribe({
        next: (response) => {
          this.matSnackBar.open(
            `${this.formGroup.controls.Detail.controls.StoreName.value} saved`, 'OK', DEFAULT_SNACKBAR_CONFIG
          );
          this.storeService.reload();
          this.router.navigate([`../`], { relativeTo: this.route, queryParamsHandling: 'preserve' });
        },
        error: (error) => {
          if (error.status === 500) {
            log.error('500 Error saving event', error);
          }
          if (error.status === 400) {
            const apiValidations: any = error.error;
            if (Array.isArray(apiValidations)) {
              apiValidations.forEach((validation: any) => {
                if (this.formGroup?.get(validation.PropertyName)) {
                  const control = this.formGroup?.get(validation.PropertyName);
                  if (control) {
                    control.markAsTouched();
                    control.setErrors({ invalid: validation.ErrorMessage });
                    this.matSnackBar.open(validation.ErrorMessage, 'OK', { verticalPosition: 'top', panelClass: ['snackbar-error'] });
                  }
                } else {
                  ///TODO: if we have cross field validation then show the validation error at the top of the screen
                  // if we have cross field validation then show the validation error at the top of the screen
                  // push general error messages to array this is displayed in a toast or dialog
                }
              });
            } else {
              this.matSnackBar.open(apiValidations, 'Error', { verticalPosition: 'top', panelClass: ['snackbar-error'] });
            }


          }
        }
      });
    }
  }

  cancel(): void {
    this.formGroup.reset();
    this.router.navigate([`../`], { relativeTo: this.route, queryParamsHandling: 'preserve' });
  }

  deleteRecord() {
    const record = this.formGroup.getRawValue();
    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Confirm Delete',
        message: `Are you sure you wish to delete ${record.Detail.StoreName}?`,
      },
      disableClose: true,
    });

    confirmDialog.afterClosed().subscribe(
      confirmResult => {
        if (confirmResult) {
          this.storeService.deleteStore(record.Detail.Id).subscribe({
            next: () => {
              this.matSnackBar.open(`${record.Detail.StoreName} deleted`, 'OK', DEFAULT_SNACKBAR_CONFIG);
              this.storeService.reload();
              this.router.navigate([`../`], { relativeTo: this.route, queryParamsHandling: 'preserve' });
            },
            error: (error) => {
              log.error('Error deleting event', error);

              if (error.error.value) {
                throw new Error(error.error.value);
              } else {
                throw new Error(error.message);
              }
            }
          });
        }
      });
  }

}
