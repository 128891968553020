<!-- page title -->
<h1>Shopper Management</h1>

<nav mat-tab-nav-bar [tabPanel]="tabPanel">
  <a mat-tab-link [routerLink]="['details']" routerLinkActive #details="routerLinkActive"
    [active]="details.isActive" data-testid="Details">Details
  </a>
  <a mat-tab-link [disabled]="shopperId =='0'" [routerLink]="['shoppingStats']" routerLinkActive #shoppingStats="routerLinkActive"
    [active]="shoppingStats.isActive">Shopping Stats
  </a>
</nav>
<mat-tab-nav-panel #tabPanel>
  <router-outlet></router-outlet>
</mat-tab-nav-panel>
