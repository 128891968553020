<mat-dialog-content class="mat-typography">
  <h2 style="padding-top: 0.8rem">New Group</h2>
  <div *ngIf="addGroupForm" [formGroup]="addGroupForm!">
    <div
      fxLayout="row wrap"
      fxLayoutGap.gt-sm="16px"
      fxLayout.lt-md="column"
      fxFlexFill
      class="group-dialog-form-row"
    >
      <mat-form-field appearance="outline" fxFlex="46">
        <mat-label>Group Name</mat-label>
        <input
          (input)="groupExists($event)"
          matInput
          required
          formControlName="group_name"
          name="group_name"
        />
        <br />
        <small *ngIf="groupNameExists == true"
          >Group already exists. Please choose a different
          name.</small
        >
      </mat-form-field>

      <div style="flex-direction: column !important" fxFlex="46">
        <mat-form-field appearance="outline" fxFlex="30">
          <mat-label>Description</mat-label>
          <input
            matInput
            required
            formControlName="description"
            name="description"
          />
          <br />
        </mat-form-field>
      </div>
    </div>

    <div
      fxLayout="row wrap"
      fxLayoutGap.gt-sm="16px"
      fxLayout.lt-md="column"
      fxFlexFill
      class="group-dialog-form-row"
    >
      <mat-form-field appearance="outline" fxFlex="46">
        <mat-label>Channel</mat-label>
        <mat-select name="channel" formControlName="channel">
          <mat-option *ngFor="let c of subscribedChannels" [value]="c.Id">
            <span>{{ c.ChannelName }}</span>
          </mat-option>
        </mat-select>
        <br />
      </mat-form-field>

      <div style="flex-direction: column !important" fxFlex="46">
        <mat-form-field appearance="outline">
          <mat-label>Status</mat-label>

          <mat-select name="status" formControlName="status">
            <mat-option *ngFor="let g of groupStatus" [value]="g">
              <span>{{ g }}</span>
            </mat-option>
          </mat-select>
          <br />
        </mat-form-field>
      </div>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions [align]="'end'">
  <button mat-button (click)="dialogRef.close()">Cancel</button>
  <button
    [disabled]="groupNameExists == true"
    mat-raised-button
    color="accent"
    cdkFocusInitial
    (click)="onSave()"
  >
    Save
  </button>
</mat-dialog-actions>
