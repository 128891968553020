import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { combineLatest, distinctUntilChanged, map, Subscription } from 'rxjs';
import { MediaChange, MediaObserver } from '@angular/flex-layout';
import { Logger, OrganizationService } from 'src/app/@shared';
import { Sort } from '@angular/material/sort';
import { PageEvent } from '@angular/material/paginator';
import { OfferPromo, PromoDomain, OfferPromoService } from '../..';
import { SelectionModel } from '@angular/cdk/collections';
import { Status } from '../../models/offer-promo-status';
import { AuthorizeControlService } from 'pr1-ui-components';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { EventWorkflowService } from '../../services/event-workflow.service';

const log = new Logger('OfferListTableComponent');

@Component({
  selector: 'app-offer-list-table',
  templateUrl: './offer-list-table.component.html',
  styleUrls: ['./offer-list-table.component.scss'],
})
export class OfferListTableComponent<
  TOfferPromo extends OfferPromo,
  TPromoDomain extends PromoDomain<OfferPromo>
> implements OnInit, AfterViewInit
{
  private assetsUrl: string = '';
  form = new FormGroup({
    versions: new FormArray([]),
  });
  viewModel$ = combineLatest([
    this.offerpromoService.getoffersPromos$,
    this.offerpromoService.isLoading$,
    this.offerpromoService.totalRecords$,
    this.offerpromoService.page$,
    this.organizationService.assetsUrl$,
  ]).pipe(
    map(([offers, isLoading, totalRecords, page, assetsUrl]) => {
      if (offers.length == 0 && page.pageIndex > 0) {
        page.previousPageIndex = 0;
        page.pageIndex = 0;
        this.offerpromoService.page(page);
        this.offerpromoService.reload();
      }
      this.form = new FormGroup({
        versions: new FormArray([]),
      });
      offers.map((x) => this.addVersion(x));
      this.assetsUrl = assetsUrl;
      return { offers, isLoading, totalRecords, page, assetsUrl };
    })
  );
  flexMediaWatcher!: Subscription;
  displayedColumns = [
    'select',
    'Image',
    'Name',
    'Headline',
    'BodyCopy',
    'EventType',
    'Status',
    'Tags',
    'VersionCodes',
    'Version',
    'Rank',
    'Versions',
    'Actions',
  ];
  offerpromosList: TPromoDomain[] = [];
  selection = new SelectionModel<TPromoDomain>(true, []);
  versionsList: any[] = [];
  readOnlyMode = false;

  @Input() set readOnly(val: boolean) {
    this.readOnlyMode = val;
    this.cdRef.detectChanges();
    this.offerpromoService.setOfferDetailsReadOnly(val);
  }

  constructor(
    private offerpromoService: OfferPromoService<TOfferPromo, TPromoDomain>,
    private organizationService: OrganizationService,
    private mediaObserver: MediaObserver,
    private authorizationService: AuthorizeControlService,
    private ewService: EventWorkflowService,
    private cdRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    log.debug('init');
    this.offerpromoService.reload();
    this.offerpromoService.getoffersPromos$.subscribe((data) => {
      this.offerpromosList = data;
    });

    // detect changes in viewport size to handle show/hide of table columns
    const getAlias = (MediaChange: MediaChange[]) => {
      return MediaChange[0].mqAlias;
    };

    this.flexMediaWatcher = this.mediaObserver
      .asObservable()
      .pipe(
        distinctUntilChanged(
          (x: MediaChange[], y: MediaChange[]) => getAlias(x) === getAlias(y)
        )
      )
      .subscribe((change) => {
        if (change.some((x) => x.mqAlias === 'xs')) {
          this.displayedColumns = ['select', 'Name', 'Actions'];
        } else if (change.some((x) => x.mqAlias === 'sm')) {
          this.displayedColumns = ['select', 'Name', 'Headline', 'Actions'];
        } else {
          this.displayedColumns = [
            'select',
            'Image',
            'Name',
            'Headline',
            'BodyCopy',
            'EventType',
            'Status',
            'Tags',
            'VersionCodes',
            'Version',
            'Rank',
            'Versions',
            'Actions',
          ];
        }
      });
  }

  ngAfterViewInit() {
    if (this.ewService.readOnlyMode != null) {
      this.readOnlyMode = this.ewService.readOnlyMode;
      this.cdRef.detectChanges();
    }
  }

  updateStatus(status: Status) {
    let selectedRecordsDetails: any;
    selectedRecordsDetails = JSON.parse(
      JSON.stringify(this.getSelectedSectionRecords())
    );
    this.updateRecordsStatus(selectedRecordsDetails, status);
  }

  updateTableStatus(status: Status, row: any) {
    let selectedRecordsDetails: any;
    selectedRecordsDetails = [JSON.parse(JSON.stringify(row))];
    this.updateRecordsStatus(selectedRecordsDetails, status);
  }

  updateRecordsStatus(selectedRecordsDetails: Array<any>, status: any) {
    if (selectedRecordsDetails && selectedRecordsDetails.length > 0) {
      selectedRecordsDetails = selectedRecordsDetails.map((x: any) => {
        x.Status = status;
        return x;
      });
      this.offerpromoService.updateStatus(selectedRecordsDetails).subscribe({
        next: () => {
          this.offerpromoService.reload();
          this.clearSelection();
          this.offerpromoService.updateRecordStatus(new Date());
        },
      });
    }
  }

  onSort(sortState: Sort): void {
    this.offerpromoService.sort(sortState);
    this.selection.clear();
  }

  onPage(pageEvent: PageEvent): void {
    this.selection.clear();
    this.offerpromoService.page(pageEvent);
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.offerpromosList.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.offerpromosList.forEach((row) => this.selection.select(row));
  }

  public getSelectedSectionRecords() {
    return this.selection.selected;
  }

  public clearSelection() {
    this.selection.clear();
  }

  public getImageSrc(assets: any) {
    let imagesrc = '';
    if (assets.Type === 'application/pdf') {
      imagesrc = './assets/images/pdf.png';
    } else if (assets.Type.indexOf('audio') > -1) {
      imagesrc = './assets/images/mp3.jpg';
    } else if (assets.Type.indexOf('video') > -1) {
      imagesrc = './assets/images/mp4.png';
    } else if (assets.Type.indexOf('text') > -1) {
      imagesrc = './assets/images/text.png';
    } else {
      imagesrc = this.assetsUrl + '/' + assets.FileName;
    }
    return imagesrc;
  }

  canEdit(): boolean {
    const hasAccess =
      this.authorizationService.checkAccess('edit||offermang') &&
      !this.readOnlyMode;
    return hasAccess;
  }

  getSelectedVersionDetail(event: any, offer: any) {
    offer.activeItem = false;
    offer.OfferVariants = offer.OfferVariants.map((x: any) => {
      x.activeItem = false;
      return x;
    });
    if (event.value && offer) {
      if (event.value.Id === offer.DomainId) {
        offer.activeItem = true;
        return;
      }
      const offerVersion = offer.OfferVariants.find(
        (x: any) => x.Id === event.value.Id
      );
      if (offerVersion) {
        offerVersion.activeItem = true;
      }
    }
  }

  getActiveOffer(offer: any) {
    if (offer.activeItem) {
      return offer.OfferVariants[0];
    }
    const versionOffer = offer.OfferVariants.find(
      (x: any) => x.activeItem === true
    );
    if (!versionOffer) {
      return offer.OfferVariants[0];
    }
    return versionOffer;
  }

  get versions(): FormArray {
    return this.form.get('versions') as FormArray;
  }
  addVersion(offer: any) {
    if (this.versions) {
      this.versions.push(new FormControl(offer.OfferVariants[0]));
    }
  }
}
