<!-- page title -->

<div class="offerMainDiv">
    <div class="headerWidth">
      <h1 class="headerMargin">Coupon Management</h1>
    </div>
  </div>
  
  
  
  <nav mat-tab-nav-bar [tabPanel]="tabPanel">
    <a mat-tab-link [routerLink]="['details']" routerLinkActive #details="routerLinkActive"
      [active]="details.isActive" (click)="setCurrentActiveItem('details')">Details
    </a>
    <a mat-tab-link [routerLink]="['products']" routerLinkActive #products="routerLinkActive"
      [active]="products.isActive" (click)="setCurrentActiveItem('products')">Products
    </a>
    <a mat-tab-link [routerLink]="['assets']" routerLinkActive #assets="routerLinkActive"
      [active]="assets.isActive" (click)="setCurrentActiveItem('assets')">Assets
    </a>
  </nav>
  
  <mat-tab-nav-panel #tabPanel>
    <router-outlet></router-outlet>
  </mat-tab-nav-panel>