import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, of, tap } from 'rxjs';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { Logger, ConfirmDialogComponent, OrganizationService } from 'src/app/@shared';
import { DEFAULT_SNACKBAR_CONFIG } from 'src/app/@shared/constants/site.constants';
import { User, UserService } from '../..';

const log = new Logger('UserComponent');

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})

export class UserComponent<T extends User> implements OnInit {
  get associated_orgs() {
    return this.formGroup.get('associated_orgs') as FormArray<FormGroup>;
  }
  private auth0Orgs = [];
  user$: Observable<T> = of({} as T);
  formGroup = new FormGroup({
    Detail: new FormGroup({
      ClientKey: new FormControl<string>(''),
      Id: new FormControl({ value: '0', disabled: true }, { nonNullable: true }),
      FirstName: new FormControl<string>('', [Validators.required, Validators.minLength(1), Validators.maxLength(100),]),
      LastName: new FormControl<string>('', [Validators.required, Validators.minLength(1), Validators.maxLength(255),]),
      AutheticatedUserId: new FormControl<string>(''),
      Email: new FormControl<string>('', [
        Validators.required,
        Validators.email,
        Validators.minLength(1),
        Validators.maxLength(250),
      ]),
      PhoneNumber: new FormControl<string>('', [
        Validators.minLength(1),
        Validators.maxLength(50),
      ])
    }),
    associated_orgs: new FormArray([] as any),
  });
  userId: string = '0';

  constructor(private router: Router,
    private route: ActivatedRoute,
    private userService: UserService<T>,
    private matSnackBar: MatSnackBar,
    private ogService: OrganizationService,
    private dialog: MatDialog) { }

  ngOnInit(): void {
    log.debug('init');
    // get the user id from the parent route
    this.userId = this.route.snapshot.params['userId'];
    this.getAuth0Orgs();
    // if we are editing a record, get the record data
    if (this.userId && this.userId !== '0') {
      this.user$ = this.userService.getUser(this.userId).pipe(
        tap((user) => {
          this.formGroup.patchValue(user as any);
          this.formGroup.controls.associated_orgs.push(this.orgFormGroup(user));
          this.formGroup.markAllAsTouched();
        })
      );
    } else {
      this.formGroup.controls.associated_orgs.push(this.orgFormGroup(null));
    }
  }

  trimControlValues(formGroup: FormGroup): void {
    Object.keys(formGroup.controls).forEach((key: string) => {
      const abstractControl = formGroup.get(key);
      if (abstractControl instanceof FormGroup) {
        this.trimControlValues(abstractControl);
      } else {
        if (typeof abstractControl?.value == 'string') {
          abstractControl.setValue(abstractControl?.value.trim());
        }
      }
    })
  }


  orgFormGroup(orgInfo: any) {
    return new FormGroup({
      organization: new FormControl(orgInfo ? this.ogService.organization : null),
      divisions: new FormControl(orgInfo ? orgInfo.Divisions : []),
      user_role: new FormControl(orgInfo ? orgInfo.Detail.Roles : null, [Validators.required]),
      user_status: new FormControl(orgInfo ? orgInfo.Detail.Active : true, [Validators.required]),
      channels: new FormControl(orgInfo ? orgInfo.ChannelIds : null),
      event_types: new FormControl(orgInfo ? orgInfo.EventTypeIds : null),
      product_categories: new FormControl(orgInfo ? orgInfo.ProductCategories : []),
    });
  }

  getUserDataFromUserForm() {
    const userDomains = [];
    const currentValue = this.formGroup?.value as any;
    if (currentValue.associated_orgs && currentValue.associated_orgs.length > 0) {
      for (let index = 0; index <= currentValue.associated_orgs.length - 1; index++) {
        const datatoReturn = {
          Detail: {
            Id: this.userId,
            Roles: currentValue?.associated_orgs[index]?.user_role as any,
            FirstName: currentValue.Detail?.FirstName as string,
            LastName: currentValue.Detail?.LastName as string,
            ClientKey: currentValue.Detail?.Email as string,
            Email: currentValue.Detail?.Email as string,
            PhoneNumber: currentValue.Detail?.PhoneNumber as string,
            OrganizationId: this.getSelectedAuth0orgId(currentValue.associated_orgs[index].organization ? currentValue.associated_orgs[index].organization : this.ogService.organization),
            Active: currentValue.associated_orgs[index].user_status,
            Password: 'abcd1234#',
            AutheticatedUserId: currentValue.Detail?.AutheticatedUserId as string,
          },
          Divisions: currentValue.associated_orgs[index].divisions ? currentValue.associated_orgs[index].divisions : [],
          ProductCategories: currentValue.associated_orgs[index].product_categories ? currentValue.associated_orgs[index].product_categories : [],
          ChannelIds: this.getSelectedRecords(currentValue.associated_orgs[index].channels),
          EventTypeIds: this.getSelectedRecords(currentValue.associated_orgs[index].event_types),
          orgPath: currentValue.associated_orgs[index].organization ? currentValue.associated_orgs[index].organization.apiPath : this.ogService.organization?.apiPath
        }
        userDomains.push(datatoReturn);
      }
    }
    return userDomains;
  }

  getSelectedAuth0orgId(organization: any) {
    if (this.auth0Orgs && this.auth0Orgs.length > 0) {
      const currentOrg = this.auth0Orgs.find((x: any) => x.DisplayName === organization.name) as any;
      if (currentOrg) {
        return currentOrg.Id;
      }
    }
  }

  getSelectedRecords(eventTypes: any) {
    if (eventTypes && eventTypes.length > 0) {
      return eventTypes.filter((x: any) => x.selected == true).map((i: any) => i.Id);
    } else {
      return [];
    }
  }

  getAuth0Orgs() {
    this.userService.getAuth0Orgs().subscribe(res => {
      this.auth0Orgs = res;
    });
  }

  save() {
    this.trimControlValues(this.formGroup);
    if (this.formGroup.valid) {
      const userData = this.getUserDataFromUserForm();
      for (let index = 0; index <= userData.length - 1; index++) {
        const currentOrg = userData[index].orgPath
        delete userData[index].orgPath;
        if (userData[index].Detail.Id == '0') {
          userData[index].Detail.Id = '';
        }
        this.userService.saveUser(userData[index] as any, currentOrg).subscribe({
          next: (response) => {
            this.matSnackBar.open(
              `${this.formGroup.controls.Detail.controls.FirstName.value} ${this.formGroup.controls.Detail.controls.LastName.value} saved`, 'OK', DEFAULT_SNACKBAR_CONFIG
            );
            this.userService.reload();
            this.router.navigate([`../`], { relativeTo: this.route, queryParamsHandling: 'preserve' });
          },
          error: (error) => {
            if (error.status === 500) {
              log.error('500 Error saving event', error);
            }
            if (error.status === 400) {
              const apiValidations: any = error.error;
              if (Array.isArray(apiValidations)) {
                apiValidations.forEach((validation: any) => {
                  if (this.formGroup?.get(validation.PropertyName)) {
                    const control = this.formGroup?.get(validation.PropertyName);
                    if (control) {
                      control.markAsTouched();
                      if (validation.PropertyName != 'Detail.ClientKey') {
                        control.setErrors({ invalid: validation.ErrorMessage });
                      }
                      this.matSnackBar.open(validation.ErrorMessage, 'OK', { verticalPosition: 'top', panelClass: ['snackbar-error'] });
                    }
                  } else {
                    ///TODO: if we have cross field validation then show the validation error at the top of the screen
                    // if we have cross field validation then show the validation error at the top of the screen
                    // push general error messages to array this is displayed in a toast or dialog
                  }
                });
              } else {
                this.matSnackBar.open(apiValidations, 'Error', { verticalPosition: 'top', panelClass: ['snackbar-error'] });
              }


            }
          }
        });
      }
    }
  }

  cancel(): void {
    this.formGroup.reset();
    this.router.navigate([`../`], { relativeTo: this.route, queryParamsHandling: 'preserve' });
  }

  deleteRecord() {
    const record = this.formGroup.getRawValue();
    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Confirm Delete',
        message: `Are you sure you wish to delete ${record.Detail.FirstName} ${record.Detail.LastName}?`,
      },
      disableClose: true,
    });

    confirmDialog.afterClosed().subscribe(
      confirmResult => {
        if (confirmResult) {
          this.userService.deleteUsers([record]).subscribe({
            next: () => {
              this.matSnackBar.open(`${record.Detail.FirstName} ${record.Detail.LastName} deleted`, 'OK', DEFAULT_SNACKBAR_CONFIG);
              this.userService.reload();
              this.router.navigate([`../`], { relativeTo: this.route, queryParamsHandling: 'preserve' });
            },
            error: (error) => {
              log.error('Error deleting event', error);

              if (error.error.value) {
                throw new Error(error.error.value);
              } else {
                throw new Error(error.message);
              }
            }
          });
        }
      });
  }

}
