<!-- page title -->
<h1>Brand Management</h1>

<form [formGroup]="filtersForm">

  <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px" fxLayout.lt-md="column"
       fxLayoutAlign.lt-md="start start" fxLayoutGap.lt-md="4px" class="actions-bar">
    <button type="button" mat-raised-button color="accent" matTooltip="Add new record" [routerLink]="[0, 'details']">
      <fa-icon [icon]="['fas', 'plus']"></fa-icon>Add Brand
    </button>
    <button type="button" mat-raised-button [matMenuTriggerFor]="menu" matTooltip="Actions">
        <mat-icon>more_vert</mat-icon>Actions
      </button>
      <mat-menu #menu="matMenu">
        <button type="button" mat-menu-item [matMenuTriggerFor]="Download"><mat-icon>arrow_circle_down</mat-icon>Download</button>         
      </mat-menu>
      <mat-menu #Download="matMenu">        
        <button mat-menu-item (click)="downloadJSON()">JSON</button>
        <button mat-menu-item (click)="downloadCSV()">CSV</button>
      </mat-menu>
    <mat-form-field appearance="outline" class="mat-form-field-no-padding">
      <mat-icon matPrefix>search</mat-icon>
      <mat-label>Search</mat-label>
      <input matInput formControlName="search" type="text" placeholder="Search" (input)="onSearch($event)" #searchInput />
      <button type="button" matSuffix mat-icon-button aria-label="Clear" *ngIf="searchInput.value" (click)="clearSearch()">
        <mat-icon>close</mat-icon>
    </button>
    </mat-form-field>
  </div>

  <app-product-brand-list-table></app-product-brand-list-table>
</form>
