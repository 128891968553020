<!-- page title -->
<h1>Shopper Management</h1>
<ng-container *ngIf="viewModel$ | async as viewModel">
    <form [formGroup]="filtersForm" novalidate>

        <!-- actions bar -->
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px" fxLayout.lt-md="column"
            fxLayoutAlign.lt-md="start start" fxLayoutGap.lt-md="4px" class="actions-bar">

            <button type="button" mat-raised-button color="accent" matTooltip="Add new record" [routerLink]="[0, 'details']"
                [queryParams]="{search: searchInput.value ? searchInput.value : null}">
                <fa-icon [icon]="['fas', 'plus']"></fa-icon>Add Shopper
            </button>

            <button type="button"
            *ngIf="getSelectedSectionRecords() && getSelectedSectionRecords().length > 0"
            mat-raised-button [matMenuTriggerFor]="menu" matTooltip="Actions">
            <mat-icon>more_vert</mat-icon>Actions
          </button>
          <mat-menu #menu="matMenu">
            <button type="button" mat-menu-item (click)="deleteSelectedRecords()"><mat-icon>delete</mat-icon>Delete</button>
            <!-- <button type="button" mat-menu-item><mat-icon>file_copy</mat-icon>Copy</button>   -->
            <button type="button" mat-menu-item>
               {{ getSelectedSectionRecords().length }}
                Selected</button>           
          </mat-menu>

            <mat-form-field appearance="outline" class="mat-form-field-no-padding">
                <mat-icon matPrefix>search</mat-icon>
                <mat-label>Search Shoppers</mat-label>
                <input matInput formControlName="search" type="text" (input)="onSearch($event)" placeholder="Search"
                    #searchInput />
                <button type="button" matSuffix mat-icon-button aria-label="Clear" *ngIf="searchInput.value"
                    (click)="clearSearch()">
                    <mat-icon>close</mat-icon>
                </button>
            </mat-form-field>

            <span class="menu-spacer" fxShow.lt-md="false"></span>

            <button type="button" mat-raised-button matTooltip="Show/hide filters" (click)="filterSidenav.toggle()">
                <fa-icon [icon]="['fas', 'filter']"></fa-icon>Filter
            </button>
        </div>

        <mat-sidenav-container>
            <!--filter sidenav-->
            <mat-sidenav #filterSidenav mode="over" position="end">
                <mat-toolbar>
                    Filter
                    <span class="menu-spacer"></span>
                    <button type="button" mat-icon-button aria-label="Close filter menu" matTooltip="Close filter menu"
                        (click)="filterSidenav.toggle()">
                        <mat-icon>close</mat-icon>
                    </button>
                </mat-toolbar>
                <div style="margin: 16px">
                    <div fxLayout="column">

                        <mat-form-field appearance="outline" fxFlex="auto">
                            <mat-label>City</mat-label>
                            <input matInput type="text" formControlName="city" [matAutocomplete]="auto" />
                            <mat-autocomplete #auto="matAutocomplete">
                                <mat-option *ngFor="let city of filteredCityNames$ | async" [value]="city"
                                    (onSelectionChange)="onCityChange($event, city)">
                                    {{city}}
                                </mat-option>
                            </mat-autocomplete>
                        </mat-form-field>

                        <mat-form-field appearance="outline">
                            <mat-label>State</mat-label>
                            <mat-select multiple formControlName="state" (selectionChange)="onFilterChange($event)">
                                <mat-option *ngFor="let state of viewModel.states" [value]="state">{{state}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                    </div>
                    <div fxLayout="row" fxLayoutAlign="end start" fxLayoutGap="16px">
                        <button type="button" mat-raised-button matTooltip="Reset filters" (click)="resetFilters()">
                            Reset
                        </button>
                        <button type="button" mat-raised-button matTooltip="Apply filters" color="accent"
                            (click)="filterSidenav.toggle()" [disabled]="filtersForm.invalid">
                            Apply
                        </button>
                    </div>
                </div>
            </mat-sidenav>

            <div class="filter-chips" *ngIf="viewModel.filters.length > 0" fxLayout="row">
                <mat-chip-listbox aria-label="Selected filters">
                    <mat-chip-option *ngFor="let filter of viewModel.filters" (removed)="removeFilter(filter)">
                        {{filter.value}}
                        <button type="button" matChipRemove>
                            <mat-icon>cancel</mat-icon>
                        </button>
                    </mat-chip-option>
                </mat-chip-listbox>
                <button type="button" mat-flat-button matTooltip="Remove all filters" (click)="clearFilters()">
                    Clear all
                </button>
            </div>

            <!--table view-->
            <table mat-table [dataSource]="viewModel.shoppers" matSort (matSortChange)="onSort($event)" matSortDisableClear [matSortStart]="'desc'"
                *ngIf="viewModel.shoppers.length">

                <!-- Checkbox Column -->
            <ng-container matColumnDef="select">
                <th class="no-ellipsis" mat-header-cell *matHeaderCellDef>
                    <mat-checkbox (change)="$event ? masterToggle() : null"
                        [checked]="selection.hasValue() && isAllSelected()"
                        [indeterminate]="selection.hasValue() && !isAllSelected()">
                    </mat-checkbox>
                </th>
                <td class="no-ellipsis" mat-cell *matCellDef="let row">
                    <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
                        [checked]="selection.isSelected(row)">
                    </mat-checkbox>
                </td>
            </ng-container>

                <!-- Id Column -->
                <ng-container matColumnDef="Id">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Id" [resizeColumn]="true">
                        ID
                    </th>
                    <td mat-cell *matCellDef="let record">{{ record.Id }}</td>
                </ng-container>

                <!-- LastName Column -->
                <ng-container matColumnDef="LastName">
                    <th style="width:12%" mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by LastName" [resizeColumn]="true">
                        Last Name
                    </th>
                    <td mat-cell *matCellDef="let record"> {{ record.LastName }}</td>
                </ng-container>

                <!-- FirstName Column -->
                <ng-container matColumnDef="FirstName">
                    <th style="width:10%" mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by FirstName" [resizeColumn]="true">
                        First Name
                    </th>
                    <td mat-cell *matCellDef="let record">{{ record.FirstName }}</td>
                </ng-container>

                <!-- Email Column -->
                <ng-container matColumnDef="Email">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Email" [resizeColumn]="true">
                        Email
                    </th>
                    <td mat-cell *matCellDef="let record">{{ record.Email }}</td>
                </ng-container>

                   <!-- Phone Column -->
                   <ng-container matColumnDef="Phone">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Phone" [resizeColumn]="true">
                        Phone
                    </th>
                    <td mat-cell *matCellDef="let record">{{ record.Phone }}</td>
                </ng-container>

                <!-- City Column -->
                <ng-container matColumnDef="City">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by City" [resizeColumn]="true">
                        City
                    </th>
                    <td mat-cell *matCellDef="let record">{{ record.City }}</td>
                </ng-container>

                <!-- State Column -->
                <ng-container matColumnDef="State">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by State" [resizeColumn]="true">
                        State
                    </th>
                    <td mat-cell *matCellDef="let record">{{ record.State }}</td>
                </ng-container>             

                <!-- Actions Column -->
                <ng-container matColumnDef="Actions">
                    <th mat-header-cell *matHeaderCellDef>Actions</th>
                    <td mat-cell *matCellDef="let record">
                        <button type="button" mat-button color="accent" [routerLink]="[record.Id, 'details']">Edit</button>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
            </table>

            <table *ngIf="!viewModel.shoppers.length">
                <tr>
                    <td class="no-records" [attr.colspan]="displayedColumns.length">
                        <h2>No Records Found</h2>
                    </td>
                </tr>
            </table>

            <!-- pager -->
            <mat-paginator [length]="viewModel.totalRecords" [pageSize]="viewModel.page.pageSize"
                [pageSizeOptions]="[5, 10, 20, 50, 100,500]" (page)="onPage($event)" *ngIf="viewModel.shoppers.length"
                showFirstLastButtons [pageIndex]="viewModel.page.pageIndex">
            </mat-paginator>

        </mat-sidenav-container>
    </form>
</ng-container>

<ng-template #loading>
    <mat-progress-bar mode="indeterminate" color="accent"></mat-progress-bar>
</ng-template>
