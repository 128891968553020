import { Component } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Subscription } from 'rxjs';
import { CouponService } from '../../services/coupon.service';
import { Coupon } from '../../models/coupon.model';

interface SortDirection {
  name: string;
  value: string;
  icon: IconProp;
}

@Component({
  selector: 'app-coupon-sort-dialog',
  moduleId: module.id,
  templateUrl: 'coupon.sort.dialog.component.html',
  styleUrls: ['coupon.sort.dialog.component.scss']
})
export class CouponSortDialogComponent {

  sortBy = [
    { name: 'Redemption Start Date', value: 'RedemptionStartDate' },
    { name: 'Summary', value: 'Summary' },
    { name: 'Status', value: 'Status' },
  ];
  sortDirection: SortDirection[] = [
    { name: 'Ascending', value: 'asc', icon: ['fas', 'arrow-up'] },
    { name: 'Descending', value: 'desc', icon: ['fas', 'arrow-down'] },
  ];
  sortForm = new FormGroup({
    sortBy: new FormControl<string>(this.sortBy[0].value),
    direction: new FormControl<string>(this.sortDirection[0].value),
  });
  subscription!: Subscription;
  
  constructor(
    private matDialogRef: MatDialogRef<CouponSortDialogComponent>,
    private couponService: CouponService<Coupon>
  ) {
  }

  ngOnInit() {
    this.subscription = this.couponService.sort$.subscribe(res => {
      this.sortForm.controls.sortBy.patchValue(res.active);
      this.sortForm.controls.direction.patchValue(res.direction);
      this.sortForm.markAllAsTouched();
    })
  }

  onSubmit() {
    if (this.sortForm.valid) {
      if (this.sortForm.controls.sortBy.value && this.sortForm.controls.direction.value) {
        const sortAction = { active: this.sortForm.controls.sortBy.value, direction: this.sortForm.controls.direction.value };
        this.couponService.sort(sortAction);
      }

      this.matDialogRef.close();
    }
  }

}
