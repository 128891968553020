import { SelectionModel } from '@angular/cdk/collections';
import {
  Component,
  OnInit,
  Input,
  OnDestroy,
  Output,
  EventEmitter,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { SystemWorkflowService } from '../../../../services/admin-workflow.service';

@Component({
  selector: 'admin-workflow-users-table',
  templateUrl: './workflow-users-table.component.html',
  styleUrls: ['./workflow-users-table.component.scss'],
})
export class WorkflowUsersTableComponent implements OnInit, OnDestroy {
  dataSource: any;
  selection = new SelectionModel<any>(true, []);

  displayedColumns: string[] = [
    'select',
    'first_name',
    'last_name',
    'role',
    'email_alerts',
    // 'actions',
  ];
  tasksRefData: any;
  emailAlertsRefData = [
    { label: 'All Activity', value: 0 },
    { label: 'Work Assigned', value: 1 },
    { label: 'Disabled', value: 2 },
  ];
  rolesRefData = [
    { label: 'Reviewer', value: 0 },
    { label: 'Editor', value: 1 },
    { label: 'Approver', value: 2 },
  ];

  @Input() set tableData(val: any[]) {
    this.dataSource = val;
    this.setProperLabelsInDataSourceIfAvailable(val);
  }

  @Input() set usersDeleted(val: any) {
    setTimeout(() => {
      this.selection.clear();
      this.selectedUsersIndex = [];
      this.allSelected = false;
    }, 100);
  }

  @Output() onCheckboxSelected = new EventEmitter();
  @Output() onUsersSelected = new EventEmitter();

  subscription$ = new Subscription();
  selectedUser = undefined;
  allSelected = false;
  selectedUsersIndex: any[] = [];

  constructor(public ewService: SystemWorkflowService) {}

  ngOnInit(): void {
    this.getRefDataForControls();
  }

  setProperLabelsInDataSourceIfAvailable(val: any[]) {
    const labelsInterval = setInterval(() => {
      if (this.tasksRefData && this.emailAlertsRefData) {
        // Tasks
        val.forEach((v, i) => {
          const ti = (this.tasksRefData as any[]).findIndex(
            (t) => t.Id == v.task
          );
          if (ti > -1) {
            val[i].task = this.tasksRefData[ti].label;
          }
        });

        // Email alerts
        val.forEach((v, i) => {
          const ei = (this.emailAlertsRefData as any[]).findIndex(
            (e) => e.Id == v.email_alerts
          );
          if (ei > -1) {
            val[i].email_alerts = this.tasksRefData[ei].label;
          }
        });

        this.dataSource = val;
        clearInterval(labelsInterval);
      }
    }, 500);
  }

  getRefDataForControls() {
    this.tasksRefData = this.ewService.usersTableRefData?.tasks;
    this.emailAlertsRefData = this.ewService.usersTableRefData?.emailAlerts
      ? this.ewService.usersTableRefData?.emailAlerts
      : this.emailAlertsRefData;
    this.subscription$.add(
      this.ewService.usersTableRefDataSubject.subscribe((data: any) => {
        this.tasksRefData = data?.tasks ? data?.tasks : this.tasksRefData;
        this.emailAlertsRefData = data?.emailAlerts
          ? data?.emailAlerts
          : this.emailAlertsRefData;
      })
    );
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.length;
    return numSelected === numRows;
  }

  toggleAllRows(e: any) {
    this.allSelected = e.checked;
    if (e.checked == false) {
      this.selectedUsersIndex = [];
      this.selection.clear();
      this.onCheckboxSelected.emit(false);
      this.onUsersSelected.emit(this.selectedUsersIndex);
      return;
    } else {
      const selectedIndexes = [];
      for (let i = 0; i < this.dataSource.length; i++) {
        selectedIndexes.push(this.dataSource[i].id);
      }
      this.selectedUsersIndex = selectedIndexes;
      this.selection.select(...this.dataSource);
      this.onCheckboxSelected.emit(true);
      this.onUsersSelected.emit(this.selectedUsersIndex);
    }
  }

  selectionToggle(e: any, rowIndex: number) {
    const u = this.dataSource[rowIndex];

    if (this.selection.isSelected(u)) {
      this.selection.deselect(u);
    } else {
      this.selection.select(u);
    }

    this.onUsersSelected.emit(this.selection.selected.map((s: any) => s.id));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${
      row.position + 1
    }`;
  }

  onTaskSelect(t: any, e: any) {
    const i = (this.dataSource as any[]).findIndex((d: any) => {
      return d.first_name == e.first_name && d.last_name == e.last_name;
    });
    if (i > -1) {
      this.dataSource[i].task = t;
      this.dataSource = [...this.dataSource];
    }
  }

  onEmailAlertSelect(e: any, x: any) {
    const i = (this.dataSource as any[]).findIndex((d: any) => {
      return d.first_name == x.first_name && d.last_name == x.last_name;
    });
    if (i > -1) {
      this.dataSource[i].email_alerts = e;
      this.dataSource = [...this.dataSource];
    }
  }

  onUserRoleSelect(e: any, x: any) {
    const i = (this.dataSource as any[]).findIndex((d: any) => {
      return d.first_name == x.first_name && d.last_name == x.last_name;
    });
    if (i > -1) {
      this.dataSource[i].role = e;
      this.dataSource = [...this.dataSource];
    }
  }

  selectUser(e: any, rowIndex: number) {
    const u = this.dataSource[rowIndex];
    if (this.allSelected == true) {
      this.onCheckboxSelected.emit(true);
    } else {
      if (this.selection.isSelected(u)) {
        this.selection.deselect(u);
        this.selectedUsersIndex = this.selectedUsersIndex.filter(
          (i) => i != u.id
        );
      } else {
        this.selection.select(u);
        this.selectedUsersIndex.push(u.id);
      }
      this.onUsersSelected.emit(this.selectedUsersIndex);
      if (this.selection.selected.length > 0) {
        this.onCheckboxSelected.emit(true);
      } else {
        this.onCheckboxSelected.emit(false);
      }
    }
  }

  onEditUser(user: any) {
    this.selectedUser = user;
  }

  ngOnDestroy(): void {
    this.subscription$.unsubscribe();
  }
}
