import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Logger } from 'src/app/@shared';

const log = new Logger('ProductTabsComponent');

@Component({
  selector: 'app-product-tabs',
  templateUrl: './product-tabs.component.html',
  styleUrls: ['./product-tabs.component.scss']
})

export class ProductTabsComponent implements OnInit {

  productId = '0';

  constructor(private route: ActivatedRoute,) { }

  ngOnInit(): void {
    log.debug('init');
    // get the product id from the route params
    this.productId = this.route.snapshot.params['productId'];
  }

}
