export interface Store {
    ClientKey?: string;
    Id: string;
    StoreName: string;
    Address1?: string;
    Address2?: string;
    City?: string;
    BannerId?: string;
    Country?: string;
    PostalCode?: string;
    Latitude?: string;
    Longitude?: string;
    State?: string;
}
