<ng-container *ngIf="viewModel$ | async as viewModel">

    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px" fxLayout.lt-md="column"
    fxLayoutAlign.lt-md="start start" fxLayoutGap.lt-md="4px">
    <h2>Select Offer</h2>
    <mat-form-field appearance="outline" class="mat-form-field-no-padding">
        <mat-icon matPrefix>search</mat-icon>
        <mat-label>Search Offers</mat-label>
        <input matInput [formControl]="search" type="text" (input)="onSearch($event)" placeholder="Search"
          #searchInput data-testid="Search Bar" />
        <button type="button" matSuffix mat-icon-button aria-label="Clear" *ngIf="searchInput.value"
          (click)="clearSearch()">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
  </div>
  <div class="grid-container">

    <table *ngIf="viewModel.offers.length" mat-table [dataSource]="viewModel.offers" matSort (matSortChange)="onSort($event)" matSortDisableClear [matSortStart]="'desc'" class="mat-elevation-z8">

        <!-- Id Column -->
        <ng-container matColumnDef="Id">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Id" [resizeColumn]="true">
                Id
            </th>
            <td mat-cell *matCellDef="let record">{{ record.Detail.Id }}</td>
        </ng-container>

        <!-- OfferName Column -->
        <ng-container matColumnDef="Name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Name" [resizeColumn]="true">
                Offer Name
            </th>
            <td mat-cell *matCellDef="let record"> {{ record.Detail.Name }}</td>
        </ng-container>

        <!-- ClientKey Column -->
        <ng-container matColumnDef="ClientKey">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by ClientKey" [resizeColumn]="true">
                Client Key
            </th>
            <td mat-cell *matCellDef="let record">{{ record.Detail.ClientKey }}</td>
        </ng-container>

        <!-- Headline Column -->
        <ng-container matColumnDef="Headline">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Headline" [resizeColumn]="true">
                Headline
            </th>
            <td mat-cell *matCellDef="let record">{{ record.Detail.Headline }}</td>
        </ng-container>

        <!-- BodyCopy Column -->
        <ng-container matColumnDef="BodyCopy">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by BodyCopy" [resizeColumn]="true">
                BodyCopy
            </th>
            <td mat-cell *matCellDef="let record">{{ record.Detail.BodyCopy }}</td>
        </ng-container>

        <!-- Actions Column -->
    <ng-container matColumnDef="Actions">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let record">
          <mat-radio-button value="{{record.selected}}" (change)="updateSelectedOffer(record)"></mat-radio-button>
        </td>
      </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>

    <table *ngIf="!viewModel.offers.length">
        <tr>
            <td class="no-records" [attr.colspan]="displayedColumns.length">
                <h2>No Records Found</h2>
            </td>
        </tr>
    </table>
  </div>
    <!-- pager -->
    <mat-paginator [length]="viewModel.totalRecords" [pageSize]="viewModel.page.pageSize"
        [pageSizeOptions]="[5, 10, 20, 50, 100,500]" (page)="onPage($event)" [hidden]="!viewModel.offers.length"
        showFirstLastButtons [pageIndex]="viewModel.page.pageIndex">
    </mat-paginator>

    <mat-card-actions fxLayout="row" fxLayoutAlign="end">

        <div fxLayout="row" fxLayoutAlign="end start" fxLayoutGap="16px">
            <button mat-stroked-button type="button" (click)="closeDialog()">
                Cancel
            </button>
            <button mat-raised-button type="button" color="accent" (click)="saveData()">
                Select
            </button>
        </div>
    </mat-card-actions>

</ng-container>
