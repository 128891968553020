import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Logger } from 'src/app/@shared';
import { ProductBrand, ProductBrandService } from '../..';

const log = new Logger('ProductBrandsComponent');

@Component({
  selector: 'app-product-brands',
  templateUrl: './product-brands.component.html',
  styleUrls: ['./product-brands.component.scss']
})
export class ProductBrandsComponent<T extends ProductBrand> implements OnInit {

  public get productBrandService(): ProductBrandService<T> {
    return this._productBrandService;
  }
  public set productBrandService(value: ProductBrandService<T>) {
    this._productBrandService = value;
  }
  filtersForm = new FormGroup({
    search: new FormControl(),
  });

  constructor(private _productBrandService: ProductBrandService<T>) {
  }

  ngOnInit() {
    log.debug('init');
    this.clearSearch();
    this.productBrandService.reload();
  }

  onSearch(event: any) {
    this.productBrandService.search(event.target.value);
  }

  clearSearch() {
    this.filtersForm.controls.search.setValue('');
    this.productBrandService.search('');
  }

  downloadJSON(){
    this.productBrandService.downloadProductBrandsJSON();
  }

  downloadCSV(){
    this.productBrandService.downloadProductBrandsCSV();
  }

}
