<div
  fxLayout="column"
  fxLayoutAlign="start start"
  fxLayoutGap="8px"
  fxLayout.lt-md="column"
  fxLayoutAlign.lt-md="start start"
  fxLayoutGap.lt-md="4px"
>
  <h1>Activity Details</h1>

  <table *ngIf="detailsDataSource && detailsDataSource.length > 0" mat-table [dataSource]="detailsDataSource" class="mat-elevation-z8">
    <!--- Note that these columns can be defined in any order.
      The actual rendered columns are set as a property on the row definition" -->

    <ng-container matColumnDef="field">
      <th mat-header-cell *matHeaderCellDef>Field</th>
      <td mat-cell *matCellDef="let element">
        {{ element.field }}
      </td>
    </ng-container>

    <ng-container matColumnDef="old_value">
      <th mat-header-cell *matHeaderCellDef>Old Value</th>
      <td mat-cell *matCellDef="let element">
        <p [innerHTML]="element.old_value"></p>
      </td>
    </ng-container>

    <ng-container matColumnDef="new_value">
      <th mat-header-cell *matHeaderCellDef>New Value</th>
      <td mat-cell *matCellDef="let element">
        <p [innerHTML]="element.new_value"></p>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
  <p *ngIf="!detailsDataSource || detailsDataSource.length == 0"><b>No Data Found</b></p>

  <div
    class="actions"
    fxLayout="row"
    fxLayoutAlign="start end"
    fxLayoutGap="8px"
    fxLayout.lt-md="column"
    fxLayoutAlign.lt-md="start start"
    fxLayoutGap.lt-md="4px"
    
  >
    <button
      type="button"
      (click)="closeDialog()"
      style="background: #ECEBE5; border: 1px solid #0000001F"
    >
      Close
    </button>
  </div>
</div>
