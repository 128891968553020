<!-- page title -->
<h1>Vendor Management</h1>
<ng-container *ngIf="viewModel$ | async as viewModel">
    <form [formGroup]="filtersForm" novalidate>

        <!-- actions bar -->
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px" fxLayout.lt-md="column"
            fxLayoutAlign.lt-md="start start" fxLayoutGap.lt-md="4px" class="actions-bar">

            <button type="button" mat-raised-button color="accent" matTooltip="Add new record" [routerLink]="[0]"
                [queryParams]="{search: searchInput.value ? searchInput.value : null}">
                <fa-icon [icon]="['fas', 'plus']"></fa-icon>New Vendor
            </button>

            <button type="button"
            *ngIf="getSelectedSectionRecords() && getSelectedSectionRecords().length > 0"
            mat-raised-button [matMenuTriggerFor]="menu" matTooltip="Actions">
            <mat-icon>more_vert</mat-icon>Actions
          </button>
          <mat-menu #menu="matMenu">
            <button type="button" class="download" mat-menu-item [matMenuTriggerFor]="Download"><mat-icon  class="ml-2">arrow_circle_down</mat-icon>Download</button>
            <button type="button" mat-menu-item (click)="deleteSelectedRecords()"><mat-icon class="ml-23">delete</mat-icon>Delete</button>
          </mat-menu>
          <mat-menu #Download="matMenu">
            <button mat-menu-item (click)="downloadJSON()">JSON</button>
            <button mat-menu-item (click)="downloadCSV()">CSV</button>
          </mat-menu>

            <mat-form-field appearance="outline" class="mat-form-field-no-padding">
                <mat-icon matPrefix>search</mat-icon>
                <mat-label>Search</mat-label>
                <input matInput formControlName="search" type="text" (input)="onSearch($event)" placeholder="Search"
                    #searchInput />
                <button type="button" matSuffix mat-icon-button aria-label="Clear" *ngIf="searchInput.value"
                    (click)="clearSearch()">
                    <mat-icon>close</mat-icon>
                </button>
            </mat-form-field>

        </div>

        <mat-sidenav-container>

            <!--table view-->
            <table mat-table [dataSource]="viewModel.vendors" matSort (matSortChange)="onSort($event)" matSortDisableClear [matSortStart]="'desc'"
                *ngIf="viewModel.vendors.length">

                <!-- Checkbox Column -->
            <ng-container matColumnDef="select">
                <th class="no-ellipsis" mat-header-cell *matHeaderCellDef>
                    <mat-checkbox (change)="$event ? masterToggle() : null"
                        [checked]="selection.hasValue() && isAllSelected()"
                        [indeterminate]="selection.hasValue() && !isAllSelected()">
                    </mat-checkbox>
                </th>
                <td class="no-ellipsis" mat-cell *matCellDef="let row">
                    <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
                        [checked]="selection.isSelected(row)">
                    </mat-checkbox>
                </td>
            </ng-container>

                <!-- Id Column -->
                <ng-container matColumnDef="Id">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Id" [resizeColumn]="true">
                        ID
                    </th>
                    <td mat-cell *matCellDef="let record">{{ record.Id }}</td>
                </ng-container>

                <!-- VendorName Column -->
                <ng-container matColumnDef="VendorName">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Name" [resizeColumn]="true">
                        Vendor Name
                    </th>
                    <td mat-cell *matCellDef="let record"> {{ record.VendorName }}</td>
                </ng-container>

                <!-- ClientKey Column -->
                <ng-container matColumnDef="ClientKey">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by ClientKey" [resizeColumn]="true">
                        Client Key
                    </th>
                    <td mat-cell *matCellDef="let record">{{ record.ClientKey }}</td>
                </ng-container>
              

                <!-- Actions Column -->
                <ng-container matColumnDef="Actions">
                    <th mat-header-cell *matHeaderCellDef>Actions</th>
                    <td mat-cell *matCellDef="let record">
                        <button type="button" mat-button color="accent" [routerLink]="[record.Id]">Edit</button>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
            </table>

            <table *ngIf="!viewModel.vendors.length">
                <tr>
                    <td class="no-records" [attr.colspan]="displayedColumns.length">
                        <h2>No Records Found</h2>
                    </td>
                </tr>
            </table>

            <!-- pager -->
            <mat-paginator [length]="viewModel.totalRecords" [pageSize]="viewModel.page.pageSize"
                [pageSizeOptions]="[5, 10, 20, 50, 100,500]" (page)="onPage($event)" *ngIf="viewModel.vendors.length"
                showFirstLastButtons [pageIndex]="viewModel.page.pageIndex">
            </mat-paginator>

        </mat-sidenav-container>
    </form>
</ng-container>

<ng-template #loading>
    <mat-progress-bar mode="indeterminate" color="accent"></mat-progress-bar>
</ng-template>
