import { Component, OnInit } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Sort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest, map } from 'rxjs';
import { ConfirmDialogComponent, Logger, OrganizationService } from 'src/app/@shared';
import { DEFAULT_SNACKBAR_CONFIG } from 'src/app/@shared/constants/site.constants';
import { OfferAsset, BrandAssetsService, ProductBrandAddAssetComponent } from '../..';

const log = new Logger('ProductBrandAssetListComponent');

@Component({
  selector: 'app-product-brand-asset-list',
  templateUrl: './product-brand-asset-list.component.html',
  styleUrls: ['./product-brand-asset-list.component.scss']
})
export class ProductBrandAssetListComponent implements OnInit {


  viewModel$ = combineLatest([
    this.brandAssetsService.assets$,
    this.brandAssetsService.isLoading$,
    this.brandAssetsService.page$,
    this.brandAssetsService.brandId$,
    this.organizationService.assetsUrl$,
    this.brandAssetsService.totalRecords$
  ]).pipe(
    map(([assets, isLoading, page, brandId, assetsUrl, totalRecords]) => {
      if (assets.length == 0 && page.pageIndex > 0) {
        page.previousPageIndex = 0
        page.pageIndex = 0
        this.brandAssetsService.page(page);
        this.brandAssetsService.reload();
        }
      return { assets, isLoading, page, brandId, assetsUrl, totalRecords }
    }),
  );

  filtersForm = new FormGroup({
    search: new FormControl(),
  });
  assetsList: OfferAsset[] = [];
  selection = new SelectionModel<OfferAsset>(true, []);

  constructor(private brandAssetsService: BrandAssetsService<OfferAsset>,
    private organizationService: OrganizationService,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private router: Router,
    private matSnackBar: MatSnackBar) { }

  ngOnInit(): void {
    log.debug('init');
    this.clearSearch();
    // get the product id from the parent route
    this.brandAssetsService.brandId = this.route.snapshot.params['brandId'];
  }

  onSort(sortState: Sort): void {
    this.brandAssetsService.sort(sortState);
    this.selection.clear();
  }

  onPage(pageEvent: PageEvent): void {
    this.selection.clear();
    this.brandAssetsService.page(pageEvent);
  }

  onSearch(event: any) {
    this.brandAssetsService.search(event.target.value);
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(ProductBrandAddAssetComponent, {
      width: '80%',
      height: '90%',
      data: { productId: this.route.snapshot.params['brandId'] }
    });
    dialogRef.afterClosed().subscribe(() => {
      this.clearSearch();
    });
  }

  diassociateAssets() {
    if (this.selection && this.selection.selected && this.selection.selected.length > 0) {
      let assets: any = this.selection.selected.map(x => x.Detail.Id);
      const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
        data: {
          title: 'Confirm Delete',
          message: `Do you want to remove from asset?`,
        },
        disableClose: true,
      });

      confirmDialog.afterClosed().subscribe(
        confirmResult => {
          if (confirmResult) {
            this.brandAssetsService.disassociateAssetToBrand(assets).subscribe({
              next: () => {
                this.matSnackBar.open(`Asset deleted`, 'OK', DEFAULT_SNACKBAR_CONFIG);
                this.clearSearch();
                this.brandAssetsService.assetsReload();
                this.router.navigate([`../`, `product-brand-assets`], { relativeTo: this.route });
              },
              error: (error) => {
                log.error('Error adding store to version', error);

                if (error.error.value) {
                  throw new Error(error.error.value);
                } else {
                  throw new Error(error.message);
                }
              }
            });
          }
        });
    }
  }

  clearSearch() {
    this.filtersForm.controls.search.setValue('');
    this.brandAssetsService.search('');
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.assetsList.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.assetsList.forEach(row => this.selection.select(row));
  }

  public getSelectedSectionRecords() {
    return this.selection.selected;
  }

  public clearSelection() {
    this.selection.clear();
  }

}
