import { Injectable } from '@angular/core';
import { OfferTag } from '..';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, combineLatest, debounceTime, distinctUntilChanged, map, Observable, of, shareReplay, switchMap, tap, } from 'rxjs';
import { environment } from 'src/environments/environment';
import { OrganizationService } from 'src/app/@shared';
import { v4 as uuidv4 } from 'uuid';
import { DEFAULT_PAGING } from 'src/app/@shared/constants/site.constants';

@Injectable({
  providedIn: 'root',
})
export class OfferTagService<T extends OfferTag> {
  // initialize behavior subjects
  private pageBehaviorSubject = new BehaviorSubject(DEFAULT_PAGING);
  private loadingBehaviorSubject = new BehaviorSubject<boolean>(false);
  private searchBehaviorSubject = new BehaviorSubject<string>('');
  private sortBehaviorSubject = new BehaviorSubject({
    active: 'OfferTagName',
    direction: 'asc',
  });
  private reloadBehaviorSubject = new BehaviorSubject<string>('');
  

  // we do not wish to expose our behavior subjects.  create public observables
  public page$ = this.pageBehaviorSubject.asObservable();
  public search$ = this.searchBehaviorSubject.asObservable();
  public sort$ = this.sortBehaviorSubject.asObservable();
  public isLoading$ = this.loadingBehaviorSubject.asObservable();
 
  constructor(private httpClient: HttpClient, private organizationService: OrganizationService) { }

  // create the parameters observable that looks for changes in page, startDate, endDate, etc
  public params$ = combineLatest([
    this.pageBehaviorSubject, // add debounce if we need to wait for user input ex: .pipe(debounceTime(300)),
    this.sortBehaviorSubject,
    this.searchBehaviorSubject.pipe(debounceTime(300)),
    this.reloadBehaviorSubject,
  ]).pipe(
    distinctUntilChanged((previous, current) => {
      // if the values coming down this pipe are the same, don't continue the pipe
      return JSON.stringify(previous) === JSON.stringify(current);
    }),
    map(([page, sort, search, reload]) => {
      // set the query string odata parameters
      let params: HttpParams = new HttpParams({
        fromObject: {
          //  mode: viewMode,
          $skip: page.pageIndex * page.pageSize,
          $top: page.pageSize,
          $orderby: `${sort.active} ${sort.direction}`,
          $count: true,
        },
      });

      // if there is a search, add the search to the parameters
      if (search.length) {
        params = params.append('$search', `"${search}"`);
      }

      return params;
    })
  );

  // create the offertags observable that calls http get when any of our parameters change
  private offerTagsResponse$ = this.params$.pipe(
    tap(() => this.loadingBehaviorSubject.next(true)), // set isLoading to true
    switchMap((_params) =>
      this.httpClient.get(
        `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/OfferTags`,
        { params: _params }
      )
    ),
    tap(() => this.loadingBehaviorSubject.next(false)), // set isLoading to false
    shareReplay(1) // make sure all subscriptions share the same http call (otherwise there will be a http call for each subscription)
  );

  // offerTag listing
  public offerTags$: Observable<T[]> = this.offerTagsResponse$.pipe(
    map((res: any) => res.value)
  );

  public getOfferTagsList(): Observable<T[]> {
    return this.httpClient
      .get<T[]>(
        `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/OfferTags/GetAllOfferTags`
      )
      .pipe(map((res: any) => res.value));
  }

  public GetOfferTagsByIdsList(OfferTagIds: string[]) {
    let url = `${environment.pr1ApiUrl}/${
      this.organizationService.organization?.apiPath
    }/${
      this.organizationService.organization?.version
    }/OfferTags/GetOfferTagsByIdsList?${this.prepareOfferTagString(
      OfferTagIds
    )}`;
    return this.httpClient.post(url, OfferTagIds);
  }
  // create the alloffertags observable that calls http get when any of our parameters change
  private OfferTagsListResponse$ = this.params$.pipe(
    tap(() => this.loadingBehaviorSubject.next(true)), // set isLoading to true
    switchMap((_params) =>
      this.httpClient.get(
        `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/OfferTags/GetAllOfferTags`
      )
    ),
    tap(() => this.loadingBehaviorSubject.next(false)), // set isLoading to false
    shareReplay(1) // make sure all subscriptions share the same http call (otherwise there will be a http call for each subscription)
  );

  private OfferTagsListSearchResponse$ = this.search$.pipe(
    tap(() => this.loadingBehaviorSubject.next(true)), // set isLoading to true
    switchMap((_search) => {
      if(_search){
        return this.httpClient.get(`${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/OfferTags/GetOfferTags?Search=${_search}`)
      } else {
        return of([])
      }
      }),
    tap(() => this.loadingBehaviorSubject.next(false)), // set isLoading to false
    shareReplay(1) // make sure all subscriptions share the same http call (otherwise there will be a http call for each subscription)
  );

  // offerTag listing
  public OfferTagsList$: Observable<T[]> = this.OfferTagsListResponse$.pipe(
    map((res: any) => res.value)
  );

  // offerTag listing on search
  public OfferTagsSearchList$: Observable<T[]> =
    this.OfferTagsListSearchResponse$.pipe(map((res: any) => res.value));

  // create the alloffertags observable that calls http get when any of our parameters change
  private allOfferTagsResponse$ = this.params$.pipe(
    tap(() => this.loadingBehaviorSubject.next(true)), // set isLoading to true
    switchMap((_params) =>
      this.httpClient.get(
        `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/OfferTags`
      )
    ),
    tap(() => this.loadingBehaviorSubject.next(false)), // set isLoading to false
    shareReplay(1) // make sure all subscriptions share the same http call (otherwise there will be a http call for each subscription)
  );

  // offerTag listing
  public allOfferTags$: Observable<T[]> = this.allOfferTagsResponse$.pipe(
    map((res: any) => res.value)
  );

  // total number of offerTag records based on filtering
  public totalRecords$: Observable<number> = this.offerTagsResponse$.pipe(
    map((res: any) => res['@odata.count'])
  );

  // set the current page
  page(page: any) {
    this.pageBehaviorSubject.next(page);
  }

  // gets an offerTag by id
  getOfferTag(offerTagId: string): Observable<T> {
    const url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/OfferTags/${offerTagId}`;
    return this.httpClient.get<T>(url);
  }

  // gets an offerTag by eventId
  getByEventId(eventId: string): Observable<T[]> {
    const url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/OfferTags/GetByEventId?eventId=${eventId}`;
    return this.httpClient.get<T[]>(url).pipe(map((res: any) => res.value));
  }

  // sets the sort property and order
  sort(sort: any) {
    this.sortBehaviorSubject.next(sort);
  }

  // sets the search phrase
  search(search: string) {
    const page = this.pageBehaviorSubject.value;
    page.pageIndex = 0;
    page.previousPageIndex = 0;
    this.searchBehaviorSubject.next(search);
    this.pageBehaviorSubject.next(page);
  }

  // reloads/refreshes the offerTag listing
  reload() {
    // reload the OfferTag data
    this.reloadBehaviorSubject.next(uuidv4());
  }

  // up-serts OfferTags
  saveOfferTag(OfferTag: T) {
    let url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/OfferTags/`;
    if (!OfferTag.Id || OfferTag.Id === '0') {
      // create new record
      return this.httpClient.post(url, OfferTag);
    } else {
      // edit existing record
      url += `${OfferTag.Id}`;
      return this.httpClient.put(url, OfferTag);
    }
  }

  // deletes an OfferTag by id
  deleteOfferTag(id: string) {
    const url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/OfferTags/${id}`;
    return this.httpClient.delete(url);
  }

  deleteOfferTags(offertags: any) {
    const url = `${environment.pr1ApiUrl}/${
      this.organizationService.organization?.apiPath
    }/${
      this.organizationService.organization?.version
    }/OfferTags/DeleteOfferTags?${this.prepareOfferTagStringtoDelete(
      offertags
    )}`;
    return this.httpClient.post(url, offertags);
  }

  prepareOfferTagStringtoDelete(offertags: any) {
    let urlParams = '';
    if (offertags && offertags.length > 0) {
      for (let index = 0; index <= offertags.length - 1; index++) {
        urlParams += `offerTagIds=${offertags[index]?.Id}`;
        if (index != offertags.length - 1) {
          urlParams += '&';
        }
      }
    }
    return urlParams;
  }

  prepareOfferTagString(offertags: any) {
    let urlParams = '';
    if (offertags && offertags.length > 0) {
      for (let index = 0; index <= offertags.length - 1; index++) {
        urlParams += `offerTagIds=${offertags[index]}`;
        if (index != offertags.length - 1) {
          urlParams += '&';
        }
      }
    }
    return urlParams;
  }

  downloadOfferTagsJSON() {
    const url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/OfferTags/EtlExport`;
    window.open(url, '_blank');
  }

  downloadOfferTagsCSV() {
    const url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/OfferTags/Export`;
    window.open(url, '_blank');
  }
}
