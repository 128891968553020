export interface AssetDetails {
  FileName: string;
  AssetName: string;
  DateAdded: string;
  Type: string;
  ClientKey?: string;
  Id?: string;
  Description?: string;
  AdditionalSpecs?: string;
  DateShot?: Date | string;
  OriginalFileName?: string;
  UserNameModified?: string;
  DateFileModified?: Date | string;
  ColorChannel?: string;
  FileType?: string;
  AssetType?: string;
  ImageSource?: string;
  DeleteStatus?: boolean;
  DoNotUse?: boolean;
  ExpiryDate?: Date | string;
  EmbargoDate?: Date | string;
  Keywords?: string[];
  Restrictions?: string;
  StockSourceInformation?: string;
  PrintUrl?: string;
  WebUrl?: string;
}
