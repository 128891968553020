<ng-container *ngIf="viewModel$ | async as viewModel">
  <table mat-table [dataSource]="viewModel.divisions" matSort (matSortChange)="onSort($event)" matSortDisableClear [matSortStart]="'desc'"
    *ngIf="viewModel.divisions.length">

      <!-- Checkbox Column -->
      <ng-container matColumnDef="select">
        <th class="no-ellipsis" mat-header-cell *matHeaderCellDef>
            <mat-checkbox (change)="$event ? masterToggle() : null"
                [checked]="selection.hasValue() && isAllSelected()"
                [indeterminate]="selection.hasValue() && !isAllSelected()">
            </mat-checkbox>
        </th>
        <td class="no-ellipsis" mat-cell *matCellDef="let row">
            <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
                [checked]="selection.isSelected(row)">
            </mat-checkbox>
        </td>
    </ng-container>

    <!-- Id Column -->
    <ng-container matColumnDef="Id">
      <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Id" [resizeColumn]="true">
        Id
      </th>
      <td mat-cell *matCellDef="let record">{{ record.Id }}</td>
    </ng-container>


    <!-- DivisionName Column -->
    <ng-container matColumnDef="DivisionName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Name" [resizeColumn]="true">
        Name
      </th>
      <td mat-cell *matCellDef="let record"> {{ record.DivisionName }}</td>
    </ng-container>


    <!-- ClientKey Column -->
    <ng-container matColumnDef="ClientKey">
      <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by ClientKey" [resizeColumn]="true">
        Client Key
      </th>
      <td mat-cell *matCellDef="let record">{{ record.ClientKey }}</td>
    </ng-container>

    <!-- Actions Column -->
    <ng-container matColumnDef="Actions">
      <th mat-header-cell *matHeaderCellDef>Actions</th>
      <td mat-cell *matCellDef="let record">
        <button type="button" mat-button color="accent" [routerLink]="[record.Id]"
          [queryParams]="{search: searchKey ? searchKey : null}">Edit</button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>


  <table *ngIf="!viewModel.divisions.length">
    <tr>
      <td class="no-records" [attr.colspan]="displayedColumns.length">
        <h2>No Records Found</h2>
      </td>
    </tr>
  </table>

  <!-- pager -->
  <mat-paginator [length]="viewModel.totalRecords" [pageSize]="viewModel.page.pageSize"
    [pageSizeOptions]="[5, 10, 20, 50, 100,500]" (page)="onPage($event)" *ngIf="viewModel.divisions.length"
    showFirstLastButtons [pageIndex]="viewModel.page.pageIndex">
  </mat-paginator>

</ng-container>
