import { Component, OnInit } from '@angular/core';
import { combineLatest, distinctUntilChanged, map, Observable, startWith, Subscription } from 'rxjs';
import { MediaChange, MediaObserver } from '@angular/flex-layout';
import { ConfirmDialogComponent, DEFAULT_SNACKBAR_CONFIG, Logger } from 'src/app/@shared';
import { Sort } from '@angular/material/sort';
import { User, UserDomain, UserService } from '../..';
import { SelectionModel } from '@angular/cdk/collections';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';

const log = new Logger('UsersComponent');

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})

export class UsersComponent<TUser extends User> implements OnInit {

  filtersForm = new FormGroup({
    search: new FormControl<string | null>(''),
  });

  viewModel$ = combineLatest([
    this.userService.users$,
    this.userService.isLoading$,
    this.userService.totalRecords$,
    this.userService.page$,
  ]).pipe(
    map(([users, isLoading, totalRecords, page]) => {
      if (users.length == 0 && page.pageIndex > 0) {
        page.previousPageIndex = 0
        page.pageIndex = 0
        this.userService.page(page);
        this.userService.reload();
      }
      return { users, isLoading, totalRecords, page }
    }),
  );

  flexMediaWatcher!: Subscription;
  displayedColumns: string[] = [
    'select',
    'first_name',
    'last_name',
    'email',
    // 'user_role',
    'user_status',
    'edit_user',
  ];
  usersList: UserDomain<TUser>[] = [];
  selection = new SelectionModel<UserDomain<TUser>>(true, []);
  userId: string = '0';


  constructor(private userService: UserService<TUser>, private mediaObserver: MediaObserver, private router: Router, private route: ActivatedRoute, private dialog: MatDialog,
    private matSnackBar: MatSnackBar) { }

  ngOnInit(): void {
    log.debug('init');
    this.clearSearch();
    this.userService.reload();

    this.route.queryParams.subscribe(queryParams => {
      let searchKey = queryParams['search'];

      if (searchKey) {
        this.userService.search(searchKey)
        this.filtersForm.controls.search.setValue(searchKey);
      } else {
        this.userService.search('');
        this.filtersForm.controls.search.setValue('');
      }
    });

    this.userService.users$.subscribe((data) => {
      this.usersList = data;
      this.selection.clear();
    });
    // detect changes in viewport size to handle show/hide of table columns
    const getAlias = (MediaChange: MediaChange[]) => {
      return MediaChange[0].mqAlias;
    };

    this.flexMediaWatcher = this.mediaObserver
      .asObservable()
      .pipe(
        distinctUntilChanged(
          (x: MediaChange[], y: MediaChange[]) => getAlias(x) === getAlias(y)
        ))
      .subscribe((change) => {
        if (change.some(x => x.mqAlias === 'xs')) {
          this.displayedColumns = ['select', 'first_name', 'last_name', 'Actions'];
        }
        else if (change.some(x => x.mqAlias === 'sm')) {
          this.displayedColumns = ['select', 'first_name', 'last_name', 'Actions'];
        }
        else {
          this.displayedColumns = ['select',
            'first_name',
            'last_name',
            'email',
            // 'user_role',
            'user_status',
            'edit_user',];
        }
      });
  }

  onPage(pageUser: any): void {
    this.selection.clear();
    this.userService.page(pageUser);
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.usersList.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.usersList.forEach(row => this.selection.select(row));
  }

  public getSelectedSectionRecords() {
    return this.selection.selected;
  }

  public clearSelection() {
    this.selection.clear();
  }

  openDialog(record: any): void {
  }

  onSort(sortState: Sort): void {
    this.userService.sort(sortState);
    this.selection.clear();
  }

  onRowDoubleClick(id: string) {
    this.router.navigate([`${id}/offers`], { relativeTo: this.route });
  }

  ngOnDestroy(): void {
    this.flexMediaWatcher.unsubscribe();
  }

  activateUserToggle(action: UserDomain<TUser>) {
    let activateString = 'activate';
    if (action.Detail.Active) {
      activateString = 'de-activate';
    }
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        message: `Are you sure you want to ${activateString} ${action.Detail.FirstName} ${action.Detail.LastName}?`
      },
    });

    dialogRef.afterClosed().subscribe((e) => {
      if (e == true && !action.Detail.Active) {
        this.handleActivateUser([action]);
      } else if (e == true && action.Detail.Active) {
        this.handleDeactivateUser([action]);
      }
    });
  }

  activateUserToggleSelection(action: string) {
    let shouldActivate: boolean = action == 'activate';
    let selectedRecords: any = this.selection.selected;

    if (selectedRecords && selectedRecords.length > 0) {
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        data: {
          message: `Are you sure you want to ${action} these user(s)?`
        },
      });

      dialogRef.afterClosed().subscribe((e) => {
        if (e == true && shouldActivate) {
          this.handleActivateUser(selectedRecords);
        } else if (e == true && !shouldActivate) {
          this.handleDeactivateUser(selectedRecords);
        }
      });
    }
  }

  handleActivateUser(selectedUsers: any[]) {
    this.userService.activateUser(selectedUsers).subscribe({
      next: () => {
        this.userService.reload();
      },
      error: (err: Error) => console.log('Error occured: ', err.message),
    })
  }

  handleDeactivateUser(selectedUsers: any[]) {
    this.userService.deactivateUser(selectedUsers).subscribe({
      next: () => {
        this.userService.reload();
      },
      error: (err: Error) => console.log('Error occured: ', err.message),
    })
  }


  onSearch(event: any) {
    this.userService.search(event.target.value);
  }

  clearSearch() {
    this.filtersForm.controls.search.setValue('');
    this.userService.search('');
  }

  deleteSelectedRecords() {
    let selectedRecords: any = this.selection.selected;

    if (selectedRecords && selectedRecords.length > 0) {
      const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
        data: {
          title: 'Confirm Delete',
          message: `Do you want to remove selected user(s)?`,
        },
        disableClose: true,
      });

      confirmDialog.afterClosed().subscribe(
        confirmResult => {
          if (confirmResult) {
            this.userService.deleteUsers(selectedRecords).subscribe({
              next: () => {
                this.matSnackBar.open(`User(s) deleted`, 'OK', DEFAULT_SNACKBAR_CONFIG);
                this.userService.reload();
              },
              error: (error) => {
                log.error('Error in deleting user', error);

                if (error.error.value) {
                  throw new Error(error.error.value);
                } else {
                  throw new Error(error.message);
                }
              }
            });
          }
        });
    }
  }

  downloadJSON() {
    this.userService.downloadUsersJSON();
  }

  downloadCSV() {
    this.userService.downloadUsersCSV();
  }
}
