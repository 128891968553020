<h1>Offer Tag Management</h1>

<form *ngIf="offerTag$ | async as T; else loading" [formGroup]="formGroup" novalidate>
    <mat-card appearance="outlined">
        <mat-card-content fxLayout="column">

            <h2>{{formGroup.controls.OfferTagName.value == '' ? "Offer Tag" : formGroup.controls.OfferTagName.value}}</h2>
            
            <div fxLayout="row wrap" fxLayoutGap.gt-sm="16px" fxLayout.lt-md="column">

                <mat-form-field appearance="outline" fxFlex="50">
                    <mat-label>Name</mat-label>
                    <input matInput type="text" formControlName="OfferTagName" required />
                    <mat-error *ngIf="formGroup.controls.OfferTagName.hasError('required')">
                        Name is required
                    </mat-error>
                    <mat-error *ngIf="formGroup.controls.OfferTagName.status=='INVALID'">
                        {{formGroup.controls.OfferTagName.errors!['maxlength'] && 'Maximum length can be '+formGroup.controls.OfferTagName.errors!['maxlength']['requiredLength']}}
                    </mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline" fxFlex="50" *ngIf="offerTagId != '0'">
                    <mat-label>Id</mat-label>
                    <input matInput formControlName="Id" readonly>
                </mat-form-field>

                <mat-form-field appearance="outline" fxFlex="auto">
                    <mat-label>Client Key</mat-label>
                    <input matInput type="text" formControlName="ClientKey" required />
                    <mat-error *ngIf="formGroup.controls.ClientKey.hasError('required')">
                        Client key is required
                    </mat-error>
                    <mat-error *ngIf="formGroup.controls.ClientKey.status=='INVALID'">
                        {{formGroup.controls.ClientKey.errors!['maxlength'] && 'Maximum length can be '+formGroup.controls.ClientKey.errors!['maxlength']['requiredLength']+'. '}}
                    </mat-error>
                </mat-form-field>

            </div>
        </mat-card-content>

        <mat-card-actions>
            <div fxLayout="row" [fxLayoutAlign]="offerTagId != '0'?'space-between':'end'" class="mat-card-actions-details">
                <button mat-raised-button type="button" color="warn" (click)="deleteRecord()" *ngIf="offerTagId != '0'">
                    Delete
                </button>
                <div>
                    <button mat-stroked-button type="reset" (click)="cancel()">
                        Cancel
                    </button>
                    <button mat-raised-button type="button" (click)="save() " [disabled]="formGroup.invalid" color="accent">
                        Save
                    </button>
                </div>
            </div>
        </mat-card-actions>
        
    </mat-card>
</form>

<ng-template #loading>
    <mat-progress-bar mode="indeterminate" color="accent"></mat-progress-bar>
</ng-template>