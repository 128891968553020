<!-- page title -->
<h1>Event Management</h1>

<ng-container *ngIf="viewModel$ | async as viewModel; else loading">
  <form [formGroup]="filtersForm" novalidate>
    <!-- actions bar -->
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px" fxLayout.lt-md="column"
      fxLayoutAlign.lt-md="start start" fxLayoutGap.lt-md="4px" class="actions-bar">
      <button appAuthorizeControl="edit||eventmang" type="button" mat-raised-button color="accent" matTooltip="Add new record" [routerLink]="[0, 'details']"
        [queryParams]="{search: searchInput.value ? searchInput.value : null}" data-testid="New Event">
        <fa-icon [icon]="['fas', 'plus']"></fa-icon>New Event
      </button>

      <button type="button"
      *ngIf="(viewModel.viewMode === 'TABLE' && eventsTable?.getSelectedSectionRecords()?.length > 0) || (viewModel.viewMode === 'EXPANSION' && eventsExpansion?.getSelectedSectionRecords()?.length > 0)"
      mat-raised-button [matMenuTriggerFor]="menu" matTooltip="Actions">
      <mat-icon>more_vert</mat-icon>Actions
  </button>
  <mat-menu #menu="matMenu">
    <button type="button" mat-menu-item [matMenuTriggerFor]="Download"><mat-icon>arrow_circle_down</mat-icon>Download</button>
    <!-- <button type="button" mat-menu-item><mat-icon>file_copy</mat-icon>Copy</button> -->
    <button appAuthorizeControl="edit||eventmang" type="button" mat-menu-item (click)="deleteSelectedRecords()"><mat-icon>delete</mat-icon>Delete</button>
    <button type="button" mat-menu-item *ngIf="(viewModel.viewMode === 'TABLE' && eventsTable?.getSelectedSectionRecords()?.length > 0) || (viewModel.viewMode === 'EXPANSION' && eventsExpansion?.getSelectedSectionRecords()?.length > 0)">
      {{ viewModel.viewMode === 'TABLE' ? eventsTable?.getSelectedSectionRecords()?.length : eventsExpansion?.getSelectedSectionRecords()?.length}} Selected
  </button>
  </mat-menu>
  <mat-menu #Download="matMenu">
    <button mat-menu-item (click)="downloadCSV()">CSV</button>
  </mat-menu>

      <mat-form-field appearance="outline" class="mat-form-field-no-padding">
        <mat-icon matPrefix>search</mat-icon>
        <mat-label>Search</mat-label>
        <input matInput formControlName="search" type="text" (input)="onSearch($event)" placeholder="Search"
          #searchInput data-testid="Search Bar" />
        <button type="button" matSuffix mat-icon-button aria-label="Clear" *ngIf="searchInput.value"
          (click)="clearSearch()">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>

      <span class="menu-spacer"></span>

      <mat-button-toggle-group #group="matButtonToggleGroup" value="table">
        <mat-button-toggle value="table" matTooltip="Table view" aria-label="Table view" (click)="toggleView('TABLE')">
          <mat-icon data-testid="Table View">table_view</mat-icon>
        </mat-button-toggle>
        <mat-button-toggle value="expansion" matTooltip="List view" aria-label="List view"
          (click)="toggleView('EXPANSION')">
          <mat-icon data-testid="View List">view_list</mat-icon>
        </mat-button-toggle>
      </mat-button-toggle-group>

      <button type="button" mat-raised-button matTooltip="Show/hide filters" (click)="filterSidenav.toggle()" data-testid="Filter">
        <fa-icon [icon]="['fas', 'filter']"></fa-icon>Filter
      </button>
    </div>

    <mat-sidenav-container>
      <!--filter sidenav-->
      <mat-sidenav #filterSidenav mode="over" position="end">
        <mat-toolbar>
          Filter
          <span class="menu-spacer"></span>
          <button type="button" mat-icon-button aria-label="Close filter menu" matTooltip="Close filter menu"
            (click)="filterSidenav.toggle()">
            <mat-icon>close</mat-icon>
          </button>
        </mat-toolbar>
        <div style="margin: 16px">
          <div fxLayout="column">

            <mat-form-field appearance="outline">
              <mat-label>Start Date</mat-label>
              <input matInput [matDatepicker]="startDatePicker" formControlName="startDate"
                (dateChange)="onDateRangeChange()">
              <!-- <mat-hint>MM/DD/YYYY</mat-hint> -->
              <mat-error *ngIf="filtersForm.controls.startDate.hasError('required')">
                Start date is required
              </mat-error>
              <mat-error *ngIf="filtersForm.controls.startDate.hasError('matStartDateInvalid')">
                Invalid start date
              </mat-error>
              <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
              <mat-datepicker #startDatePicker></mat-datepicker>
            </mat-form-field>

            <mat-form-field appearance="outline">
              <mat-label>End Date</mat-label>
              <input matInput [matDatepicker]="endDatePicker" formControlName="endDate"
                (dateChange)="onDateRangeChange()">
              <!-- <mat-hint>MM/DD/YYYY</mat-hint> -->
              <mat-error *ngIf="filtersForm.controls.endDate.hasError('required')">
                End date is required
              </mat-error>
              <mat-error *ngIf="filtersForm.controls.endDate.hasError('matEndDateInvalid')">
                Invalid end date
              </mat-error>
              <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
              <mat-datepicker #endDatePicker></mat-datepicker>
            </mat-form-field>

            <mat-form-field appearance="outline">
              <mat-label>Divisions</mat-label>
              <mat-select multiple formControlName="divisions" (selectionChange)="onFilterChange($event)">
                <mat-option *ngFor="let division of viewModel.divisions" [value]="division">
                  {{division.DivisionName}}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field appearance="outline">
              <mat-label>Event type</mat-label>
              <mat-select formControlName="eventTypeId" (selectionChange)="onFilterChange($event)">
                <mat-option value="">All</mat-option>
                <mat-option *ngFor="let eventType of viewModel.eventTypes" [value]="eventType">
                  {{eventType.EventTypeName}}
                </mat-option>
              </mat-select>
            </mat-form-field>

          </div>
          <div fxLayout="row" fxLayoutAlign="end start" fxLayoutGap="16px">
            <button type="button" mat-raised-button matTooltip="Reset filters" (click)="resetFilters()">
              Reset
            </button>
            <button type="button" mat-raised-button matTooltip="Apply filters" color="accent"
              (click)="filterSidenav.toggle()" [disabled]="filtersForm.invalid">
              Apply
            </button>
          </div>
        </div>

      </mat-sidenav>

      <!--main content-->

      <!--filter chips -->
      <div class="filter-chips" *ngIf="viewModel.filters.length > 0" fxLayout="row">
        <mat-chip-listbox aria-label="Selected filters">
          <mat-chip-option *ngFor="let filter of viewModel.filters" (removed)="removeFilter(filter)">
            {{filter.displayText}}
            <button type="button" matChipRemove>
              <mat-icon>cancel</mat-icon>
            </button>
          </mat-chip-option>
        </mat-chip-listbox>
        <button type="button" mat-flat-button matTooltip="Remove all filters" (click)="clearFilters()">
          Clear all
        </button>
      </div>

      <!--table view-->
      <app-event-list-table #eventsTable *ngIf="viewModel.viewMode === 'TABLE'"></app-event-list-table>
      <!--expansion view-->
      <app-event-list-expansion-panel #eventsExpansion *ngIf="viewModel.viewMode === 'EXPANSION'"></app-event-list-expansion-panel>
    </mat-sidenav-container>

  </form>
</ng-container>

<ng-template #loading>
  <mat-progress-bar mode="indeterminate" color="accent"></mat-progress-bar>
</ng-template>
