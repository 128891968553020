<!-- page title -->

<h1>Assets Management</h1>

<ng-container *ngIf="viewModel$ | async as viewModel">
    <form [formGroup]="filtersForm" novalidate>
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px" fxLayout.lt-md="column"
            fxLayoutAlign.lt-md="start start" fxLayoutGap.lt-md="4px" class="actions-bar">
            <button type="button" mat-raised-button color="accent" matTooltip="Add new record"
                [routerLink]="['upload-assets']">
                <fa-icon [icon]="['fas', 'plus']"></fa-icon>Upload Assets
            </button>

            <button type="button"
                *ngIf="(viewModel.viewMode === 'TABLE' && assetsTable?.getSelectedSectionRecords()?.length > 0) || (viewModel.viewMode === 'CARDS' && assetsCards?.getSelectedSectionRecords()?.length > 0)"
                mat-raised-button [matMenuTriggerFor]="menu" matTooltip="Actions">
                <mat-icon>more_vert</mat-icon>Actions
            </button>
            <mat-menu #menu="matMenu">
                <button type="button" mat-menu-item [matMenuTriggerFor]="Download"><mat-icon>arrow_circle_down</mat-icon>Download</button>                
                <!-- <button type="button" mat-menu-item><mat-icon>file_copy</mat-icon>Copy</button>   -->
                <button type="button" mat-menu-item (click)="deleteSelectedRecords()"><mat-icon>delete</mat-icon>Delete</button>
                <button type="button" mat-menu-item *ngIf="(viewModel.viewMode === 'TABLE' && assetsTable?.getSelectedSectionRecords()?.length > 0) || (viewModel.viewMode === 'CARDS' && assetsCards?.getSelectedSectionRecords()?.length > 0)">
                    {{ viewModel.viewMode === 'TABLE' ? assetsTable?.getSelectedSectionRecords()?.length : assetsCards?.getSelectedSectionRecords()?.length}} Selected
                </button>
              </mat-menu>
              <mat-menu #Download="matMenu">
                <button mat-menu-item (click)="downloadJSON()">JSON</button>
                <button mat-menu-item (click)="downloadCSV()">CSV</button>
              </mat-menu>

            <mat-form-field appearance="outline" class="mat-form-field-no-padding">
                <mat-icon matPrefix>search</mat-icon>
                <mat-label>Search Assets</mat-label>
                <input matInput formControlName="search" type="text" (input)="onSearch($event)" placeholder="Search"
                    #searchInput />
                <button type="button" matSuffix mat-icon-button aria-label="Clear" *ngIf="searchInput.value"
                    (click)="clearSearch()">
                    <mat-icon>close</mat-icon>
                </button>
            </mat-form-field>

            <span class="menu-spacer"></span>

            <mat-button-toggle-group #group="matButtonToggleGroup" value="cards">
                <mat-button-toggle value="cards" matTooltip="Card view" aria-label="Card view"
                    (click)="toggleView('CARDS')">
                    <mat-icon>grid_view</mat-icon>
                </mat-button-toggle>
                <mat-button-toggle value="table" matTooltip="Table view" aria-label="Table view"
                    (click)="toggleView('TABLE')">
                    <mat-icon>table_view</mat-icon>
                </mat-button-toggle>
            </mat-button-toggle-group>
            <button type="button" mat-raised-button matTooltip="Show/hide filters" (click)="filterSidenav.toggle()">
                <fa-icon [icon]="['fas', 'filter']"></fa-icon>Filter
            </button>
        </div>

        <mat-sidenav-container>
            <mat-sidenav #filterSidenav mode="over" position="end">
                <mat-toolbar>
                    Filter
                    <span class="menu-spacer"></span>
                    <button type="button" mat-icon-button aria-label="Close filter menu" matTooltip="Close filter menu"
                        (click)="filterSidenav.toggle()">
                        <mat-icon>close</mat-icon>
                    </button>
                </mat-toolbar>
                <div style="margin: 16px">
                    <div fxLayout="column">

                        <mat-form-field appearance="outline">
                            <mat-label>Upload Start Date</mat-label>
                            <input matInput [matDatepicker]="startDatePicker" formControlName="startDate"
                                (dateChange)="onDateRangeChange()">
                            <!-- <mat-hint>MM/DD/YYYY</mat-hint> -->
                            <mat-error *ngIf="filtersForm.controls.startDate.hasError('required')">
                                Upload start date is required
                            </mat-error>
                            <mat-error *ngIf="filtersForm.controls.startDate.hasError('matStartDateInvalid')">
                                Invalid upload start date
                            </mat-error>
                            <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
                            <mat-datepicker #startDatePicker></mat-datepicker>
                        </mat-form-field>

                        <mat-form-field appearance="outline">
                            <mat-label>Upload End Date</mat-label>
                            <input matInput [matDatepicker]="endDatePicker" formControlName="endDate"
                                (dateChange)="onDateRangeChange()">
                            <!-- <mat-hint>MM/DD/YYYY</mat-hint> -->
                            <mat-error *ngIf="filtersForm.controls.endDate.hasError('required')">
                                Upload end date is required
                            </mat-error>
                            <mat-error *ngIf="filtersForm.controls.endDate.hasError('matEndDateInvalid')">
                                Invalid upload end date
                            </mat-error>
                            <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
                            <mat-datepicker #endDatePicker></mat-datepicker>
                        </mat-form-field>

                        <mat-form-field appearance="outline">
                            <mat-label>Group</mat-label>
                            <mat-select formControlName="groupName" multiple (selectionChange)="onFilterChange($event)">
                                <mat-option *ngFor="let group of viewModel.assetGroups" [value]="group">
                                    {{group.AssetGroupName}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field appearance="outline">
                            <mat-label>Type</mat-label>
                            <mat-select formControlName="type" multiple (selectionChange)="onFilterChange($event)">
                                <mat-option *ngFor="let type of getDistinctRecords(viewModel.assetsFilters)"
                                    [value]="type">
                                    {{type}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                    </div>
                    <div fxLayout="row" fxLayoutAlign="end start" fxLayoutGap="16px">
                        <button type="reset" mat-raised-button matTooltip="Reset filters" (click)="resetFilters()">
                            Reset
                        </button>
                        <button type="button" mat-raised-button matTooltip="Apply filters" color="accent"
                            (click)="filterSidenav.toggle()" [disabled]="filtersForm.invalid">
                            Apply
                        </button>
                    </div>
                </div>

            </mat-sidenav>

          <!--filter chips -->
          <div class="filter-chips" *ngIf="viewModel.filters.length > 0" fxLayout="row">
            <mat-chip-listbox aria-label="Selected filters">
              <mat-chip-option *ngFor="let filter of viewModel.filters" (removed)="removeFilter(filter)">
                {{filter.displayText}}
                <button type="button" matChipRemove>
                  <mat-icon>cancel</mat-icon>
                </button>
              </mat-chip-option>
            </mat-chip-listbox>
            <button type="button" mat-flat-button matTooltip="Remove all filters" (click)="clearFilters()">
              Clear all
            </button>
          </div>

            <app-asset-list-cards #assetsCards *ngIf="viewModel.viewMode === 'CARDS'"></app-asset-list-cards>
            <!--expansion view-->
            <app-asset-list-table #assetsTable *ngIf="viewModel.viewMode === 'TABLE'"></app-asset-list-table>

        </mat-sidenav-container>


    </form>
</ng-container>

<ng-template #loading>
    <mat-progress-bar mode="indeterminate" color="accent"></mat-progress-bar>
</ng-template>