import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  combineLatest,
  debounceTime,
  distinctUntilChanged,
  map,
  Observable,
  shareReplay,
  switchMap,
  tap,
} from 'rxjs';
import * as dayjs from 'dayjs';
import { environment } from '../../../../../environments/environment';
import * as isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import { v4 as uuidv4 } from 'uuid';
import { OrganizationService } from '../../../../@shared/services/organization.service';
import { Filter } from '../../../../@shared/models/filter.model';
import { DEFAULT_PAGING } from '../../../../@shared/constants/site.constants';
import { AuthUser } from '../models/authuser.model';
import { AuthUserDomain } from '../models/authuser-domain.model';

@Injectable({
  providedIn: 'root',
})
export class AuthUserService<TAuthUser extends AuthUser> {
  constructor(
    private httpClient: HttpClient,
    private organizationService: OrganizationService
  ) {}

  private loadingBehaviorSubject = new BehaviorSubject<boolean>(false);
  private pageBehaviorSubject = new BehaviorSubject(DEFAULT_PAGING);
  private sortBehaviorSubject = new BehaviorSubject({
    active: 'FirstName',
    direction: 'desc',
  });
  private searchBehaviorSubject = new BehaviorSubject<string>('');
  private dialogSearchBehaviorSubject = new BehaviorSubject<string>('');
  private reloadBehaviorSubject = new BehaviorSubject<string>('');
  private viewModeBehaviorSubject = new BehaviorSubject<string>('TABLE');
  private filterBehaviorSubject = new BehaviorSubject<Filter[]>([]);
  private selectedAuthUserForAdBehaviorSubject = new BehaviorSubject<
    AuthUserDomain<TAuthUser>[]
  >([]);
  private onlyActiveUsersBehaviorSubject = new BehaviorSubject<boolean>(false);

  public isLoading$ = this.loadingBehaviorSubject.asObservable();
  public sort$ = this.sortBehaviorSubject.asObservable();
  public page$ = this.pageBehaviorSubject.asObservable();
  public search$ = this.searchBehaviorSubject.asObservable();
  public dialogSearch$ = this.dialogSearchBehaviorSubject.asObservable();
  public viewMode$ = this.viewModeBehaviorSubject.asObservable();
  public filters$ = this.filterBehaviorSubject.asObservable();
  public selectedAuthUserForAd$ =
    this.selectedAuthUserForAdBehaviorSubject.asObservable();
  public onlyActiveUsers$ = this.onlyActiveUsersBehaviorSubject.asObservable();

  // create the parameters observable that looks for changes in page, startDate, endDate, etc
  public params$ = combineLatest([
    this.pageBehaviorSubject, // add debounce if we need to wait for user input ex: .pipe(debounceTime(300)),
    this.sortBehaviorSubject,
    this.searchBehaviorSubject.pipe(debounceTime(300)),
    this.filterBehaviorSubject.pipe(debounceTime(50)),
    this.reloadBehaviorSubject,
    this.onlyActiveUsersBehaviorSubject,
  ]).pipe(
    distinctUntilChanged((previous, current) => {
      // if the values coming down this pipe are the same, don't continue the pipe
      return JSON.stringify(previous) === JSON.stringify(current);
    }),
    map(([page, sort, search, filters, reload, activeUsersOnly]) => {
      let _orderby = `Detail/${sort.active} ${sort.direction}`;
      if (sort.active == 'DivisionNames' || sort.active == 'AuthUserTypeName') {
        _orderby = `${sort.active} ${sort.direction}`;
      }

      // set the query string parameters
      let params: HttpParams = new HttpParams({
        fromObject: {
          $expand: 'Detail',
          $skip: page.pageIndex * page.pageSize,
          $top: page.pageSize,
          $orderby: _orderby,
          $count: true,
        },
      });

      // if there is a search, add the search to the parameters
      if (search.length) {
        params = params.append('$search', `"${search}"`);
      }

      // if there are filters, add the filters to the parameters
      if (filters.length > 0) {
        params = this.buildFilterParams(filters, params);
      }

      if (activeUsersOnly == true) {
        params = params.append('isActive', true);
      }

      return params;
    })
  );

  clearAuthUserForAd() {
    this.selectedAuthUserForAdBehaviorSubject.next([]);
  }

  seTAuthUserForAd(AuthUser: AuthUserDomain<TAuthUser>[]) {
    this.selectedAuthUserForAdBehaviorSubject.next(AuthUser);
  }

  // create the AuthUsers observable that calls http get when any of our parameters change
  private AuthUsersResponse$ = this.params$.pipe(
    tap(() => this.loadingBehaviorSubject.next(true)), // set isLoading to true
    switchMap((_params) =>
      this.httpClient.get(
        `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/AuthUserDomains`,
        { params: _params }
      )
    ),
    tap(() => this.loadingBehaviorSubject.next(false)), // set isLoading to false
    shareReplay(1) // make sure all subscriptions share the same http call (otherwise there will be a http call for each subscription)
  );

  // AuthUser listing
  public AuthUsers$: Observable<AuthUserDomain<TAuthUser>[]> =
    this.AuthUsersResponse$.pipe(map((res: any) => res.value));

  ///TODO: remove this observable
  private allAuthUsersResponse$ = this.params$.pipe(
    tap(() => this.loadingBehaviorSubject.next(true)), // set isLoading to true
    switchMap((_params) =>
      this.httpClient.get(
        `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/AuthUserDomains/GetAll`,
        { params: _params }
      )
    ),
    tap(() => this.loadingBehaviorSubject.next(false)), // set isLoading to false
    shareReplay(1) // make sure all subscriptions share the same http call (otherwise there will be a http call for each subscription)
  );

  // AuthUser listing
  public allAuthUsers$: Observable<AuthUserDomain<TAuthUser>[]> =
    this.AuthUsersResponse$.pipe(map((res: any) => res.value));

  // AuthUser listing
  public AuthUsersList$: Observable<AuthUserDomain<TAuthUser>[]> =
    this.allAuthUsersResponse$.pipe(map((res: any) => res.value));

  // total number of AuthUser records based on filtering
  public totalRecords$: Observable<number> = this.AuthUsersResponse$.pipe(
    map((res: any) => res['@odata.count'])
  );

  // total number of AuthUser records
  public totalAuthUsersRecords$: Observable<number> =
    this.allAuthUsersResponse$.pipe(map((res: any) => res['@odata.count']));

  // set the current page
  page(page: any) {
    this.pageBehaviorSubject.next(page);
  }

  // sets the sort property and order
  sort(sort: any) {
    this.sortBehaviorSubject.next(sort);
  }

  // sets the search phrase
  search(search: string) {
    const page = this.pageBehaviorSubject.value;
    page.pageIndex = 0;
    page.previousPageIndex = 0;
    this.searchBehaviorSubject.next(search);
    this.pageBehaviorSubject.next(page);
  }

  // reloads/refreshes the AuthUser listing
  reload() {
    // reload the AuthUser data
    this.reloadBehaviorSubject.next(uuidv4());
  }

  getOnlyActiveUsers() {
    this.onlyActiveUsersBehaviorSubject.next(true);
  }

  resetActiveUsersFlag() {
    this.onlyActiveUsersBehaviorSubject.next(false);
  }

  // changes the view mode of the AuthUser listing
  toggleViewMode(mode: string) {
    this.viewModeBehaviorSubject.next(mode);
    if (mode.toUpperCase() !== 'EXPANSION') {
      this.pageBehaviorSubject.next(DEFAULT_PAGING);
    }
  }

  // gets an AuthUser by id
  geTAuthUser(AuthUserId: string): Observable<TAuthUser> {
    const url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/AuthUsers/${AuthUserId}`;
    return this.httpClient.get<TAuthUser>(url);
  }

  getUserRoles(orgPath: string): Observable<any> {
    const url = `${environment.pr1ApiUrl}/${orgPath}/${this.organizationService.organization?.version}/AuthUserDomains/GetRolesList`;
    return this.httpClient.get<any>(url);
  }

  getDivisions(orgPath: string): Observable<any> {
    const url = `${environment.pr1ApiUrl}/${orgPath}/${this.organizationService.organization?.version}/Divisions`;
    return this.httpClient.get<any>(url);
  }

  getEventTypes(orgPath: string): Observable<any> {
    const url = `${environment.pr1ApiUrl}/${orgPath}/${this.organizationService.organization?.version}/EventTypes`;
    return this.httpClient.get<any>(url);
  }

  getChannels(orgPath: string): Observable<any> {
    const url = `${environment.pr1ApiUrl}/${orgPath}/${this.organizationService.organization?.version}/Channels`;
    return this.httpClient.get<any>(url);
  }

  getAuth0Orgs(): Observable<any> {
    const url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/AuthUserDomains/GetAuth0Orgs`;
    return this.httpClient.get<any>(url);
  }

  // an AuthUser domain by id
  getAuthUserDomain(AuthUserId: string): Observable<AuthUserDomain<TAuthUser>> {
    const url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/AuthUserDomains/${AuthUserId}?$expand=Detail`;
    return this.httpClient.get<AuthUserDomain<TAuthUser>>(url);
  }

  // up-serts AuthUser
  saveAuthUser(AuthUser: AuthUserDomain<TAuthUser>) {
    let url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/AuthUserDomains/`;
    if (!AuthUser.Detail.Id || AuthUser.Detail.Id === '0') {
      // create new record
      return this.httpClient.post(url, AuthUser);
    } else {
      // edit existing record
      url += `${AuthUser.Detail.Id}`;
      return this.httpClient.put(url, AuthUser);
    }
  }

  // up-serts AuthUser domain
  saveAuthUserDomain(
    AuthUserDomain: AuthUserDomain<TAuthUser>,
    pathName: string
  ) {
    let url = `${environment.pr1ApiUrl}/${pathName}/${this.organizationService.organization?.version}/AuthUserDomains/`;

    if (!AuthUserDomain.Detail.Id || AuthUserDomain.Detail.Id === '0') {
      // clear the Detail Id
      AuthUserDomain.Detail.Id = '';
      // create new record
      return this.httpClient.post(url, AuthUserDomain);
    } else {
      // edit existing record
      url += `${AuthUserDomain.Detail.Id}`;
      return this.httpClient.put(url, AuthUserDomain);
    }
  }

  // deletes an AuthUser by id
  deleteAuthUser(id: string) {
    const url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/AuthUserDomains/${id}`;
    return this.httpClient.delete(url);
  }

  deleteAuthUsers(AuthUsers: any) {
    const url = `${environment.pr1ApiUrl}/${
      this.organizationService.organization?.apiPath
    }/${
      this.organizationService.organization?.version
    }/AuthUserDomains/DeleteUsers?${this.prepareAssetStringtoDelete(
      AuthUsers
    )}`;
    return this.httpClient.post(url, AuthUsers);
  }

  deactivateUser(AuthUsers: any) {
    const url = `${environment.pr1ApiUrl}/${
      this.organizationService.organization?.apiPath
    }/${
      this.organizationService.organization?.version
    }/AuthUserDomains/DeActivateUsers?${this.prepareAssetStringtoDelete(
      AuthUsers
    )}`;
    return this.httpClient.post(url, AuthUsers);
  }

  activateUser(AuthUsers: any) {
    const url = `${environment.pr1ApiUrl}/${
      this.organizationService.organization?.apiPath
    }/${
      this.organizationService.organization?.version
    }/AuthUserDomains/ActivateUsers?${this.prepareAssetStringtoDelete(
      AuthUsers
    )}`;
    return this.httpClient.post(url, AuthUsers);
  }

  prepareAssetStringtoDelete(authUsers: any) {
    let urlParams = '';
    if (authUsers && authUsers.length > 0) {
      for (let index = 0; index <= authUsers.length - 1; index++) {
        urlParams += `keys=${authUsers[index]?.Detail?.Id}`;
        if (index != authUsers.length - 1) {
          urlParams += '&';
        }
      }
    }
    return urlParams;
  }

  // adds filters to the AuthUser listing
  addFilters(newFilters: Filter[]) {
    const filters = this.filterBehaviorSubject.value;

    newFilters.forEach((filter) => {
      if (
        filters.findIndex(
          (item) =>
            item.fieldName.toLowerCase() === filter.fieldName.toLowerCase() &&
            item.value.toLowerCase() === filter.value.toLowerCase()
        ) === -1
      ) {
        filters.push(filter);
      }
    });

    this.filterBehaviorSubject.next(filters);
  }

  // removes a filter from the AuthUser listing
  removeFilter(filter: Filter) {
    const filters = this.filterBehaviorSubject.value.filter(
      (item) => item !== filter
    );
    this.filterBehaviorSubject.next(filters);
  }

  // removes a filter from the AuthUser listing
  removeFilterByFieldName(fieldName: string) {
    const filters = this.filterBehaviorSubject.value.filter(
      (item) => item.fieldName.toLowerCase() !== fieldName.toLowerCase()
    );
    this.filterBehaviorSubject.next(filters);
  }

  // removes all filters for the AuthUser listing
  clearFilters() {
    this.filterBehaviorSubject.next([]);
  }

  // build the list of filter parameters
  private buildFilterParams(filters: Filter[], params: HttpParams): HttpParams {
    // get the division id filters
    const divisionIdFilters = filters.filter(
      (item) => item.fieldName.toLowerCase() === 'divisionid'
    );

    // loop through the division id filters and add filter statement to param
    divisionIdFilters.forEach((filter) => {
      params = params.append('divisionIds', filter.value);
    });

    // get the AuthUser type id filter
    const AuthUserTypeIdFilter = filters.filter(
      (item) => item.fieldName.toLowerCase() === 'AuthUsertypeid'
    );

    // loop through the AuthUser type id filter and add filter statement to param
    if (AuthUserTypeIdFilter.length > 0) {
      const AuthUserType: any = filters.filter(
        (item) => item.fieldName.toLowerCase() === 'AuthUsertypeid'
      )[0].value;
      params = params.append('AuthUserTypeId', AuthUserType.Id);
    }

    // return the params
    return params;
  }

  downloadAuthUsersJSON() {
    const url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/AuthUsers/EtlExport`;
    window.open(url, '_blank');
  }

  downloadAuthUsersCSV() {
    const url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/AuthUsers/Export`;
    window.open(url, '_blank');
  }

  cloneAuthUser(
    sourceId: string,
    includeVersions: boolean,
    keepStatus: boolean,
    currentObj: any
  ) {
    const url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/AuthUserDomains/CloneAuthUser`;
    return this.httpClient.post<any>(url, currentObj, {
      params: {
        sourceId: sourceId,
        includeVersions: includeVersions,
        keepStatus: keepStatus,
      },
    });
  }
}
export { AuthUser, AuthUserDomain };
