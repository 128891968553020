import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, of, tap } from 'rxjs';
import { DEFAULT_SNACKBAR_CONFIG, Logger } from 'src/app/@shared';
import { EventAssetsService, Offer, OfferAsset, OfferDomain, OfferService, ProductAssetsService, Version, VersionService } from '../..';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AuthorizeControlService } from 'pr1-ui-components';
import { SelectionModel } from '@angular/cdk/collections';
import { Sort } from '@angular/material/sort';

const log = new Logger('ProductAssetRankComponent');

interface DialogData {
  offerName: string;
  eventId: string;
  selectedVersionsList: any;
}

@Component({
  selector: 'app-clone-offer',
  templateUrl: './clone-offer.component.html',
  styleUrls: ['./clone-offer.component.scss']
})

export class CloneOfferComponent<T extends Offer, TOfferDomain extends OfferDomain<Offer>, TVersion extends Version> implements OnInit {

  offer$: Observable<OfferDomain<T>> = of({} as OfferDomain<T>);
  offerName: string = '';
  formGroup = new FormGroup({
    Detail: new FormGroup({
      Name: new FormControl<string>('', [Validators.required, Validators.minLength(1), Validators.maxLength(100)]),
    })

  });
  private eventId: any = '';
  versionList: Version[] = [];
  displayedColumns = ['select', 'VersionName'];
  selection = new SelectionModel<Version>(true, []);

  constructor(private eventassetService: EventAssetsService<OfferAsset>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData, private versionService: VersionService<TVersion>,
    private dialogRef: MatDialogRef<CloneOfferComponent<Offer, OfferDomain<Offer>, Version>>) { }

  ngOnInit(): void {
    log.debug('init');
    this.eventId = this.data.eventId;
    this.versionService.getVersionsForVariants(this.data.eventId).subscribe((data) => {
      this.versionList = data;
      if (this.data.selectedVersionsList && this.data.selectedVersionsList.length > 0) {
        this.selection.clear();
        this.data.selectedVersionsList.forEach((row: any) => {
          const version = this.versionList.find(i => i.Id === row.Id)
          if (version) {
            this.selection.select(version)
          }
        });
      }
    })
    //this.eventassetService.eventId = this.eventId;
    this.formGroup.controls['Detail'].controls['Name'].patchValue(this.data.offerName + ' Copy');
    this.offerName = this.data.offerName;

  }


  trimControlValues(formGroup: FormGroup): void {
    Object.keys(formGroup.controls).forEach((key: string) => {
      const abstractControl = formGroup.get(key);
      if (abstractControl instanceof FormGroup) {
        this.trimControlValues(abstractControl);
      } else {
        if (typeof abstractControl?.value == 'string') {
          abstractControl.setValue(abstractControl?.value.trim());
        }
      }
    })
  }

  saveData() {
    this.trimControlValues(this.formGroup);
    const datatoReturn: any = {
      selectedIds: this.selection.selected,
      formvalues: this.formGroup.value
    }
    this.dialogRef.close(datatoReturn);
  }

  onSort(sortState: Sort): void {
    this.versionService.sort(sortState);
    this.selection.clear();
  }

  cancel(): void {
    this.dialogRef.close();
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.versionList.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.versionList.forEach(row => this.selection.select(row));
  }

  public getSelectedSectionRecords() {
    return this.selection.selected;
  }

  public clearSelection() {
    this.selection.clear();
  }
}
