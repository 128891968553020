import { Component } from '@angular/core';
import { Coupon } from '../../models/coupon.model';
import { combineLatest, map } from 'rxjs';
import { FormArray, FormGroup } from '@angular/forms';
import { SelectionModel } from '@angular/cdk/collections';
import { CouponService } from '../../services/coupon.service';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';

@Component({
  selector: 'app-coupon-list-table',
  moduleId: module.id,
  templateUrl: 'coupon.list.table.component.html',
  styleUrls: ['coupon.list.table.component.scss']
})
export class CouponListTableComponent {
  viewModel$ = combineLatest([
    this.couponService.isLoading$,
    this.couponService.couponList$,
    this.couponService.page$,
    this.couponService.totalRecordsCount$
  ]).pipe(
    map(([isLoading, coupons, page, totalRecords]) => {
      return { isLoading, coupons, page, totalRecords }
    }),
  );

  displayedColumns = [
    'select',
    'Image',
    'Summary',
    'Description',
    'RedemptionStartDate',
    'Status',
    'ExpiryDate',
    'Actions',
  ];

  form = new FormGroup({
    versions: new FormArray([])
  });
  selection = new SelectionModel<Coupon>(true, []);
  couponsList: Coupon[] = []
  
  constructor(private couponService: CouponService<Coupon>) {
  }

  ngOnInit() {
    this.couponService.couponList$.subscribe(res => {
      this.couponsList = res;
    })
  }

  onPage(pageEvent: PageEvent): void {
    this.selection.clear();
    this.couponService.page(pageEvent);
  }

  public getSelectedSectionRecords() {
    return this.selection.selected;
  }

  updateTableStatus(status: string, row: any) {
    let selectedRecordsDetails: any;
    selectedRecordsDetails = [JSON.parse(JSON.stringify(row))];
    this.updateRecordsStatus(selectedRecordsDetails, status);
  }

  updateRecordsStatus(selectedRecordsDetails: Array<any>, status: any) {
    if (selectedRecordsDetails && selectedRecordsDetails.length > 0) {
      selectedRecordsDetails = selectedRecordsDetails.map((x: any) => x.Id);
      this.couponService.updateStatus(status, selectedRecordsDetails).subscribe({
        next: () => {
          this.couponService.reload();
          this.selection.clear();
          this.couponService.updateRecordStatus(new Date());
        },
      });
    }
  };

  clearSelection() {
    this.selection.clear();
  }

  isSelected(coupon: Coupon) {
    return this.selection.isSelected(coupon);
  }

  onSort(sortState: Sort): void {
    this.couponService.sort(sortState);
    this.selection.clear();
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.couponsList.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected() ?
    this.selection.clear() :
    this.couponsList.forEach(row => this.selection.select(row));
  }
}
