<form *ngIf="editWorkflowForm" [formGroup]="editWorkflowForm">
  <div class="edit-workflow-container">
    <div class="first-section">
      <div class="flex-space-between">
        <h1>
          {{
            editWorkflowForm.value?.template_info?.template_name
              ? editWorkflowForm.value?.template_info?.template_name
              : template_header
          }}
        </h1>
        <span class="close-icon" (click)="closeEditWorkflow()">
          <fa-icon [icon]="['fas', 'x']"></fa-icon>
        </span>
      </div>

      <!-- Template Details -->
      <div
        fxLayout="row wrap"
        fxLayoutGap.gt-sm="16px"
        fxLayout.lt-md="column"
        fxFlexFill
        formGroupName="template_info"
      >
        <div fxFlex="30" class="templateNameControlDiv">
          <mat-form-field appearance="outline" class="templateNameControl">
            <mat-label>Template Name</mat-label>
            <input matInput required formControlName="template_name" />
            <br />
          </mat-form-field>
          <small *ngIf="nameExists != null" style="padding-left: 0.4rem"
            >Template "{{ nameExists }}" already exists.</small
          >
        </div>
        <div style="flex-direction: column !important" fxFlex="30">
          <mat-form-field
            appearance="outline"
            style="
              width: 100% !important;
              padding-bottom: 0 !important;
              max-height: 68% !important;
            "
          >
            <mat-label>Group</mat-label>
            <mat-select formControlName="template_group" name="template_group">
              <mat-option
                *ngFor="let w of workflowActiveGroupsRefData"
                [value]="w.value"
              >
                <span>{{ w.label }}</span>
              </mat-option>
            </mat-select>
            <br />
          </mat-form-field>

          <div class="add-group-link-div">
            <span class="add-group-link" (click)="onAddGroup()"
              >+ Add Group</span
            >
          </div>
        </div>

        <mat-form-field appearance="outline" fxFlex="30">
          <mat-label>Category</mat-label>
          <input
            matInput
            formControlName="template_category"
            name="template_category"
          />
          <br />
        </mat-form-field>
      </div>
    </div>

    <!-- Workflow Config -->
    <mat-accordion class="config-accordion">
      <mat-expansion-panel [expanded]="true">
        <mat-expansion-panel-header>
          <mat-panel-title>Workflow Configuration</mat-panel-title>
        </mat-expansion-panel-header>

        <div
          fxLayout="row wrap"
          fxLayoutGap.gt-sm="16px"
          fxLayout.lt-md="column"
          fxFlexFill
          formGroupName="template_info"
        >
          <mat-stepper
            [linear]="true"
            labelPosition="bottom"
            #stepper
            (selectionChange)="onSelectionChange($event)"
            cdkDropList
            cdkDropListOrientation="horizontal"
            [cdkDropListData]="availableWorkflowStages"
            (cdkDropListDropped)="drop($event)"
          >
            <mat-step
              *ngFor="
                let stage of availableWorkflowStages;
                let stageIndex = index
              "
              [label]="stage.value?.stage_name"
              [stepControl]="stage"
              [completed]="false"
            >
              <ng-template matStepLabel>
                <div>
                  <p
                    [matMenuTriggerFor]="menu"
                    style="padding: 10px !important"
                  >
                    {{ stage.value?.stage_name }}
                  </p>
                </div>
                <div
                  class="stage-drag grabbable"
                  cdkDragLockAxis="x"
                  cdkDrag
                ></div>
              </ng-template>
              <mat-menu #menu="matMenu">
                <button mat-menu-item (click)="removeStage(stageIndex)">
                  Remove Stage
                </button>
              </mat-menu>
            </mat-step>
          </mat-stepper>
        </div>
      </mat-expansion-panel>
    </mat-accordion>

    <!-- <div
      *ngIf="availableWorkflowStages.length == 0"
      class="add-stage-link-container"
    >
      <b><a (click)="addStage()">+ Add Stage</a></b>
    </div> -->

    <!-- Workflow Stages -->
    <div formArrayName="workflow_stages">
      <div style="margin-top: 1rem !important">
        <mat-accordion
          cdkDropList
          [cdkDropListData]="availableWorkflowStages"
          (cdkDropListDropped)="drop($event)"
        >
          <mat-expansion-panel
            style="margin: 1rem 0"
            *ngFor="let w of availableWorkflowStages; let i = index"
            [ngClass]="{ 'mat-expansion-panel-error': duplicateStageFound[i] }"
            [expanded]="expandedPanels[i] == true"
            (opened)="onPanelOpen(i)"
            cdkDrag
          >
            <mat-expansion-panel-header>
              <mat-panel-title
                [ngClass]="{ 'panel-title-error': duplicateStageFound[i] }"
                style="justify-content: flex-start; gap: 1rem"
              >
                <div class="skip-drag-container">
                  <span cdkDragHandle class="grabbable">
                    <mat-icon class="drag-indicator-icon"
                      >drag_indicator</mat-icon
                    >
                  </span>
                </div>
                <div style="font-weight: bold">
                  {{ availableWorkflowStages[i].value?.stage_name }} -
                  <a (click)="removeStage(i)">Remove Stage</a>
                </div>
              </mat-panel-title>
            </mat-expansion-panel-header>

            <div
              fxLayout="row wrap"
              fxLayoutGap.gt-sm="16px"
              fxLayout.lt-md="column"
              fxFlexFill
              formGroupName="{{ i }}"
            >
              <!-- First Row -->
              <div
                fxLayout="row wrap"
                fxLayoutGap.gt-sm="16px"
                fxLayout.lt-md="column"
                fxFlexFill
              >
                <mat-form-field appearance="outline" fxFlex="31">
                  <mat-label>Stage Name</mat-label>
                  <input
                    matInput
                    required
                    formControlName="stage_name"
                    (input)="checkIfStageExists($event, i)"
                  />

                  <br />
                  <small *ngIf="duplicateStageFound[i]"
                    >Stage already exists. Please choose a different
                    name.</small
                  >
                </mat-form-field>

                <mat-form-field appearance="outline" fxFlex="31">
                  <mat-label>Description</mat-label>
                  <input matInput formControlName="stage_description" />
                  <br />
                </mat-form-field>

                <mat-form-field appearance="outline" fxFlex="31">
                  <mat-label data-testid="Task">Task</mat-label>
                  <mat-select
                    formControlName="stage_task"
                    name="stage_task"
                    (selectionChange)="onStageTaskChange($event, i)"
                  >
                    <mat-option *ngFor="let task of stageTasks" [value]="task">
                      <span>{{ task }}</span>
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

              <!-- Second Row -->
              <div
                fxLayout="row wrap"
                fxLayoutGap.gt-sm="16px"
                fxLayout.lt-md="column"
                fxFlexFill
                style="margin-top: 2.5rem !important"
              >
                <!-- Users Heading -->
                <div
                  fxLayout="row wrap"
                  fxLayoutGap.gt-sm="16px"
                  fxLayout.lt-md="column"
                  class="users-table-heading"
                >
                  <h3 class="users-heading">Users</h3>

                  <button
                    type="button"
                    mat-raised-button
                    color="accent"
                    matTooltip="Add User"
                    (click)="openAddUsersToWorkflow(i)"
                  >
                    <fa-icon [icon]="['fas', 'plus']"></fa-icon>Add User
                  </button>

                  <!-- Actions drop down -->
                  <button
                    mat-raised-button
                    [matMenuTriggerFor]="actionsMenu"
                    *ngIf="showActions[i] == true"
                  >
                    <mat-icon>more_vert</mat-icon>
                    Actions
                  </button>
                  <mat-menu class="actions-mat-menu" #actionsMenu="matMenu">
                    <button mat-menu-item [matMenuTriggerFor]="roles">
                      <mat-icon>mail</mat-icon><span>Role</span>
                    </button>

                    <button mat-menu-item [matMenuTriggerFor]="emailAlertsMenu">
                      <mat-icon>notifications</mat-icon
                      ><span>Email Alerts</span>
                    </button>

                    <button mat-menu-item (click)="deleteSelectedUsers(i)">
                      <mat-icon>delete</mat-icon>
                      <span>Delete</span>
                    </button>

                    <button mat-menu-item style="margin-top: 2px">
                      <span>{{ selectedUsersIndex?.length }} Selected</span>
                    </button>
                  </mat-menu>

                  <!-- Task Menu -->
                  <mat-menu #roles="matMenu">
                    <button
                      mat-menu-item
                      *ngFor="let role of rolesRefData"
                      (click)="rolesMultiSet(role.label, i)"
                    >
                      {{ role.label }}
                    </button>
                  </mat-menu>

                  <!-- Email Alerts Menu -->
                  <mat-menu #emailAlertsMenu="matMenu">
                    <button
                      mat-menu-item
                      *ngFor="let alert of emailAlertsRefData"
                      (click)="emailAlertsMultiSet(alert.label, i)"
                    >
                      {{ alert.label }}
                    </button>
                  </mat-menu>
                </div>

                <!-- Users Table -->
                <div class="users-table">
                  <admin-workflow-users-table
                    [tableData]="availableWorkflowStages[i].value?.users"
                    [usersDeleted]="usersDeleted"
                    (onCheckboxSelected)="onSelectUsers($event, i)"
                    (onUsersSelected)="selectUsers($event, i)"
                  ></admin-workflow-users-table>
                </div>
              </div>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </div>

    <div class="add-stage-link-container">
      <b><a (click)="addStage()">+ Add Stage</a></b>
    </div>

    <div class="edit-workflow-actions">
      <button mat-button (click)="closeEditWorkflow()">Cancel</button>
      <button
        mat-raised-button
        color="accent"
        (click)="onSave()"
        [disabled]="
          duplicateInAnyStage ||
          templateInfoInvalid() ||
          availableWorkflowStages.length == 0 ||
          anyStageInvalid() ||
          anyStageWithNoUsers()
        "
      >
        Save
      </button>
    </div>
  </div>
</form>
