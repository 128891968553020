import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConfirmDialogComponent, DEFAULT_SNACKBAR_CONFIG, Logger } from 'src/app/@shared';
import { Product, ProductCategory, ProductBrand, ProductService, ProductCategoryService, ProductBrandService } from '../..';

const log = new Logger('ProductsComponent');

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss']
})

export class ProductsComponent<T extends Product, TProductCategory extends ProductCategory, TProductBrand extends ProductBrand> implements OnInit {
  @ViewChild('productsTable', { static: false }) productsTable: any;
  productCategories$: TProductCategory[] = [] as TProductCategory[];
  productBrands$: TProductBrand[] = [] as TProductBrand[];

  public get productService(): ProductService<T> {
    return this._productService;
  }
  public set productService(value: ProductService<T>) {
    this._productService = value;
  }

  constructor(private _productService: ProductService<T>,
    private categoryService: ProductCategoryService<TProductCategory>,
    private productBrandService: ProductBrandService<TProductBrand>,
    private dialog: MatDialog,
    private matSnackBar: MatSnackBar) { }

  ngOnInit(): void {
    log.debug('init');
    this.clearSearch();
    // this.categoryService.allCategories$.subscribe((data) => {
    //   this.productCategories$ = data;
    // });

    // this.productBrandService.allProductBrands$.subscribe((data) => {
    //   this.productBrands$ = data;
    // });
    this.productService.reload();
  }

  onSearch(event: any) {
    this.productService.search(event.target.value);
  }
  clearSearch() {
    this.filtersForm.controls.search.setValue('');
    this.productService.search('');
  }

  filtersForm = new FormGroup({
    search: new FormControl(),
    productName: new FormControl(),
    productDisplayName: new FormControl(),
    sku: new FormControl(''),
  });

  onDateRangeChange() {
    if (this.filtersForm.valid) {
    }
  }

  resetFilters() {
    this.filtersForm.reset();
  }

  deleteSelectedRecords() {
    let selectedRecords: any;
    if (this.productsTable) {
      selectedRecords = this.productsTable.getSelectedSectionRecords();
    } 
    if (selectedRecords && selectedRecords.length > 0) {
      const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
        data: {
          title: 'Confirm Delete',
          message: `Do you want to remove Product(s)?`,
        },
        disableClose: true,
      });

      confirmDialog.afterClosed().subscribe(
        confirmResult => {
          if (confirmResult) {
            this.productService.deleteProducts(selectedRecords).subscribe({
              next: () => {
                this.matSnackBar.open(`Product(s) deleted`, 'OK', DEFAULT_SNACKBAR_CONFIG);
                this.clearSearch();
                this.productService.reload();
                if (this.productsTable) {
                  this.productsTable.clearSelection();
                } 
              },
              error: (error) => {
                log.error('Error in deleting Product', error);

                if (error.error.value) {
                  throw new Error(error.error.value);
                } else {
                  throw new Error(error.message);
                }
              }
            });
          }
        });
    }
  }

  downloadJSON(){
    this.productService.downloadProductsJSON();
  }

  downloadCSV(){
    this.productService.downloadProductsCSV();
  }
}
