<!-- page title -->
<h1>Event Management</h1>

<nav mat-tab-nav-bar [tabPanel]="tabPanel">
  <a mat-tab-link [routerLink]="['details']" routerLinkActive #details="routerLinkActive"
    [active]="details.isActive">Details
  </a>
  <a mat-tab-link *ngIf="versionId !=='0'"  [routerLink]="['stores']" routerLinkActive #stores="routerLinkActive"
    [active]="stores.isActive">Stores
  </a>
  <a mat-tab-link *ngIf="versionId !=='0'"  [routerLink]="['version-assets']" routerLinkActive #assets="routerLinkActive"
    [active]="assets.isActive">Asset List
  </a>
</nav>
<mat-tab-nav-panel #tabPanel>
  <router-outlet></router-outlet>
</mat-tab-nav-panel>