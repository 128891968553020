<form *ngIf="asset$ | async as AssetDetails; else loading" [formGroup]="formGroup" novalidate>
    <mat-card appearance="outlined" class="styled-card">
        <h3>{{formGroup.controls.AssetName.value}} </h3>
        <mat-card-content fxLayout="column">
  <mat-form-field appearance="outline">
    <mat-label>Product Rank</mat-label>
    <input matInput type="text" formControlName="Rank" />
    <mat-error *ngIf="formGroup.controls.Rank.hasError('required')">
        Rank is required
    </mat-error>
</mat-form-field>
</mat-card-content>
</mat-card>

<mat-card-actions fxLayout="row" fxLayoutAlign="end">

    <div>
        <button mat-stroked-button type="button" (click)="cancel()">
            Cancel
        </button>
        <button mat-raised-button type="button" color="accent" (click)="saveData()">
            Save
        </button>
    </div>
</mat-card-actions>

</form>
<ng-template #loading>
    <mat-progress-bar mode="indeterminate" color="accent"></mat-progress-bar>
</ng-template>
