import { NgModule } from '@angular/core';
import { EventDetailsComponent } from '.';
import { SharedModule } from 'src/app/@shared';
import { WalgreensV1RoutingModule } from './walgreens.v1.routing.module';

@NgModule({
  declarations: [
    EventDetailsComponent,
  ],
  imports: [
    SharedModule,
    WalgreensV1RoutingModule
  ]
})

export class WalgreensV1Module { }
