import { Component, ViewChild } from '@angular/core';
import { Banner } from '../../../models/banner.model';
import { Observable, of, tap } from 'rxjs';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BannersService } from '../../../services/banners.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent, DEFAULT_SNACKBAR_CONFIG, Logger } from 'src/app/@shared';

const log = new Logger('BannerDetailsComponent');

@Component({
  selector: 'app-banner-details',
  templateUrl: './banner-details.component.html',
  styleUrls: ['./banner-details.component.scss']
})
export class BannerDetailsComponent<TBanner extends Banner> {
  banner$: Observable<TBanner> = of({} as TBanner);
  formGroup = new FormGroup({
    ClientKey: new FormControl<string>('', [Validators.required, Validators.minLength(1), Validators.maxLength(200)]),
    Id: new FormControl<string>({ value: '', disabled: true }, { nonNullable: true }),
    BannerName: new FormControl<string>('', [Validators.required, Validators.minLength(1), Validators.maxLength(100)]),
  });
  bannerId: string = '0';

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private bannersService: BannersService<TBanner>,
    private matSnackBar: MatSnackBar,
    private dialog: MatDialog) { }

  ngOnInit() {
    log.debug('init');
    // get the banner id from the parent route
    this.bannerId = this.route.snapshot.params['bannerId'];

    // if we are editing a record, get the record data
    if (this.bannerId && this.bannerId !== '0') {
      this.banner$ = this.bannersService.getBanner(this.bannerId).pipe(
        tap((banner) => {
          this.formGroup.patchValue(banner);
          this.formGroup.markAllAsTouched();
        })
      );
    }
  }

  trimControlValues(formGroup: FormGroup): void {
    Object.keys(formGroup.controls).forEach((key: string) => {
      const abstractControl = formGroup.get(key);
      if (abstractControl instanceof FormGroup) {
        this.trimControlValues(abstractControl);
      } else {
        if (typeof abstractControl?.value == 'string') {
          abstractControl.setValue(abstractControl?.value.trim());
        }
      }
    })
  }
  
  save() {
    this.trimControlValues(this.formGroup);
    if (this.formGroup.valid) {
      this.bannersService.saveBanner(this.formGroup.getRawValue() as TBanner).subscribe({
        next: (response) => {
          this.matSnackBar.open(
            `${this.formGroup.controls.BannerName.value} saved`, 'OK', DEFAULT_SNACKBAR_CONFIG
          );
          this.bannersService.reload();
          this.router.navigate([`../`], { relativeTo: this.route, queryParamsHandling: 'preserve' });
        },
        error: (error) => {
          if (error.status === 500) {
            log.error('500 Error saving event', error);
            this.matSnackBar.open('500 Error saving event', 'Error', { verticalPosition: 'top', panelClass: ['snackbar-error'] });
          }
          
          if (error.status === 400) {
            const apiValidations: any = error.error;
            if (Array.isArray(apiValidations)) {
              apiValidations.forEach((validation: any) => {
                if (this.formGroup?.get(validation.PropertyName)) {
                  const control = this.formGroup?.get(validation.PropertyName);
                  if (control) {
                    control.markAsTouched();
                    control.setErrors({ invalid: validation.ErrorMessage });
                    this.matSnackBar.open(validation.ErrorMessage, 'OK', { verticalPosition: 'top', panelClass: ['snackbar-error'] });
                  }
                } else {
                  ///TODO: if we have cross field validation then show the validation error at the top of the screen
                  // if we have cross field validation then show the validation error at the top of the screen
                  // push general error messages to array this is displayed in a toast or dialog
                }
              });
            } else {
              this.matSnackBar.open(apiValidations, 'Error', { verticalPosition: 'top', panelClass: ['snackbar-error'] });
            }


          }
        }
      });
    }
  }

  cancel(): void {
    this.formGroup.reset();
    this.router.navigate([`../`], { relativeTo: this.route, queryParamsHandling: 'preserve' });
  }

  deleteRecord() {
    const record = this.formGroup.getRawValue();
    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Confirm Delete',
        message: `Are you sure you wish to delete ${record.BannerName}?`,
      },
      disableClose: true,
    });

    confirmDialog.afterClosed().subscribe(
      confirmResult => {
        if (confirmResult) {
          this.bannersService.deleteBanner(record.Id).subscribe({
            next: () => {
              this.matSnackBar.open(`${record.BannerName} deleted`, 'OK', DEFAULT_SNACKBAR_CONFIG);
              this.bannersService.reload();
              this.router.navigate([`../`], { relativeTo: this.route, queryParamsHandling: 'preserve' });
            },
            error: (error) => {
              log.error('Error deleting event', error);

              if (error.error.value) {
                throw new Error(error.error.value);
              } else {
                throw new Error(error.message);
              }
            }
          });
        }
      });
  }

}
