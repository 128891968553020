import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, catchError, combineLatest, debounceTime, distinctUntilChanged, map, Observable, shareReplay, switchMap, tap, } from 'rxjs';
import { environment } from 'src/environments/environment';
import { OrganizationService } from 'src/app/@shared';
import { v4 as uuidv4 } from 'uuid';
import * as dayjs from 'dayjs';
import { DEFAULT_PAGING } from 'src/app/@shared/constants/site.constants';
import { Filter } from 'src/app/@shared/models/filter.model';
// import { CouponAsset } from '..';

const DEFAULT_DATE_RANGE = {
  startDate: dayjs().subtract(1, 'days').format('YYYY-MM-DD'),
  endDate: dayjs().add(5, 'months').format('YYYY-MM-DD'),
};

@Injectable({
  providedIn: 'root'
})

export class CouponAssetsService<T extends any> {

  // initialize behavior subjects
  private dateRangeBehaviorSubject = new BehaviorSubject(DEFAULT_DATE_RANGE);
  private pageBehaviorSubject = new BehaviorSubject(DEFAULT_PAGING);
  private loadingBehaviorSubject = new BehaviorSubject<boolean>(false);
  private searchBehaviorSubject = new BehaviorSubject<string>('');
  private sortBehaviorSubject = new BehaviorSubject({ active: 'Rank', direction: 'asc', });
  private reloadBehaviorSubject = new BehaviorSubject<string>('');
  private filterBehaviorSubject = new BehaviorSubject<Filter[]>([]);
  private viewModeBehaviorSubject = new BehaviorSubject<string>('CARDS');
  private couponIdBehaviorSubject = new BehaviorSubject<string>('');
  private dialogSearchBehaviorSubject = new BehaviorSubject<string>('');
  private dialogPageBehaviorSubject = new BehaviorSubject(DEFAULT_PAGING);
  private dialogSortBehaviorSubject = new BehaviorSubject({ active: 'Rank', direction: 'asc', });
  private dialogReloadBehaviorSubject = new BehaviorSubject<string>('');

  // we do not wish to expose our behavior subjects.  create public observables
  public dateRange$ = this.dateRangeBehaviorSubject.asObservable();
  public page$ = this.pageBehaviorSubject.asObservable();
  public search$ = this.searchBehaviorSubject.asObservable();
  public sort$ = this.sortBehaviorSubject.asObservable();
  public isLoading$ = this.loadingBehaviorSubject.asObservable();
  public filters$ = this.filterBehaviorSubject.asObservable();
  public viewMode$ = this.viewModeBehaviorSubject.asObservable();
  public couponId$ = this.couponIdBehaviorSubject.asObservable();
  public dialogSearch$ = this.dialogSearchBehaviorSubject.asObservable();
  public dialogPage$ = this.dialogPageBehaviorSubject.asObservable();
  public dialogSort$ = this.dialogSortBehaviorSubject.asObservable();
  public dialogReload$ = this.dialogReloadBehaviorSubject.asObservable();

  constructor(private httpClient: HttpClient, private organizationService: OrganizationService) { }

  // create the parameters observable that looks for changes in page, startDate, endDate, etc
  public params$ = combineLatest([
    this.couponIdBehaviorSubject,
    this.pageBehaviorSubject, // add debounce if we need to wait for user input ex: .pipe(debounceTime(300)), 
    this.sortBehaviorSubject,
    this.searchBehaviorSubject.pipe(debounceTime(300)),
    this.filterBehaviorSubject.pipe(debounceTime(50)),
    this.reloadBehaviorSubject,
  ]).pipe(
    distinctUntilChanged((previous, current) => {
      // if the values coming down this pipe are the same, don't continue the pipe
      return JSON.stringify(previous) === JSON.stringify(current);
    }),
    map(([couponId, page, sort, search, reload]) => {

      // set the query string odata parameters
      let params: HttpParams = new HttpParams({
        fromObject: {
        couponId: this.couponIdBehaviorSubject.value,
        // mode: viewMode,
        $skip: page.pageIndex * page.pageSize,
        $top: page.pageSize,
        $orderby: `${sort.active} ${sort.direction}`,
        $expand: 'Detail',
        $count: true
      }
    });

      // if there is a search, add the search to the parameters
      if (search.length) {
        params = params.append('$search', `"${search}"`);
      }

      return params;
    })
  );

  // sets the search phrase
  dialogSearch(search: string) {
    this.dialogSearchBehaviorSubject.next(search);
    this.dialogPageBehaviorSubject.next(DEFAULT_PAGING);
  }

  public dialogParams$ = combineLatest([
    this.dialogPageBehaviorSubject, // add debounce if we need to wait for user input ex: .pipe(debounceTime(300)), 
    this.dialogSortBehaviorSubject,
    this.dialogSearchBehaviorSubject.pipe(debounceTime(300)),
    this.dialogReloadBehaviorSubject,
  ]).pipe(
    distinctUntilChanged((previous, current) => {
      // if the values coming down this pipe are the same, don't continue the pipe
      return JSON.stringify(previous) === JSON.stringify(current);
    }),
    map(([page, sort, search, reload]) => {

      // set the query string odata parameters
      let params: HttpParams = new HttpParams({
        fromObject: {
        couponId: this.couponIdBehaviorSubject.value,
        $skip: page.pageIndex * page.pageSize,
        $top: page.pageSize,
        $orderby: `${sort.active} ${sort.direction}`,
        $expand: 'Detail',
        $count: true,
      }
    });

      // if there is a search, add the search to the parameters
      if (search.length) {
        params = params.append('$search', `"${search}"`);
      }

      return params;
    })
  );

  set couponId(couponId: string) {
    this.couponIdBehaviorSubject.next(couponId);
  }

  //get the list of assets by couponId
  private couponAssetResponse$ = this.params$.pipe(
    tap(() => this.loadingBehaviorSubject.next(true)), // set isLoading to true
    switchMap((_params) =>
      this.httpClient.get(
        `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/AssetDomains/ByCoupon`,
        { params: _params })
    ),
    tap(() => this.loadingBehaviorSubject.next(false)), // set isLoading to false
    shareReplay(1) // make sure all subscriptions share the same http call (otherwise there will be a http call for each subscription)
  );

  //get the list of assets by couponId
  private withoutCouponAssetsResponse$ = this.dialogParams$.pipe(
    tap(() => this.loadingBehaviorSubject.next(true)), // set isLoading to true
    switchMap((_params) =>
      this.httpClient.get(
        `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/AssetDomains/SearchWithoutCouponAssociation`,
        { params: _params })
    ),
    tap(() => this.loadingBehaviorSubject.next(false)), // set isLoading to false
    shareReplay(1) // make sure all subscriptions share the same http call (otherwise there will be a http call for each subscription)
  );

  //assets listing by productId
  public assets$: Observable<T[]> = this.couponAssetResponse$.pipe(
    map((res: any) => res.value)
  );

  //assets listing by productId
  public withoutCouponAssets$: Observable<T[]> = this.withoutCouponAssetsResponse$.pipe(
    map((res: any) => res.value)
  );

  public totalRecords$: Observable<number> = this.couponAssetResponse$.pipe(
    map((res: any) => res['@odata.count'])
  );

  public withoutCouponTotalRecords$: Observable<number> = this.withoutCouponAssetsResponse$.pipe(
    map((res: any) => res['@odata.count'])
  );

  associateAssetToCoupon(couponId: string, assets: string[]) {
    const url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/AssetDomains/AssociateToCoupon?couponId=${couponId}`;
    return this.httpClient.post(url, assets);
  }

  prepareCouponIdString(couponIds: any) {
    let urlParams = '';
    if (couponIds && couponIds.length > 0) {
      for (let index = 0; index <= couponIds.length - 1; index++) {
        urlParams += `couponIds=${couponIds[index]}`;
        if (index != couponIds.length - 1) {
          urlParams += '&'
        }
      }
    }
    return urlParams;
  }

  disassociateAssetToCoupon(couponId: string,  assets: string[]) {
    const url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/AssetDomains/DisAssociateFromCoupon?couponId=${couponId}`;
    return this.httpClient.post(url, assets);
  }

  saveRank(couponIds: string[], dataSave: any) {
    const url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/AssetDomains/UpdateCouponAssetRank?${this.prepareCouponIdString(couponIds)}`;
    return this.httpClient.post(url, dataSave);
  }

  UpdateCouponAssociationRank(couponId: string, productId: string, rank: number) {
    let url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/AssetDomains/UpdateCouponAssociationRank?couponId=${couponId}&assetId=${productId}&rank=${+rank}`;
    return this.httpClient.post(url, {});
  }

  // set the current page
  page(page: any) {
    this.pageBehaviorSubject.next(page);
  }

  dialogNotCouponAssetPage(page: any) {
    this.dialogPageBehaviorSubject.next(page);
  }

  toggleViewMode(mode: string) {
    this.viewModeBehaviorSubject.next(mode);
  }

  // sets the date range of the event listing
  dateRange(start?: string, end?: string) {
    if (start && end) {
      const range = { startDate: start, endDate: end };
      this.pageBehaviorSubject.next(DEFAULT_PAGING);

      this.dateRangeBehaviorSubject.next(range);
    }
  }

  // sets the sort property and order
  sort(sort: any) {
    this.sortBehaviorSubject.next(sort);
  }

  // sets the search phrase
  search(search: string) {
    this.searchBehaviorSubject.next(search);
    this.pageBehaviorSubject.next(DEFAULT_PAGING);
  }

  // reloads/refreshes the store listing
  reload() {
    // reload the Store data
    this.reloadBehaviorSubject.next(uuidv4());
  }

  // reset the current page
  resetpage() {
    this.pageBehaviorSubject.next(DEFAULT_PAGING);
  }

  getRecordsForFilters() {
    return this.httpClient.get(`${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/AssetDomains`);
  }

}
