import { Event } from "..";
import { StatusCount } from "./status-count.model";

export interface EventDomain<TEvent extends Event> {
  Detail: TEvent;
  EventTypeId?: string;
  EventTypeName?: string;
  EventStatus?: StatusCount;
  DivisionIds: string[];
  DivisionNames?: string[];
}
