import { SelectionModel } from '@angular/cdk/collections';
import { Component, Inject, OnInit } from '@angular/core';
import { MediaChange, MediaObserver } from '@angular/flex-layout';
import { FormControl } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Sort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { map, combineLatest, Subscription, distinctUntilChanged } from 'rxjs';
import { Logger } from 'src/app/@shared';
import { ProductBrandDomain, ProductBrand, ProductBrandService } from 'src/app/modules/standard/v1';

const log = new Logger('UploadAssetsProductBrandsComponent');
interface DialogData {
  offerId: string;
}


@Component({
  selector: 'app-upload-assets-product-brands',
  templateUrl: './upload-assets-product-brands.component.html',
  styleUrls: ['./upload-assets-product-brands.component.scss']
})
export class UploadAssetsProductBrandsComponent<TProductBrand extends ProductBrand, TProductBrandDomain extends ProductBrandDomain<ProductBrand>>  implements OnInit {
  selectedProductBrand: any;
  search = new FormControl<string>('');
  viewModel$ = combineLatest([
    this.productBrandService.allProductBrands$,
    this.productBrandService.isLoading$,
    this.productBrandService.uploadBrandtotalRecords$,
    this.productBrandService.uploadBrandpage$,
  ]).pipe(
    map(([allProductBrands, isLoading, totalRecords, page]) => {
      console.log(allProductBrands);
      return { allProductBrands, isLoading, totalRecords, page }
    }),
  );

  flexMediaWatcher!: Subscription;
  displayedColumns = ['ProductBrandName', 'Actions'];
  selection = new SelectionModel<ProductBrand>(true, []);


  constructor(private productBrandService: ProductBrandService<TProductBrand>,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private router: Router,
    private matSnackBar: MatSnackBar,
    private mediaObserver: MediaObserver,
    public dialogRef: MatDialogRef<UploadAssetsProductBrandsComponent<TProductBrand, TProductBrandDomain>>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  ngOnInit(): void {
    log.debug('init');
    // detect changes in viewport size to handle show/hide of table columns
    const getAlias = (MediaChange: MediaChange[]) => {
      return MediaChange[0].mqAlias;
    };

    this.flexMediaWatcher = this.mediaObserver
      .asObservable()
      .pipe(
        distinctUntilChanged(
          (x: MediaChange[], y: MediaChange[]) => getAlias(x) === getAlias(y)
        ))
      .subscribe((change) => {
        if (change.some(x => x.mqAlias === 'xs')) {
          this.displayedColumns = ['ProductBrandName', 'Actions'];
        }
        else {
          this.displayedColumns = ['ProductBrandName','Actions'];
        }
      });

  }


  closeDialog() {
    this.dialogRef.close();
  }


  updateSelectedProductBrand(product: ProductBrand) {
    console.log(product, this.selectedProductBrand);
    this.selectedProductBrand = product;
  }
  saveData() {
    if (this.selectedProductBrand) {
      this.dialogRef.close(this.selectedProductBrand);
      this.productBrandService.uploadBrandsearch('');
    }
  }

  onSort(sortState: Sort): void {
    this.productBrandService.uploadBrandsort(sortState);
    this.selection.clear();
  }

  onPage(pageEvent: PageEvent): void {
    this.selection.clear();
    this.productBrandService.uploadBrandpage(pageEvent);
  }

  onSearch(event: any) {
    this.productBrandService.uploadBrandsearch(event.target.value);
  }

  clearSearch() {
    this.search.setValue('');
    this.productBrandService.uploadBrandsearch('');
   }

  onRowDoubleClick(id: string) {
    this.router.navigate([`${id}/details`], { relativeTo: this.route });
  }

}

