export interface Product {
  ClientKey?: string;
  Id: string;
  ProductName: string;
  ProductDescription?: string;
  ProductDisplayName?: string;
  SKU?: string;
  UPC?: number;
  Category?: string;
  Brand?: string;
  IsAvailableInStoreOnly?: boolean;
  IsExclusive?: boolean
}