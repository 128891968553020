<!-- page title -->
<h1>User Management</h1>

<form [formGroup]="filtersForm" class="user-management">
    <!-- actions bar -->
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px" fxLayout.lt-md="column"
        fxLayoutAlign.lt-md="start start" fxLayoutGap.lt-md="4px" class="actions-bar">
        <button *ngIf="searchInput" type="button" mat-raised-button color="accent" matTooltip="Add new record"
            [routerLink]="[0]" [queryParams]="{search: searchInput.value ? searchInput.value : null}">
            <fa-icon [icon]="['fas', 'plus']"></fa-icon>Add User
        </button>

        <button type="button" *ngIf=" getSelectedSectionRecords() && getSelectedSectionRecords().length > 0"
            mat-raised-button [matMenuTriggerFor]="menu" matTooltip="Actions">
            <mat-icon>more_vert</mat-icon>Actions
        </button>
        <mat-menu #menu="matMenu">
            <button mat-menu-item [matMenuTriggerFor]="user_status">
                <mat-icon>verified_user</mat-icon><span>User Status</span>
            </button>
            <button type="button" mat-menu-item
                [matMenuTriggerFor]="Download"><mat-icon>arrow_circle_down</mat-icon>Download</button>
            <!-- <button type="button" mat-menu-item><mat-icon>file_copy</mat-icon>Copy</button> -->
            <button type="button" mat-menu-item
                (click)="deleteSelectedRecords()"><mat-icon>delete</mat-icon>Delete</button>
            <button type="button" mat-menu-item
                *ngIf="getSelectedSectionRecords() && getSelectedSectionRecords().length > 0">
                {{ getSelectedSectionRecords().length}} Selected
            </button>
        </mat-menu>
        <mat-menu #user_status="matMenu">
            <button mat-menu-item (click)="activateUserToggleSelection('activate')">
                Active
            </button>
            <button mat-menu-item (click)="activateUserToggleSelection('de-activate')">
                Not Active
            </button>
        </mat-menu>
        <mat-menu #Download="matMenu">
            <button mat-menu-item (click)="downloadJSON()">JSON</button>
            <button mat-menu-item (click)="downloadCSV()">CSV</button>
        </mat-menu>

        <mat-form-field appearance="outline" class="mat-form-field-no-padding">
            <mat-icon matPrefix>search</mat-icon>
            <mat-label>Search</mat-label>
            <input matInput formControlName="search" type="text" placeholder="Search" (input)="onSearch($event)"
                #searchInput />
            <button type="button" matSuffix mat-icon-button aria-label="Clear" *ngIf="searchInput && searchInput.value"
                (click)="clearSearch()">
                <mat-icon>close</mat-icon>
            </button>
        </mat-form-field>
    </div>

    <ng-container *ngIf="viewModel$ | async as viewModel">
        <div class="users-table">
            <table mat-table [dataSource]="viewModel.users" class="mat-elevation-z8 user-management-table">
                <!-- Checkbox Column -->
                <ng-container matColumnDef="select">
                    <th class="no-ellipsis" mat-header-cell *matHeaderCellDef>
                        <mat-checkbox (change)="$event ? masterToggle() : null"
                            [checked]="selection.hasValue() && isAllSelected()"
                            [indeterminate]="selection.hasValue() && !isAllSelected()">
                        </mat-checkbox>
                    </th>
                    <td class="no-ellipsis" mat-cell *matCellDef="let row">
                        <mat-checkbox (click)="$event.stopPropagation()"
                            (change)="$event ? selection.toggle(row) : null" [checked]="selection.isSelected(row)">
                        </mat-checkbox>
                    </td>
                </ng-container>

                <!-- Name Column -->
                <ng-container matColumnDef="first_name">
                    <th mat-header-cell *matHeaderCellDef>First Name</th>
                    <td mat-cell *matCellDef="let element">{{ element.Detail.FirstName }}</td>
                </ng-container>

                <ng-container matColumnDef="last_name">
                    <th mat-header-cell *matHeaderCellDef>Last Name</th>
                    <td mat-cell *matCellDef="let element">{{ element.Detail.LastName }}</td>
                </ng-container>

                <!-- Email Column -->
                <ng-container matColumnDef="email">
                    <th mat-header-cell *matHeaderCellDef>Email</th>
                    <td mat-cell *matCellDef="let element">{{ element.Detail.Email }}</td>
                </ng-container>
                <ng-container matColumnDef="user_status">
                    <th mat-header-cell *matHeaderCellDef>User Status</th>
                    <td mat-cell *matCellDef="let element">
                        {{ element.user_status }}

                        <!-- Actions drop down -->
                        <button class="user-status-toggle-button" mat-raised-button [matMenuTriggerFor]="statusMenu"
                            [class]="element.Detail.Active ? 'active-dropdown' : 'notactive-dropdown'">
                            {{ element.Detail.Active == true ? 'Active' : 'Not Active' }}
                            <mat-icon>expand_more</mat-icon>
                        </button>
                        <mat-menu #statusMenu="matMenu" name="userStatus" ngDefaultControl>
                            <button mat-menu-item (click)="
                      element.Detail.Active != true
                        ? activateUserToggle(element)
                        : null
                    ">
                                Active
                            </button>
                            <button mat-menu-item (click)="
                      element.Detail.Active == true
                        ? activateUserToggle(element)
                        : null
                    ">
                                Not Active
                            </button>
                        </mat-menu>
                    </td>
                </ng-container>

                <!-- Edit Link Column -->
                <ng-container matColumnDef="edit_user">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let element">
                        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="32px" class="user-table-actions">
                            <!-- Edit Link -->
                            <div>
                                <a [routerLink]="[element.DomainId]">Edit</a>
                            </div>
                        </div>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
            </table>

            <table *ngIf="!viewModel.users.length">
                <tr>
                    <td class="no-records" [attr.colspan]="displayedColumns.length">
                        <h2>No Records Found</h2>
                    </td>
                </tr>
            </table>

            <!-- pager -->
            <mat-paginator [length]="viewModel.totalRecords" [pageSize]="viewModel.page.pageSize"
                [pageSizeOptions]="[5, 10, 20, 50, 100,500]" (page)="onPage($event)" *ngIf="viewModel.users.length"
                showFirstLastButtons [pageIndex]="viewModel.page.pageIndex">
            </mat-paginator>
        </div>
    </ng-container>


    <ng-template #loading>
        <mat-progress-bar mode="indeterminate" color="accent"></mat-progress-bar>
    </ng-template>

</form>