import { Component } from '@angular/core';
import { combineLatest, map } from 'rxjs';
import { CouponService } from '../../services/coupon.service';
import { Coupon } from '../../models/coupon.model';
import { FormArray, FormGroup } from '@angular/forms';
import { PageEvent } from '@angular/material/paginator';
import { SelectionModel } from '@angular/cdk/collections';

@Component({
  selector: 'app-coupons-list-cards',
  moduleId: module.id,
  templateUrl: 'coupons.list.cards.component.html',
  styleUrls: ['coupons.list.cards.component.scss']
})
export class CouponsListCardsComponent {

  viewModel$ = combineLatest([
    this.couponService.isLoading$,
    this.couponService.couponList$,
    this.couponService.page$,
    this.couponService.totalRecordsCount$
  ]).pipe(
    map(([isLoading, coupons, page, totalRecords]) => {
      return { isLoading, coupons, page, totalRecords }
    }),
  );

  form = new FormGroup({
    versions: new FormArray([])
  });
  selection = new SelectionModel<Coupon>(true, []);
  
  constructor(private couponService: CouponService<Coupon>) {
  }

  onPage(pageEvent: PageEvent): void {
    this.selection.clear();
    this.couponService.page(pageEvent);
  }

  public getSelectedSectionRecords() {
    return this.selection.selected;
  }

  updateTableStatus(status: string, row: any) {
    let selectedRecordsDetails: any;
    selectedRecordsDetails = [JSON.parse(JSON.stringify(row))];
    this.updateRecordsStatus(selectedRecordsDetails, status);
  }

  updateRecordsStatus(selectedRecordsDetails: Array<any>, status: any) {
    if (selectedRecordsDetails && selectedRecordsDetails.length > 0) {
      selectedRecordsDetails = selectedRecordsDetails.map((x: any) => x.Detail.Id);
      this.couponService.updateStatus(status, selectedRecordsDetails).subscribe({
        next: () => {
          this.couponService.reload();
          this.selection.clear();
          this.couponService.updateRecordStatus(new Date());
        },
      });
    }
  };

  clearSelection() {
    this.selection.clear();
  }

  isSelected(coupon: Coupon) {
    return this.selection.isSelected(coupon);
  }
}
