import { Component, OnInit } from '@angular/core';
import { combineLatest, distinctUntilChanged, map, Subscription } from 'rxjs';
import { MediaChange, MediaObserver } from '@angular/flex-layout';
import { Logger, OrganizationService } from 'src/app/@shared';
import { Sort } from '@angular/material/sort';
import { PageEvent } from '@angular/material/paginator';
import { Product, ProductService } from '../..';
import { SelectionModel } from '@angular/cdk/collections';

const log = new Logger('ProductListTableComponent');

@Component({
  selector: 'app-product-list-table',
  templateUrl: './product-list-table.component.html',
  styleUrls: ['./product-list-table.component.scss']
})
export class ProductListTableComponent<T extends Product> implements OnInit {
  private assetsUrl: string = '';
  viewModel$ = combineLatest([
    this.productService.products$,
    this.productService.isLoading$,
    this.productService.totalRecords$,
    this.productService.page$,
    this.organizationService.assetsUrl$
  ]).pipe(
    map(([products, isLoading, totalRecords, page, assetsUrl]) => {
      if (products.length == 0 && page.pageIndex > 0) {
        page.previousPageIndex = 0
        page.pageIndex = 0
        this.productService.page(page);
        this.productService.reload();
        }
        this.assetsUrl = assetsUrl;
      return { products, isLoading, totalRecords, page, assetsUrl }
    }),
  );
  flexMediaWatcher!: Subscription;
  displayedColumns = ['select', 'ProductImage', 'ProductName', 'UPC', 'SKU', 'ClientKey', 'Actions'];
  productsList: Product[] = [];
  selection = new SelectionModel<Product>(true, []);

  constructor(private productService: ProductService<T>, private organizationService: OrganizationService, private mediaObserver: MediaObserver) { }

  ngOnInit() {

    log.debug('init');
    this.productService.products$.subscribe((data) => {
      this.productsList = data;
    })
    // detect changes in viewport size to handle show/hide of table columns
    const getAlias = (MediaChange: MediaChange[]) => {
      return MediaChange[0].mqAlias;
    };

    this.flexMediaWatcher = this.mediaObserver
      .asObservable()
      .pipe(
        distinctUntilChanged(
          (x: MediaChange[], y: MediaChange[]) => getAlias(x) === getAlias(y)
        ))
      .subscribe((change) => {
        if (change.some(x => x.mqAlias === 'xs')) {
          this.displayedColumns = ['select', 'ProductImage', 'ProductName', 'Actions'];
        }
        else if (change.some(x => x.mqAlias === 'sm')) {
          this.displayedColumns = ['select', 'ProductImage', 'ProductName', 'ClientKey', 'Actions'];
        }
        else {
          this.displayedColumns = ['select', 'ProductImage', 'ProductName', 'UPC', 'SKU', 'ClientKey', 'Actions'];
        }
      });
  }

  onSort(sortState: Sort): void {
    this.productService.sort(sortState);
    this.selection.clear();
  }

  onPage(pageEvent: PageEvent): void {
    this.selection.clear();
    this.productService.page(pageEvent);
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.productsList.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.productsList.forEach(row => this.selection.select(row));
  }

  public getSelectedSectionRecords() {
    return this.selection.selected;

  }

  public clearSelection() {
    this.selection.clear();
  }

  public getImageSrc(assets: any) {
    let imagesrc = '';
    if (assets.Type === 'application/pdf') {
      imagesrc = './assets/images/pdf.png';
    } else if (assets.Type.indexOf('audio') > -1) {
      imagesrc = './assets/images/mp3.jpg';
    } else if (assets.Type.indexOf('video') > -1) {
      imagesrc = './assets/images/mp4.png';
    } else if (assets.Type.indexOf('text') > -1) {
      imagesrc = './assets/images/text.png';
    }
    else {
      imagesrc = this.assetsUrl + '/' + assets.FileName;
    }
    return imagesrc;
  }

}
