import { Injectable } from '@angular/core';
import { GridsterItem } from 'angular-gridster2';

@Injectable({
  providedIn: 'root'
})
export class ComponentGenerateService {

  constructor() { }

  // createComponent(component: ComponentGenerate): ComponentDomain {
  //   // console.log('Create new component with detals: ', component);
  //   let guid = crypto.randomUUID();
  //   let _itemConfig: GridsterItem = { "x": 0, "y": 0, "rows": 2, "cols": 2 };

  //   if (component.GridItemConfig) {
  //     _itemConfig = component.GridItemConfig;
  //   }

  //   let newComponentDomain: ComponentDomain = {
  //     ParentComponentId: component.ParentComponentId,
  //     VersionId: component.VersionId,
  //     ChildComponentDomains: [],
  //     OfferTagIds: [],
  //     OfferTagNames:[],
  //     Detail: {
  //       Id: guid,
  //       ClientKey: `clientKey_${guid}`,
  //       ComponentName: '',
  //       ComponentType: component.Detail.Type,
  //       Config: {
  //         uiConfig: {
  //           itemConfig: { ..._itemConfig, Icon: component.Detail.Icon },
  //           componentConfig: {},
  //           attributes: {},
  //           classes: {},
  //           layers: [],
  //           styles: {},
  //         },
  //         attributes: {},
  //         classes: [],
  //         styles: {},
  //         contentAttributes: {},
  //         contentClasses: [],
  //         contentStyles: {},
  //         autoPlay: true,
  //       },
  //       DataId: null,
  //       Description: '',
  //     },
  //     TemplateHtml: '',
  //   };

  //   if (component.Detail.Type == 'layout') {
  //     newComponentDomain.Detail.ComponentName = component.Detail.Name;
  //     newComponentDomain.Detail.Config.classes = ['pr1-ad-component-root'];
  //     newComponentDomain.Detail.Config.contentClasses = ['layout-content-container'];
  //   }

  //   if (component.Detail.Type == 'section') {
  //     let uiConfig = newComponentDomain.Detail.Config.uiConfig;
  //     if (uiConfig) {
  //       uiConfig.itemConfig!.minItemRows = 1;
  //       uiConfig.itemConfig!.cols = 6;
  //       uiConfig.itemConfig!.resizeEnabled = false;
  //       uiConfig.headerConfig = {
  //         type: 'header',
  //         text: '',
  //         attributes: {},
  //         classes: {},
  //         styles: {},
  //       };
  //       uiConfig.footerConfig = {
  //         type: 'footer',
  //         text: '',
  //         attributes: {},
  //         classes: {},
  //         styles: {},
  //       };
  //     }

  //     if (newComponentDomain.Detail.Config) {
  //       newComponentDomain.Detail.Config.classes = ['section-container'];
  //       newComponentDomain.Detail.Config.contentClasses = ['content-container'];
  //       newComponentDomain.Detail.Config.headerAttributes = {};
  //       newComponentDomain.Detail.Config.headerClasses = ['section-header'];
  //       newComponentDomain.Detail.Config.headerStyles = {};
  //       newComponentDomain.Detail.Config.headerText = '';
  //       newComponentDomain.Detail.Config.footerAttributes = {};
  //       newComponentDomain.Detail.Config.footerClasses = ['section-footer'];
  //       newComponentDomain.Detail.Config.footerStyles = {};
  //       newComponentDomain.Detail.Config.footerText = '';
  //     }
  //   }

  //   if (component.Detail.Type == 'promo' || component.Detail.Type == 'offer') {
  //     newComponentDomain.Detail.DataId = "00000000-0000-0000-0000-000000000000";
  //   }

  //   return newComponentDomain
  // }
}
