import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit } from '@angular/core';
import { MediaChange, MediaObserver } from '@angular/flex-layout';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest, distinctUntilChanged, map, Subscription } from 'rxjs';
import { Logger } from 'src/app/@shared';
import { CloneEventComponent, Event, EventAddAssetComponent, EventDomain, EventService } from '../..';
import { MatDialog } from '@angular/material/dialog';
import { CloneComponent } from '../../pages/clone/clone.component';

const log = new Logger('EventListTableComponent');

@Component({
  selector: 'app-event-list-table',
  templateUrl: './event-list-table.component.html',
  styleUrls: ['./event-list-table.component.scss']
})

export class EventListTableComponent<TEvent extends Event> implements OnInit {

  viewModel$ = combineLatest([
    this.eventService.events$,
    this.eventService.isLoading$,
    this.eventService.totalRecords$,
    this.eventService.page$,
  ]).pipe(
    map(([events, isLoading, totalRecords, page]) => {
      if (events.length == 0 && page.pageIndex > 0) {
        page.previousPageIndex = 0
        page.pageIndex = 0
        this.eventService.page(page);
        this.eventService.reload();
      }
      return { events, isLoading, totalRecords, page }
    }),
  );

  flexMediaWatcher!: Subscription;
  displayedColumns = ['select', 'HasWorkflow','EventName', 'StartDate', 'DivisionNames', 'EndDate', 'EventTypeName', 'EventStatus', 'ClientKey', 'Actions'];
  eventsList: EventDomain<TEvent>[] = [];
  selection = new SelectionModel<EventDomain<TEvent>>(true, []);
  eventId: string = '0';


  constructor(private eventService: EventService<TEvent>, private mediaObserver: MediaObserver, private router: Router, private route: ActivatedRoute, private dialog: MatDialog) { }

  ngOnInit(): void {
    log.debug('init');
    this.eventService.events$.subscribe((data) => {
      this.eventsList = data;
      this.selection.clear();
    });
    // detect changes in viewport size to handle show/hide of table columns
    const getAlias = (MediaChange: MediaChange[]) => {
      return MediaChange[0].mqAlias;
    };

    this.flexMediaWatcher = this.mediaObserver
      .asObservable()
      .pipe(
        distinctUntilChanged(
          (x: MediaChange[], y: MediaChange[]) => getAlias(x) === getAlias(y)
        ))
      .subscribe((change) => {
        if (change.some(x => x.mqAlias === 'xs')) {
          this.displayedColumns = ['select', 'HasWorkflow', 'EventName', 'Actions'];
        }
        else if (change.some(x => x.mqAlias === 'sm')) {
          this.displayedColumns = ['select', 'HasWorkflow', 'EventName', 'StartDate', 'Actions'];
        }
        else {
          this.displayedColumns = ['select', 'HasWorkflow', 'EventName', 'StartDate', 'DivisionNames', 'EndDate', 'EventTypeName','EventStatus', 'ClientKey', 'Actions'];
        }
      });
  }

  onPage(pageEvent: PageEvent): void {
    this.selection.clear();
    this.eventService.page(pageEvent);
  }
  
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.eventsList.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.eventsList.forEach(row => this.selection.select(row));
  }

  public getSelectedSectionRecords() {
    return this.selection.selected;
  }

  public clearSelection() {
    this.selection.clear();
  }

  openDialog(record: any): void {
    
    const dialogRef = this.dialog.open(CloneComponent, {
      width: '80%',
      height: '60%',
      data: { eventId: record.Detail.Id}
    });
  }

  onSort(sortState: Sort): void {
    this.eventService.sort(sortState);
    this.selection.clear();
  }

  onRowDoubleClick(id: string) {
    this.router.navigate([`${id}/offers`], { relativeTo: this.route });
  }

  ngOnDestroy(): void {
    this.flexMediaWatcher.unsubscribe();
  }

}
