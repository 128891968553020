import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, of, tap } from 'rxjs';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { Logger, ApiValidation, ConfirmDialogComponent, OrganizationService } from 'src/app/@shared';
import { Product, ProductCategory, ProductBrand, ProductService, ProductCategoryService, ProductBrandService, ProductView, VendorService, Vendor } from '../..';
import { DEFAULT_SNACKBAR_CONFIG } from 'src/app/@shared/constants/site.constants';

const log = new Logger('ProductComponent');

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss']
})

export class ProductComponent<T extends Product, TProductCategory extends ProductCategory, TProductBrand extends ProductBrand> implements OnInit {
  product$: Observable<T> = of({} as T);
  productCategories$: any[] = [] as any[];
  productBrands$: any[] = [] as any[];
  vendors$: any[] = [] as any[];
  productDomain$: Observable<any> = of({} as any);
  assetsUrl$ = this.organizationService.assetsUrl$;
  formGroup = new FormGroup({
    ClientKey: new FormControl<string>('', [Validators.required, Validators.minLength(1), Validators.maxLength(100)]),
    Id: new FormControl<string>({value: '0', disabled: true}, { nonNullable: true }),
    ProductName: new FormControl<string>('', [Validators.required, Validators.minLength(1), Validators.maxLength(100)]),
    ProductDescription: new FormControl<string | null>('', [Validators.minLength(1), Validators.maxLength(100)]),
    ProductDisplayName: new FormControl<string | null>('', [Validators.minLength(1), Validators.maxLength(100)]),
    SKU: new FormControl<string | null>('', [Validators.minLength(1), Validators.maxLength(100), Validators.pattern("[a-zA-Z0-9 \\-#&*\\'/.,;]*")]),
    UPC: new FormControl<number | null>(null, [Validators.minLength(1), Validators.maxLength(20), Validators.pattern("[0-9]*")]),
    ProductCategoryIds: new FormControl<string | null>('', [Validators.minLength(1), Validators.maxLength(100)]),
    ProductBrandId: new FormControl<string | null>('', [Validators.minLength(1), Validators.maxLength(100)]),
    VendorId: new FormControl<string | null>(''),
    IsAvailableInStoreOnly: new FormControl<boolean>(false, [Validators.required]),
    IsExclusive: new FormControl<boolean>(false, [Validators.required]),
  });
  productId: string = '0';
  category?: string = '';
  brand?: string;
  vendor?: string;
  productName: string = "";

  constructor(private router: Router,
    private route: ActivatedRoute,
    private productService: ProductService<T>,
    private vendorService: VendorService<Vendor>,
    private categoryService: ProductCategoryService<TProductCategory>,
    private productBrandService: ProductBrandService<TProductBrand>,
    private organizationService: OrganizationService,
    private matSnackBar: MatSnackBar,
    private dialog: MatDialog) { }

  ngOnInit(): void {
    log.debug('init');
    // get the product id from the parent route
    this.productId = this.route.snapshot.params['productId'];

    this.categoryService.getLeafCategories().subscribe({
      next: (data: any) => {
        this.productCategories$ = data.value;
      }
    });

    this.productBrandService.getLeafBrands().subscribe({
      next: (data: any) => {
        this.productBrands$ = data.value;
      }
    });

    this.vendorService.getVendors().subscribe({
      next: (data: any) => {
        this.vendors$ = data.value;
      }
    });

    // if we are editing a record, get the record data
    if (this.productId && this.productId !== '0') {
      this.productDomain$ = this.productService.getProduct(this.productId).pipe(
        tap((productDomain) => {
          this.category = productDomain.ProductCategoryIds.length > 0 ? productDomain.ProductCategoryIds[0] : "";
          this.brand = productDomain.ProductBrandId;
          this.vendor = productDomain.VendorId;
          let product: any;
          product = productDomain.Detail;
          product.ProductBrandId = this.brand;
          product.VendorId = this.vendor;
          product.ProductCategoryIds = this.category;
          this.productName = productDomain.Detail != null ? productDomain.Detail.ProductName : "";
          this.formGroup.patchValue(product);
          this.formGroup.markAllAsTouched();
        })
      );
    }
  }

  trimControlValues(formGroup: FormGroup): void {
    Object.keys(formGroup.controls).forEach((key: string) => {
      const abstractControl = formGroup.get(key);
      if (abstractControl instanceof FormGroup) {
        this.trimControlValues(abstractControl);
      } else {
        if (typeof abstractControl?.value == 'string') {
          abstractControl.setValue(abstractControl?.value.trim());
        }
      }
    })
  }

  save() {
    this.trimControlValues(this.formGroup);
    if (this.formGroup.valid) {
      this.productService.saveProduct(this.formGroup.getRawValue() as ProductView).subscribe({
        next: (response) => {
          this.matSnackBar.open(
            `${this.formGroup.controls.ProductName.value} saved`, 'OK', DEFAULT_SNACKBAR_CONFIG
          );
          this.productService.reload();
          this.router.navigate([`../../`], { relativeTo: this.route });
        },
        error: (error) => {
          if (error.status === 500) {
            log.error('500 Error saving event', error);
            this.matSnackBar.open('500 Error saving product', 'Error', { verticalPosition: 'top', panelClass: ['snackbar-error'] });
          }
          if (error.status === 400) {
            const apiValidations: ApiValidation[] = error.error;

            apiValidations.forEach((validation) => {
              if(validation && validation.PropertyName){
                validation.PropertyName = validation.PropertyName.toString().replace('Detail.', '');
              }
              if (this.formGroup.get(validation.PropertyName)) {
                const control = this.formGroup.get(validation.PropertyName);
                if (control) {
                  control.markAsTouched();
                  control.setErrors({ invalid: validation.ErrorMessage });
                  this.matSnackBar.open(validation.ErrorMessage, 'Error', { verticalPosition: 'top', panelClass: ['snackbar-error'] });
                }
              } else {
                ///TODO: if we have cross field validation then show the validation error at the top of the screen
                // if we have cross field validation then show the validation error at the top of the screen
                // push general error messages to array this is displayed in a toast or dialog
              }
            });
          }
        }
      });
    }
  }

  cancel(): void {
    this.formGroup.reset();
    this.router.navigate([`../../`], { relativeTo: this.route });
  }

  deleteRecord() {
    const record = this.formGroup.getRawValue();
    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Confirm Delete',
        message: `Are you sure you wish to delete ${record.ProductName}?`,
      },
      disableClose: true,
    });

    confirmDialog.afterClosed().subscribe(
      confirmResult => {
        if (confirmResult) {
          this.productService.deleteProduct(record.Id).subscribe({
            next: () => {
              this.matSnackBar.open(`${record.ProductName} deleted`, 'OK', DEFAULT_SNACKBAR_CONFIG);
              this.productService.reload();
              this.router.navigate([`../../`], { relativeTo: this.route });
            },
            error: (error) => {
              log.error('Error deleting event', error);

              if (error.error.value) {
                throw new Error(error.error.value);
              } else {
                throw new Error(error.message);
              }
            }
          });
        }
      });
  }

  public getImageSrc(assetsUrl: any, assets: any) {
    let imagesrc = '';
    if (assets.Type === 'application/pdf') {
      imagesrc = './assets/images/pdf.png';
    } else if (assets.Type.indexOf('audio') > -1) {
      imagesrc = './assets/images/mp3.jpg';
    } else if (assets.Type.indexOf('video') > -1) {
      imagesrc = './assets/images/mp4.png';
    } else if (assets.Type.indexOf('text') > -1) {
      imagesrc = './assets/images/text.png';
    }
    else {
      imagesrc = assetsUrl + '/' + assets.FileName;
    }
    return imagesrc;
  }

}
