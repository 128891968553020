<!-- page title -->
<h1>Product Management</h1>

<nav mat-tab-nav-bar [tabPanel]="tabPanel">
  <a mat-tab-link [routerLink]="['details']" routerLinkActive #details="routerLinkActive"
    [active]="details.isActive">Product Details
  </a>
  <a mat-tab-link *ngIf="productId !=='0'" [routerLink]="['product-assets']" routerLinkActive #assetList="routerLinkActive"
    [active]="assetList.isActive">Asset List
  </a>
</nav>
<mat-tab-nav-panel #tabPanel>
  <router-outlet></router-outlet>
</mat-tab-nav-panel>