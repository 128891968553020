import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Logger } from 'src/app/@shared';
import { ProductCategory, ProductCategoryService } from '../..';

const log = new Logger('ProductCategoriesComponent');

@Component({
  selector: 'app-product-categories',
  templateUrl: './product-categories.component.html',
  styleUrls: ['./product-categories.component.scss']
})

export class ProductCategoriesComponent<T extends ProductCategory> implements OnInit {

  public get categoryService(): ProductCategoryService<T> {
    return this._categoryService;
  }
  public set categoryService(value: ProductCategoryService<T>) {
    this._categoryService = value;
  }

  constructor(private _categoryService: ProductCategoryService<T>) { }

  ngOnInit(): void {
    log.debug('init');
    this.clearSearch();
    this.categoryService.reload();
  }

  onSearch(event: any) {
    this.categoryService.search(event.target.value);
  }

  clearSearch() {
    this.filtersForm.controls.search.setValue('');
    this.categoryService.search('');
  }

  filtersForm = new FormGroup({
    search: new FormControl(),
    startDate: new FormControl('', [Validators.required]),
    endDate: new FormControl('', [Validators.required]),
    eventType: new FormControl(''),
  });

  onDateRangeChange() {
    if (this.filtersForm.valid) {
    }
  }

  resetFilters() {
    this.filtersForm.reset();
  }

  downloadJSON(){
    this.categoryService.downloadProductCategoriesJSON();
  }

  downloadCSV(){
    this.categoryService.downloadProductCategoriesCSV();
  }
}