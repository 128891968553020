<!-- page title -->
<h1>Brand Management</h1>

<nav mat-tab-nav-bar [tabPanel]="tabPanel">
  <a mat-tab-link [routerLink]="['details']" routerLinkActive #details="routerLinkActive"
    [active]="details.isActive">Brand Details
  </a>
  <a mat-tab-link *ngIf="brandId !=='0'" [routerLink]="['product-brand-assets']" routerLinkActive #assetList="routerLinkActive"
    [active]="assetList.isActive">Asset List
  </a>
</nav>
<mat-tab-nav-panel #tabPanel>
  <router-outlet></router-outlet>
</mat-tab-nav-panel>