import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, debounceTime, distinctUntilChanged, map, Observable, of, shareReplay, switchMap, tap, } from 'rxjs';
import { environment } from 'src/environments/environment';
import { v4 as uuidv4 } from 'uuid';
import { OrganizationService } from 'src/app/@shared/services/organization.service';
import { OdataResponse, Version } from '..';
import { DEFAULT_PAGING } from 'src/app/@shared/constants/site.constants';

@Injectable({
  providedIn: 'root',
})

export class VersionService<TVersion extends Version> {

  // initialize behavior subjects
  private eventIdBehaviorSubject = new BehaviorSubject<string>('');
  private loadingBehaviorSubject = new BehaviorSubject<boolean>(false);
  private pageBehaviorSubject = new BehaviorSubject(DEFAULT_PAGING);
  private sortBehaviorSubject = new BehaviorSubject({ active: 'VersionName', direction: 'asc', });
  private searchBehaviorSubject = new BehaviorSubject<string>('');
  private uploadVersionsPageBehaviorSubject = new BehaviorSubject(DEFAULT_PAGING);
  private uploadVersionsSortBehaviorSubject = new BehaviorSubject({ active: 'VersionName', direction: 'asc', });
  private uploadVersionsSearchBehaviorSubject = new BehaviorSubject<string>('');
  private reloadBehaviorSubject = new BehaviorSubject<string>('');

  // we do not wish to expose our behavior subjects.  create public observables
  public isLoading$ = this.loadingBehaviorSubject.asObservable();
  public sort$ = this.sortBehaviorSubject.asObservable();
  public page$ = this.pageBehaviorSubject.asObservable();
  public search$ = this.searchBehaviorSubject.asObservable();
  public uploadVersionsSort$ = this.uploadVersionsSortBehaviorSubject.asObservable();
  public uploadVersionsPage$ = this.uploadVersionsPageBehaviorSubject.asObservable();
  public uploadVersionsSearch$ = this.uploadVersionsSearchBehaviorSubject.asObservable();

  // create the parameters observable that looks for changes in page, startDate, endDate, etc
  public params$ = combineLatest([
    this.eventIdBehaviorSubject,
    this.pageBehaviorSubject, // add debounce if we need to wait for user input ex: .pipe(debounceTime(300)),
    this.sortBehaviorSubject,
    this.searchBehaviorSubject.pipe(debounceTime(300)),
    this.reloadBehaviorSubject,
  ]).pipe(
    distinctUntilChanged((previous, current) => {
      // if the values coming down this pipe are the same, don't continue the pipe
      return JSON.stringify(previous) === JSON.stringify(current);
    }),
    map(([eventId, page, sort, search, reload]) => {

      // set the query string odata parameters
      let params: HttpParams = new HttpParams({
        fromObject: {
        eventId: this.eventIdBehaviorSubject.value,
        $skip: page.pageIndex * page.pageSize,
        $top: page.pageSize,
        $orderby: `${sort.active} ${sort.direction}`,
        $count: true,
      }
    });

      // if there is a search, add the search to the parameters
      if (search.length) {
        params = params.append('$search', `"${search}"`);
      }

      return params;
    })
  );

    // create the parameters observable that looks for changes in page, startDate, endDate, etc
    public uploadVersionsParams$ = combineLatest([
      this.eventIdBehaviorSubject,
      this.uploadVersionsPageBehaviorSubject, // add debounce if we need to wait for user input ex: .pipe(debounceTime(300)),
      this.uploadVersionsSortBehaviorSubject,
      this.uploadVersionsSearchBehaviorSubject.pipe(debounceTime(300)),
      this.reloadBehaviorSubject,
    ]).pipe(
      distinctUntilChanged((previous, current) => {
        // if the values coming down this pipe are the same, don't continue the pipe
        return JSON.stringify(previous) === JSON.stringify(current);
      }),
      map(([eventId, page, sort, search, reload]) => {
  
        // set the query string odata parameters
        let params: HttpParams = new HttpParams({
          fromObject: {
          eventId: this.eventIdBehaviorSubject.value,
          $skip: page.pageIndex * page.pageSize,
          $top: page.pageSize,
          $orderby: `${sort.active} ${sort.direction}`,
          $count: true,
        }
      });
  
        // if there is a search, add the search to the parameters
        if (search.length) {
          params = params.append('$search', `"${search}"`);
        }
  
        return params;
      })
    );

  // create the versions observable that calls http get when any of our parameters change
  private versionResponse$ = this.params$.pipe(
    tap(() => this.loadingBehaviorSubject.next(true)), // set isLoading to true
    switchMap((_params) => {
      const url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/Versions/ByEventId`;
      return this.httpClient.get(url, { params: _params });
    }),
    tap(() => this.loadingBehaviorSubject.next(false)), // set isLoading to false
    shareReplay(1) // make sure all subscriptions share the same http call (otherwise there will be a http call for each subscription)
  );

    // create the versions observable that calls http get when any of our parameters change
    private uploadVersionResponse$ = this.uploadVersionsParams$.pipe(
      tap(() => this.loadingBehaviorSubject.next(true)), // set isLoading to true
      switchMap((_params) => {
        const url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/Versions/ByEventId`;
        return this.httpClient.get(url, { params: _params });
      }),
      tap(() => this.loadingBehaviorSubject.next(false)), // set isLoading to false
      shareReplay(1) // make sure all subscriptions share the same http call (otherwise there will be a http call for each subscription)
    );

  private allVersionsResponse$ = this.params$.pipe(
    tap(() => this.loadingBehaviorSubject.next(true)), // set isLoading to true
    switchMap((_params) => {
      const url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/Versions/AllVersionByEventId`;
      return this.httpClient.get(url, { params: { eventId: this.eventIdBehaviorSubject.value } });
    }),
    tap(() => this.loadingBehaviorSubject.next(false)), // set isLoading to false
    shareReplay(1) // make sure all subscriptions share the same http call (otherwise there will be a http call for each subscription)
  );

  // version listing
  public allVersions$: Observable<TVersion[]> = this.allVersionsResponse$.pipe(
    map((res: any) => res.value)
  );

  getVersionsForVariants(eventId: string) : Observable<TVersion[]> {
    const url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/Versions/AllVersionByEventId?eventId=${eventId}`;
      return this.httpClient.get<TVersion[]>(url).pipe(map((res: any) => res.value));
  }

  

  // version listing
  public versions$: Observable<TVersion[]> = this.versionResponse$.pipe(
    map((res: any) => res.value)
  );

  // total number of version records based on filtering
  public totalRecords$: Observable<number> = this.versionResponse$.pipe(
    map((res: any) => res['@odata.count'])
  );

    // version listing
    public uploadVersions$: Observable<TVersion[]> = this.uploadVersionResponse$.pipe(
      map((res: any) => res.value)
    );
  
    // total number of version records based on filtering
    public uploadVersionstotalRecords$: Observable<number> = this.uploadVersionResponse$.pipe(
      map((res: any) => res['@odata.count'])
    );

  constructor(private httpClient: HttpClient, private organizationService: OrganizationService) { }

  set eventId(eventId: string) {
    this.eventIdBehaviorSubject.next(eventId);
  }

  get eventId() {
    return this.eventIdBehaviorSubject.value;
  }

  // set the current page
  page(page: any) {
    this.pageBehaviorSubject.next(page);
  }

  // sets the sort property and order
  sort(sort: any) {
    this.sortBehaviorSubject.next(sort);
  }

  // sets the search phrase
  search(search: string) {
    const page = this.pageBehaviorSubject.value;
    page.pageIndex = 0;
    page.previousPageIndex = 0;
    this.searchBehaviorSubject.next(search);
    this.pageBehaviorSubject.next(page);
  }

  // set the current page
  uploadVersionsPage(page: any) {
    this.uploadVersionsPageBehaviorSubject.next(page);
  }

  // sets the sort property and order
  uploadVersionsSort(sort: any) {
    this.uploadVersionsSortBehaviorSubject.next(sort);
  }

  // sets the search phrase
  uploadVersionsSearch(search: string) {
    const page = this.uploadVersionsPageBehaviorSubject.value;
    page.pageIndex = 0;
    page.previousPageIndex = 0;
    this.uploadVersionsSearchBehaviorSubject.next(search);
    this.uploadVersionsPageBehaviorSubject.next(page);
  }

  // reloads/refreshes the version listing
  reload() {
    // reload the event data
    this.reloadBehaviorSubject.next(uuidv4());
  }

  // gets an version by id
  getVersion(versionId: string): Observable<TVersion> {
    const url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/Versions/${versionId}`;
    return this.httpClient.get<TVersion>(url);
  }

  // up-serts versions
  saveVersion(eventId: string, version: TVersion) {

    if (!version.Id || version.Id === '0') {
      // create new record
      version.Id = '';
      let url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/Versions/AddToEvent?eventId=${eventId}`;
      return this.httpClient.post(url, version);
    } else {
      // edit existing record
      let url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/Versions/${version.Id}?eventId=${eventId}`;
      return this.httpClient.put(url, version);
    }
  }

  // deletes an version by id
  deleteVersion(id: string) {
    const url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/Versions/${id}`;
    return this.httpClient.delete(url);
  }

  deleteVersions(versions: any) {
    const url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/Versions/DeleteVersions?${this.prepareVersionStringtoDelete(versions)}`;
    return this.httpClient.post(url, versions);
  }

  prepareVersionStringtoDelete(versions: any) {
    let urlParams = '';
    if (versions && versions.length > 0) {
      for (let index = 0; index <= versions.length - 1; index++) {
        urlParams += `versionIds=${versions[index]?.Id}`;
        if (index != versions.length - 1) {
          urlParams += '&'
        }
      }
    }
    return urlParams;
  }

  // gets the distinct list of version names for the organization
  ///TODO: at some point the list of names could get large and we may need to apply an odata search to this function
  getDistinctVersionNames(): Observable<OdataResponse> {
    const url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/Versions/DistinctNames`;
    return this.httpClient.get<OdataResponse>(url);
  }

  public versionNames$: Observable<Version[]> = this.getDistinctVersionNames().pipe(
    map((res: any) => res.value)
  );

  downloadVersions(eventId: string) {
    const url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/Events/ExportVersions?eventId=${eventId}`;
    window.open(url,'_blank');
  }

  downloadVersionStores(eventId: string) {
    const url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/Events/ExportVersionStores?eventId=${eventId}`;
    window.open(url,'_blank');
  }

  applyVersionCodes(versions: any, body: any) {
    const url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/PromoDomains/ApplyVersionCodes?${this.prepareVersionStringtoDelete(versions)}`
    return this.httpClient.post(url, body)
  }


}
