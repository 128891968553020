import { Component, Inject, OnInit } from '@angular/core';
import { combineLatest, distinctUntilChanged, map, Subscription } from 'rxjs';
import { MediaChange, MediaObserver } from '@angular/flex-layout';
import { Logger } from 'src/app/@shared';
import { Sort } from '@angular/material/sort';
import { PageEvent } from '@angular/material/paginator';
import { Asset, AssetsService, OfferAsset, OfferAssetsService, AssetDomain, OfferService, Offer, OfferDomain } from 'src/app/modules/standard/v1';
import { SelectionModel } from '@angular/cdk/collections';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DEFAULT_SNACKBAR_CONFIG } from 'src/app/@shared/constants/site.constants';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

const log = new Logger('OfferAddAssetComponent');

interface DialogData {
  productId: string;
}

@Component({
  selector: 'app-offer-add-asset',
  templateUrl: './offer-add-asset.component.html',
  styleUrls: ['./offer-add-asset.component.scss']
})

export class OfferAddAssetComponent<T extends Asset, TAsset extends AssetDomain, TOffer extends Offer,TOfferDomain extends OfferDomain<Offer>> implements OnInit {

  viewModel$ = combineLatest([
    this.assetsService.allAssetsForOffers$,
    this.assetsService.isLoading$,
    this.assetsService.allAssetsForOffersTotalRecords$,
    this.assetsService.page$,
  ]).pipe(
    map(([allAssetsForOffers, isLoading, totalRecords, page]) => {
      this.assetsList = allAssetsForOffers;
      return { allAssetsForOffers, isLoading, totalRecords, page }
    }),
  );
  flexMediaWatcher!: Subscription;
  displayedColumns = ['select', 'AssetName', 'Group', 'DateAdded', 'Type'];
  assetsList: Asset[] = [];
  selection = new SelectionModel<Asset>(true, []);
  assetId: string = '0';
  offerId: string = '0';
  constructor(private mediaObserver: MediaObserver,
    private assetsService: AssetsService<Asset>,
    private offerassetService: OfferAssetsService<OfferAsset>,
    private route: ActivatedRoute,
    public offerService: OfferService<TOffer, TOfferDomain>,
    public dialogRef: MatDialogRef<OfferAddAssetComponent<Asset, AssetDomain, TOffer, TOfferDomain>>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private matSnackBar: MatSnackBar,) { }

  ngOnInit(): void {
    log.debug('init');
    this.clearSearch();
    this.assetsService.resetpage();
    this.assetsService.reload();
    this.offerId = this.route.snapshot.params['offerId'];
    this.assetId = this.route.snapshot.params['assetId'];
    const getAlias = (MediaChange: MediaChange[]) => {
      return MediaChange[0].mqAlias;
    };

    this.flexMediaWatcher = this.mediaObserver
      .asObservable()
      .pipe(
        distinctUntilChanged(
          (x: MediaChange[], y: MediaChange[]) => getAlias(x) === getAlias(y)
        ))
      .subscribe((change) => {
        if (change.some(x => x.mqAlias === 'xs')) {
          this.displayedColumns = ['select', 'AssetName'];
        }
        else if (change.some(x => x.mqAlias === 'sm')) {
          this.displayedColumns = ['select', 'AssetName', 'Type'];
        }
        else {
          this.displayedColumns = ['select', 'AssetName', 'Group', 'DateAdded', 'Type'];
        }
      });
  }

  filtersForm = new FormGroup({
    search: new FormControl(),
  });


  onSort(sortState: Sort): void {
    this.assetsService.sort(sortState);
    this.selection.clear();
  }

  closeDialog() {
    this.dialogRef.close();
  }

  onSearch(event: any) {
    this.assetsService.dialogAssetSearch(event.target.value);
  }

  clearSearch() {
    this.filtersForm.controls.search.setValue('');
    this.assetsService.dialogAssetSearch('');
  }


  saveData() {
    if (this.selection && this.selection.selected && this.selection.selected.length > 0) {
      let assets: any = this.selection.selected.map(x => x.Detail.Id);
      const offers = (this.offerService.selection.selected && this.offerService.selection.selected.length > 0) ? this.offerService.selection.selected.map(x => x.Id) : [this.offerId];
      this.offerassetService.associateAssetToOffer(offers, assets).subscribe({
        next: () => {
          this.matSnackBar.open(`Assets are added`, 'OK', DEFAULT_SNACKBAR_CONFIG);
          this.offerassetService.resetpage();
          this.offerassetService.reload();
          this.dialogRef.close(true);
        },
        error: (error) => {
          log.error('Error adding asset to offer', error);

          if (error.error.value) {
            throw new Error(error.error.value);
          } else {
            throw new Error(error.message);
          }
        }
      });
    }
  }



  onPage(pageEvent: PageEvent): void {
    this.selection.clear();
    this.assetsService.page(pageEvent);
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.assetsList.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.assetsList.forEach(row => this.selection.select(row));
  }

  public getSelectedSectionRecords() {
    return this.selection.selected;
  }

  public clearSelection() {
    this.selection.clear();
  }

}
