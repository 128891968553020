<ng-container *ngIf="viewModel$ | async as viewModel">

  <form *ngIf="eventDomain$ | async as TEvent; else loading" [formGroup]="formGroup">

    <mat-card appearance="outlined" [class.mat-elevation-z0]="true">
      <mat-card-content fxLayout="column">

        <h2>{{formGroup.controls.Detail.controls.EventName.value == '' ? "Event Name" :
          formGroup.controls.Detail.controls.EventName.value}}</h2>

        <div fxLayout="row wrap" fxLayoutGap.gt-sm="16px" fxLayout.lt-md="column">

          <mat-form-field appearance="outline" fxFlex="32">
            <mat-label data-testid="DivisionName">Divisions</mat-label>
            <mat-select multiple formControlName="DivisionIds">
              <mat-option *ngFor="let division of viewModel.divisions" [value]="division.Id">
                {{division.DivisionName}}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field appearance="outline" fxFlex="32">
            <mat-label data-testid="EventTypeName">EventType</mat-label>
            <mat-select formControlName="EventTypeId">
              <mat-option value="">
              </mat-option>
              <mat-option *ngFor="let eventType of viewModel.eventTypes" [value]="eventType.Id">
                {{eventType.EventTypeName}}
              </mat-option>
            </mat-select>
          </mat-form-field>

        </div>

        <div formGroupName="Detail" fxLayout="row wrap" fxLayoutGap.gt-sm="16px" fxLayout.lt-md="column">

          <mat-form-field appearance="outline" fxFlex="32">
            <mat-label data-testid="EventName">Name</mat-label>
            <input matInput type="text" formControlName="EventName" />
            <mat-error *ngIf="formGroup.controls.Detail.controls.EventName.hasError('required')">
              Name is required
            </mat-error>
            <mat-error *ngIf="formGroup.controls.Detail.controls.EventName.hasError('invalid')">
              {{formGroup.controls.Detail.controls.EventName.getError('invalid')}}
            </mat-error>
            <mat-error *ngIf="formGroup.controls.Detail.controls.EventName.status=='INVALID'">
              {{formGroup.controls.Detail.controls.EventName.errors!['maxlength'] && 'Maximum length can be' + formGroup.controls.Detail.controls.EventName.errors!['maxlength']['requiredLength'] + '.'}}
            </mat-error>
          </mat-form-field>

          <mat-form-field appearance="outline" fxFlex="32">
            <mat-label data-testid="StartDate">Start Date</mat-label>
            <input matInput [matDatepicker]="startDatePicker" formControlName="StartDate">
            <mat-error *ngIf="formGroup.controls.Detail.controls.StartDate.hasError('required')">
              Start date is required
            </mat-error>
            <mat-error *ngIf="formGroup.controls.Detail.controls.StartDate.hasError('invalid')">
              {{formGroup.controls.Detail.controls.StartDate.getError('invalid')}}
            </mat-error>
            <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #startDatePicker></mat-datepicker>
          </mat-form-field>

          <mat-form-field appearance="outline" fxFlex="32">
            <mat-label data-testid="EndDate">End Date</mat-label>
            <input matInput [matDatepicker]="endDatePicker" formControlName="EndDate">
            <mat-error *ngIf="formGroup.controls.Detail.controls.EndDate.hasError('required')">
              End date is required
            </mat-error>
            <mat-error *ngIf="formGroup.controls.Detail.controls.EndDate.hasError('invalid')">
              {{formGroup.controls.Detail.controls.EndDate.getError('invalid')}}
            </mat-error>
            <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #endDatePicker></mat-datepicker>
          </mat-form-field>

          <mat-form-field appearance="outline" *ngIf="formGroup.controls.Detail.controls.Id.value !=='0'" fxFlex="32">
            <mat-label>Id</mat-label>
            <input matInput formControlName="Id" readonly>
          </mat-form-field>

          <mat-form-field appearance="outline" fxFlex="32">
            <mat-label data-testid="EventsClientKey">Client Key</mat-label>
            <input matInput type="text" formControlName="ClientKey" />
            <mat-error *ngIf="formGroup.controls.Detail.controls.ClientKey.hasError('required')">
              Client key is required
            </mat-error>
            <mat-error *ngIf="formGroup.controls.Detail.controls.ClientKey.hasError('invalid')">
              {{formGroup.controls.Detail.controls.ClientKey.getError('invalid')}}
            </mat-error>
            <mat-error *ngIf="formGroup.controls.Detail.controls.ClientKey.status=='INVALID'">
              {{formGroup.controls.Detail.controls.ClientKey.errors!['maxlength'] && 'Maximum length can be' + formGroup.controls.Detail.controls.ClientKey.errors!['maxlength']['requiredLength'] + '.'}}
            </mat-error>
          </mat-form-field>

          <mat-form-field appearance="outline" fxFlex="32">
            <mat-label data-testid="Disclaimer">Disclaimer</mat-label>
            <input matInput type="text" formControlName="Disclaimer" />
        </mat-form-field>

        </div>
      </mat-card-content>

      <mat-card-actions>
        <div fxLayout="row" [fxLayoutAlign]="eventId != '0'?'space-between':'end'" class="mat-card-actions-details">
          <button *ngIf="eventId !== '0'" mat-raised-button type="button" color="warn" (click)="deleteRecord()">
            Delete
          </button>
          <div>
            <button mat-stroked-button type="reset" (click)="cancel()" data-testid="EventsCancelButton">
              Cancel
            </button>
            <button mat-raised-button type="button" (click)="save() " [disabled]="formGroup.invalid" color="accent" data-testid="EventsSaveButton">
              Save
            </button>
          </div>
        </div>
      </mat-card-actions>

    </mat-card>

  </form>

</ng-container>

<ng-template #loading>
  <mat-progress-bar mode="indeterminate" color="accent"></mat-progress-bar>
</ng-template>
