import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { map, combineLatest } from 'rxjs';
import { ConfirmDialogComponent, Logger } from 'src/app/@shared';
import { DEFAULT_SNACKBAR_CONFIG } from 'src/app/@shared/constants/site.constants';
import { AuthorizationService } from 'src/app/@shared/services/authorization.service';
import { CouponAssetsService } from '../../services/coupon-assets.service';
import { CouponAsset } from '../../models/coupon-asset.model';
import { CouponService } from '../../services/coupon.service';
import { Coupon } from '../../models/coupon.model';
import { Asset } from '../../models/asset.model';
import { AssetsService } from '../../services/assets.service';
import { CouponAddAssetComponent } from '../coupon-add-asset/coupon-add-asset.component';


const log = new Logger('CouponAssetsComponent');

@Component({
  selector: 'app-coupon-assets',
  templateUrl: './coupon-assets.component.html',
  styleUrls: ['./coupon-assets.component.scss'],
})

export class CouponAssetsComponent implements OnInit, OnDestroy {

  viewModel$ = combineLatest([
    this.couponAssetService.isLoading$,
    this.couponAssetService.viewMode$,
    this.couponAssetService.couponId$,
  ]).pipe(
    map(([isLoading, viewMode, couponId]) => {
      return { isLoading, viewMode, couponId }
    }),
  );

  filtersForm = new FormGroup({
    search: new FormControl(),
  });
  @ViewChild('assetTable', { static: false }) assetTable: any;
  @ViewChild('assetCards', { static: false }) assetCards: any;
  public assetsList: any;
  couponId = '0';
  constructor(private couponAssetService: CouponAssetsService<CouponAsset>,
    private couponService: CouponService<Coupon>,
    private assetsService: AssetsService<Asset>,
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private matSnackBar: MatSnackBar,
    private authorizationService: AuthorizationService
    ) { }

  ngOnInit(): void {
    log.debug('init');
    this.clearSearch();
    this.couponAssetService.couponId = this.route.snapshot.params['couponId'];
    this.couponId = this.route.snapshot.params['couponId'];
    this.couponAssetService.assets$.subscribe((data) => {
      this.assetsList = data;
    });
  }

  ngOnDestroy() { }

  onSearch(event: any) {
    this.couponAssetService.search(event.target.value);
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(CouponAddAssetComponent, {
      width: '80%',
      height: '90%',
      data: { couponId: this.route.snapshot.params['couponId'] }
    });
    this.couponAssetService.dialogSearch('');
  }

  clearSearch() {
    this.filtersForm.controls.search.setValue('');
    this.couponAssetService.search('');
  }

  toggleView(mode: string) {
    this.couponAssetService.toggleViewMode(mode);
  }

  cancel(): void {
    this.couponService.reload();
    this.router.navigate([`../../`], { relativeTo: this.route });
  }

  deleteSelectedRecords() {
    let selectedRecords: any;
    if (this.assetTable) {
      selectedRecords = this.assetTable.getSelectedSectionRecords();
    } else if (this.assetCards) {
      selectedRecords = this.assetCards.getSelectedSectionRecords();
    }
    if (selectedRecords && selectedRecords.length > 0) {
      const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
        data: {
          title: 'Confirm Delete',
          message: `Do you want to remove Asset?`,
        },
        disableClose: true,
      });


      confirmDialog.afterClosed().subscribe(
        confirmResult => {
          if (confirmResult) {
            const idstoPass = selectedRecords.map((x: any) => x.Detail.Id);
            this.couponAssetService.disassociateAssetToCoupon(this.couponId , idstoPass).subscribe({
              next: () => {
                this.matSnackBar.open(`Asset(s) deleted`, 'OK', DEFAULT_SNACKBAR_CONFIG);
                if (this.assetTable) {
                  this.assetTable.clearSelection();
                } else if (this.assetCards) {
                  this.assetCards.clearSelection();
                }
                this.couponAssetService.reload();
                this.router.navigate([`../`, 'assets'], { relativeTo: this.route });
              },
              error: (error) => {
                log.error('Error deleting asset from coupon', error);

                if (error.error.value) {
                  throw new Error(error.error.value);
                } else {
                  throw new Error(error.message);
                }
              }
            });
          }
        });
    }

  }

  getCouponName(coupons: any) {
    if (coupons && coupons.length > 0) {
      const couponDetail = coupons.find((x: any) => x.DomainId === this.route.snapshot.params['couponId']);
      if (couponDetail && couponDetail.Detail) {
        return couponDetail.Detail.Name;
      } else {
        return '';
      }
    }
    return '';
  }

  
  canEdit(): boolean {
    const hasAccess = this.authorizationService.checkAccess('edit||couponmang');
    return hasAccess;
  }
}
