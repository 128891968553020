import { SelectionModel } from '@angular/cdk/collections';
import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { Subscription, map } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogData } from 'src/app/@shared';
import { SystemWorkflowService } from '../../../../services/admin-workflow.service';
import { AuthUserService } from '../../../../services/authuser.service';

@Component({
  selector: 'app-add-workflow-users',
  templateUrl: './admin-add-workflow-users.component.html',
  styleUrls: ['./admin-add-workflow-users.component.scss'],
})
export class AdminAddWorkflowUsersComponent implements OnInit {
  dataSource: any;
  selection = new SelectionModel<any>(true, []);
  displayedColumns: string[] = [
    'select',
    'first_name',
    'last_name',
    'user_role',
    'email',
  ];
  selectedUsers: any;
  userFullName = 'this user';
  paginationData: any;
  subscription$ = new Subscription();
  usersToFilterOut: any = undefined;
  allUsersAlreadyAdded: boolean = false;

  constructor(
    private ewService: SystemWorkflowService,
    public dialogRef: MatDialogRef<AdminAddWorkflowUsersComponent>,
    public userService: AuthUserService<any>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  ngOnInit(): void {
    this.usersToFilterOut = this.data;
  }

  handleCheckboxSelection(e: any) {
    this.selectedUsers = e.map((d: any) => ({
      ...d.Detail,
      first_name: d.Detail.FirstName,
      last_name: d.Detail.LastName,
      user_role: d.Detail.Roles,
      user_status: d.Detail.Active ? 'Active' : 'Not Active',
    }));
  }

  onSave() {
    console.log(`this.selectedUsers: `, this.selectedUsers);
    this.dialogRef.close(this.selectedUsers);
  }

  handleOnAllUsersAdded(e: boolean) {
    if (this.allUsersAlreadyAdded != e) {
      setTimeout(() => {
        this.allUsersAlreadyAdded = e;
      }, 200);
    }
  }
}
