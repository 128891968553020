<mat-dialog-content class="mat-typography">
  <div
    fxLayout="row wrap"
    fxLayoutGap.gt-sm="16px"
    fxLayout.lt-md="column"
    class="add-users-container"
  >
    <h2 style="padding-top: 0.8rem">Add Users</h2>
    <app-users-table
      *ngIf="usersToFilterOut"
      [usersToFilterOut]="usersToFilterOut"
      [displayedColumns]="displayedColumns"
      [addWorkflowUsers]="true"
      (checkboxChanges)="handleCheckboxSelection($event)"
      (onAllUsersAdded)="handleOnAllUsersAdded($event)"
    ></app-users-table>
  </div>
</mat-dialog-content>

<mat-dialog-actions [align]="'end'">
  <button mat-button (click)="dialogRef.close()">Cancel</button>
  <button *ngIf="!allUsersAlreadyAdded" mat-raised-button color="accent" cdkFocusInitial (click)="onSave()">
    Select
  </button>
</mat-dialog-actions>
