import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import {
  BehaviorSubject,
  Observable,
  Subject,
  Subscription,
  combineLatest,
  debounceTime,
  distinctUntilChanged,
  map,
  shareReplay,
  switchMap,
  tap,
} from 'rxjs';
import { DEFAULT_PAGING, Filter, OrganizationService } from 'src/app/@shared';
import { environment as env } from 'src/environments/environment';
import { v4 as uuidv4 } from 'uuid';

@Injectable({
  providedIn: 'root',
})
export class SystemWorkflowService implements OnDestroy {
  baseUrl = env.pr1ApiUrl;
  public eventName = 'Event Name';
  public authToken = '';
  public headersWithBearerToken: any;
  public usersTableRefData: any;
  private usersTask$: any;
  private emailAlert$: any;

  public templatesSelected = new Subject();
  public selectedUsersToAddToWorkflow = new Subject();
  public usersTableRefDataSubject = new Subject();
  public subscription$ = new Subscription();

  public loadingBehaviorSubject = new BehaviorSubject<boolean>(false);

  private pageBehaviorSubject = new BehaviorSubject(DEFAULT_PAGING);
  private sortBehaviorSubject = new BehaviorSubject({
    active: 'Name',
    direction: 'desc',
  });
  private searchBehaviorSubject = new BehaviorSubject<string>('');
  private reloadBehaviorSubject = new BehaviorSubject<string>('');
  private filterBehaviorSubject = new BehaviorSubject<Filter[]>([]);

  public isLoading$ = this.loadingBehaviorSubject.asObservable();
  public page$ = this.pageBehaviorSubject.asObservable();

  public params$ = combineLatest([
    this.pageBehaviorSubject, // add debounce if we need to wait for user input ex: .pipe(debounceTime(300)),
    this.sortBehaviorSubject,
    this.searchBehaviorSubject.pipe(debounceTime(300)),
    this.filterBehaviorSubject.pipe(debounceTime(50)),
    this.reloadBehaviorSubject,
  ]).pipe(
    distinctUntilChanged((previous, current) => {
      // if the values coming down this pipe are the same, don't continue the pipe
      return JSON.stringify(previous) === JSON.stringify(current);
    }),
    map(([page, sort, search, filters, reload]) => {
      let _orderby = `Detail/${sort.active} ${sort.direction}`;
      if (sort.active == 'Name') {
        _orderby = `${sort.active} ${sort.direction}`;
      }

      // set the query string parameters
      let params: HttpParams = new HttpParams({
        fromObject: {
          Skip: page.pageIndex * page.pageSize,
          Limit: page.pageSize,
          Orderby: _orderby,
        },
      });

      // if there is a search, add the search to the parameters
      if (search.length) {
        params = params.append('Search', `${search}`);
      }

      return params;
    })
  );

  private masterTemplateAPIEndpoint = `${env.pr1ApiUrl}/${
    this.orgService.organization?.apiPath
  }/${this.orgService.organization?.version?.toUpperCase()}/WorkflowTemplate/GetWorkflowTemplate`;

  private getWorkflowAPIEndpoint = `${env.pr1ApiUrl}/${
    this.orgService.organization?.apiPath
  }/${this.orgService.organization?.version?.toUpperCase()}/WorkflowTemplate/GetWorkflowTemplateDetails`;

  private getWorkflowTypeAPIEndpoint = `${env.pr1ApiUrl}/${
    this.orgService.organization?.apiPath
  }/${this.orgService.organization?.version?.toUpperCase()}/WorkflowType/GetWorkflowType`;

  private getUsersAPIEndpoint = `https://pr1-std-sysadmin-${
    env.environment
  }-portal-api-app.azurewebsites.net/standard/V1/AuthUsers/GetOrganizationUsers?orgId=${localStorage.getItem(
    'orgId'
  )}`;

  private createNewWorkflowAPIEndpoint = `${env.pr1ApiUrl}/${
    this.orgService.organization?.apiPath
  }/${this.orgService.organization?.version?.toUpperCase()}/WorkflowTemplate/CreateWorkflowTemplate`;

  private getMasterRefData = `${env.pr1ApiUrl}/${
    this.orgService.organization?.apiPath
  }/${this.orgService.organization?.version?.toUpperCase()}/WorkflowTemplate/GetMasters`;

  private newWorkflowGroupAPIEndpoint = `${env.pr1ApiUrl}/${
    this.orgService.organization?.apiPath
  }/${this.orgService.organization?.version?.toUpperCase()}/WorkflowGroup/CreateWorkflowGroup`;

  private cloneWorkflowsAPIEndpoint = `${env.pr1ApiUrl}/${
    this.orgService.organization?.apiPath
  }/${this.orgService.organization?.version?.toUpperCase()}/WorkflowTemplate/CloneWorkflowTemplate`;

  private deleteWorkflowAPIEndpoint = `${env.pr1ApiUrl}/${
    this.orgService.organization?.apiPath
  }/${this.orgService.organization?.version?.toUpperCase()}/WorkflowTemplate/DeleteWorkflowTemplate`;

  private rolesRefData = [
    { label: 'Reviewer', value: 0 },
    { label: 'Editor', value: 1 },
    { label: 'Approver', value: 2 },
  ];

  constructor(
    private httpClient: HttpClient,
    public orgService: OrganizationService
  ) {}

  private getListWorkflowTemplates$ = this.params$.pipe(
    tap(() => this.loadingBehaviorSubject.next(true)), // set isLoading to true
    switchMap((_params) =>
      this.httpClient.get(this.masterTemplateAPIEndpoint, {
        headers: {
          Authorization: `Bearer ${this.orgService.silentAuthToken}`,
        },
        params: _params,
      })
    ),
    tap(() => this.loadingBehaviorSubject.next(false)), // set isLoading to false
    shareReplay(1), // make sure all subscriptions share the same http call (otherwise there will be a http call for each subscription)
    map((data) => {
      return data;
    })
  );

  public Templates$ = this.getListWorkflowTemplates$!.pipe(
    map((res: any) => res['value'])
  );

  public totalRecords$ = this.getListWorkflowTemplates$!.pipe(
    map((res: any) => res['@odata.count'])
  );

  public createUpdateWorkflow(payload: any): Observable<Object> {
    return this.httpClient.post(this.createNewWorkflowAPIEndpoint, payload, {
      responseType: 'text',
      headers: {
        Authorization: `Bearer ${this.orgService.silentAuthToken}`,
      },
    });
  }

  public getMasterTemplate(): Observable<Object> {
    return this.getListWorkflowTemplates$!;
  }

  public getWorkflow(token: string, templateId: string) {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    return this.httpClient
      .get(this.getWorkflowAPIEndpoint, {
        params: {
          templateId,
        },
        headers,
      })
      .pipe(
        map((data: any) => {
          const wd = data;
          const mappedData = {
            ...data,
            id: wd.Id,
            template_info: {
              template_name: wd.TemplateName,
              template_owner: wd.UserId,
              template_group: wd.Channel,
              template_category: wd.WorkflowType,
            },
            workflow_config: wd.Tasks?.map((s: any) => s.Name),
            workflow_stages: wd.Tasks?.map((s: any) => {
              return {
                stage_name: s.Name,
                stage_description: s.Description,
                stage_status: s.StageStatus,
                stage_id: s.Id,
                stage_task: s.StageTask,
                sort_order: s.SortOrder,
                users: s.Users.map((u: any) => ({
                  first_name: u.FirstName,
                  last_name: u.LastName,
                  // task: u.UserTask,
                  email_alerts: u.EmailAlert,
                  id: u.Id,
                  email: u.Email,
                  role: this.getRoleLabelFromValue(u.Role),
                })),
              };
            }),
          };

          return mappedData;
        })
      );
  }

  getRoleLabelFromValue(value: number) {
    const i = this.rolesRefData.findIndex((r) => r.value == value);
    if (i > -1) {
      return this.rolesRefData[i].label;
    }
    return undefined;
  }

  public getRefDataForEditWorkflowControls(token?: string) {
    return this.httpClient
      .get(this.getMasterRefData, {
        headers: {
          Authorization: `Bearer ${
            this.orgService.silentAuthToken
              ? this.orgService.silentAuthToken
              : token
          }`,
        },
      })
      .pipe(
        map((data: any) => {
          let startTriggers = data?.StartTriggers?.map((s: any) => ({
            ...s,
            label: s.Name,
            value: s.Id,
          }));

          let endTriggers = data?.EndTriggers?.map((e: any) => ({
            ...e,
            label: e.Name,
            value: e.Id,
          }));

          let tasks = data?.UserTasks?.map((t: any) => ({
            ...t,
            label: t.Name,
            value: t.Id,
          }));

          let emailAlerts = data?.EmailAlerts?.map((e: any) => ({
            ...e,
            label: e.Name,
            value: e.Id,
          }));

          let workflowGroups = data?.WorkflowGroups?.map((w: any) => ({
            ...w,
            label: w.Name,
            value: w.Id,
          }));

          this.usersTableRefData = {
            tasks,
            emailAlerts,
          };

          this.usersTableRefDataSubject.next(this.usersTableRefData);

          return {
            startTriggers,
            endTriggers,
            tasks,
            emailAlerts,
            workflowGroups,
          };
        })
      );
  }

  public getRefDataForWorkflowUsersTable() {
    return combineLatest([this.usersTask$, this.emailAlert$]).pipe(
      map((data: any) => {
        let tasks = data[0].value;
        let emailAlerts = data[1].value;

        tasks = tasks.map((t: any) => ({
          ...t,
          label: t.UserTaskName,
          value: t.UserTaskName,
        }));

        emailAlerts = emailAlerts.map((e: any) => ({
          ...e,
          label: e.EmailAlertName,
          value: e.EmailAlertName,
        }));

        return {
          tasks,
          emailAlerts,
        };
      })
    );
  }

  public createWorkflowGroup(payload: any) {
    return this.httpClient.post(this.newWorkflowGroupAPIEndpoint, payload, {
      responseType: 'text',
      headers: {
        Authorization: `Bearer ${this.orgService.silentAuthToken}`,
      },
    });
  }

  public cloneWorkflowTemplates(payload: string[]) {
    return this.httpClient.post(this.cloneWorkflowsAPIEndpoint, payload, {
      responseType: 'text',
      headers: {
        Authorization: `Bearer ${this.orgService.silentAuthToken}`,
      },
    });
  }

  public deleteWorkflowTemplate(templateId: string) {
    return this.httpClient.delete(
      `${this.deleteWorkflowAPIEndpoint}?templateId=${templateId}`,
      {
        headers: {
          Authorization: `Bearer ${this.orgService.silentAuthToken}`,
        },
      }
    );
  }

  page(page: any) {
    this.pageBehaviorSubject.next(page);
  }

  reload() {
    this.reloadBehaviorSubject.next(uuidv4());
  }

  // sets the search phrase
  search(search: string) {
    const page = this.pageBehaviorSubject.value;
    page.pageIndex = 0;
    page.previousPageIndex = 0;
    this.searchBehaviorSubject.next(search);
    this.pageBehaviorSubject.next(page);
  }

  ngOnDestroy(): void {
    this.subscription$.unsubscribe();
  }
}
