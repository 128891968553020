export interface Shopper {
    ClientKey?: string;
    Id: string;
    FirstName: string;
    LastName: string;
    Email: string;
    Phone?: string;
    Address1?: string;
    Address2?: string;
    City?: string;
    ZipCode?: string;
    State?: string;
}
