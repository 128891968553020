<ng-container *ngIf="viewModel$ | async as viewModel">

    <table mat-table [dataSource]="viewModel.assets" matSort (matSortChange)="onSort($event)" matSortDisableClear [matSortStart]="'asc'"
        *ngIf="viewModel.assets.length">
        <!-- Checkbox Column -->
        <ng-container matColumnDef="select">
            <th mat-header-cell *matHeaderCellDef>
                <mat-checkbox (change)="$event ? masterToggle() : null"
                    [checked]="selection.hasValue() && isAllSelected()"
                    [indeterminate]="selection.hasValue() && !isAllSelected()">
                </mat-checkbox>
            </th>
            <td class="no-ellipsis" mat-cell *matCellDef="let row">
                <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
                    [checked]="selection.isSelected(row)">
                </mat-checkbox>
            </td>
        </ng-container>

        <!--  Image Column -->
        <ng-container matColumnDef="Image">
            <th mat-header-cell *matHeaderCellDef [resizeColumn]="true">
                Preview
            </th>
            <td  mat-cell class="offer-image no-ellipsis" *matCellDef="let record">
                <img *ngIf="record.Detail.FileName" [src]="getImageSrc(record)">
                <img *ngIf="!record.Detail.FileName" src="./assets/images/default-image.png" alt="Offer image">
            </td>
        </ng-container>

        <!-- AssetName Column -->
        <ng-container matColumnDef="AssetName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Name" [resizeColumn]="true">
                Asset Name
            </th>
            <td mat-cell *matCellDef="let record"> {{ record?.AssetName }}</td>
        </ng-container>

        <!-- Group Column -->
        <ng-container matColumnDef="Group">
            <th mat-header-cell *matHeaderCellDef [resizeColumn]="true">
                Group
            </th>
            <td mat-cell *matCellDef="let record">{{ record?.AssetGroupName }}</td>
        </ng-container>

        <!-- DateAdded Column -->
        <ng-container matColumnDef="DateAdded">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by DateAdded" [resizeColumn]="true">
                Date Added
            </th>
            <td mat-cell *matCellDef="let record">{{ record?.DateAdded }}</td>
        </ng-container>

        <!-- Type Column -->
        <ng-container matColumnDef="Type">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Type" [resizeColumn]="true">
                Type
            </th>
            <td mat-cell *matCellDef="let record">{{ record?.Type }}</td>
        </ng-container>

        <!-- Type Column -->
        <ng-container matColumnDef="FileName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by FileName" [resizeColumn]="true">
                File Name
            </th>
            <td mat-cell *matCellDef="let record">{{ record?.Detail.FileName }}</td>
        </ng-container>

        <!-- Actions Column -->
        <ng-container matColumnDef="Actions">
            <th mat-header-cell *matHeaderCellDef>Actions</th>
            <td mat-cell *matCellDef="let record">
                <button type="button" mat-button color="accent" [routerLink]="[record?.DomainId]">Edit</button>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>

    <table *ngIf="!viewModel.assets.length">
        <tr>
            <td class="no-records" [attr.colspan]="displayedColumns.length">
                <h2>No Records Found</h2>
            </td>
        </tr>
    </table>

    <!-- pager -->
    <mat-paginator [length]="viewModel.totalRecords" [pageSize]="viewModel.page.pageSize"
        [pageSizeOptions]="[5, 10, 20, 50, 100,500]" (page)="onPage($event)" *ngIf="viewModel.assets.length"
        showFirstLastButtons [pageIndex]="viewModel.page.pageIndex">
    </mat-paginator>

</ng-container>
