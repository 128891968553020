import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ConfirmDialogComponent, DEFAULT_SNACKBAR_CONFIG, Logger } from 'src/app/@shared';
import { Division, DivisionService } from '../..';
import { ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';

const log = new Logger('DivisionsComponent');

@Component({
  selector: 'app-divisions',
  templateUrl: './divisions.component.html',
  styleUrls: ['./divisions.component.scss']
})
export class DivisionsComponent<T extends Division> implements OnInit {
  @ViewChild('divisionsTable', { static: false }) divisionsTable: any;

  public get divisionService(): DivisionService<T> {
    return this._divisionService;
  }
  public set divisionService(value: DivisionService<T>) {
    this._divisionService = value;
  }
  filtersForm = new FormGroup({
    search: new FormControl<string | null>(''),
  });

  constructor(private _divisionService: DivisionService<T>, private route: ActivatedRoute, private dialog: MatDialog,
    private matSnackBar: MatSnackBar) { }

  ngOnInit() {
    log.debug('init');
    this.clearSearch();
    this.divisionService.reload();

    this.route.queryParams.subscribe(queryParams => {
      let searchKey = queryParams['search'];

      if (searchKey) {
        this._divisionService.search(searchKey)
        this.filtersForm.controls.search.setValue(searchKey);
      } else {
        this._divisionService.search('');
        this.filtersForm.controls.search.setValue('');
      }
    });
  }

  onSearch(event: any) {
    this.divisionService.search(event.target.value);
  }

  clearSearch() {
    this.filtersForm.controls.search.setValue('');
    this.divisionService.search('');
  }

  deleteSelectedRecords() {
    let selectedRecords: any;
    if (this.divisionsTable) {
      selectedRecords = this.divisionsTable.getSelectedSectionRecords();
    } 
    if (selectedRecords && selectedRecords.length > 0) {
      const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
        data: {
          title: 'Confirm Delete',
          message: `Do you want to remove Division(s)?`,
        },
        disableClose: true,
      });

      confirmDialog.afterClosed().subscribe(
        confirmResult => {
          if (confirmResult) {
            this.divisionService.deleteDivisions(selectedRecords).subscribe({
              next: () => {
                this.matSnackBar.open(`Division(s) deleted`, 'OK', DEFAULT_SNACKBAR_CONFIG);
                this.divisionService.reload();
                if (this.divisionsTable) {
                  this.divisionsTable.clearSelection();
                } 
              },
              error: (error) => {
                log.error('Error in deleting division', error);

                if (error.error.value) {
                  throw new Error(error.error.value);
                } else {
                  throw new Error(error.message);
                }
              }
            });
          }
        });
    }
  }

  downloadJSON(){
    this.divisionService.downloadDivisionsJSON();
  }

  downloadCSV(){
    this.divisionService.downloadDivisionsCSV();
  }

}
