import { Injectable } from '@angular/core';
import { Banner } from '../models/banner.model';
import { BehaviorSubject, Observable, combineLatest, debounceTime, distinctUntilChanged, map, shareReplay, switchMap, tap } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
import { DEFAULT_PAGING } from 'src/app/@shared/constants/site.constants';
import { environment } from 'src/environments/environment';
import { OrganizationService } from 'src/app/@shared';
import { HttpClient, HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class BannersService<Tbanner extends Banner> {
    private reloadBehaviorSubject = new BehaviorSubject<string>('');
    private pageBehaviorSubject = new BehaviorSubject(DEFAULT_PAGING);
    private searchBehaviorSubject = new BehaviorSubject<string>('');
    private sortBehaviorSubject = new BehaviorSubject({ active: 'BannerName', direction: 'asc', });
    private loadingBehaviorSubject = new BehaviorSubject<boolean>(false);

    public sort$ = this.sortBehaviorSubject.asObservable();
    public isLoading$ = this.loadingBehaviorSubject.asObservable();
    public page$ = this.pageBehaviorSubject.asObservable();

    constructor(
        private organizationService: OrganizationService,
        private httpClient: HttpClient
    ) { }

    // create the parameters observable that looks for changes in page, startDate, endDate, etc
  public params$ = combineLatest([
    this.pageBehaviorSubject, // add debounce if we need to wait for user input ex: .pipe(debounceTime(300)), 
    this.sortBehaviorSubject,
    this.searchBehaviorSubject.pipe(debounceTime(300)),
    this.reloadBehaviorSubject,
  ]).pipe(
    distinctUntilChanged((previous, current) => {
      // if the values coming down this pipe are the same, don't continue the pipe
      return JSON.stringify(previous) === JSON.stringify(current);
    }),
    map(([page, sort, search, reload]) => {

      // set the query string odata parameters
      let params: HttpParams = new HttpParams({
        fromObject: {
        $skip: page.pageIndex * page.pageSize,
        $top: page.pageSize,
        $orderby: `${sort.active} ${sort.direction}`,
        $count: true,
      }
    });

      // if there is a search, add the search to the parameters
      if (search.length) {
        params = params.append('$search', `"${search}"`);
      }

      return params;
    })
  );

    // create the banners observable that calls http get when any of our parameters change
  private bannersResponse$ = this.params$.pipe(
    tap(() => this.loadingBehaviorSubject.next(true)), // set isLoading to true
    switchMap((_params) =>
      this.httpClient.get(
        `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/Banners`,
        { params: _params })
    ),
    tap(() => this.loadingBehaviorSubject.next(false)), // set isLoading to false
    shareReplay(1) // make sure all subscriptions share the same http call (otherwise there will be a http call for each subscription)
  );

  // banner listing
  public banners$: Observable<Tbanner[]> = this.bannersResponse$.pipe(
    map((res: any) => res.value)
   );

   // total number of Division records based on filtering
    public totalRecords$: Observable<number> = this.bannersResponse$.pipe(
        map((res: any) => res['@odata.count'])
    );

  // reloads/refreshes the banners listing
  reload() {
    // reload the banner data
    this.reloadBehaviorSubject.next(uuidv4());
  }

  // sets the search phrase
  search(search: string) {
    const page = this.pageBehaviorSubject.value;
    page.pageIndex = 0;
    page.previousPageIndex = 0;
    this.searchBehaviorSubject.next(search);
    this.pageBehaviorSubject.next(page);
  }

  // set the current page
  page(page: any) {
    this.pageBehaviorSubject.next(page);
  }

  // sets the sort property and order
  sort(sort: any) {
    this.sortBehaviorSubject.next(sort);
  }

  deleteBanner(bannerId: string) {
    const url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/Banners/${bannerId}`;
    return this.httpClient.delete(url);
  }

  deleteBanners(banners: any) {
    const url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/Banners/DeleteBanners?${this.prepareBannerStringtoDelete(banners)}`;
    return this.httpClient.post(url, {});
  }

  prepareBannerStringtoDelete(banners: any) {
    let urlParams = '';
    if (banners && banners.length > 0) {
      for (let index = 0; index <= banners.length - 1; index++) {
        urlParams += `bannerIds=${banners[index]?.Id}`;
        if (index != banners.length - 1) {
          urlParams += '&'
        }
      }
    }
    return urlParams;
  }

  // gets an banner by id
  getBanner(bannerId: string): Observable<Tbanner> {
    const url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/Banners/${bannerId}`;
    return this.httpClient.get<Tbanner>(url);
  }

  // up-serts banner
  saveBanner(banner: Tbanner) {
    let url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/Banners/`;
    if (!banner.Id || banner.Id === '0') {
      // create new record
      return this.httpClient.post(url, banner);
    } else {
      // edit existing record
      url += `${banner.Id}`;
      return this.httpClient.put(url, banner);
    }
  }

  downloadBannersJSON() {
    const url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/Banners/EtlExport`;
    window.open(url,'_blank');
  }

}
