<ng-container *ngIf="viewModel$ | async as viewModel; else loading">

    <div class="grid" *ngIf="viewModel.assets.length">
        <mat-card appearance="outlined" *ngFor="let assets of viewModel.assets">
            <mat-card-content>
                <div class="grid-col-span-2 offer-name" title="{{ assets.AssetName }}" fxLayout="row wrap">
                    <mat-checkbox class="card-checkbox" (click)="$event.stopPropagation()" fxFlex="30px"
                        (change)="$event ? selection.toggle(assets) : null" [checked]="selection.isSelected(assets)">
                    </mat-checkbox>

                </div>
                <div fxLayoutAlign="center center" class="offer-image">
                    <ng-container *ngIf="assets.Detail.FileName">
                        <img mat-card-md-image [src]="getImageSrc(assets)" alt="Offer image">
                    </ng-container>
                    <img mat-card-md-image *ngIf="!assets.Detail.FileName"
                        src="./assets/images/default-image.png" alt="Offer image">
                </div>
                <div class="offer-content" fxLayout="column">
                    <div class="offer-headline" title="{{ assets.DateAdded }}">
                        <div class="ellipsis-3-line offer-headline-bold">{{ assets.AssetName }}</div>
                        <span class="ellipsis-3-line">{{assets.DateAdded }}</span>
                    </div>
                    <div *ngIf="assets.AssetGroupName">
                        <span class="ellipsis-3-line">{{ assets.AssetGroupName }}</span>
                    </div>
                    <div *ngIf="assets.Type">
                        <span class="ellipsis-3-line">{{ assets.Type }}</span>
                    </div>
                    <div fxFlex fxLayout="row" fxLayoutAlign="end end">
                        <button class="offer-button" type="button" mat-button color="accent"
                            [routerLink]="[assets?.DomainId]">Edit</button>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>

    </div>
    <mat-paginator [length]="viewModel.totalRecords" [pageSize]="viewModel.page.pageSize"
        [pageSizeOptions]="[5, 10, 20, 50, 100,500]" (page)="onPage($event)" *ngIf="viewModel.assets.length"
        showFirstLastButtons [pageIndex]="viewModel.page.pageIndex">
    </mat-paginator>
    <table *ngIf="!viewModel.assets.length">
        <tr>
            <td class="no-records">
                <h2>No Records Found</h2>
            </td>
        </tr>
    </table>
</ng-container>



<ng-template #loading>
    <mat-progress-bar mode="indeterminate" color="accent"></mat-progress-bar>
</ng-template>
