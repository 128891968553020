import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, of, tap } from 'rxjs';
import { Logger } from 'src/app/@shared/services/logger.service';
import { ProductDomain, Product, ProductService } from 'src/app/modules/standard/v1';
import { ApiValidation, DEFAULT_SNACKBAR_CONFIG } from 'src/app/@shared';
import { AuthorizationService } from 'src/app/@shared/services/authorization.service';
import { CouponService } from '../../services/coupon.service';
import { Coupon } from '../../models/coupon.model';
import { CouponProductService } from '../../services/coupon-product.service';

const log = new Logger('OfferProductUpdateRankComponent');

@Component({
  selector: 'app-coupon-product-update-rank',
  templateUrl: './coupon-product-update-rank.component.html',
  styleUrls: ['./coupon-product-update-rank.component.scss']
})
export class CouponProductUpdateRankComponent<T extends Product, TProductDomain extends ProductDomain<T>> implements OnInit {
  asset$: Observable<any> = of({} as any);
  formGroup = new FormGroup({
    Rank: new FormControl<number | null | undefined>(null, [Validators.min(1), Validators.max(99)]),
    ProductName: new FormControl<string | null>(null)
  });

  public product: any;
  private couponId: string = '';
  private productId: string = '';
  constructor(private couponProductService: CouponProductService<Product>, private router: Router,
    private route: ActivatedRoute,
    private matSnackBar: MatSnackBar,
    private offerService: CouponService<Coupon>

  ) { }

  ngOnInit(): void {
    log.debug('init');
    this.couponId = this.route.snapshot.params['couponId'];
    this.productId = this.route.snapshot.params['productId'];
    this.couponProductService.couponId = this.couponId;
    this.couponProductService.products$.subscribe(products => {
      if(products && products.length) {
        this.product = products.find(product => product.Detail.Id === this.productId);
        this.formGroup.patchValue({
          ProductName: this.product.Detail.ProductName,
          Rank: this.product.Rank
        })
      }
    })
  }
  
  saveData() { 
    if(this.formGroup.controls.Rank.value){   
      this.couponProductService.UpdateCouponAssociationRank(this.couponId, this.productId, this.formGroup.controls.Rank.value).subscribe(res => {
        this.matSnackBar.open(
          ` ${this.formGroup.controls.ProductName.value} saved`, 'OK', DEFAULT_SNACKBAR_CONFIG
        );
        this.couponProductService.reload();
        this.router.navigate([`../`], { relativeTo: this.route, queryParamsHandling: 'preserve' });
      })
    }
  }
  

  cancel(): void {
    this.formGroup.reset();
    this.router.navigate([`../`], { relativeTo: this.route })

  }
}
