import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { environment as env } from 'src/environments/environment';
import { OrganizationService } from './organization.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class KnockNotificationService {
  private triggerWorkflowAPIEndpoint = `${env.pr1ApiUrl}/${this.orgService.organization?.apiPath}/${this.orgService.organization?.version}/AuthUserDomains/TriggerWorkflow`;

  constructor(
    private httpClient: HttpClient,
    private orgService: OrganizationService
  ) { }

  public triggerKnockNotification(
    collectionIds: string[],
    customMessage = 'Trigerred Knock Notification',
    workflowName = 'in-app-testing',
    collectionName = '$users',
    listOfCustomMessages?: string[]
  ) {
    const knockNotificationSubs = new Subscription();
    for (let i = 0; i < collectionIds?.length; i++) {
      let collectionId = collectionIds[i];
      try {
        knockNotificationSubs.add(
          this.httpClient
            .get(
              `${this.triggerWorkflowAPIEndpoint
              }?collectionName=${collectionName}&workflowName=${workflowName}&collectionId=${collectionId}&customMessage=${encodeURIComponent(
                !listOfCustomMessages ? customMessage : listOfCustomMessages[i]
              )}&tenantName=${this.orgService.organization?.apiPath.toLowerCase()}`
            )
            .subscribe({
              next: (resp: any) => {
                if (i == collectionIds.length - 1) {
                  knockNotificationSubs.unsubscribe();
                }
              },
              error: (e: any) => {
                console.log('Error caught in Knock notification call: ', e);
              },
            })
        );
      } catch (e: any) {
        console.log('Error caught in Knock notification call: ', e);
      }
    }
  }

  public triggerCustomKnockNotification(
    collectionIds: string[],
    customMessage = 'Trigerred Knock Notification',
    workflowName = 'in-app',
    collectionName = '$users',
    inappContent = ''
  ) {
    const reqModel = {
      collectionName: collectionName,
      workflowName: workflowName,
      collectionIds: collectionIds,
      customMessage: customMessage,
      tenantName: this.orgService.organization?.apiPath.toLowerCase(),
      inappContent: inappContent
    };
    const url = `${environment.pr1ApiUrl}/${this.orgService.organization?.apiPath}/${this.orgService.organization?.version}/AuthUserDomains/TriggerCustomWorkFlow`;
    return this.httpClient.post<any>(url, reqModel);
  }
}
