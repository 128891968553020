import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, combineLatest, debounceTime, distinctUntilChanged, map, Observable, shareReplay, switchMap, tap, } from 'rxjs';
import { environment } from 'src/environments/environment';
import { OrganizationService } from 'src/app/@shared';
import { v4 as uuidv4 } from 'uuid';
import * as dayjs from 'dayjs';
import { DEFAULT_PAGING } from 'src/app/@shared/constants/site.constants';
import { Filter } from 'src/app/@shared/models/filter.model';
import { AssetDomain } from '..';

const DEFAULT_DATE_RANGE = {
  startDate: dayjs().subtract(1, 'days').format('YYYY-MM-DD'),
  endDate: dayjs().add(5, 'months').format('YYYY-MM-DD'),
};

@Injectable({
  providedIn: 'root'
})

export class AssetsDomainService<T extends AssetDomain> {

  // initialize behavior subjects
  private dateRangeBehaviorSubject = new BehaviorSubject(DEFAULT_DATE_RANGE);
  private pageBehaviorSubject = new BehaviorSubject(DEFAULT_PAGING);
  private loadingBehaviorSubject = new BehaviorSubject<boolean>(false);
  private searchBehaviorSubject = new BehaviorSubject<string>('');
  private sortBehaviorSubject = new BehaviorSubject({ active: 'assetName', direction: 'asc', });
  private reloadBehaviorSubject = new BehaviorSubject<string>('');
  private filterBehaviorSubject = new BehaviorSubject<Filter[]>([]);
  private viewModeBehaviorSubject = new BehaviorSubject<string>('CARDS');
  private assetIdBehaviorSubject = new BehaviorSubject<string>('');

  // we do not wish to expose our behavior subjects.  create public observables
  public dateRange$ = this.dateRangeBehaviorSubject.asObservable();
  public page$ = this.pageBehaviorSubject.asObservable();
  public search$ = this.searchBehaviorSubject.asObservable();
  public sort$ = this.sortBehaviorSubject.asObservable();
  public isLoading$ = this.loadingBehaviorSubject.asObservable();
  public filters$ = this.filterBehaviorSubject.asObservable();
  public viewMode$ = this.viewModeBehaviorSubject.asObservable();
  public assetId$ = this.assetIdBehaviorSubject.asObservable();

  constructor(private httpClient: HttpClient, private organizationService: OrganizationService) { }

  // create the parameters observable that looks for changes in page, startDate, endDate, etc
  public params$ = combineLatest([
    this.assetIdBehaviorSubject,
    this.pageBehaviorSubject, // add debounce if we need to wait for user input ex: .pipe(debounceTime(300)), 
    this.sortBehaviorSubject,
    this.searchBehaviorSubject.pipe(debounceTime(300)),
    this.filterBehaviorSubject.pipe(debounceTime(50)),
    this.reloadBehaviorSubject,
  ]).pipe(
    distinctUntilChanged((previous, current) => {
      // if the values coming down this pipe are the same, don't continue the pipe
      return JSON.stringify(previous) === JSON.stringify(current);
    }),
    map(([assetId, page, sort, search, filters, reload]) => {

      // set the query string odata parameters
      let params: HttpParams = new HttpParams({
        fromObject: {
        // mode: viewMode,
        $skip: page.pageIndex * page.pageSize,
        $top: page.pageSize,
        $orderby: `${sort.active} ${sort.direction}`,
        $expand: 'Detail',
        $count: true
      }
    });

      // if there is a search, add the search to the parameters
      if (search.length) {
        params = params.append('$search', `"${search}"`);
      }

      // if there are filters, add the filters to the parameters
      if (filters.length > 0) {
        params = params.append('$filter', `"${this.buildFilterParam(filters)}"`);
      }

      return params;
    })
  );

  set assetId(assetId: string) {
    this.assetIdBehaviorSubject.next(assetId);
  }


  // gets an asset by id
  getAssetById(assetId: string): Observable<T> {
    const url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/AssetDomains/Get?key=${assetId}&$expand=Detail&$expand=AssetGroup`
    return this.httpClient.get<T>(url);
  }

  updateAsset(assetDomain: any, assetId: string) {
    const url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/AssetDomains/UpdateAsset?key=${assetId}`;
    return this.httpClient.put(url, assetDomain);
  }

  deleteAssets(assets: any) {
    const url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/AssetDomains/DeleteAssets?${this.prepareAssetStringtoDelete(assets)}`;
    return this.httpClient.delete(url, assets);
  }

  prepareAssetStringtoDelete(assets: any) {
    let urlParams = '';
    if (assets && assets.length > 0) {
      for (let index = 0; index <= assets.length - 1; index++) {
        urlParams += `keys=${assets[index]?.Detail?.Id}`;
        if (index != assets.length - 1) {
          urlParams += '&'
        }
      }
    }
    return urlParams;
  }

  // set the current page
  page(page: any) {
    this.pageBehaviorSubject.next(page);
  }

  toggleViewMode(mode: string) {
    this.viewModeBehaviorSubject.next(mode);
  }

  // sets the date range of the event listing
  dateRange(start?: string, end?: string) {
    if (start && end) {
      const range = { startDate: start, endDate: end };
      this.pageBehaviorSubject.next(DEFAULT_PAGING);

      this.dateRangeBehaviorSubject.next(range);
    }
  }


  // sets the sort property and order
  sort(sort: any) {
    this.sortBehaviorSubject.next(sort);
  }

  // sets the search phrase
  search(search: string) {
    const page = this.pageBehaviorSubject.value;
    page.pageIndex = 0;
    page.previousPageIndex = 0;
    this.searchBehaviorSubject.next(search);
    this.pageBehaviorSubject.next(page);
  }

  // reloads/refreshes the store listing
  reload() {
    // reload the Store data
    this.reloadBehaviorSubject.next(uuidv4());
  }

  getRecordsForFilters() {
    return this.httpClient.get(`${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/AssetDomains`);
  }

  // getListByFieldName for filters
  getListByFieldName(fieldName: string): Observable<T[]> {
    const url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/AssetDomains/GetAssetDomains?$apply=groupby((${fieldName}))`;
    return this.httpClient.get<T[]>(url);
  }

  // adds filters to the event listing
  addFilters(newFilters: Filter[]) {
    const filters = this.filterBehaviorSubject.value;

    newFilters.forEach(filter => {
      if (filters.findIndex(item => item.fieldName.toLowerCase() === filter.fieldName.toLowerCase() && item.value.toLowerCase() === filter.value.toLowerCase()) === -1) {
        filters.push(filter)
      }
    });

    this.filterBehaviorSubject.next(filters);
  }

  // removes a filter from the event listing
  removeFilter(filter: Filter) {
    const filters = this.filterBehaviorSubject.value.filter(item => item !== filter);
    this.filterBehaviorSubject.next(filters)
  }

  // removes a filter from the event listing
  removeFilterByFieldName(fieldName: string) {
    const filters = this.filterBehaviorSubject.value.filter(item => item.fieldName.toLowerCase() !== fieldName.toLowerCase());
    this.filterBehaviorSubject.next(filters)
  }

  // removes all filters for the event listing
  clearFilters() {
    this.filterBehaviorSubject.next([]);
  }

  // builds the filter expressions for filtering the event listing
  private buildFilterParam(filters: Filter[]): string {

    // init the filter expressions
    let expressions = '';

    // build the DivisionIds expression
    const groupNameFilters = filters.filter(item => item.fieldName.toLowerCase() === 'assetgroupname');

    // loop through the division id filters and add filter statement to param
    groupNameFilters.forEach((filter, index) => {
      expressions += `assetgroupname eq '${filter.value}'`;
      // if this index is not the last in the array, add ' or '  else add ')' to param
      if (index !== groupNameFilters.length - 1) {
        expressions += ' or ';
      }
    });

    const typeFilters = filters.filter(item => item.fieldName.toLowerCase() === 'type');

    // if there is an EventType filter and we have a Division filter, add ' and (' to expressions
    if (groupNameFilters.length > 0 && typeFilters.length > 0) {
      expressions += ' and (';
    }

    // loop through the division id filters and add filter statement to param
    typeFilters.forEach((filter, index) => {
      expressions += `type eq '${filter.value}'`;
      // if this index is not the last in the array, add ' or '  else add ')' to param
      if (index !== typeFilters.length - 1) {
        expressions += ' or ';
      }
    });

    // if there is an EventType filter and we have a Division filter, add ')' to expressions
    if (groupNameFilters.length > 0 && typeFilters.length > 0) {
      expressions += ')';
    }

    const dateAddedFilters = filters.filter(item => item.fieldName.toLowerCase() === 'dateadded');
    if (groupNameFilters.length > 0 && typeFilters.length > 0 && dateAddedFilters.length > 0) {
      expressions += ' and (';
    }
    dateAddedFilters.forEach((filter, index) => {
      expressions += `dateAdded eq '${filter.value}'`;
      // if this index is not the last in the array, add ' or '  else add ')' to param
      if (index !== dateAddedFilters.length - 1) {
        expressions += ' or ';
      }
    });
    if (groupNameFilters.length > 0 && typeFilters.length > 0 && dateAddedFilters.length > 0) {
      expressions += ')';
    }

    // return the filter expressions
    return expressions;
  }


}
