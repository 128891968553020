<!-- page title -->
<h1>Division Management</h1>

<form [formGroup]="filtersForm">
  <!-- actions bar -->
  <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px" fxLayout.lt-md="column"
    fxLayoutAlign.lt-md="start start" fxLayoutGap.lt-md="4px" class="actions-bar">
    <button *ngIf="searchInput" type="button" mat-raised-button color="accent" matTooltip="Add new record" [routerLink]="[0]"
      [queryParams]="{search: searchInput.value ? searchInput.value : null}">
      <fa-icon [icon]="['fas', 'plus']"></fa-icon>Add Division
    </button>

    <button type="button"
      *ngIf=" divisionsTable && divisionsTable.getSelectedSectionRecords() && divisionsTable.getSelectedSectionRecords().length > 0"
      mat-raised-button [matMenuTriggerFor]="menu" matTooltip="Actions">
      <mat-icon>more_vert</mat-icon>Actions
    </button>
    <mat-menu #menu="matMenu">
      <button type="button" mat-menu-item [matMenuTriggerFor]="Download"><mat-icon>arrow_circle_down</mat-icon>Download</button>
      <!-- <button type="button" mat-menu-item><mat-icon>file_copy</mat-icon>Copy</button> -->
      <button type="button" mat-menu-item (click)="deleteSelectedRecords()"><mat-icon>delete</mat-icon>Delete</button>
      <button type="button" mat-menu-item *ngIf="divisionsTable && divisionsTable.getSelectedSectionRecords() && divisionsTable.getSelectedSectionRecords().length > 0">
        {{ divisionsTable?.getSelectedSectionRecords()?.length}} Selected
    </button>
    </mat-menu>
    <mat-menu #Download="matMenu">
      <button mat-menu-item (click)="downloadJSON()">JSON</button>
      <button mat-menu-item (click)="downloadCSV()">CSV</button>
    </mat-menu>

    <mat-form-field appearance="outline" class="mat-form-field-no-padding">
      <mat-icon matPrefix>search</mat-icon>
      <mat-label>Search</mat-label>
      <input matInput formControlName="search" type="text" placeholder="Search" (input)="onSearch($event)"
        #searchInput />
      <button type="button" matSuffix mat-icon-button aria-label="Clear" *ngIf="searchInput && searchInput.value"
        (click)="clearSearch()">
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
  </div>


  <app-division-list-table #divisionsTable [searchKey]="searchInput.value"></app-division-list-table>
</form>