import { NgModule } from '@angular/core';
import { SharedModule } from 'src/app/@shared';
import { ShopifyV1RoutingModule } from './shopify.v1.routing.module';

@NgModule({
  declarations: [
  ],
  imports: [
    SharedModule,
    ShopifyV1RoutingModule
  ]
})

export class ShopifyV1Module { }
