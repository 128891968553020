<h2>Clone</h2>
<div fxLayout="row" fxLayoutGap.gt-sm="16px" fxLayout.lt-md="column">
    <mat-radio-group class="sort-radio-group" aria-label="Clone Type" [formControl]="selectedCloneType">
        <mat-radio-button class="sort-radio-button" [labelPosition]="'after'" aria-label="Event"
            [value]="CloneType.EVENT">
            Event
        </mat-radio-button>
        <mat-radio-button class="sort-radio-button" [labelPosition]="'after'" aria-label="Offers"
            [value]="CloneType.OFFER">
            Offers
        </mat-radio-button>
        <mat-radio-button class="sort-radio-button" [labelPosition]="'after'" aria-label="Promos"
            [value]="CloneType.PROMO">
            Promos
        </mat-radio-button>
    </mat-radio-group>
</div>

<app-clone-event *ngIf="selectedCloneType.value === CloneType.EVENT"></app-clone-event>
<app-clone-offers *ngIf="selectedCloneType.value === CloneType.OFFER"></app-clone-offers>
<app-clone-offers *ngIf="selectedCloneType.value === CloneType.PROMO"></app-clone-offers>

<ng-template #loading>
    <mat-progress-bar mode="indeterminate" color="accent"></mat-progress-bar>
</ng-template>