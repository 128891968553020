import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { SelectionModel } from '@angular/cdk/collections';
import { Subscription } from 'rxjs';
import { MatDialogRef } from '@angular/material/dialog';
import { SystemWorkflowService } from '../../../services/admin-workflow.service';

@Component({
  selector: 'app-admin-add-workflow',
  templateUrl: './admin-add-workflow.component.html',
  styleUrls: ['./admin-add-workflow.component.scss'],
})
export class AdminAddWorkflowComponent implements OnInit, OnDestroy {
  displayedColumns: string[] = [
    'select',
    'templateName',
    'eventType',
    'channel',
  ];
  dataSource: any = [];
  showAddTemplateView = false;
  selection = new SelectionModel<any>(true, []);
  masterDataSource: any;
  @Output() templatesSelected = new EventEmitter<any>();
  subscription$ = new Subscription();

  constructor(
    public ewService: SystemWorkflowService,
    public auth: AuthService,
    public dialogRef: MatDialogRef<AdminAddWorkflowComponent>
  ) {}

  ngOnInit(): void {
    this.getMasterTemplate();
  }

  getMasterTemplate() {
    this.subscription$.add(
      this.ewService.getMasterTemplate()?.subscribe((data: any) => {
        console.log(`getMasterTemplate - data: `, data);
        this.dataSource = data.map((d: any) => {
          return {
            templateName: d.TemplateName,
            eventType: d.WorkflowType ? d.WorkflowType : '-',
            channel: d.Channel ? d.Channel : '-',
            user: d.UserId,
          };
        });
        this.masterDataSource = this.dataSource;
      })
    );
  }

  onSearch(data: any) {
    const keyword = data.srcElement.value;

    // this.umService.search(event.target.value);
    this.dataSource = this.masterDataSource.filter((x: any) => {
      const keys = Object.keys(x);
      for (let key of keys) {
        if (x[key]?.toLowerCase()?.includes(keyword?.toLowerCase()?.trim())) {
          return true;
        }
      }
      return false;
    });
  }

  clearSearch() {
    // this.userManagementForm.controls.search.setValue('');
    // this.umService.search('');
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.dataSource);
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${
      row.position + 1
    }`;
  }

  onSave() {
    this.ewService.templatesSelected.next(this.selection.selected);
    this.dialogRef.close();
  }

  ngOnDestroy() {
    this.subscription$.unsubscribe();
  }
}
