<div id="users-table">
  <table
    mat-table
    [dataSource]="dataSource"
    class="mat-elevation-z8 user-management-table"
  >
    <!-- Checkbox Column -->
    <ng-container matColumnDef="select" *ngIf="!isReadOnly">
      <th class="no-ellipsis" mat-header-cell *matHeaderCellDef>
        <mat-checkbox
          *ngIf="dataSource?.length > 0 && !isReadOnly"
          (change)="toggleAllRows($event)"
          [checked]="selection.hasValue() && isAllSelected()"
          [indeterminate]="selection.hasValue() && !isAllSelected()"
          [aria-label]="checkboxLabel()"
        >
        </mat-checkbox>
      </th>
      <td class="no-ellipsis" mat-cell *matCellDef="let row; let i = index">
        <mat-checkbox
          *ngIf="!isReadOnly"
          (change)="selectUser($event, i)"
          [checked]="selection.isSelected(row)"
        >
        </mat-checkbox>
      </td>
    </ng-container>

    <!-- First Name Column -->
    <ng-container matColumnDef="first_name">
      <th mat-header-cell *matHeaderCellDef>First Name</th>
      <td mat-cell *matCellDef="let element">
        {{ element.first_name }}
      </td>
    </ng-container>

    <!-- Last Name Column -->
    <ng-container matColumnDef="last_name">
      <th mat-header-cell *matHeaderCellDef>Last Name</th>
      <td mat-cell *matCellDef="let element">
        {{ element.last_name }}
      </td>
    </ng-container>

    <!-- Role Column -->
    <ng-container matColumnDef="role">
      <th mat-header-cell *matHeaderCellDef>Role</th>
      <td mat-cell *matCellDef="let element" style="padding: 0 !important">
        <span *ngIf="isReadOnly">
          {{ element.role }}
        </span>
        <!-- Actions drop down -->
        <button
          *ngIf="!isReadOnly"
          class="user-status-toggle-button"
          mat-raised-button
          [matMenuTriggerFor]="rolesMenu"
          style="margin-left: 0 !important"
        >
          {{ element.role ? element.role : "Select Role" }} &#9660;
        </button>
        <mat-menu #rolesMenu="matMenu" name="roles" ngDefaultControl>
          <button
            mat-menu-item
            *ngFor="let role of rolesRefData"
            (click)="onUserRoleSelect(role.label, element)"
          >
            {{ role.label }}
          </button>
        </mat-menu>
      </td>
    </ng-container>

    <!-- Email Alerts Column -->
    <ng-container matColumnDef="email_alerts">
      <th mat-header-cell *matHeaderCellDef>Email Alerts</th>
      <td mat-cell *matCellDef="let element" style="padding: 0 !important">
        <span *ngIf="isReadOnly">
          {{ element.email_alerts }}
        </span>
        <!-- Actions drop down -->
        <button
          *ngIf="!isReadOnly"
          class="email-alerts-toggle-button"
          mat-raised-button
          [matMenuTriggerFor]="emailAlertsMenu"
          style="margin-left: 0 !important"
        >
          {{
            element.email_alerts ? element.email_alerts : "Select Email Alerts"
          }}
          &#9660;
        </button>
        <mat-menu
          #emailAlertsMenu="matMenu"
          name="email_alerts"
          ngDefaultControl
        >
          <button
            mat-menu-item
            *ngFor="let alert of emailAlertsRefData"
            (click)="onEmailAlertsSelect(alert.label, element)"
          >
            {{ alert.label }}
          </button>
        </mat-menu>
      </td>
    </ng-container>
    <!-- Edit Link Column -->
    <!-- <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element">
        <div
          fxLayout="row"
          fxLayoutAlign="start center"
          fxLayoutGap="32px"
          class="user-table-actions"
        >
          
          <div>
            <a (click)="onEditUser(element)">Edit</a>
          </div>
        </div>
      </td>
    </ng-container> -->

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" [attr.colspan]="displayedColumns.length">
        No users added. Please add atleast one.
      </td>
    </tr>
  </table>
</div>
