import { Component, Input } from '@angular/core';
import { Subscription, combineLatest, distinctUntilChanged, map } from 'rxjs';
import { Banner } from '../../../models/banner.model';
import { Logger } from 'src/app/@shared';
import { BannersService } from '../../../services/banners.service';
import { MediaObserver, MediaChange } from '@angular/flex-layout';
import { SelectionModel } from '@angular/cdk/collections';
import { Sort } from '@angular/material/sort';
import { PageEvent } from '@angular/material/paginator';

const log = new Logger('BannerListTableComponent');

@Component({
  selector: 'app-banner-list-table',
  templateUrl: './banner-list-table.component.html',
  styleUrls: ['./banner-list-table.component.scss']
})
export class BannerListTableComponent<TBanner extends Banner> {
  @Input() searchKey = ''

  viewModel$ = combineLatest([
    this.bannerService.banners$,
    this.bannerService.isLoading$,
    this.bannerService.totalRecords$,
    this.bannerService.page$,
  ]).pipe(
    map(([banners, isLoading, totalRecords, page]) => {
      if (banners.length == 0 && page.pageIndex > 0) {
        page.previousPageIndex = 0
        page.pageIndex = 0
        this.bannerService.page(page);
        this.bannerService.reload();
      }
      return { banners, isLoading, totalRecords, page }
    }),
  );

  flexMediaWatcher!: Subscription;
  displayedColumns = ['select', 'BannerName', 'ClientKey', 'Actions'];
  bannerList: Banner[] = [];
  selection = new SelectionModel<Banner>(true, []);

  constructor(private bannerService: BannersService<TBanner>, private mediaObserver: MediaObserver) { }

  ngOnInit() {
    log.debug('init');
    this.bannerService.banners$.subscribe((data) => {
      this.bannerList = data;
    })
    // detect changes in viewport size to handle show/hide of table columns
    const getAlias = (MediaChange: MediaChange[]) => {
      return MediaChange[0].mqAlias;
    };

    this.flexMediaWatcher = this.mediaObserver
      .asObservable()
      .pipe(
        distinctUntilChanged(
          (x: MediaChange[], y: MediaChange[]) => getAlias(x) === getAlias(y)
        ))
      .subscribe((change) => {
        if (change.some(x => x.mqAlias === 'xs')) {
          this.displayedColumns = ['select', 'BannerName', 'Actions'];
        }
        else {
          this.displayedColumns = ['select', 'BannerName', 'ClientKey', 'Actions'];
        }
      });


  }

  onSort(sortState: Sort): void {
    this.bannerService.sort(sortState);
    this.selection.clear();
  }

  

  onPage(pageEvent: PageEvent): void {
    this.selection.clear();
    this.bannerService.page(pageEvent);
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.bannerList.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.bannerList.forEach(row => this.selection.select(row));
  }

  public getSelectedSectionRecords() {
    return this.selection.selected;
  }

  public clearSelection() {
    this.selection.clear();
  }
}
