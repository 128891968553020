import { Component, Input, OnInit } from '@angular/core';
import { combineLatest, distinctUntilChanged, map, Subscription } from 'rxjs';
import { MediaChange, MediaObserver } from '@angular/flex-layout';
import { Logger } from 'src/app/@shared';
import { Division, DivisionService } from '../..';
import { Sort } from '@angular/material/sort';
import { PageEvent } from '@angular/material/paginator';
import { SelectionModel } from '@angular/cdk/collections';

const log = new Logger('DivisionListTableComponent');

@Component({
  selector: 'app-division-list-table',
  templateUrl: './division-list-table.component.html',
  styleUrls: ['./division-list-table.component.scss']
})

export class DivisionListTableComponent<TDivision extends Division> implements OnInit {
  @Input() searchKey = ''

  viewModel$ = combineLatest([
    this.divisionService.divisions$,
    this.divisionService.isLoading$,
    this.divisionService.totalRecords$,
    this.divisionService.page$,
  ]).pipe(
    map(([divisions, isLoading, totalRecords, page]) => {
      if (divisions.length == 0 && page.pageIndex > 0) {
        page.previousPageIndex = 0
        page.pageIndex = 0
        this.divisionService.page(page);
        this.divisionService.reload();
      }
      return { divisions, isLoading, totalRecords, page }
    }),
  );

  flexMediaWatcher!: Subscription;
  displayedColumns = ['select', 'DivisionName', 'ClientKey', 'Actions'];
  divisionList: Division[] = [];
  selection = new SelectionModel<Division>(true, []);

  constructor(private divisionService: DivisionService<TDivision>, private mediaObserver: MediaObserver) { }

  ngOnInit() {
    log.debug('init');
    this.divisionService.divisions$.subscribe((data) => {
      this.divisionList = data;
    })
    // detect changes in viewport size to handle show/hide of table columns
    const getAlias = (MediaChange: MediaChange[]) => {
      return MediaChange[0].mqAlias;
    };

    this.flexMediaWatcher = this.mediaObserver
      .asObservable()
      .pipe(
        distinctUntilChanged(
          (x: MediaChange[], y: MediaChange[]) => getAlias(x) === getAlias(y)
        ))
      .subscribe((change) => {
        if (change.some(x => x.mqAlias === 'xs')) {
          this.displayedColumns = ['select', 'DivisionName', 'Actions'];
        }
        else {
          this.displayedColumns = ['select', 'DivisionName', 'ClientKey', 'Actions'];
        }
      });


  }

  onSort(sortState: Sort): void {
    this.divisionService.sort(sortState);
    this.selection.clear();
  }

  

  onPage(pageEvent: PageEvent): void {
    this.selection.clear();
    this.divisionService.page(pageEvent);
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.divisionList.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.divisionList.forEach(row => this.selection.select(row));
  }

  public getSelectedSectionRecords() {
    return this.selection.selected;
  }

  public clearSelection() {
    this.selection.clear();
  }
}
