import { Component, Inject, OnInit } from '@angular/core';
import { MediaChange, MediaObserver } from '@angular/flex-layout';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest, distinctUntilChanged, map, Observable, of, Subscription } from 'rxjs';
import { Logger } from 'src/app/@shared';
import { Event, EventDomain, EventService } from '../..';
import { FormControl, FormGroup } from '@angular/forms';

const log = new Logger('UploadAssetsEventsComponent');

@Component({
  selector: 'app-upload-assets-events',
  templateUrl: './upload-assets-events.component.html',
  styleUrls: ['./upload-assets-events.component.scss']
})

export class UploadAssetsEventsComponent<TEvent extends Event> implements OnInit {
  selectedEvent: any;
  viewModel$ = combineLatest([
    this.eventService.uploadevents$,
    this.eventService.isLoading$,
    this.eventService.uploadEventsRecords$,
    this.eventService.uploadEventPage$,
  ]).pipe(
    map(([allevents, isLoading, totalRecords, page]) => {
      return { allevents, isLoading, totalRecords, page }
    }),
  );

  flexMediaWatcher!: Subscription;
  displayedColumns = ['EventName', 'StartDate', 'EndDate', 'Actions'];
  eventDomain$: Observable<EventDomain<TEvent>> = of({} as EventDomain<TEvent>);

  constructor(private eventService: EventService<TEvent>, private mediaObserver: MediaObserver, private router: Router, private route: ActivatedRoute,
    public dialogRef: MatDialogRef<UploadAssetsEventsComponent<TEvent>>,
  ) { }

  ngOnInit(): void {
    log.debug('init');

    // detect changes in viewport size to handle show/hide of table columns
    const getAlias = (MediaChange: MediaChange[]) => {
      return MediaChange[0].mqAlias;
    };

    this.flexMediaWatcher = this.mediaObserver
      .asObservable()
      .pipe(
        distinctUntilChanged(
          (x: MediaChange[], y: MediaChange[]) => getAlias(x) === getAlias(y)
        ))
      .subscribe((change) => {
        if (change.some(x => x.mqAlias === 'xs')) {
          this.displayedColumns = ['EventName', 'Actions'];
        }
        else if (change.some(x => x.mqAlias === 'sm')) {
          this.displayedColumns = ['EventName', 'StartDate'];
        }
        else {
          this.displayedColumns = ['EventName', 'StartDate', 'EndDate', 'Actions'];
        }
      });
  }

  filtersForm = new FormGroup({
    search: new FormControl(),
  });

  onPage(pageEvent: PageEvent): void {
    this.eventService.uploadEventpage(pageEvent);
  }

  onSearch(event: any) {
    this.eventService.uploadEventsearch(event.target.value);
  }

  clearSearch() {
    this.filtersForm.controls.search.setValue('');
    this.eventService.uploadEventsearch('');
   }

  onSort(sortState: Sort): void {
    this.eventService.uploadEventsort(sortState);
  }

  onRowDoubleClick(id: string) {
    this.router.navigate([`${id}/offers`], { relativeTo: this.route });
  }

  ngOnDestroy(): void {
    this.flexMediaWatcher.unsubscribe();
  }

  closeDialog() {
    this.dialogRef.close();
    this.eventService.uploadEventsearch('');
  }

  updateSelectedEvent(event: TEvent) {
    this.selectedEvent = event;
  }
  
  saveData() {
    if (this.selectedEvent) {
      this.dialogRef.close(this.selectedEvent);
      this.eventService.uploadEventsearch('');
    }
  }


}
