import { Offer } from "..";

export interface OfferDomain<TOffer extends Offer> {
    Detail: TOffer;
    ParentOfferId?: string;
    OfferTagIds?: string[];
    VersionIds?: string[];
    AssetIds?: string[];
    BadgeAssetId?: string;
    FileName?: string;
    OfferTypeId?: string;
    PricingPreviewHtml?: string;
    DomainId?: string;
    Versions?: any;
    PrintPageId?:string;
    PageNumber?:string;
    Position?:number;
}