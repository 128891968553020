import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { DEFAULT_SNACKBAR_CONFIG, Logger } from 'src/app/@shared';
import { OfferAsset, BrandAssetsService } from '../..';

const log = new Logger('ProductBrandAssetRankComponent');
@Component({
  selector: 'app-product-brand-asset-rank',
  templateUrl: './product-brand-asset-rank.component.html',
  styleUrls: ['./product-brand-asset-rank.component.scss']
})


export class ProductBrandAssetRankComponent implements OnInit {

  asset$: Observable<OfferAsset> = of({} as OfferAsset);
  formGroup = new FormGroup({
    Rank: new FormControl<number | null>(null, [Validators.min(1), Validators.max(99)]),
    AssetName: new FormControl<string | null>(null)
  });
  private brandId: string = '';
  private assetId: string = '';

  constructor(private brandassetService: BrandAssetsService<OfferAsset>, private router: Router,
    private route: ActivatedRoute,
    private matSnackBar: MatSnackBar) { }

  ngOnInit(): void {
    log.debug('init');
    this.brandId = this.route.snapshot.params['brandId'];
    this.assetId = this.route.snapshot.params['assetId'];
    this.brandassetService.brandId = this.brandId;
    this.getRankandAssetData();
  }

  getRankandAssetData() {
    this.brandassetService.assets$.subscribe(res => {
      if (res && res.length > 0) {
        const assetRecor = res.find(x => x.Detail.Id === this.assetId);
        if (assetRecor) {
          this.formGroup?.patchValue(assetRecor);
          this.formGroup.markAllAsTouched();
        }
      }
    });
  }

  trimControlValues(formGroup: FormGroup): void {
    Object.keys(formGroup.controls).forEach((key: string) => {
      const abstractControl = formGroup.get(key);
      if (abstractControl instanceof FormGroup) {
        this.trimControlValues(abstractControl);
      } else {
        if (typeof abstractControl?.value == 'string') {
          abstractControl.setValue(abstractControl?.value.trim());
        }
      }
    })
  }
  
  saveData() {
    this.trimControlValues(this.formGroup);

    const datatoSave = {
      Detail: {
        Id: this.assetId
      },
      ProductBrandId: this.brandId,
      Rank: this.formGroup.controls.Rank.value
    }
    this.brandassetService.saveRank(datatoSave).subscribe(res => {
      this.matSnackBar.open(
        `${this.formGroup.controls.AssetName.value} saved`, 'OK', DEFAULT_SNACKBAR_CONFIG
      );
      this.brandassetService.reload();
      this.router.navigate([`../../`], { relativeTo: this.route, queryParamsHandling: 'preserve' });
    });

  }

  cancel(): void {
    this.formGroup.reset();
    this.router.navigate([`../../`], { relativeTo: this.route })

  }


}
