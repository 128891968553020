import { SelectionModel } from '@angular/cdk/collections';
import {
  AfterViewChecked,
  Component,
  ChangeDetectorRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { AuthService } from '@auth0/auth0-angular';
import { Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';

import { ActivatedRoute, Router } from '@angular/router';
import {
  ConfirmDialogComponent,
  ErrorDialogComponent,
  NotificationService,
  OrganizationService,
} from 'src/app/@shared';
import { AddGroupDialogComponent } from './add-group-dialog/add-group-dialog.component';
import { SystemWorkflowService } from '../../../services/admin-workflow.service';
import { AdminAddWorkflowUsersComponent } from '../../..';
import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
} from '@angular/cdk/drag-drop';

interface WorkflowUser {
  first_name: string;
  last_name: string;
}

@Component({
  selector: 'app-admin-edit-workflow',
  templateUrl: './admin-edit-workflow.component.html',
  styleUrls: ['./admin-edit-workflow.component.scss'],
})
export class AdminEditWorkflowComponent
  implements OnInit, AfterViewChecked, OnDestroy
{
  editWorkflowForm: FormGroup | undefined;
  template_header = 'Template Name';
  panelOpenState = false;

  startingTriggerDropdownData = [
    { label: 'When workflow is applied to an Event', value: 0 },
    { label: 'When workflow2 is applied to an Event', value: 1 },
    { label: 'When workflow3 is applied to an Event', value: 2 },
    { label: 'When workflow4 is applied to an Event', value: 3 },
  ];

  endingTriggerDropdownData = [
    {
      label: `When all Approvers have marked all offers as "Approved"`,
      value: 0,
    },
    {
      label: `When all Approvers have marked all offers as "Approved2"`,
      value: 1,
    },
    {
      label: `When all Approvers have marked all offers as "Approved3"`,
      value: 2,
    },
    {
      label: `When all Approvers have marked all offers as "Approved4"`,
      value: 3,
    },
  ];

  templateIdValue: string | undefined = undefined;
  public selectedUsersIndex: any;
  duplicateStageFound: boolean[] = [];
  onlyOneStageError: boolean = false;
  nameExists: any = null;

  emailAlertsRefData = [
    { label: 'All Activity', value: 0 },
    { label: 'Work Assigned', value: 1 },
    { label: 'Disabled', value: 2 },
  ];

  @Input() set templateId(val: any) {
    this.templateIdValue = val;
    if (val && val?.trim()?.length > 0) {
      this.getWorkflow(this.templateIdValue!);
    }
  }

  @Output() onClose = new EventEmitter();

  dataSource: MatTableDataSource<any> | undefined;
  selection = new SelectionModel<any>(true, []);
  startTriggerRefData: any;
  endTriggerRefData: any;
  workflowActiveGroupsRefData: any;
  allWorkflowGroups: any;
  subscription$ = new Subscription();
  showActions: boolean[] = [];
  isNewWorkflow = false;
  gotWorkflowDetails = false;
  ewId: any = '';
  ewData: any;
  public expandedPanels: any = [];
  public usersDeleted = false;
  public emailAlerts = [
    'All Activity',
    'Replies to my comments',
    'Decisions',
    'Final Decisions',
    'Disabled',
  ];

  public taskItems = [
    'All Activity',
    'Replies to my comments',
    'Decisions',
    'Final Decisions',
    'Disabled',
  ];

  // public stageTasks = ['User Approval', 'Document Upload', 'ETL Process'];
  public stageTasks = ['User Approval'];

  rolesRefData = [
    { label: 'Reviewer', value: 0 },
    { label: 'Editor', value: 1 },
    { label: 'Approver', value: 2 },
  ];

  needsSaving = false;

  constructor(
    private ewService: SystemWorkflowService,
    public auth: AuthService,
    public dialog: MatDialog,
    public router: Router,
    private route: ActivatedRoute,
    public legacyDialogRef: MatDialog,
    public notificationService: NotificationService,
    private cdref: ChangeDetectorRef,
    private orgService: OrganizationService
  ) {}

  ngOnInit(): void {
    const getControlsRefDataInterval = setInterval(() => {
      if (this.orgService.silentAuthToken.length > 0) {
        this.getControlsRefData();
        clearInterval(getControlsRefDataInterval);
      }
    }, 1000);

    this.createForm();
    if (this.router.url.includes('new-workflow')) {
      this.isNewWorkflow = true;
    }
  }

  ngAfterViewChecked() {
    this.autoCheckStagesForDuplicates();
    this.cdref.detectChanges();
  }

  getSelectedUsers() {}

  getControlsRefData() {
    this.subscription$.add(
      this.ewService
        .getRefDataForEditWorkflowControls(this.orgService.silentAuthToken)
        .subscribe((data: any) => {
          this.emailAlerts = data?.emailAlerts?.map((e: any) => e.Name);
          this.taskItems = data?.tasks?.map((t: any) => t.Name);
          this.startTriggerRefData = data.startTriggers;
          this.endTriggerRefData = data.endTriggers;
          this.allWorkflowGroups = data.workflowGroups;
          this.workflowActiveGroupsRefData = data.workflowGroups.filter(
            (w: any) => w.IsActive == true
          );
        })
    );
  }

  getWorkflow(templateId: string) {
    this.ewService.loadingBehaviorSubject.next(true);
    this.createForm();
    this.subscription$.add(
      this.ewService
        .getWorkflow(this.orgService.silentAuthToken, templateId)
        .subscribe((data: any) => {
          for (let i = 0; i < data?.workflow_stages?.length; i++) {
            const workflowS = this.workflowStage;
            for (let u of data.workflow_stages[i]?.users) {
              (workflowS.get('users') as FormArray).push(
                this.workflowUserFormControl
              );
            }
            workflowS.patchValue(data.workflow_stages[i]);
            this.availableWorkflowStages.push(workflowS);
          }
          this.editWorkflowForm?.patchValue(data);
          this.ewId = data.id;
          this.ewData = data;
          const unskippedStages = data.workflow_stages.filter(
            (s: any) => s.stage_status != 'skipped'
          );

          if (unskippedStages.length == 0) {
            this.onlyOneStageError = true;
          }

          if (this.onlyOneStageError == true) {
            this.availableWorkflowStages.push(this.workflowStage);
          }

          this.gotWorkflowDetails = true;
          this.ewService.loadingBehaviorSubject.next(false);
        })
    );
  }

  createForm() {
    this.editWorkflowForm = new FormGroup({
      template_info: new FormGroup({
        template_name: new FormControl(undefined, Validators.required),
        template_group: new FormControl(undefined, Validators.required),
        template_category: new FormControl(undefined, Validators.required),
      }),
      workflow_config: new FormControl(['ATL', 'OnSite']),
      workflow_stages: new FormArray([]),
    });
  }

  templateInfoInvalid() {
    return this.editWorkflowForm?.get('template_info')?.invalid;
  }

  get workflowStage() {
    return new FormGroup({
      stage_name: new FormControl('Stage Name', Validators.required),
      stage_description: new FormControl(undefined, Validators.required),
      stage_task: new FormControl(undefined, Validators.required),
      stage_id: new FormControl(undefined),
      stage_status: new FormControl(''),
      sort_order: new FormControl(undefined),
      users: new FormArray([]), // Add workflow users here
    });
  }

  get workflowUserFormControl() {
    return new FormGroup({
      first_name: new FormControl(),
      last_name: new FormControl(),
      role: new FormControl(),
      id: new FormControl(),
      email: new FormControl(),
      email_alerts: new FormControl(),
    });
  }

  public get availableWorkflowStages() {
    return (this.editWorkflowForm?.get('workflow_stages') as FormArray)
      ?.controls;
  }

  public getAvailableUsersInStage(i: number): FormArray {
    return (
      this.editWorkflowForm?.get('workflow_stages') as FormArray
    )?.controls[i]?.get('users') as FormArray;
  }

  closeEditWorkflow() {
    if (this.needsSaving == true) {
      const dialogRef = this.legacyDialogRef.open(ConfirmDialogComponent, {
        data: {
          title: 'Cannot Close',
          hideCanel: true,
          confirmText: 'Ok',
          message: 'Workflow needs to be saved',
        },
      });
      dialogRef.afterClosed().subscribe(() => {});
      return;
    } else {
      if (this.router.url.includes('new-workflow')) {
        this.router.navigate([`../`], { relativeTo: this.route });
      } else {
        this.onClose.emit();
      }
    }
  }

  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }
    this.selection.select(...this.dataSource!.data);
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource!.data.length;
    return numSelected === numRows;
  }

  anyStageInvalid() {
    const i = this.availableWorkflowStages.findIndex(
      (s: any) => s.invalid == true
    );

    return i > -1;
  }

  anyStageWithNoUsers() {
    const i = this.availableWorkflowStages.findIndex((s: any) => {
      return s.value.users.length == 0;
    });
    return i > -1;
  }

  onSave(forceSave = false) {
    const ewPayload = this.getPayloadFromEditWorkflowForm();
    this.ewService.createUpdateWorkflow(ewPayload)?.subscribe({
      next: (res: any) => {
        this.notificationService.showSuccess(res);
        this.nameExists = null;
        if (!forceSave) {
          this.needsSaving = false;
          this.onlyOneStageError = false;
          this.ewService.reload();
          this.closeEditWorkflow();
        }
        if (forceSave == true) {
          this.getWorkflow(this.templateIdValue!);
        }
      },
      error: (res: any) => {
        const err = JSON.parse(res.error);
        if (err.Message) {
          this.notificationService.showError(err.Message);
          this.nameExists = ewPayload.Name;
        }
      },
    });
  }

  setSortOrderForEveryStage() {
    for (let i = 0; i < this.availableWorkflowStages.length; i++) {
      this.availableWorkflowStages[i].patchValue({ sort_order: i + 1 });
    }
  }

  getPayloadFromEditWorkflowForm() {
    const f = this.editWorkflowForm!.value;
    this.setSortOrderForEveryStage();

    const payload = {
      WorkflowCategory: f.template_info.template_category,
      WorkflowGroup: f.template_info.template_group,
      Id: this.templateIdValue,
      Name: f.template_info.template_name,
      Description: '',
      CreatedBy: this.ewData?.UserId
        ? this.ewData?.UserId
        : f.template_info.template_owner,
      Tasks: this.availableWorkflowStages?.map((fg: any) => {
        const s = fg.value;
        return {
          Name: s.stage_name,
          Id: s.id ? s.id : '',
          Description: s.stage_description,
          IsComplete: true,
          StageTask: s.stage_task,
          StageStatus: s.stage_status,
          SortOrder: s.sort_order,
          Assignments: s.users?.map((u: any) => ({
            Assignee: {
              Id: u.id,
              FirstName: u.first_name,
              LastName: u.last_name,
              Email: u.email,
              EmailAlert: u.email_alerts,
              // UserTask: u.task,
            },
            Status: 0,
            Role: this.getRoleValueFromLabel(u.role),
          })),
        };
      }),
    };

    return payload;
  }

  getRoleValueFromLabel(label: string) {
    const i = this.rolesRefData.findIndex((r) => r.label == label);
    if (i > -1) {
      return this.rolesRefData[i].value;
    }
    return 0;
  }

  openAddUsersToWorkflow(stageIndex: number) {
    const dialogRef = this.dialog.open(AdminAddWorkflowUsersComponent, {
      data: this.availableWorkflowStages[stageIndex].value?.users?.map(
        (u: any) => u.id
      ),
    });
    this.subscription$.add(
      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          result = result?.map((r: any) => ({
            first_name: r.FirstName,
            last_name: r.LastName,
            email: r.Email,
            // task: '',
            // email_alerts: '',
            id: r.Id,
          }));

          for (let j = 0; j < result?.length; j++) {
            const existingUsers = this.getAvailableUsersInStage(stageIndex)
              .value as any[];
            const euIndex = existingUsers.findIndex(
              (e) =>
                (e.id as string)?.trim() == (result[j]?.id as string)?.trim()
            );

            if (euIndex == -1) {
              const newUser = this.workflowUserFormControl;
              newUser.patchValue(result[j]);
              this.getAvailableUsersInStage(stageIndex).push(newUser);
            }
          }
        }
      })
    );
  }

  onSelectUsers(e: any, i: number) {
    this.showActions[i] = e;
  }

  addStage() {
    if (this.onlyOneStageError == true) {
      this.needsSaving = true;
    }
    this.availableWorkflowStages?.push(this.workflowStage);
    this.onPanelOpen(this.availableWorkflowStages.length - 1);
  }

  removeStage(i: number) {
    let onlyOneStageNotSkipped = false;
    const l = this.availableWorkflowStages.length;
    const skippedStages = this.availableWorkflowStages.filter((s) => {
      return s?.value?.stage_status == 'skipped';
    });
    onlyOneStageNotSkipped = l - skippedStages.length == 1;

    if (this.availableWorkflowStages.length == 1 || onlyOneStageNotSkipped) {
      const dialogRef = this.legacyDialogRef.open(ConfirmDialogComponent, {
        data: {
          message:
            'Cannot remove Stage. Need atleast one un-skipped stage in a template.',
          confirmText: 'Ok',
          hideCanel: true,
          title: 'Not allowed',
        },
      });
    } else {
      const dialogRef = this.legacyDialogRef.open(ConfirmDialogComponent, {
        data: {
          message: 'Are you sure you want to delete this stage?',
          confirmText: 'Yes',
          cancelText: 'No',
        },
      });
      this.subscription$.add(
        dialogRef.afterClosed().subscribe((result) => {
          if (result == true) {
            this.availableWorkflowStages.splice(i, 1);
          }
        })
      );
    }
  }

  onPanelOpen(panelIndex: number) {
    for (let i = 0; i < this.expandedPanels.length; i++) {
      this.expandedPanels[i] = false;
    }
    this.expandedPanels[panelIndex] = true;
  }

  onSelectionChange(e: any) {
    this.onPanelOpen(e.selectedIndex);
  }

  onStageTaskChange(e: any, i: number) {
    (this.availableWorkflowStages[i] as FormGroup)
      .get('stage_task')
      ?.patchValue(e.value);
  }

  selectUsers(users: any, i: number) {
    this.selectedUsersIndex = users;

    this.showActions[i] = users.length > 0;
  }

  deleteSelectedUsers(stageIndex: number) {
    const dialogRef = this.legacyDialogRef.open(ConfirmDialogComponent, {
      data: {
        message: 'Are you sure you want to delete these users?',
        confirmText: 'Yes',
        cancelText: 'No',
      },
    });
    this.subscription$.add(
      dialogRef.afterClosed().subscribe((result) => {
        if (result == true) {
          for (let i = 0; i < this.selectedUsersIndex.length; i++) {
            // setTimeout(() => {
            const alreadyAddedUsers = this.availableWorkflowStages[
              stageIndex
            ]?.get('users') as FormArray;

            const indexToRemove = alreadyAddedUsers.value.findIndex(
              (u: any) => {
                return u.id == this.selectedUsersIndex[i];
              }
            );

            alreadyAddedUsers.removeAt(indexToRemove);
            // }, 100);
          }

          // setTimeout(
          //   () => this.resetUserSelection(stageIndex),
          //   100 * this.selectedUsersIndex.length
          // );
          this.resetUserSelection(stageIndex);
        }
      })
    );
  }

  emailAlertsMultiSet(e: string, stageIndex: number) {
    const currentStageUsers: FormArray = this.availableWorkflowStages[
      stageIndex
    ]?.get('users') as FormArray;

    for (let i = 0; i < this.selectedUsersIndex.length; i++) {
      const sui = currentStageUsers.value.findIndex(
        (c: any) => c.id == this.selectedUsersIndex[i]
      );
      setTimeout(() => {
        currentStageUsers.controls[sui]?.patchValue({ email_alerts: e });
      }, 200);
    }

    this.resetUserSelection(stageIndex);
  }

  // taskMultiSet(t: string, stageIndex: number) {
  //   const currentStageUsers: FormArray = this.availableWorkflowStages[
  //     stageIndex
  //   ]?.get('users') as FormArray;

  //   for (let i = 0; i < this.selectedUsersIndex.length; i++) {
  //     const sui = currentStageUsers.value.findIndex(
  //       (c: any) => c.id == this.selectedUsersIndex[i]
  //     );
  //     setTimeout(() => {
  //       currentStageUsers.controls[sui]?.patchValue({ task: t });
  //     }, 200);
  //   }

  //   this.resetUserSelection(stageIndex);
  // }

  rolesMultiSet(t: any, stageIndex: number) {
    const currentStageUsers: FormArray = this.availableWorkflowStages[
      stageIndex
    ]?.get('users') as FormArray;

    for (let i = 0; i < this.selectedUsersIndex.length; i++) {
      const sui = currentStageUsers.value.findIndex(
        (c: any) => c.id == this.selectedUsersIndex[i]
      );
      setTimeout(() => {
        currentStageUsers.controls[sui]?.patchValue({ role: t });
      }, 200);
    }

    this.resetUserSelection(stageIndex);
  }

  resetUserSelection(stageIndex: number) {
    this.usersDeleted = true;
    this.selectedUsersIndex = [];
    this.showActions[stageIndex] = false;
    setTimeout(() => {
      this.usersDeleted = false;
    }, 100);
  }

  public get availableStageNames() {
    return this.availableWorkflowStages.map((fg: any) => {
      return fg?.value?.stage_name;
    });
    // return (
    //   this.editWorkflowForm?.get('workflow_stages') as FormArray
    // ).value.map((ws: any) => ws.stage_name);
  }

  public autoCheckStagesForDuplicates() {
    for (let i = 0; i < this.availableStageNames.length; i++) {
      this.checkIfStageExists(this.availableStageNames[i], i);
    }
  }

  public checkIfStageExists(stageNameControl: any, stageIndex: number) {
    const val: string =
      typeof stageNameControl == 'string'
        ? stageNameControl
        : stageNameControl.target.value;
    const stageExistsIndex = this.availableStageNames.findIndex(
      (sn: string) => sn?.toLowerCase()?.trim() == val?.toLowerCase()?.trim()
    );

    const duplicateIndexes = this.availableStageNames.reduce(
      (r, sn, i) =>
        r.concat(
          sn?.toLowerCase()?.trim() == val?.toLowerCase()?.trim() ? i : []
        ),
      []
    );

    this.duplicateStageFound[stageIndex] = duplicateIndexes.length > 1;
  }

  public get duplicateInAnyStage() {
    return this.hasDuplicates(
      this.availableStageNames.map((s: string) => s.toLowerCase().trim())
    );
  }

  public hasDuplicates(array: any[]) {
    const x = new Set(array).size !== array.length;
    return x;
  }

  public onAddGroup() {
    const dialogRef = this.legacyDialogRef.open(AddGroupDialogComponent, {
      data: this.allWorkflowGroups,
      minWidth: '900px',
    });
    this.subscription$.add(
      dialogRef.afterClosed().subscribe((r) => {
        if (r) {
          this.createNewWorkflowGroup(r);
        }
      })
    );
  }

  createNewWorkflowGroup(r: any) {
    const payload = {
      Id: '',
      Name: r.group_name,
      IsActive: r.status === 'Active',
      Description: r.description,
      Channel: r.channel,
      CreatedBy: this.ewData?.UserId,
      CreatedDate: new Date().toISOString(),
    };
    this.subscription$.add(
      this.ewService.createWorkflowGroup(payload).subscribe(() => {
        this.getControlsRefData();
      })
    );
  }

  drop(event: CdkDragDrop<any[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    }
  }

  ngOnDestroy(): void {
    if (this.needsSaving == true) {
      this.onSave();
      setTimeout(() => {
        this.subscription$.unsubscribe();
      }, 3000);
    } else {
      this.subscription$.unsubscribe();
    }
  }
}
