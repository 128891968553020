import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-activity-log-details-dialog',
  templateUrl: './activity-log-details-dialog.component.html',
  styleUrls: ['./activity-log-details-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ActivityLogDetailsDialogComponent {
  displayedColumns: string[] = ['field', 'old_value', 'new_value'];

  detailsDataSource = [];

  constructor(
    public matDialogRef: MatDialogRef<ActivityLogDetailsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public ds: DomSanitizer
  ) {
    const source = data?.details?.map((d: any) => {
      return {
        field: d.PropertyName,
        old_value: ds.bypassSecurityTrustHtml(d.OldValue),
        new_value: ds.bypassSecurityTrustHtml(d.NewValue),
      };
    });
    this.detailsDataSource = source;
  }

  closeDialog() {
    this.matDialogRef.close();
  }
}
