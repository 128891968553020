import { Injectable } from '@angular/core';
import { OdataResponse, Product, ProductDomain, Vendor } from '..';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, combineLatest, debounceTime, distinctUntilChanged, map, Observable, shareReplay, switchMap, tap, } from 'rxjs';
import { environment } from 'src/environments/environment';
import { OrganizationService } from 'src/app/@shared';
import { v4 as uuidv4 } from 'uuid';
import { DEFAULT_PAGING } from 'src/app/@shared/constants/site.constants';
import { Filter } from 'src/app/@shared/models/filter.model';

@Injectable({
  providedIn: 'root'
})

export class VendorService<T extends Vendor> {

  // initialize behavior subjects
  private pageBehaviorSubject = new BehaviorSubject(DEFAULT_PAGING);
  private loadingBehaviorSubject = new BehaviorSubject<boolean>(false);
  private searchBehaviorSubject = new BehaviorSubject<string>('');
  private sortBehaviorSubject = new BehaviorSubject({ active: 'VendorName', direction: 'asc', });
  private reloadBehaviorSubject = new BehaviorSubject<string>('');
  private filterBehaviorSubject = new BehaviorSubject<Filter[]>([]);

  // we do not wish to expose our behavior subjects.  create public observables
  public page$ = this.pageBehaviorSubject.asObservable();
  public search$ = this.searchBehaviorSubject.asObservable();
  public sort$ = this.sortBehaviorSubject.asObservable();
  public isLoading$ = this.loadingBehaviorSubject.asObservable();
  public filters$ = this.filterBehaviorSubject.asObservable();

  constructor(private httpClient: HttpClient, private organizationService: OrganizationService) { }

  // create the parameters observable that looks for changes in page, startDate, endDate, etc
  public params$ = combineLatest([
    this.pageBehaviorSubject, // add debounce if we need to wait for user input ex: .pipe(debounceTime(300)), 
    this.sortBehaviorSubject,
    this.searchBehaviorSubject.pipe(debounceTime(300)),
    this.filterBehaviorSubject.pipe(debounceTime(50)),
    this.reloadBehaviorSubject,
  ]).pipe(
    distinctUntilChanged((previous, current) => {
      // if the values coming down this pipe are the same, don't continue the pipe
      return JSON.stringify(previous) === JSON.stringify(current);
    }),
    map(([page, sort, search, filters, reload]) => {

      // set the query string parameters
      let params: HttpParams = new HttpParams({
        fromObject: {
          $skip: page.pageIndex * page.pageSize,
          $top: page.pageSize,
          $orderby: `${sort.active} ${sort.direction}`,
          $count: true,
        }
      });

      // if there is a search, add the search to the parameters
      if (search.length) {
        params = params.append('$search', `"${search}"`);
      }

      // if there are filters, add the filters to the parameters
      // if (filters.length > 0) {
      //   params = this.buildFilterParams(filters, params);
      // }

      return params;
    })
  );

  // create the stores observable that calls http get when any of our parameters change
  private vendorsResponse$ = this.params$.pipe(
    tap(() => this.loadingBehaviorSubject.next(true)), // set isLoading to true
    switchMap((_params) =>
      this.httpClient.get(
        `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/Vendors/GetVendors`,
        { params: _params })
    ),
    tap(() => this.loadingBehaviorSubject.next(false)), // set isLoading to false
    shareReplay(1) // make sure all subscriptions share the same http call (otherwise there will be a http call for each subscription)
  );

  // store listing
  public vendors$: Observable<T[]> = this.vendorsResponse$.pipe(
    map((res: any) => res.value)
  );

  // total number of store records based on filtering
  public totalRecords$: Observable<number> = this.vendorsResponse$.pipe(
    map((res: any) => res['@odata.count'])
  );

  getVendors(): Observable<T[]> {
    const url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/Vendors/GetAllVendors`;
    return this.httpClient.get<T[]>(url);
  }


  // set the current page
  page(page: any) {
    this.pageBehaviorSubject.next(page);
  }

  // gets an store by id
  getVendor(vendorId: string): Observable<T> {
    const url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/Vendors/${vendorId}`;
    return this.httpClient.get<T>(url);
  }

  // sets the sort property and order
  sort(sort: any) {
    this.sortBehaviorSubject.next(sort);
  }

  // search phrase
  get search() {
    return this.searchBehaviorSubject.value;
  }

  set search(search: string) {
    const page = this.pageBehaviorSubject.value;
    page.pageIndex = 0;
    page.previousPageIndex = 0;
    this.searchBehaviorSubject.next(search);
    this.pageBehaviorSubject.next(page);
  }

  // reloads/refreshes the store listing
  reload() {
    // reload the Store data
    this.reloadBehaviorSubject.next(uuidv4());
  }

  // up-serts Stores
  saveVendor(Vendor: T) {
    let url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/Vendors/`;
    if (!Vendor.Id || Vendor.Id === '0') {
      // create new record
      return this.httpClient.post(url, Vendor);
    } else {
      // edit existing record
      url += `${Vendor.Id}`;
      return this.httpClient.put(url, Vendor);
    }
  }

 // deletes vendor by id
  deleteVendor(id: string) {
    const url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/Vendors/${id}`;
    return this.httpClient.delete(url);
  }

  deleteVendors(vendors: any) {
    const url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/Vendors/DeleteVendors?${this.prepareVendorStringtoDelete(vendors)}`;
    return this.httpClient.post(url, vendors);
  }

  prepareVendorStringtoDelete(vendors: any) {
    let urlParams = '';
    if (vendors && vendors.length > 0) {
      for (let index = 0; index <= vendors.length - 1; index++) {
        urlParams += `vendorIds=${vendors[index]?.Id}`;
        if (index != vendors.length - 1) {
          urlParams += '&'
        }
      }
    }
    return urlParams;
  }

  downloadVendorsJSON(){
    const url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/Vendors/EtlExport`;
    window.open(url,'_blank');
  }

  downloadVendorsCSV(){
    const url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/Vendors/Export`;
    window.open(url,'_blank');
  }

}
