import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate, CanLoad, Route, UrlSegment, CanActivateChild, UrlTree } from '@angular/router';
import { AuthGuard } from '@auth0/auth0-angular';
import { environment as env } from 'src/environments/environment';

@Injectable()
export class PrAuthGuard implements CanActivate, CanLoad, CanActivateChild {

  constructor(private auth0AuthGuard: AuthGuard) {

  }

  canLoad(route: Route, segments: UrlSegment[]) {

    if (env.appMode == 'shopify') {
      return true;
    }

    return this.auth0AuthGuard.canLoad(route, segments);
  }
  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    if (env.appMode == 'shopify') {
      return true;
    }

    return this.auth0AuthGuard.canActivateChild(childRoute, state);
  }

  canActivate(_route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): any {

    //bypass guard here
    if (env.appMode == 'shopify') {
      return true;
    }

    // call your guard here
    return this.auth0AuthGuard.canActivate(_route, _state);
  }
}