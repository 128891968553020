import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { Logger, ApiValidation, ConfirmDialogComponent } from 'src/app/@shared';
import { ProductCategory, ProductCategoryService, ProductCategoryDomain } from '../..';
import { DEFAULT_SNACKBAR_CONFIG } from 'src/app/@shared/constants/site.constants';
import { Filter } from 'src/app/@shared/models/filter.model';

const log = new Logger('ProductCategoryComponent');
@Component({
  selector: 'app-product-category',
  templateUrl: './product-category.component.html',
  styleUrls: ['./product-category.component.scss']
})

export class ProductCategoryComponent<T extends ProductCategory> implements OnInit {
  category$: Observable<T> = of({} as T);
  categoryDomain$: Observable<ProductCategoryDomain<ProductCategory>> = of({} as ProductCategoryDomain<ProductCategory>);
  categories$: T[] = [] as T[];
  productCategoryName: string = "";
  parentCategoryName: string = "";
 
  formGroup = new FormGroup({
    ChildProductCategoryIds: new FormControl<string[]>([]),
    Detail: new FormGroup({
      ClientKey: new FormControl<string | null>('', [Validators.required, Validators.minLength(1), Validators.maxLength(100)]),
      Id: new FormControl<string>({value: '0', disabled: true},  { nonNullable: true }),
      ProductCategoryName: new FormControl<string>('', [Validators.required, Validators.minLength(1), Validators.maxLength(100)]),
    }),
    ParentCategory: new FormControl<string | null>({ value: null, disabled: true }),
  });
  categoryId: string = '0';
  parentCategory!: string | null;
  ChildProductCategory: any;

  constructor(private router: Router,
    private route: ActivatedRoute,
    private categoryService: ProductCategoryService<T>,
    private matSnackBar: MatSnackBar,
    private dialog: MatDialog) { }

  ngOnInit(): void {
    log.debug('init');
    this.categoryService.reload();
    // get the category id from the parent route
    this.categoryId = this.route.snapshot.params['categoryId'];

    if (this.categoryId == '0') {
      this.formGroup.controls.ParentCategory.enable();
    }

    this.categoryService.allCategories$.subscribe((data) => {
      if (this.categoryId && this.categoryId !== '0') {
        data = data.filter(obj => obj.Id != this.categoryId)
      }
      this.categories$ = data;
      if (this.route.snapshot.params['parentCategoryId']) {
        this.parentCategory = this.route.snapshot.params['parentCategoryId'];
        this.formGroup.controls.ParentCategory.setValue(this.parentCategory);
        this.formGroup.controls.ParentCategory.disable();
      }
    });

    // if we are editing a record, get the record data
    if (this.categoryId && this.categoryId !== '0') {
      this.categoryService.getCategoryDomain(this.categoryId).subscribe((categoryDomain) => {
        this.ChildProductCategory = categoryDomain.value[0];
        this.parentCategory = categoryDomain.value.length > 0 ? categoryDomain.value[0].ParentCategory : 'null';
        this.productCategoryName = categoryDomain.value.length > 0 ? categoryDomain.value[0].Detail.ProductCategoryName : null;
        this.formGroup.patchValue(this.ChildProductCategory);
        this.formGroup.markAllAsTouched();
      })
    }
  }

  trimControlValues(formGroup: FormGroup): void {
    Object.keys(formGroup.controls).forEach((key: string) => {
      const abstractControl = formGroup.get(key);
      if (abstractControl instanceof FormGroup) {
        this.trimControlValues(abstractControl);
      } else {
        if (typeof abstractControl?.value == 'string') {
          abstractControl.setValue(abstractControl?.value.trim());
        }
      }
    })
  }
  
  save() {
    this.trimControlValues(this.formGroup);
    if (this.formGroup.valid) {
      this.categoryService.saveCategory(this.formGroup.getRawValue() as ProductCategoryDomain<ProductCategory>).subscribe({
        next: (response) => {
          this.matSnackBar.open(
            `${this.formGroup.controls.Detail.controls.ProductCategoryName.value} saved`, 'OK', DEFAULT_SNACKBAR_CONFIG
          );
          this.categoryService.reload();
          if (this.route.snapshot.params['parentCategoryId'] != null) {
            this.router.navigate([`../../`], { relativeTo: this.route });
          }
          else {
            this.router.navigate([`../`], { relativeTo: this.route });
          }
        },
        error: (error) => {
          if (error.status === 500) {
            log.error('500 Error saving event', error);
          }
          if (error.status === 400) {
            const apiValidations: ApiValidation[] = error.error;

            if (Array.isArray(apiValidations)) {
              apiValidations.forEach((validation: any) => {
                if (this.formGroup?.get(validation.PropertyName)) {
                  const control = this.formGroup?.get(validation.PropertyName);
                  if (control) {
                    control.markAsTouched();
                    control.setErrors({ invalid: validation.ErrorMessage });
                    this.matSnackBar.open(validation.ErrorMessage, 'Error', { verticalPosition: 'top', panelClass: ['snackbar-error'] });
                  }
                } else {
                  ///TODO: if we have cross field validation then show the validation error at the top of the screen
                  // if we have cross field validation then show the validation error at the top of the screen
                  // push general error messages to array this is displayed in a toast or dialog
                }
              });
            } else {
              this.matSnackBar.open(apiValidations, 'Error', { verticalPosition: 'top', panelClass: ['snackbar-error'] });
            }
          }
        }
      });
    }
  }

  cancel(): void {
    this.formGroup.reset();
    if (this.route.snapshot.params['parentCategoryId'] != null) {
      this.router.navigate([`../../`], { relativeTo: this.route });
    }
    else {
      this.router.navigate([`../`], { relativeTo: this.route });
    }
  }

  deleteRecord() {
    const record = this.formGroup.getRawValue();
   if(record.ChildProductCategoryIds && record.ChildProductCategoryIds.length > 0) {
    this.matSnackBar.open(`Cannot delete this category record because it has children associated with it`, 'OK',  { verticalPosition: 'top', panelClass: ['snackbar-error'] })
    return;
   }
    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Confirm Delete',
        message: `Are you sure you wish to delete ${record.Detail.ProductCategoryName}?`,
      },
      disableClose: true,
    });

    confirmDialog.afterClosed().subscribe(
      confirmResult => {
        if (confirmResult) {
          this.categoryService.deleteCategory(record.Detail.Id).subscribe({
            next: () => {
              this.matSnackBar.open(`${record.Detail.ProductCategoryName} deleted`, 'OK', DEFAULT_SNACKBAR_CONFIG);
              this.categoryService.reload();
              this.router.navigate([`../`], { relativeTo: this.route });
            },
            error: (error) => {
              log.error('Error deleting event', error);

              if (error.error.value) {
                throw new Error(error.error.value);
              } else {
                throw new Error(error.message);
              }
            }
          });
        }
      });
  }

  onFilterChange(event: any) {
    // get the selected category ids 
    const values: T[] = event.value;
    // remove any existing category id filters
    //this.categoryService.removeFilterByFieldName('categoryId');
    // init the array of category filters
    const categoryFilters: Filter[] = [];
    // loop through the selected category ids and add each as a filter
    values.forEach(value => {
      // init the new filter
      const categoryFilter: Filter = { displayText: value.ProductCategoryName, fieldName: 'ParentCategory', value: value.Id };
      // add the filter to the list of filters
      categoryFilters.push(categoryFilter)
    });
    // add the filters to the event service
    //this.categoryService.addFilters(categoryFilters);

  }
}
