import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Logger } from 'src/app/@shared';

const log = new Logger('VersionComponent');

@Component({
  selector: 'app-version',
  templateUrl: './version.component.html',
  styleUrls: ['./version.component.scss']
})

export class VersionComponent implements OnInit {

  versionId = '0';

  constructor(private route: ActivatedRoute,) { }

  ngOnInit(): void {
    log.debug('init');
    // get the versionId from the route params
    this.route.params.subscribe(params => {
      this.versionId = params['versionId'];
    });
  }

}
