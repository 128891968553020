import { Injectable } from '@angular/core';
import { Version } from "..";
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, catchError, combineLatest, debounceTime, distinctUntilChanged, map, Observable, shareReplay, switchMap, tap, } from 'rxjs';
import { environment } from 'src/environments/environment';
import { OrganizationService } from 'src/app/@shared';
import { v4 as uuidv4 } from 'uuid';
import { DEFAULT_PAGING } from 'src/app/@shared/constants/site.constants';
import { MatDialog } from '@angular/material/dialog';

@Injectable({
  providedIn: 'root',
})

export class VersionPromoService {

  // initialize the service url 
  private serviceUrl = environment.pr1ApiUrl;
  private serviceBaseUrl = environment.pr1ApiUrl;


  // initialize behavior subjects
  private eventIdBehaviorSubject = new BehaviorSubject<string>('');
  private promoIdBehaviorSubject = new BehaviorSubject<string>('');
  private pageBehaviorSubject = new BehaviorSubject(DEFAULT_PAGING);
  private versionsNotInPromoPageBehaviorSubject = new BehaviorSubject(DEFAULT_PAGING);
  private loadingBehaviorSubject = new BehaviorSubject<boolean>(false);
  private versionsNotInPromoLoadingBehaviorSubject = new BehaviorSubject<boolean>(false);
  private searchBehaviorSubject = new BehaviorSubject<string>('');
  private dialogSearchBehaviorSubject = new BehaviorSubject<string>('');
  private sortBehaviorSubject = new BehaviorSubject({ active: 'VersionName', direction: 'asc', });
  private versionsortBehaviorSubject = new BehaviorSubject({ active: 'VersionName', direction: 'asc', });
  private reloadBehaviorSubject = new BehaviorSubject<string>('');
  private versionsNotInPromoReloadBehaviorSubject = new BehaviorSubject<string>('');
  private viewModeBehaviorSubject = new BehaviorSubject<string>('CARDS');

  // we do not wish to expose our behavior subjects.  create public observables
  public page$ = this.pageBehaviorSubject.asObservable();
  public versionsNotInPromoPage$ = this.versionsNotInPromoPageBehaviorSubject.asObservable();
  public search$ = this.searchBehaviorSubject.asObservable();
  public dialogSearch$ = this.dialogSearchBehaviorSubject.asObservable();
  public sort$ = this.sortBehaviorSubject.asObservable();
  public versionsort$ = this.versionsortBehaviorSubject.asObservable();
  public isLoading$ = this.loadingBehaviorSubject.asObservable();
  public versionsNotInPromoisLoading$ = this.versionsNotInPromoLoadingBehaviorSubject.asObservable();
  public viewMode$ = this.viewModeBehaviorSubject.asObservable();
  public promoId$ = this.promoIdBehaviorSubject.asObservable();
  public eventId$ = this.eventIdBehaviorSubject.asObservable();

  constructor(private httpClient: HttpClient, private organizationService: OrganizationService, private dialog: MatDialog) {

    if (this.organizationService.organization) {
      this.serviceUrl += `/${this.organizationService.organization.apiPath}/${this.organizationService.organization.version}/Offers`;
      this.serviceBaseUrl += `/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/Versions`;
    }
  }

  // create the parameters observable that looks for changes in page, startDate, endDate, etc


  public params$ = combineLatest([
    this.promoIdBehaviorSubject,
    this.pageBehaviorSubject, // add debounce if we need to wait for user input ex: .pipe(debounceTime(300)), 
    this.sortBehaviorSubject,
    this.searchBehaviorSubject.pipe(debounceTime(300)),
    this.reloadBehaviorSubject,
    this.eventIdBehaviorSubject,
  ]).pipe(
    distinctUntilChanged((previous, current) => {
      // if the values coming down this pipe are the same, don't continue the pipe
      return JSON.stringify(previous) === JSON.stringify(current);
    }),
    map(([versionOfferId, page, sort, search, reload, eventId]) => {

      // set the query string odata parameters
      let params: HttpParams = new HttpParams({
        fromObject: {
        promoId: this.promoIdBehaviorSubject.value,
        eventId: eventId,
        $skip: page.pageIndex * page.pageSize,
        $top: page.pageSize,
        $orderby: `${sort.active} ${sort.direction}`,
        $count: true,
      }
    });

      // if there is a search, add the search to the parameters
      if (search.length) {
        params = params.append('$search', `"${search}"`);
      }

      return params;
    })
  );


  public dialogParams$ = combineLatest([
    this.promoIdBehaviorSubject,
    this.versionsNotInPromoPageBehaviorSubject, // add debounce if we need to wait for user input ex: .pipe(debounceTime(300)), 
    this.sortBehaviorSubject,
    this.searchBehaviorSubject.pipe(debounceTime(300)),
    this.versionsNotInPromoReloadBehaviorSubject,
    this.eventIdBehaviorSubject,
  ]).pipe(
    distinctUntilChanged((previous, current) => {
      // if the values coming down this pipe are the same, don't continue the pipe
      return JSON.stringify(previous) === JSON.stringify(current);
    }),
    map(([versionOfferId, page, sort, search, reload, eventId]) => {

      // set the query string odata parameters
      let params: HttpParams = new HttpParams({
        fromObject: {
        promoId: this.promoIdBehaviorSubject.value,
        eventId: eventId,
        $skip: page.pageIndex * page.pageSize,
        $top: page.pageSize,
        $orderby: `${sort.active} ${sort.direction}`,
        $count: true,
      }
    });

      // if there is a search, add the search to the parameters
      if (search.length) {
        params = params.append('$search', `"${search}"`);
      }

      return params;
    })
  );

  set eventId(eventId: string) {
    this.eventIdBehaviorSubject.next(eventId);
  }

  set promoId(promoId: string) {
    this.promoIdBehaviorSubject.next(promoId);
  }

  //get the list of versions by offerId
  private promosVersionResponse$ = this.params$.pipe(
    tap(() => this.loadingBehaviorSubject.next(true)), // set isLoading to true
    switchMap((_params) =>
      this.httpClient.get(`${this.serviceBaseUrl}/GetVersionsByPromoId`, { params: _params })
    ),
    tap(() => this.loadingBehaviorSubject.next(false)), // set isLoading to false
    catchError(err => { console.log(err); return ''; }),
    shareReplay(1) // make sure all subscriptions share the same http call (otherwise there will be a http call for each subscription)
  );


  private versionsNotInPromoResponse$ = this.dialogParams$.pipe(
    tap(() => this.loadingBehaviorSubject.next(true)), // set isLoading to true
    switchMap((_params) =>
      this.httpClient.get(`${this.serviceBaseUrl}/GetVersionsByNotPromoId`, { params: _params })
    ),
    tap(() => this.loadingBehaviorSubject.next(false)), // set isLoading to false
    catchError(err => { console.log(err); return ''; }),
    shareReplay(1) // make sure all subscriptions share the same http call (otherwise there will be a http call for each subscription)
  );

  associateVersionToPromo(version: string[]) {
    let url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/PromoDomains/AssociateVersions?promoId=${this.promoIdBehaviorSubject.value}`;
    return this.httpClient.post(url, version);
  }

  disassociateVersion(version: string[]) {
    let url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/PromoDomains/DisAssociateVersions?promoId=${this.promoIdBehaviorSubject.value}`;
    return this.httpClient.post(url, version);
  }

  //versions listing by offerId
  public versions$: Observable<Version[]> = this.promosVersionResponse$.pipe(
    map((res: any) => res ? res.value : [])
  );


  public versionsNotInPromo$: Observable<Version[]> = this.versionsNotInPromoResponse$.pipe(
    map((res: any) => res ? res.value : [])
  );

  public totalRecords$: Observable<number> = this.promosVersionResponse$.pipe(
    map((res: any) => res ? res['@odata.count'] : 0)
  );


  public totalVersionsNotInPromo$: Observable<number> = this.versionsNotInPromoResponse$.pipe(
    map((res: any) => res ? res['@odata.count'] : 0)
  );

  // set the current page
  page(page: any) {
    this.pageBehaviorSubject.next(page);
  }
  // set the current page
  resetpage() {
    this.pageBehaviorSubject.next(DEFAULT_PAGING);
  }

  // set the current page
  versionsNotInPromoPage(page: any) {
    this.versionsNotInPromoPageBehaviorSubject.next(page);
  }
  // set the current page
  versionsNotInPromoResetpage() {
    this.versionsNotInPromoPageBehaviorSubject.next(DEFAULT_PAGING);
  }

  // sets the sort property and order
  sort(sort: any) {
    this.sortBehaviorSubject.next(sort);
  }

  // sets the search phrase
  search(search: string) {
    this.searchBehaviorSubject.next(search);
    this.pageBehaviorSubject.next(DEFAULT_PAGING);
  }

  // sets the search phrase
  dialogSearch(search: string) {
    this.dialogSearchBehaviorSubject.next(search);
    this.pageBehaviorSubject.next(DEFAULT_PAGING);
  }

  // reloads/refreshes the offer listing
  reload() {
    // update the service url in the case that the user has switched organization or offer
    this.serviceUrl = `${environment.pr1ApiUrl}/`;

    if (this.organizationService.organization) {
      this.serviceUrl += `standard/${this.organizationService.organization.version}/Versions/${this.promoId}/versions`;
    }

    // reload the Offer data
    this.reloadBehaviorSubject.next(uuidv4());
  }
  
  // set the current page
  versionsNotInPromoReload() {
      this.versionsNotInPromoReloadBehaviorSubject.next(uuidv4());
    }
  
  // changes the view mode of the offer listing
  toggleViewMode(mode: string) {
    this.viewModeBehaviorSubject.next(mode);
  }

}
