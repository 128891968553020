import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit } from '@angular/core';
import { Subscription, map } from 'rxjs';
import { MatDialogRef } from '@angular/material/dialog';
import { EventWorkflowService } from '../../../../services/event-workflow.service';
import { AuthUserService } from '../../../../services/authuser.service';

@Component({
  selector: 'app-add-workflow-users',
  templateUrl: './add-workflow-users.component.html',
  styleUrls: ['./add-workflow-users.component.scss'],
})
export class AddWorkflowUsersComponent implements OnInit {
  dataSource: any;
  selection = new SelectionModel<any>(true, []);
  displayedColumns: string[] = [
    'select',
    'first_name',
    'last_name',
    'user_role',
    'email',
  ];
  subscription$ = new Subscription();

  constructor(
    private ewService: EventWorkflowService,
    public dialogRef: MatDialogRef<AddWorkflowUsersComponent>,
    public userService: AuthUserService<any>
  ) {}

  ngOnInit(): void {
    this.dataSource = this.getDataSource();
    this.getUsers();
  }

  getUsers() {
    this.userService.AuthUsers$.pipe(
      map((data: any[]) => {
        return data.map((d) => ({
          ...d.Detail,
          first_name: d.Detail.FirstName,
          last_name: d.Detail.LastName,
          user_role: d.Detail.Roles,
          // user_status: d.Detail.Active ? 'Active' : 'Not Active',
          email: d.Detail.Email
        }));
      })
    ).subscribe((userData: any) => {
      this.dataSource = userData;
    });

    // this.ewService.getUsersList().subscribe((userData: any) => {
    //   console.log(`this.dataSource: `, userData);
    //   this.dataSource = userData;
    // });
  }

  onSearch(e: any) {
    console.log(`onsearch: `, e);
  }

  clearSearch() {}

  getDataSource() {
    return [
      {
        first_name: '',
        last_name: '',
        user_role: '',
        email: '',
      },
      {
        first_name: '',
        last_name: '',
        user_role: '',
        email: '',
      },
      {
        first_name: '',
        last_name: '',
        user_role: '',
        email_alerts: '',
      },
      {
        first_name: '',
        last_name: '',
        user_role: '',
        email_alerts: '',
      },
    ];
  }

  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.dataSource);
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.length;
    return numSelected === numRows;
  }
  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${
      row.position + 1
    }`;
  }

  onSave() {
    this.dialogRef.close(this.selection.selected);
  }
}
