import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest, map, Observable, of, startWith, tap } from 'rxjs';
import { ApiValidation, ConfirmDialogComponent, Logger } from 'src/app/@shared';
import { DEFAULT_SNACKBAR_CONFIG } from 'src/app/@shared/constants/site.constants';
import { Event, EventService, Version, VersionService } from '../..';

const log = new Logger('VersionDetailsComponent');

@Component({
  selector: 'app-version-details',
  templateUrl: './version-details.component.html',
  styleUrls: ['./version-details.component.scss']
})

export class VersionDetailsComponent<TEvent extends Event, TVersion extends Version> implements OnInit {

  viewModel$ = combineLatest([
    this.eventService.getEvent(this.route.snapshot.parent?.params['eventId'])
  ]).pipe(
    map(([event]) => {
      this.eventClientKey=event.ClientKey;
      return {
        event
      }
    }),
  );

  formGroup = new FormGroup({
    ClientKey: new FormControl<string>(''),
    Id: new FormControl<string>('0', { nonNullable: true }),
    VersionName: new FormControl<string>('', [Validators.required, Validators.minLength(1), Validators.maxLength(100)]),
    Disclaimer: new FormControl<string>(''),
  });

  version$: Observable<TVersion> = of({} as TVersion);
  distinctVersionNames: Array<string> = [];
  eventId = '';
  versionId = '0'
  filteredVersionNames$: Observable<string[]> | undefined;
  eventClientKey?='';
  constructor(
    private versionService: VersionService<TVersion>,
    private eventService: EventService<TEvent>,
    private router: Router,
    private route: ActivatedRoute,
    private matSnackBar: MatSnackBar,

    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    log.debug('init');

    // get the event and version ids from the route
    this.route.params.subscribe(params => {
      this.eventId = params['eventId'];
      this.versionId = params['versionId'];
    });

    // if we are editing a record, get the record data
    if (this.versionId !== '0') {
      this.versionService.getVersion(this.versionId).subscribe((record) => {
        this.formGroup.patchValue(record);
        this.formGroup.markAllAsTouched();
      });
    }

    // get the distinct list of version names to populate the autocomplete
    this.versionService.getDistinctVersionNames().subscribe((names) => {
      this.distinctVersionNames = names.value;
    });

    this.filteredVersionNames$ = this.formGroup.controls.VersionName.valueChanges.pipe(
      startWith(''),
      map(value => this.filterVersionNames(value ? value : '')),
    );

  }

  filterVersionNames(value: string): string[] {
    const filterValue = value?.toLowerCase();
    return this.distinctVersionNames.filter(option => option.toLowerCase().includes(filterValue));
  }

  trimControlValues(formGroup: FormGroup): void {
    Object.keys(formGroup.controls).forEach((key: string) => {
      const abstractControl = formGroup.get(key);
      if (abstractControl instanceof FormGroup) {
        this.trimControlValues(abstractControl);
      } else {
        if (typeof abstractControl?.value == 'string') {
          abstractControl.setValue(abstractControl?.value.trim());
        }
      }
    })
  }
  
  save(): void {
    this.trimControlValues(this.formGroup);

    if (this.formGroup.valid) {
      var clientKeyValue = this.eventClientKey +'-'+(this.formGroup.getRawValue() as TVersion).VersionName;
      this.formGroup.patchValue({ClientKey:clientKeyValue});
      this.versionService.saveVersion(this.eventId,this.formGroup.getRawValue() as TVersion).subscribe({
        next: (response) => {
          this.matSnackBar.open(
            `${this.formGroup.controls.VersionName.value} saved`, 'OK', DEFAULT_SNACKBAR_CONFIG
          );
          this.versionService.reload();
          this.router.navigate([`../../`], { relativeTo: this.route });
        },
        error: (error) => {
          if (error.status === 500) {
            log.error('500 Error saving event', error);
          }
          if (error.status === 400) {
            const apiValidations: ApiValidation[] = error.error;

            apiValidations.forEach((validation) => {
              if (this.formGroup.get(validation.PropertyName)) {
                const control = this.formGroup.get(validation.PropertyName);
                if (control) {
                  control.markAsTouched();
                  control.setErrors({ invalid: validation.ErrorMessage });
                }
              } else {
                ///TODO: if we have cross field validation then show the validation error at the top of the screen
                // if we have cross field validation then show the validation error at the top of the screen
                // push general error messages to array this is displayed in a toast or dialog
              }
            });
          }
        }
      });
    }
  }

  cancel(): void {
    this.formGroup.reset();
    this.router.navigate([`../../`], { relativeTo: this.route });
  }

  deleteRecord() {
    const record = this.formGroup.getRawValue() as TVersion;
    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Confirm Delete',
        message: `Are you sure you wish to delete ${record.VersionName}?`,
      },
      disableClose: true,
    });

    confirmDialog.afterClosed().subscribe(
      confirmResult => {
        if (confirmResult) {
          this.versionService.deleteVersion(record.Id).subscribe({
            next: () => {
              this.matSnackBar.open(`${record.VersionName} deleted`, 'OK', DEFAULT_SNACKBAR_CONFIG);
              this.versionService.reload();
              this.router.navigate([`../../`], { relativeTo: this.route });
            },
            error: (error) => {
              log.error('Error deleting event', error);

              if (error.error.value) {
                throw new Error(error.error.value);
              } else {
                throw new Error(error.message);
              }
            }
          });
        }
      });
  }

  ngOnDestroy() { }

}
