import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Logger } from 'src/app/@shared';

const log = new Logger('ShopperComponent');

@Component({
  selector: 'app-shopper',
  templateUrl: './shopper.component.html',
  styleUrls: ['./shopper.component.scss']
})

export class ShopperComponent implements OnInit {

  shopperId = '0';

  constructor(private route: ActivatedRoute,) { }

  ngOnInit(): void {
    log.debug('init');
    // get the event id from the route params
    this.route.params.subscribe(params => {
      this.shopperId = params['shopperId'];
    });
  }

}
