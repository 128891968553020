import { Component, Input, OnInit } from '@angular/core';
import { Logger } from '../../services/logger.service';

const log = new Logger('LoaderComponent');

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})

export class LoaderComponent implements OnInit {

  @Input() isLoading = false;
  @Input() size = 1;
  @Input() message: string | undefined;

  constructor() { }

  ngOnInit(): void {
    log.debug('init');
  }

}
