import { Component, Inject, OnInit } from '@angular/core';
import { combineLatest, distinctUntilChanged, map, Subscription } from 'rxjs';
import { MediaChange, MediaObserver } from '@angular/flex-layout';
import { Logger } from 'src/app/@shared';
import { Sort } from '@angular/material/sort';
import { PageEvent } from '@angular/material/paginator';
import { Offer, OfferDomain, OfferService } from '../..';
import { ActivatedRoute } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl } from '@angular/forms';

const log = new Logger('UploadAssetsOffersComponent');

@Component({
  selector: 'app-upload-assets-offers',
  templateUrl: './upload-assets-offers.component.html',
  styleUrls: ['./upload-assets-offers.component.scss']
})

export class UploadAssetsOffersComponent<T extends Offer, TOfferDomain extends OfferDomain<Offer>> implements OnInit {
  selectedOffer: any;
  search = new FormControl<string>('');
  viewModel$ = combineLatest([
    this.offerService.offers$,
    this.offerService.isLoading$,
    this.offerService.totalRecords$,
    this.offerService.page$,
  ]).pipe(
    map(([offers, isLoading, totalRecords, page]) => {
      return { offers, isLoading, totalRecords, page }
    }),
  );
  flexMediaWatcher!: Subscription;
  displayedColumns = ['Name', 'Headline', 'BodyCopy', 'Actions'];
  constructor(private offerService: OfferService<T, TOfferDomain>, private mediaObserver: MediaObserver, private route: ActivatedRoute,
    @Inject(MAT_DIALOG_DATA) private dialogData: any,
    public dialogRef: MatDialogRef<UploadAssetsOffersComponent<T, TOfferDomain>>) { }

  ngOnInit(): void {
    log.debug('init');
    this.offerService.eventId = this.dialogData?.event?.Detail?.Id;
    // detect changes in viewport size to handle show/hide of table columns
    const getAlias = (MediaChange: MediaChange[]) => {
      return MediaChange[0].mqAlias;
    };

    this.flexMediaWatcher = this.mediaObserver
      .asObservable()
      .pipe(
        distinctUntilChanged(
          (x: MediaChange[], y: MediaChange[]) => getAlias(x) === getAlias(y)
        ))
      .subscribe((change) => {
        if (change.some(x => x.mqAlias === 'xs')) {
          this.displayedColumns = ['Name', 'Actions'];
        }
        else if (change.some(x => x.mqAlias === 'sm')) {
          this.displayedColumns = ['Name', 'Actions'];
        }
        else {
          this.displayedColumns = ['Name', 'Headline', 'BodyCopy', 'Actions'];
        }
      });
  }

  onSearch(event: any) {
    this.offerService.search(event.target.value);
  }

  clearSearch() {
    this.search.setValue('');
    this.offerService.search('');
   }

  onSort(sortState: Sort): void {
    this.offerService.sort(sortState);
  }

  onPage(pageEvent: PageEvent): void {
    this.offerService.page(pageEvent);
  }

  closeDialog() {
    this.dialogRef.close();
    this.offerService.search('');
  }

  updateSelectedOffer(offer: T) {
    this.selectedOffer = offer;
  }
  saveData() {
    if (this.selectedOffer) {
      this.dialogRef.close(this.selectedOffer);
      this.offerService.search('');
    }
  }


}
