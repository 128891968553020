import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { combineLatest, map } from 'rxjs';
import { Logger, OrganizationService } from 'src/app/@shared';
import { Asset, AssetsService } from 'src/app/modules/standard/v1';
import * as dayjs from 'dayjs';

const log = new Logger('AssetListCardsComponent');

@Component({
  selector: 'app-asset-list-cards',
  templateUrl: './asset-list-cards.component.html',
  styleUrls: ['./asset-list-cards.component.scss']
})

export class AssetListCardsComponent<T extends Asset> implements OnInit {
  private assetsUrl: string = '';
  viewModel$ = combineLatest([
    this.assetsService.assets$,
    this.assetsService.isLoading$,
    this.assetsService.assetsTotalRecords$,
    this.assetsService.page$,
    this.organizationService.assetsUrl$
  ]).pipe(
    map(([assets, isLoading, totalRecords, page, assetsUrl]) => {
      if (assets.length == 0 && page.pageIndex > 0) {
        page.previousPageIndex = 0
        page.pageIndex = 0
        this.assetsService.page(page);
        this.assetsService.reload();
      }
      assets.forEach(asset => asset.DateAdded = dayjs(asset.DateAdded).format('MM/DD/YYYY'));
      this.assetsUrl = assetsUrl;
      return { assets, isLoading, totalRecords, page, assetsUrl }
    }),
  );
  assetsList: Asset[] = [];
  selection = new SelectionModel<Asset>(true, []);
  constructor(private assetsService: AssetsService<Asset>, private organizationService: OrganizationService) { }

  ngOnInit(): void {
    log.debug('init');
  }

  onSort(sortState: Sort): void {
    this.assetsService.sort(sortState);
  }

  onPage(pageEvent: PageEvent): void {
    this.selection.clear();
    this.assetsService.page(pageEvent);
  }


  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.assetsList.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.assetsList.forEach(row => this.selection.select(row));
  }

  public getSelectedSectionRecords() {
    return this.selection.selected;
  }

  public clearSelection() {
    this.selection.clear();
  }

  public getImageSrc(assets: any) {
    let imagesrc = '';
    if (assets.Type === 'application/pdf') {
      imagesrc = './assets/images/pdf.png';
    } else if (assets.Type.indexOf('audio') > -1) {
      imagesrc = './assets/images/mp3.jpg';
    } else if (assets.Type.indexOf('video') > -1) {
      imagesrc = './assets/images/mp4.png';
    } else if (assets.Type.indexOf('text') > -1) {
      imagesrc = './assets/images/text.png';
    }
    else {
      imagesrc = this.assetsUrl + '/' + assets.Detail.FileName;
    }
    return imagesrc;
  }
}
