<ng-container *ngIf="viewModel$ | async as viewModel">
  <div class="tree-container" *ngIf="viewModel.categories.length">
    <mat-tree [dataSource]="treeDataSource" [treeControl]="treeControl">
      <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle>
        <li class="mat-tree-node">
          <div class="tree-node-container">
            <button type="button" mat-icon-button disabled></button>
            {{node.title}}

            <button type="button" mat-icon-button [routerLink]="[0,node.id]">
              <mat-icon>add</mat-icon>
            </button>

            <button type="button" mat-button color="accent" class="float-right" [routerLink]="[node.id]">Edit</button>
          </div>

        </li>
      </mat-tree-node>
      <mat-nested-tree-node *matTreeNodeDef="let node; when: canHaveChildren">
        <li >
          <div class="mat-tree-node">

            <div style="width: 100%;">
              <button type="button" mat-icon-button matTreeNodeToggle>
                <mat-icon>
                  {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                </mat-icon>
              </button>
              <span>{{node.title}}</span>
              <button type="button" mat-icon-button [routerLink]="[0,node.id]">
                <mat-icon>add</mat-icon>
              </button>

              <button type="button" mat-button color="accent" class="float-right" [routerLink]="[node.id]">Edit</button>
            </div>

          </div>
          <mat-progress-bar mode="indeterminate" color="primary" *ngIf="node.loading"></mat-progress-bar>
          <ul style="margin: 0;" [class.hidden]="!treeControl.isExpanded(node)">
            <div>
              <ng-container matTreeNodeOutlet></ng-container>
            </div>
          </ul>
        </li>
      </mat-nested-tree-node>
    </mat-tree>
  </div>

  <!-- <div class="tree-container">
   
    <mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="example-tree">
          

            <mat-tree-node  *matTreeNodeDef="let node" matTreeNodeToggle > 
        <div class="tree-node-container">
            <button type="button" mat-icon-button matTreeNodeToggle [attr.aria-label]="'Toggle ' + node.ProductCategoryName">
                <mat-icon class="mat-icon-rtl-mirror">
                   chevron_right 
                </mat-icon>
        </button>
        
            {{node.ProductCategoryName}}

        
            <button type="button" mat-icon-button [routerLink]="[0,node.Id]" ><mat-icon>add</mat-icon></button> 

            <button type="button" mat-button color="accent" class="float-right" [routerLink]="[node.Id]">Edit</button>
        </div>
                    
            </mat-tree-node>
            
            <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
                <div class="mat-tree-node">
                    <div class="tree-node-container">

                        <button type="button" mat-icon-button matTreeNodeToggle [attr.aria-label]="'Toggle ' + node.ProductCategoryName">
                                <mat-icon class="mat-icon-rtl-mirror">
                                    {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                                </mat-icon>
                        </button>
                {{node.ProductCategoryName}}

                <button type="button" mat-icon-button [routerLink]="[0,node.Id]" ><mat-icon>add</mat-icon></button> 

                <button type="button" mat-button color="accent" class="float-right" [routerLink]="[node.Id]">Edit</button>
                    </div>
          

                </div>
                
                <div [class.example-tree-invisible]="!treeControl.isExpanded(node)"
                    role="group">
                  <ng-container matTreeNodeOutlet></ng-container>
              </div>      <mat-progress-bar  *ngIf="node.isLoading" mode="indeterminate" class="example-tree-progress-bar"  >
                    
            </mat-progress-bar>
            </mat-nested-tree-node> 
    
    </mat-tree>
 </div> -->

  <table *ngIf="!viewModel.categories.length">
    <tr>
      <td class="no-records">
        <h2>No Records Found</h2>
      </td>
    </tr>
  </table>

  <!-- pager -->
  <mat-paginator [length]="viewModel.totalRecords" [pageSize]="viewModel.page.pageSize"
    [pageSizeOptions]="[5, 10, 20, 50, 100,500]" (page)="onPage($event)" *ngIf="viewModel.categories.length"
    showFirstLastButtons [pageIndex]="viewModel.page.pageIndex">
  </mat-paginator>
