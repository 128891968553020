import { Injectable } from "@angular/core";

/**
 * Provides the ability for the user to switch the theme of the portal
 */
@Injectable({
    providedIn: 'root'
})

export class ThemeService {

    private theme = "pr1-light-theme";
    private body = document.getElementsByTagName('body')[0];

    constructor() { }

    /**
     * gets the current theme for the portal
     */
    public get currentTheme(): String | null {
        return this.theme;
    }

    /**
     * Sets the default theme for the portal
     */
    setDefaultTheme() {
        if (localStorage.getItem('pr1-theme')) {
            this.theme = localStorage.getItem('pr1-theme') as unknown as string;
            //const body = document.getElementsByTagName('body')[0];
            this.body.classList.add(this.theme);
        } else {
            localStorage.setItem('pr1-theme', this.theme);
        }
    }

    /**
     * Toggles the portal theme between light and dark
     */
    toggleTheme() {
        //const body = document.getElementsByTagName('body')[0];
        this.body.classList.remove(this.theme);
        this.theme === 'pr1-light-theme' ? this.theme = 'pr1-dark-theme' : this.theme = 'pr1-light-theme';
        this.body.classList.add(this.theme);
        localStorage.setItem('pr1-theme', this.theme);
    }

}
