<!-- page title -->
<h1>Product Management</h1>

<form [formGroup]="filtersForm" novalidate>
    <!-- actions bar -->
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px" fxLayout.lt-md="column"
        fxLayoutAlign.lt-md="start start" fxLayoutGap.lt-md="4px" class="actions-bar">
        <button type="button" mat-raised-button color="accent" matTooltip="Add new record"
            [routerLink]="[0, 'details']">
            <fa-icon [icon]="['fas', 'plus']"></fa-icon>New Product
        </button>

        <button type="button"
            *ngIf=" productsTable && productsTable.getSelectedSectionRecords() && productsTable.getSelectedSectionRecords().length > 0"
            mat-raised-button [matMenuTriggerFor]="menu" matTooltip="Actions">
            <mat-icon>more_vert</mat-icon>Actions
        </button>

        <mat-menu #menu="matMenu">
            <button type="button" mat-menu-item [matMenuTriggerFor]="Download"><mat-icon>arrow_circle_down</mat-icon>Download</button>
            <!-- <button type="button" mat-menu-item><mat-icon>file_copy</mat-icon>Copy</button> -->
            <button type="button" mat-menu-item (click)="deleteSelectedRecords()"><mat-icon>delete</mat-icon>Delete</button>
            <button type="button" mat-menu-item *ngIf="productsTable">                
                    {{productsTable.getSelectedSectionRecords().length}}
                Selected</button>                 
          </mat-menu>
          <mat-menu #Download="matMenu">
            <!-- <button mat-menu-item>CSV</button> -->
            <button mat-menu-item (click)="downloadJSON()">JSON</button>
            <button mat-menu-item (click)="downloadCSV()">CSV</button>
          </mat-menu>

        <mat-form-field appearance="outline" class="mat-form-field-no-padding">
            <mat-icon matPrefix>search</mat-icon>
            <mat-label>Search</mat-label>
            <input matInput formControlName="search" type="text" (input)="onSearch($event)" placeholder="Search"
                #searchInput />
            <button type="button" matSuffix mat-icon-button aria-label="Clear" *ngIf="searchInput.value"
                (click)="clearSearch()">
                <mat-icon>close</mat-icon>
            </button>
        </mat-form-field>
        <!-- <span class="menu-spacer"></span>

        <button mat-raised-button matTooltip="Show/hide filters" (click)="filterSidenav.toggle()">
            <fa-icon [icon]="['fas', 'filter']"></fa-icon>Filter
        </button> -->
    </div>

    <mat-sidenav-container>
        <!--filter sidenav-->
        <mat-sidenav #filterSidenav mode="over" position="end">
            <mat-toolbar>
                Filter
                <span class="menu-spacer"></span>
                <button type="button" mat-icon-button aria-label="Close filter menu" matTooltip="Close filter menu"
                    (click)="filterSidenav.toggle()">
                    <mat-icon>close</mat-icon>
                </button>
            </mat-toolbar>
            <div style="margin: 16px">
                <div fxLayout="column">

                    <!-- <mat-form-field appearance="outline" fxFlex>
                    <mat-label>Parent Category</mat-label>
                    <mat-select formControlName="Category">
                      <mat-option *ngFor="let category of productCategories$" [value]="category.Id">
                        {{category.ProductCategoryName}}
                      </mat-option>
                        </mat-select>
                    </mat-form-field>

                  <mat-form-field appearance="outline" fxFlex="33">
                        <mat-label>Brand</mat-label>
                    <mat-select formControlName="Brand">
                      <mat-option *ngFor="let brand of productBrands$" [value]="brand.Id">
                        {{brand.ProductBrandName}}
                      </mat-option>
                        </mat-select>
                    </mat-form-field> -->

                </div>
                <div fxLayout="row" fxLayoutAlign="end start" fxLayoutGap="16px">
                    <button type="reset" mat-raised-button matTooltip="Reset filters" (click)="resetFilters()">
                        Reset
                    </button>
                    <button type="button" mat-raised-button matTooltip="Apply filters" color="accent"
                        (click)="filterSidenav.toggle()" [disabled]="filtersForm.invalid">
                        Apply
                    </button>
                </div>
            </div>
        </mat-sidenav>

        <!--table view-->
        <app-product-list-table #productsTable></app-product-list-table>

    </mat-sidenav-container>
</form>