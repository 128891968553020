import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { Observable, of, tap } from 'rxjs';
import { Logger, ConfirmDialogComponent } from 'src/app/@shared';
import { OfferTag, OfferTagService } from '../..';
import { DEFAULT_SNACKBAR_CONFIG } from 'src/app/@shared/constants/site.constants';

const log = new Logger('OfferTagComponent');

@Component({
  selector: 'app-offer-tag',
  templateUrl: './offer-tag.component.html',
  styleUrls: ['./offer-tag.component.scss']
})
export class OfferTagComponent<T extends OfferTag> implements OnInit {

  offerTag$: Observable<T> = of({} as T);
  formGroup = new FormGroup({
    ClientKey: new FormControl<string>('', [Validators.required, Validators.minLength(1), Validators.maxLength(100)]),
    Id: new FormControl<string>({ value: '0', disabled: true }, { nonNullable: true }),
    OfferTagName: new FormControl<string>('', [Validators.required, Validators.minLength(1), Validators.maxLength(100)]),
  });
  offerTagId: string = '0';

  constructor(private router: Router,
    private route: ActivatedRoute,
    private offerTagService: OfferTagService<T>,
    private matSnackBar: MatSnackBar,
    private dialog: MatDialog) { }

  ngOnInit(): void {
    log.debug('init');
    // get the offerTag id from the parent route
    this.offerTagId = this.route.snapshot.params['offertagId'];

    // if we are editing a record, get the record data
    if (this.offerTagId && this.offerTagId !== '0') {
      this.offerTag$ = this.offerTagService.getOfferTag(this.offerTagId).pipe(
        tap((offerTag) => {
          this.formGroup.patchValue(offerTag);
          this.formGroup.markAllAsTouched();
        })
      );
    }
  }

  trimControlValues(formGroup: FormGroup): void {
    Object.keys(formGroup.controls).forEach((key: string) => {
      const abstractControl = formGroup.get(key);
      if (abstractControl instanceof FormGroup) {
        this.trimControlValues(abstractControl);
      } else {
        if (typeof abstractControl?.value == 'string') {
          abstractControl.setValue(abstractControl?.value.trim());
        }
      }
    })
  }
  
  save() {
    this.trimControlValues(this.formGroup);
    if (this.formGroup.valid) {
      this.offerTagService.saveOfferTag(this.formGroup.getRawValue() as T).subscribe({
        next: (response) => {
          this.matSnackBar.open(
            `${this.formGroup.controls.OfferTagName.value} saved`, 'OK', DEFAULT_SNACKBAR_CONFIG
          );
          this.offerTagService.reload();
          this.router.navigate([`../`], { relativeTo: this.route, queryParamsHandling: 'preserve' });
        },
        error: (error) => {
          if (error.status === 500) {
            log.error('500 Error saving event', error);
          }
          if (error.status === 400) {
            const apiValidations: any = error.error;
            if (Array.isArray(apiValidations)) {
              apiValidations.forEach((validation: any) => {
                if (this.formGroup?.get(validation.PropertyName)) {
                  const control = this.formGroup?.get(validation.PropertyName);
                  if (control) {
                    control.markAsTouched();
                    control.setErrors({ invalid: validation.ErrorMessage });
                    this.matSnackBar.open(validation.ErrorMessage, 'OK', { verticalPosition: 'top', panelClass: ['snackbar-error'] });
                  }
                } else {
                  ///TODO: if we have cross field validation then show the validation error at the top of the screen
                  // if we have cross field validation then show the validation error at the top of the screen
                  // push general error messages to array this is displayed in a toast or dialog
                }
              });
            } else {
              this.matSnackBar.open(apiValidations, 'Error', { verticalPosition: 'top', panelClass: ['snackbar-error'] });
            }


          }
        }
      });
    }
  }

  cancel(): void {
    this.formGroup.reset();
    this.router.navigate([`../`], { relativeTo: this.route, queryParamsHandling: 'preserve' });
  }

  deleteRecord() {
    const record = this.formGroup.getRawValue();
    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Confirm Delete',
        message: `Are you sure you wish to delete ${record.OfferTagName}?`,
      },
      disableClose: true,
    });

    confirmDialog.afterClosed().subscribe(
      confirmResult => {
        if (confirmResult) {
          this.offerTagService.deleteOfferTag(record.Id).subscribe({
            next: () => {
              this.matSnackBar.open(`${record.OfferTagName} deleted`, 'OK', DEFAULT_SNACKBAR_CONFIG);
              this.offerTagService.reload();
              this.router.navigate([`../`], { relativeTo: this.route, queryParamsHandling: 'preserve' });
            },
            error: (error) => {
              log.error('Error deleting event', error);

              if (error.error.value) {
                throw new Error(error.error.value);
              } else {
                throw new Error(error.message);
              }
            }
          });
        }
      });
  }

}
