import { SelectionModel } from '@angular/cdk/collections';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest, map, Subscription } from 'rxjs';
import { Logger } from 'src/app/@shared';
import { Version, VersionOfferService } from 'src/app/modules/standard/v1';
import { DEFAULT_SNACKBAR_CONFIG } from 'src/app/@shared/constants/site.constants';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormControl, FormGroup } from '@angular/forms';


const log = new Logger('OfferAddVersionsComponent');

interface DialogData {
  eventId: string;
}

@Component({
  selector: 'app-offer-add-versions',
  templateUrl: './offer-add-versions.component.html',
  styleUrls: ['./offer-add-versions.component.scss'],
})

export class OfferAddVersionsComponent implements OnInit {
  filtersForm = new FormGroup({
    search: new FormControl(),
  });
  viewModel$ = combineLatest([
    this.versionOfferService.versionsNotInOffer$,
    this.versionOfferService.versionsNotInOfferisLoading$,
    this.versionOfferService.totalVersionsNotInOffer$,
    this.versionOfferService.versionsNotInOfferPage$
  ]).pipe(
    map(([eventversions, isLoading, totalVersionsNotInOffer, page]) => {
      return { eventversions, isLoading, totalVersionsNotInOffer, page }
    }),
  );

  flexMediaWatcher!: Subscription;
  displayedColumns = ['select', 'VersionName'];
  versionsList: Version[] = [];
  selection = new SelectionModel<Version>(true, []);
  constructor(
    private versionOfferService: VersionOfferService,
    private router: Router,
    private route: ActivatedRoute,
    private matSnackBar: MatSnackBar,
    public dialogRef: MatDialogRef<OfferAddVersionsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  ngOnInit(): void {
    log.debug('init');
    this.clearSearch();
    this.versionOfferService.eventId = this.data.eventId
    this.versionOfferService.versionsNotInOfferReload();
    this.versionOfferService.versionsNotInOfferResetpage();
    this.versionOfferService.versionsNotInOffer$.subscribe((data) => {
      this.versionsList = data;
    });
  }

  closeDialog() {
    this.dialogRef.close();
  }

  saveData() {
    if (this.selection && this.selection.selected && this.selection.selected.length > 0) {
      let versions: string[] = this.selection.selected.map(x => x.Id);
      this.versionOfferService.associateOfferToVersion(versions).subscribe({
        next: () => {
          this.matSnackBar.open(`Versions are added`, 'OK', DEFAULT_SNACKBAR_CONFIG);
          this.versionOfferService.resetpage();
          this.versionOfferService.reload();
          this.dialogRef.close(true);
        },
        error: (error) => {
          log.error('Error adding store to version', error);

          if (error.error.value) {
            throw new Error(error.error.value);
          } else {
            throw new Error(error.message);
          }
        }
      });
    }
  }

  onSearch(event: any) {
    this.versionOfferService.dialogSearch(event.target.value);
  }

  clearSearch() {
    this.filtersForm.controls.search.setValue('');
    this.versionOfferService.dialogSearch('');
  }

  onPage(pageEvent: PageEvent): void {
    this.selection.clear();
    this.versionOfferService.versionsNotInOfferPage(pageEvent);
  }

  onSort(sortState: Sort): void {
    this.versionOfferService.sort(sortState);
    this.selection.clear();
  }

  onRowDoubleClick(id: string) {
    this.router.navigate([`${id}/details`], { relativeTo: this.route, queryParamsHandling: 'preserve' });
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.versionsList.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.versionsList.forEach(row => this.selection.select(row));
  }

}
