import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';

import { Injectable } from '@angular/core';
import { AuthClientConfig, AuthHttpInterceptor } from '@auth0/auth0-angular';
import { Observable } from 'rxjs';
import { environment as env } from 'src/environments/environment';
import { ShopifyJwtInterceptor } from './shopify-jwt-interceptor';

@Injectable()
export class CustomAuthHttpInterceptor implements HttpInterceptor {
  constructor(
    public auth0HttpInterceptor: AuthHttpInterceptor,
    private auth0ClientConfig: AuthClientConfig,
    public shopifyJwtInterceptor: ShopifyJwtInterceptor
  ) {}

  public intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return this.interceptFromAuthHttpInterceptor(req, next);
  }

  public interceptFromAuthHttpInterceptor(
    req: HttpRequest<any>,
    next: HttpHandler
  ) {

    if(env.appMode == 'shopify')
    {
      return this.shopifyJwtInterceptor.intercept(req, next);
    }

    if (!this.auth0ClientConfig.get()) {
      console.log(
        'Setting Auth Client Config manually, because AuthHttpInterceptor cannot find it.'
      );
      this.auth0ClientConfig.set({
        ...env.auth,
        httpInterceptor: {
          ...env.httpInterceptor,
        },
        organization: env.auth.organization,
        scope: 'openid profile email',
      });
    }

    return this.auth0HttpInterceptor?.intercept(req, next);
  }

  async delayPromise() {
    const p = new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(true);
      }, 3000);
    });

    await p;
  }
}
