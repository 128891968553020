import { Component, Injector, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { AngularPlugin } from '@microsoft/applicationinsights-angularplugin-js';
import { filter, map, switchMap } from 'rxjs';
import { environment } from '../environments/environment';
import { Logger, ThemeService, OrganizationService, ErrorDialogService, DialogData, GlobalErrorHandler } from './@shared';
import { AuthService } from '@auth0/auth0-angular';
import { AuthorizationService } from './@shared/services/authorization.service';

const log = new Logger('AppComponent');

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {

  constructor(private router: Router,
    private activatedRoute: ActivatedRoute,
    private themeService: ThemeService,
    private titleService: Title,
    private auth: AuthService,
    private organizationService: OrganizationService,
    private errorDialogService: ErrorDialogService,
    private injector: Injector,
    private authService: AuthorizationService
  ) { }

  ngOnInit(): void {

    // Setup logger
    if (environment.production) {
      Logger.enableProductionMode();
    }

    log.debug('init');

    // setup application insights
    var angularPlugin = new AngularPlugin();
    const appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: environment.applicationInsights.instrumentationKey,
        extensions: [angularPlugin],
        extensionConfig: {
          [angularPlugin.identifier]: { router: this.router, errorServices: [new GlobalErrorHandler(this.injector)] },
          enableCorsCorrelation: true,
          enableRequestHeaderTracking: true,
          enableResponseHeaderTracking: true,
          correlationHeaderExcludedDomains: ['*.queue.core.windows.net']
        }
      }
    });
    appInsights.loadAppInsights();

    if (environment.appMode == 'shopify') {
      this.authService.setPermissions(['org:shopify']);
      this.organizationService.setOrganizations(['shopify']);
    }
    else {
      // authorize the 
      this.auth.user$.subscribe(profile => {
        if (profile) {
          this.authService.setPermissions(profile["pr1.permissions"]);
          const userOrganizations = profile["pr1.permissions"].map(function (x: string) { return x.replace("org:", "") });
          if (userOrganizations.length > 0) {
            if (!this.organizationService.organizations) {
              this.organizationService.setOrganizations(userOrganizations);
            }
          } else {
            this.errorDialogService.openDialog({
              title: "Organizations not configured",
              message: "Organizations are not configured for you. Please contact administrator."
            } as DialogData)
          }
        }
      })
    }



    // Apply the theme the user has selected (or default theme)
    this.themeService.setDefaultTheme();

    // Change page title on navigation based on route data
    const onNavigationEnd = this.router.events.pipe(filter(event => event instanceof NavigationEnd));

    onNavigationEnd
      .pipe(
        map(() => {
          let route = this.activatedRoute;
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        filter(route => route.outlet === 'primary'),
        switchMap(route => route.data),
      )
      .subscribe(event => {
        const title = event['title'];
        if (title) {
          this.titleService.setTitle(title);
        }
      });

  }

}
