import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { OrganizationService } from 'src/app/@shared';
import { AuthUserService } from '../../../../services/authuser.service';

@Component({
  selector: 'app-add-group-dialog',
  templateUrl: './add-group-dialog.component.html',
  styleUrls: ['./add-group-dialog.component.scss'],
})
export class AddGroupDialogComponent {
  public addGroupForm: FormGroup | undefined;
  public subscribedChannels: any[] = [];
  public groupStatus = ['Active', 'Not Active'];
  public groupNameExists = false;

  constructor(
    public dialogRef: MatDialogRef<AddGroupDialogComponent>,
    private umApi: AuthUserService<any>,
    private orgService: OrganizationService,
    @Inject(MAT_DIALOG_DATA) public dialogData: any
  ) {
    this.createForm();
    this.getChannels();
  }

  public getChannels() {
    if (this.orgService.organization?.apiPath) {
      const org = this.orgService.organization?.apiPath;
      this.umApi.getChannels(org).subscribe((res) => {
        this.subscribedChannels = res.value;
        // if (this.subscribedChannels && this.subscribedChannels.length > 0 && currentValue.organization && currentValue.channels && currentValue.channels.length > 0) {
        //   this.subscribedChannels.map((i: any) => i.selected = currentValue.channels.includes(i.Id));
        //   this.form?.controls['channels'].patchValue(this.subscribedChannels);
        // }
      });
    }
  }

  createForm() {
    this.addGroupForm = new FormGroup({
      group_name: new FormControl(null, Validators.required),
      description: new FormControl(null, Validators.required),
      channel: new FormControl(null, Validators.required),
      status: new FormControl(null, Validators.required),
    });
  }

  onSave() {
    if (this.addGroupForm?.invalid) {
      this.addGroupForm.markAllAsTouched();
      this.addGroupForm.markAsDirty();
      return;
    }
    this.dialogRef.close(this.addGroupForm!.value);
  }

  groupExists(data: any) {
    const keyword = data.srcElement.value;
    const groupIndex = this.dialogData?.findIndex(
      (gd: any) =>
        gd?.Name?.toLowerCase()?.trim() == keyword?.toLowerCase()?.trim()
    );
    if (groupIndex > -1) {
      this.groupNameExists = true;
    } else {
      this.groupNameExists = false;
    }
  }
}
