import { Injectable } from '@angular/core';
import { ProductCategoryDomain, ProductCategory, ProductCategoryView } from '..';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, combineLatest, debounceTime, distinctUntilChanged, map, Observable, shareReplay, switchMap, tap, } from 'rxjs';
import { environment } from 'src/environments/environment';
import { OrganizationService } from 'src/app/@shared';
import { v4 as uuidv4 } from 'uuid';
import * as dayjs from 'dayjs';
import { DEFAULT_PAGING } from 'src/app/@shared/constants/site.constants';

const DEFAULT_DATE_RANGE = {
  startDate: dayjs().subtract(1, 'days').format('YYYY-MM-DD'),
  endDate: dayjs().add(5, 'months').format('YYYY-MM-DD'),
};

@Injectable({
  providedIn: 'root'
})

export class ProductCategoryService<TProductCategory extends ProductCategory> {

  // initialize behavior subjects
  private dateRangeBehaviorSubject = new BehaviorSubject(DEFAULT_DATE_RANGE);
  private pageBehaviorSubject = new BehaviorSubject(DEFAULT_PAGING);
  private loadingBehaviorSubject = new BehaviorSubject<boolean>(false);
  private searchBehaviorSubject = new BehaviorSubject<string>('');
  private sortBehaviorSubject = new BehaviorSubject({ active: 'ProductCategoryName', direction: 'asc', });
  private reloadBehaviorSubject = new BehaviorSubject<string>('');

  // we do not wish to expose our behavior subjects.  create public observables
  public dateRange$ = this.dateRangeBehaviorSubject.asObservable();
  public page$ = this.pageBehaviorSubject.asObservable();
  public search$ = this.searchBehaviorSubject.asObservable();
  public sort$ = this.sortBehaviorSubject.asObservable();
  public isLoading$ = this.loadingBehaviorSubject.asObservable();

  constructor(private httpClient: HttpClient, private organizationService: OrganizationService) { }

  // create the parameters observable that looks for changes in page, startDate, endDate, etc
  public params$ = combineLatest([
    this.pageBehaviorSubject, // add debounce if we need to wait for user input ex: .pipe(debounceTime(300)), 
    this.sortBehaviorSubject,
    this.searchBehaviorSubject.pipe(debounceTime(300)),
    this.reloadBehaviorSubject,
  ]).pipe(
    distinctUntilChanged((previous, current) => {
      // if the values coming down this pipe are the same, don't continue the pipe
      return JSON.stringify(previous) === JSON.stringify(current);
    }),
    map(([page, sort, search, reload]) => {

      // set the query string odata parameters
      let params: HttpParams = new HttpParams({
        fromObject: {
        // mode: viewMode,
        $expand: 'Detail',
        //$filter: '(parentcategory eq null)',
        $skip: page.pageIndex * page.pageSize,
        $top: page.pageSize,
        $orderby: `${sort.active} ${sort.direction}`,
        $count: true,
      }
    });

      // if there is a search, add the search to the parameters
      if (search.length) {
        params = params.append('$search', `"${search}"`);
      }

      return params;
    })
  );

  // create the categories observable that calls http get when any of our parameters change
  private categoriesResponse$ = this.params$.pipe(
    tap(() => this.loadingBehaviorSubject.next(true)), // set isLoading to true
    switchMap((_params) =>
      this.httpClient.get(
        `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/ProductCategoryDomains`,
        { params: _params })
    ),
    tap(() => this.loadingBehaviorSubject.next(false)), // set isLoading to false
    shareReplay(1) // make sure all subscriptions share the same http call (otherwise there will be a http call for each subscription)
  );

  // category listing
  public categories$: Observable<ProductCategoryDomain<TProductCategory>[]> = this.categoriesResponse$.pipe(
    map((res: any) => res.value)
  );

  private allCategoriesResponse$ = this.params$.pipe(
    switchMap((_params) =>
      this.httpClient.get(`${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/ProductCategories/GetProductCategories`)
    )
  );

  public allCategories$: Observable<TProductCategory[]> = this.allCategoriesResponse$.pipe(
    map((res: any) => res.value)
  );

  // total number of category records based on filtering
  public totalRecords$: Observable<number> = this.categoriesResponse$.pipe(
    map((res: any) => res['@odata.count'])
  );

  // set the current page
  page(page: any) {
    this.pageBehaviorSubject.next(page);
  }

  // gets an category by id
  getLeafCategories(): Observable<TProductCategory[]> {
    const url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/ProductCategoryDomains/GetLeafProductCategories`;
    return this.httpClient.get<TProductCategory[]>(url);
  }

  // gets an category by id
  getCategory(categoryId: string): Observable<TProductCategory> {
    const url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/ProductCategories/${categoryId}`;
    return this.httpClient.get<TProductCategory>(url);
  }

  getCategoryDomain(categoryId: string): Observable<any> {
    const url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/ProductCategoryDomains/Get?&$expand=Detail&key=${categoryId}`;
    return this.httpClient.get(url);
  }

  // get child the category listing
  getChildNodes(parentId: string): Observable<any> {
    let url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/ProductCategoryDomains/GetByParentCategoryId?$expand=Detail&parentCategoryId=${parentId}`;
    return this.httpClient.get(url);
  }

  // sets the sort property and order
  sort(sort: any) {
    this.sortBehaviorSubject.next(sort);
  }

  // sets the search phrase
  search(search: string) {
    const page = this.pageBehaviorSubject.value;
    page.pageIndex = 0;
    page.previousPageIndex = 0;
    this.searchBehaviorSubject.next(search);
    this.pageBehaviorSubject.next(page);
  }

  // reloads/refreshes the category listing
  reload() {
    // reload the Category data
    this.reloadBehaviorSubject.next(uuidv4());
  }

  // up-serts Categories
  saveCategory(productCategoryDomain: ProductCategoryDomain<ProductCategory>) {
    let url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/ProductCategoryDomains/`;
    productCategoryDomain.ParentCategory = productCategoryDomain.ParentCategory ? productCategoryDomain.ParentCategory : '';
    if (!productCategoryDomain.Detail.Id || productCategoryDomain.Detail.Id === '0') {
      // create new record
      if (productCategoryDomain.ParentCategory)
        return this.httpClient.post(`${url}CreateProductCategory`, productCategoryDomain);
      else
        return this.httpClient.post(`${url}CreateProductCategory`, productCategoryDomain);
    } else {
      // edit existing record      
      url += `UpdateProductCategory?key=${productCategoryDomain.Detail.Id}`;
      return this.httpClient.put(url, productCategoryDomain);
    }
  }

  // deletes an Category by id
  deleteCategory(id: string) {
    const url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/ProductCategories/${id}`;
    return this.httpClient.delete(url);
  }

  isProductCategoryHasProducts(parentProductCategoryId: string): Observable<any> {
    const url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/ProductCategoryDomains/IsProductCategoryHasProductEdges?parentProductCategoryId=${parentProductCategoryId}`;
    return this.httpClient.get(url);
  }

  downloadProductCategoriesJSON() {
    const url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/ProductCategories/EtlExport`;
    window.open(url, '_blank');
  }

  downloadProductCategoriesCSV() {
    const url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/ProductCategories/Export`;
    window.open(url, '_blank');
  }
}
