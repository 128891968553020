import { NgModule } from '@angular/core';
import { SharedModule } from 'src/app/@shared';
import { StandardV1RoutingModule } from './standard.v1.routing.module';
import { GridsterModule } from 'angular-gridster2';
import {
  ProductBrandComponent,
  ProductBrandsComponent,
  ProductBrandListTableComponent,
  DivisionComponent,
  DivisionListTableComponent,
  DivisionsComponent,
  EventComponent,
  EventDetailsComponent,
  EventListExpansionPanelComponent,
  EventListTableComponent,
  EventTypesComponent,
  EventTypeComponent,
  EventWorkflowComponent,
  EventsComponent,
  OfferComponent,
  OfferListCardsComponent,
  OfferListTableComponent,
  OfferDetailsComponent,
  OffersComponent,
  OfferTagsComponent,
  OfferTagComponent,
  StoresComponent,
  StoreComponent,
  UsersComponent,
  UserComponent,
  VersionComponent,
  VersionListComponent,
  VersionDetailsComponent,
  ProductCategoriesComponent,
  ProductCategoryComponent,
  ProductCategoryListTableComponent,
  ProductListTableComponent,
  ProductComponent,
  ProductsComponent,
  VersionStoreListComponent,
  VersionStoreDialogComponent,
  OfferVersionsComponent,
  OfferProductsComponent,
  OfferAssetsComponent,
  OfferActivityLogComponent,
  OfferAddVersionsComponent,
  OfferProductsCardsComponent,
  OfferProductsTableComponent,
  OfferAddProductsComponent,
  AssetListCardsComponent,
  AssetListTableComponent,
  AssetsComponent,
  UploadAssetsComponent,
  UploadAssetsProductsComponent,
  UploadAssetsOffersComponent,
  UploadAssetsEventsComponent,
  AssetsEditDetailsComponent,
  ProductAssetListComponent,
  ProductTabsComponent,
  ProductAddAssetComponent,
  OfferAssetCardsComponent,
  OfferAssetsTableComponent,
  OfferAddAssetComponent,
  OfferProductUpdateRankComponent,
  OfferPromoSortDialogComponent,
  PromoAssetsComponent,
  PromoDetailsComponent,
  PromoAssetCardsComponent,
  PromoAddVersionsComponent,
  PromoAssetsTableComponent,
  PromoAddAssetComponent,
  PromoComponent,
  PromoVersionsComponent,
  UploadAssetsPromosComponent,
  UploadAssetsProductBrandsComponent,
  AssetRankComponent,
  PromoAssetRankComponent,
  PromoActivityLogComponent,
  ProductAssetRankComponent,
  ProductBrandTabsComponent,
  ProductBrandAssetListComponent,
  ProductBrandAssetRankComponent,
  ProductBrandAddAssetComponent,
  EventAddAssetComponent,
  EventAssetListComponent,
  EventAssetRankComponent,
  VersionAssetListComponent,
  VersionAddAssetComponent,
  VendorsComponent,
  VendorComponent,
  VersionAssetRankComponent,
  EventAssetCardsComponent,
  EventAssetsTableComponent,
  ProductAssetCardsComponent,
  ProductAssetsTableComponent,
  CloneEventComponent,
  CloneOfferComponent,
  VersionOfferCardsComponent,
  VersionOffersComponent,
  VersionOfferTableComponent,
  VersionPromosComponent,
  VersionPromoCardsComponent,
  VersionPromoTableComponent,
  AddWorkflowComponent,
  EditWorkflowComponent,
  UsersTableComponent,
  AddWorkflowUsersComponent,
  ShoppersComponent,
  ShopperDetailsComponent,
  ShopperComponent,
  ShoppingStatsComponent,
  AddUserOrganizationDetailsComponent,
  WorkflowManagementComponent,
  AdminAddWorkflowComponent,
  AdminAddWorkflowUsersComponent,
  AdminEditWorkflowComponent,
  AddGroupDialogComponent,
  WorkflowUsersTableComponent,
  AdminUsersTableComponent,
  UploadAssetsVersionsComponent,
  EditOfferTagsComponent,
} from '.';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CouponManagementComponent } from './pages/coupon-management/coupon.management.component';
import { CouponComponent } from './pages/coupon/coupon.component';
import { CouponDetailsComponent } from './components/coupon-details/coupon.details.component';
import { CouponsListCardsComponent } from './components/coupons-list-cards/coupons.list.cards.component';
import { CouponSortDialogComponent } from './components/coupon-sort-dialog/coupon.sort.dialog.component';
import { CouponProductsComponent } from './components/coupon-products/coupon.products.component';
import { CouponProductsTableComponent } from './components/coupon-products-table/coupon-products-table.component';
import { CouponProductsCardsComponent } from './components/coupon-products-cards/coupon-products-cards.component';
import { CouponProductUpdateRankComponent } from './components/coupon-product-update-rank/coupon-product-update-rank.component';
import { CouponAddProductsComponent } from './components/coupon-add-products/coupon-add-products.component';
import { CouponAssetsComponent } from './components/coupon-assets/coupon-assets.component';
import { CouponAssetCardsComponent } from './components/coupon-asset-cards/coupon-asset-cards.component';
import { CouponAssetsTableComponent } from './components/coupon-assets-table/coupon-assets-table.component';
import { CouponAddAssetComponent } from './components/coupon-add-asset/coupon-add-asset.component';
import { CouponAssetUpdateRankComponent } from './components/coupon-asset-update-rank/coupon-asset-update-rank.component';
import { CouponListTableComponent } from './components/coupon-list-table/coupon.list.table.component';
import { MaterialModule } from 'src/app/material.module';
import { BannersComponent } from './pages/banners/banners.component';
import { BannerListTableComponent } from './pages/banners/banner-list-table/banner-list-table.component';
import { BannerDetailsComponent } from './pages/banners/banner-details/banner-details.component';
import { CloneComponent } from './pages/clone/clone.component';
import { CloneOffersComponent } from './pages/clone-offers/clone-offers.component';
import { CloneOffersListTableComponent } from './pages/clone-offers-list-table/clone-offers-list-table.component';
import { CloneOffersListCardsComponent } from './pages/clone-offers-list-cards/clone-offers-list-cards.component';

@NgModule({
  declarations: [
    ProductBrandComponent,
    ProductBrandsComponent,
    ProductBrandListTableComponent,
    DivisionComponent,
    DivisionListTableComponent,
    DivisionsComponent,
    EventComponent,
    EventDetailsComponent,
    EventListExpansionPanelComponent,
    EventListTableComponent,
    EventTypesComponent,
    EventTypeComponent,
    EventWorkflowComponent,
    AddWorkflowComponent,
    EditWorkflowComponent,
    UsersTableComponent,
    EventsComponent,
    EventAddAssetComponent,
    EventAssetListComponent,
    EventAssetRankComponent,
    EventAssetCardsComponent,
    EventAssetsTableComponent,
    OfferComponent,
    OfferListCardsComponent,
    OfferListTableComponent,
    OfferDetailsComponent,
    OfferVersionsComponent,
    OffersComponent,
    AssetListCardsComponent,
    AssetListTableComponent,
    AssetsComponent,
    OfferTagsComponent,
    OfferTagComponent,
    StoresComponent,
    StoreComponent,
    UsersComponent,
    UserComponent,
    AddUserOrganizationDetailsComponent,
    UploadAssetsComponent,
    UploadAssetsProductsComponent,
    UploadAssetsOffersComponent,
    UploadAssetsEventsComponent,
    UploadAssetsProductBrandsComponent,
    UploadAssetsPromosComponent,
    AssetsEditDetailsComponent,
    ProductTabsComponent,
    ProductAssetListComponent,
    ProductAssetCardsComponent,
    ProductAssetsTableComponent,
    VersionComponent,
    VersionListComponent,
    VersionDetailsComponent,
    VersionAssetListComponent,
    VersionAddAssetComponent,
    VersionAssetRankComponent,
    ProductCategoriesComponent,
    ProductCategoryComponent,
    ProductCategoryListTableComponent,
    ProductListTableComponent,
    ProductAddAssetComponent,
    ProductComponent,
    ProductsComponent,
    OfferProductsComponent,
    OfferProductsCardsComponent,
    OfferProductsTableComponent,
    OfferAssetsComponent,
    OfferActivityLogComponent,
    OfferAddAssetComponent,
    OfferAssetCardsComponent,
    OfferAssetsTableComponent,
    AssetRankComponent,
    PromoAssetRankComponent,
    PromoActivityLogComponent,
    ProductAssetRankComponent,
    OfferAddProductsComponent,
    VersionStoreListComponent,
    VersionStoreDialogComponent,
    OfferAddVersionsComponent,
    OfferProductUpdateRankComponent,
    OfferPromoSortDialogComponent,
    PromoComponent,
    PromoAssetsComponent,
    PromoDetailsComponent,
    PromoVersionsComponent,
    PromoAddVersionsComponent,
    PromoAssetCardsComponent,
    PromoAssetsTableComponent,
    PromoAddAssetComponent,
    ProductBrandTabsComponent,
    ProductBrandAssetListComponent,
    ProductBrandAssetRankComponent,
    ProductBrandAddAssetComponent,
    VendorsComponent,
    VendorComponent,
    CloneEventComponent,
    CloneOfferComponent,
    VersionOfferCardsComponent,
    VersionOfferTableComponent,
    VersionOffersComponent,
    VersionPromosComponent,
    VersionPromoCardsComponent,
    VersionPromoTableComponent,
    CouponManagementComponent,
    CouponComponent,
    CouponDetailsComponent,
    CouponsListCardsComponent,
    CouponSortDialogComponent,
    CouponProductsComponent,
    CouponProductsCardsComponent,
    CouponProductsTableComponent,
    CouponProductUpdateRankComponent,
    CouponAddProductsComponent,
    CouponAssetsComponent,
    CouponAssetCardsComponent,
    CouponAssetsTableComponent,
    CouponAddAssetComponent,
    CouponAssetUpdateRankComponent,
    CouponListTableComponent,
    ShoppersComponent,
    ShopperDetailsComponent,
    ShopperComponent,
    ShoppingStatsComponent,
    AddWorkflowUsersComponent,
    WorkflowManagementComponent,
    AdminAddWorkflowComponent,
    AdminAddWorkflowUsersComponent,
    AdminEditWorkflowComponent,
    AddGroupDialogComponent,
    WorkflowUsersTableComponent,
    AdminUsersTableComponent,
    UploadAssetsVersionsComponent,
    EditOfferTagsComponent,
    BannersComponent,
    BannerListTableComponent,
    BannerDetailsComponent,
    CloneComponent,
    CloneOffersComponent,
    CloneOffersListTableComponent,
    CloneOffersListCardsComponent
  ],
  imports: [
    StandardV1RoutingModule,
    SharedModule,
    GridsterModule,
    DragDropModule,
    MaterialModule,
  ],
})
export class StandardV1Module {}
