import {
  Directive,
  ElementRef,
  Input,
  OnInit,
  Renderer2,
  SimpleChanges,
} from '@angular/core';
import { AuthorizationService } from '../services/authorization.service';
import { OrganizationService } from '../services/organization.service';

@Directive({
  selector: '[restrictControl]',
})
export class RestrictControlDirective implements OnInit {
  @Input() restrictControl: string = '';

  constructor(
    private authService: AuthorizationService,
    private orgService: OrganizationService,
    private el: ElementRef
  ) {}

  ngOnInit() {
    if (this.restrictControl) {
      this.checkAccess(this.restrictControl);
    }
  }
  public checkAccess(authorizationString: string) {
    if (!this.authService.checkAccess(authorizationString)) {
      if (this.el && this.el.nativeElement && this.el.nativeElement.style) {
        this.el.nativeElement.style.display = 'none';
      }
      this.el.nativeElement.remove();
    }
  }
}
