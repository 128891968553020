export interface User {
    ClientKey?: string;
    OrganizationId?: string;
    Id?: string;
    Email: string;
    PhoneNumber: string;
    FirstName: string;
    LastName: string;
    Password?: string;
    Active?: boolean;
    Roles?: string;
}
