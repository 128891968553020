import { Component, Inject, OnInit } from '@angular/core';
import { combineLatest, distinctUntilChanged, map, Subscription } from 'rxjs';
import { MediaChange, MediaObserver } from '@angular/flex-layout';
import { Logger } from 'src/app/@shared';
import { Sort } from '@angular/material/sort';
import { PageEvent } from '@angular/material/paginator';
import { Offer, OfferDomain, OfferService, Version, VersionService } from '../..';
import { ActivatedRoute } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl } from '@angular/forms';
import { SelectionModel } from '@angular/cdk/collections';

const log = new Logger('UploadAssetsVersionsComponent');

interface DialogData {
  isMultiSelect: boolean;
  event: string;
}

@Component({
  selector: 'app-upload-assets-versions',
  templateUrl: './upload-assets-versions.component.html',
  styleUrls: ['./upload-assets-versions.component.scss']
})

export class UploadAssetsVersionsComponent<TVersion extends Version> implements OnInit {
  selectedVersion: any;
  search = new FormControl<string>('');
  viewModel$ = combineLatest([
    this.versionService.uploadVersions$,
    this.versionService.isLoading$,
    this.versionService.uploadVersionstotalRecords$,
    this.versionService.uploadVersionsPage$,
  ]).pipe(
    map(([versions, isLoading, totalRecords, page]) => {
      return { versions, isLoading, totalRecords, page }
    }),
  );
  flexMediaWatcher!: Subscription;
  versionList: Version[] = [];
  selection = new SelectionModel<Version>(true, []);
  displayedColumns = ['VersionName', 'ClientKey', 'Actions'];
  constructor(private versionService: VersionService<TVersion>, private mediaObserver: MediaObserver, private route: ActivatedRoute,
    @Inject(MAT_DIALOG_DATA) public dialogData: DialogData,
    public dialogRef: MatDialogRef<UploadAssetsVersionsComponent<TVersion>>) { }

  ngOnInit(): void {
    log.debug('init');
    this.versionService.eventId = this.dialogData?.event;
    this.versionService.uploadVersions$.subscribe((data) => {
      this.versionList = data;
    })
    // detect changes in viewport size to handle show/hide of table columns
    const getAlias = (MediaChange: MediaChange[]) => {
      return MediaChange[0].mqAlias;
    };

    this.flexMediaWatcher = this.mediaObserver
      .asObservable()
      .pipe(
        distinctUntilChanged(
          (x: MediaChange[], y: MediaChange[]) => getAlias(x) === getAlias(y)
        ))
      .subscribe((change) => {
        if (change.some(x => x.mqAlias === 'xs')) {
          this.displayedColumns = ['VersionName', 'ClientKey'];
        }
        else if (change.some(x => x.mqAlias === 'sm')) {
          this.displayedColumns = ['VersionName', 'ClientKey'];
        }
        else {
          this.displayedColumns = ['VersionName', 'ClientKey', 'Actions'];
        }
      });
  }

  onSearch(event: any) {
    this.versionService.uploadVersionsSearch(event.target.value);
  }

  clearSearch() {
    this.search.setValue('');
    this.versionService.uploadVersionsSearch('');
   }

  onSort(sortState: Sort): void {
    this.versionService.uploadVersionsSort(sortState);
  }

  onPage(pageEvent: PageEvent): void {
    this.versionService.uploadVersionsPage(pageEvent);
  }

  closeDialog() {
    this.dialogRef.close();
    this.versionService.uploadVersionsSearch('');
  }

  updateSelectedVersion(version: TVersion) {
    this.selectedVersion = version;
  }

  saveData() {    
    if (this.selectedVersion && !this.dialogData.isMultiSelect) {
      this.dialogRef.close(this.selectedVersion);
    } else {
      this.selectedVersion = this.selection.selected.map(x => x);
      this.dialogRef.close(this.selectedVersion);      
    }
    this.versionService.uploadVersionsSearch('');
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.versionList.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.versionList.forEach(row => this.selection.select(row));
  }

  public getSelectedSectionRecords() {
    return this.selection.selected;
  }


}
