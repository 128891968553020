import { Component, OnInit } from '@angular/core';
import { combineLatest, map, Subscription } from 'rxjs';
import { MediaChange, MediaObserver } from '@angular/flex-layout';
import { Logger } from 'src/app/@shared';
import { Sort } from '@angular/material/sort';
import { PageEvent } from '@angular/material/paginator';
import { ProductBrandDomain, ProductBrand, ProductBrandService } from '../..';
import { NestedTreeControl } from '@angular/cdk/tree';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { mapTo, switchMap, tap } from 'rxjs/operators';
import { CollectionViewer, } from '@angular/cdk/collections';
import { MatTreeNestedDataSource } from '@angular/material/tree';

const log = new Logger('ProductBrandListTableComponent');

@Component({
  selector: 'app-product-brand-list-table',
  templateUrl: './product-brand-list-table.component.html',
  styleUrls: ['./product-brand-list-table.component.scss']
})

export class ProductBrandListTableComponent<T extends ProductBrand> implements OnInit {

  treeControl: NestedTreeControl<NestedNode>
  treeDataSource: TreeDataSource

  viewModel$ = combineLatest([
    this.productBrandService.productBrands$,
    this.productBrandService.isLoading$,
    this.productBrandService.totalRecords$,
    this.productBrandService.page$,
  ]).pipe(
    map(([brands, isLoading, totalRecords, page]) => {
      return { brands, isLoading, totalRecords, page }
    }),
  );
  flexMediaWatcher!: Subscription;
  // displayedColumns = ['ProductCategoryName', 'ParentCategory', 'Id', 'ClientKey', 'Actions'];

  constructor(private productBrandService: ProductBrandService<T>, private mediaObserver: MediaObserver) {

    this.treeControl = new NestedTreeControl<NestedNode>(node => node.children$.pipe(map((res: any) => this.mapToNestedNode(res.value))))
    this.treeDataSource = new TreeDataSource(this.treeControl, [])

    this.productBrandService.productBrands$.subscribe(list => {
      this.treeDataSource = new TreeDataSource(this.treeControl, this.mapToNestedNode(list));
    })
  }

  ngOnInit() {
    log.debug('init');
    // detect changes in viewport size to handle show/hide of table columns
    const getAlias = (MediaChange: MediaChange[]) => {
      return MediaChange[0].mqAlias;
    };
  }

  canHaveChildren = (_: number, node: NestedNode) => {
    return node.canHaveChildren
  }

  mapToNestedNode(list: ProductBrandDomain<ProductBrand>[]): NestedNode[] {
    if (!list) list = [];
    return list.map(l => {
      let childNodes;
      if (l.ChildProductBrandIds && l.ChildProductBrandIds.length) {
        childNodes = this.productBrandService.getChildNodes(l.DomainId)
      }
      return new NestedNode(l.Detail.ProductBrandName, l.DomainId, childNodes)
    })
  }




  onSort(sortState: Sort): void {
    this.productBrandService.sort(sortState);
  }

  onPage(pageEvent: PageEvent): void {
    this.productBrandService.page(pageEvent);
  }

}

class NestedNode {
  title: string
  id: string
  // returns children for current node. values are emitted on change$ (i.e. TreeControl selection added/removed)
  children$: Observable<NestedNode[]>
  canHaveChildren: boolean
  loading = false
  change$ = new BehaviorSubject<'added' | 'removed'>('removed')

  constructor(title: string, id: string, children$?: Observable<NestedNode[]>) {
    this.title = title;

    this.id = id;

    if (children$) {
      this.canHaveChildren = true
      this.children$ = this.change$.pipe(
        // whenever change$ gets a new value, load children
        switchMap(change => {
          if (change === 'added') {
            // mark loading
            this.loading = true
            return children$.pipe(
              // done loading
              tap(() => this.loading = false),
            )
          } else {
            this.loading = false
            return of([]).pipe(
              // this is also called twice (why?)
              tap(val => {
                // console.log('close');
              })
            )
          }
        })
      )
    } else {
      // if node was constructed without children, it does not need to emit on change$
      this.canHaveChildren = false
      this.children$ = of([])
    }
  }
}

class TreeDataSource extends MatTreeNestedDataSource<NestedNode> {
  private changeSub: Subscription | undefined

  constructor(private treeControl: NestedTreeControl<NestedNode>, private nodes: NestedNode[]) {
    super()
  }

  override connect(collectionViewer: CollectionViewer): Observable<NestedNode[]> {
    this.changeSub = this.treeControl.expansionModel.changed.pipe(
      tap(selection => {
        selection.added.map(node => node.change$.next('added'))
        selection.removed.map(node => node.change$.next('removed'))
      })
    ).subscribe()

    return collectionViewer.viewChange.pipe(
      tap(() => {
        // console.log('view changed!')
      }),
      mapTo(this.nodes)
    )
  }

  override disconnect(): void {
    if (this.changeSub) {
      this.changeSub.unsubscribe()
    }
    super.disconnect()
  }
}









