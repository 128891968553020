import { Component, ViewChild } from '@angular/core';
import { BannersService } from '../../services/banners.service';
import { Banner } from '../../models/banner.model';
import { FormControl, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent, DEFAULT_SNACKBAR_CONFIG, Logger } from 'src/app/@shared';

const log = new Logger('BannersComponent');

@Component({
  selector: 'app-banners',
  templateUrl: './banners.component.html',
  styleUrls: ['./banners.component.scss']
})
export class BannersComponent<T extends Banner> {
  @ViewChild('bannerTable', { static: false }) bannerTable: any;

  public get bannersService(): BannersService<T> {
    return this._bannersService;
  }
  public set bannersService(value: BannersService<T>) {
    this._bannersService = value;
  }
  filtersForm = new FormGroup({
    search: new FormControl<string | null>(''),
  });

  constructor(private _bannersService: BannersService<T>, private route: ActivatedRoute, private dialog: MatDialog,
    private matSnackBar: MatSnackBar) { }

  ngOnInit() {
    log.debug('init');
    this.clearSearch();
    this.bannersService.reload();

    this.route.queryParams.subscribe(queryParams => {
      let searchKey = queryParams['search'];

      if (searchKey) {
        this._bannersService.search(searchKey)
        this.filtersForm.controls.search.setValue(searchKey);
      } else {
        this._bannersService.search('');
        this.filtersForm.controls.search.setValue('');
      }
    });
  }

  onSearch(event: any) {
    this.bannersService.search(event.target.value);
  }

  clearSearch() {
    this.filtersForm.controls.search.setValue('');
    this.bannersService.search('');
  }

  deleteSelectedRecords() {
    let selectedRecords: any;
    if (this.bannerTable) {
      selectedRecords = this.bannerTable.getSelectedSectionRecords();
    } 
    if (selectedRecords && selectedRecords.length > 0) {
      const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
        data: {
          title: 'Confirm Delete',
          message: `Do you want to remove Banner(s)?`,
        },
        disableClose: true,
      });

      confirmDialog.afterClosed().subscribe(
        confirmResult => {
          if (confirmResult) {
            this.bannersService.deleteBanners(selectedRecords).subscribe({
              next: () => {
                this.matSnackBar.open(`Banner(s) deleted`, 'OK', DEFAULT_SNACKBAR_CONFIG);
                this.bannersService.reload();
                if (this.bannerTable) {
                  this.bannerTable.clearSelection();
                } 
              },
              error: (error) => {
                log.error('Error in deleting banner', error);

                if (error.error.value) {
                  throw new Error(error.error.value);
                } else {
                  throw new Error(error.message);
                }
              }
            });
          }
        });
    }
  }

  downloadJSON(){
    this.bannersService.downloadBannersJSON();
  }

}
