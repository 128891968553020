import { Component, Inject, OnInit } from '@angular/core';
import { combineLatest, distinctUntilChanged, map, Observable, of, Subscription, tap } from 'rxjs';
import { MediaChange, MediaObserver } from '@angular/flex-layout';
import { Logger, OrganizationService } from 'src/app/@shared';
import { Sort } from '@angular/material/sort';
import { PageEvent } from '@angular/material/paginator';
import { Asset, EventService, Event, EventDomain, DivisionService, Division, OfferPromoService, OfferPromo, PromoDomain, UploadAssetsEventsComponent, VersionService, Version } from 'src/app/modules/standard/v1';
import { SelectionModel } from '@angular/cdk/collections';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DEFAULT_SNACKBAR_CONFIG } from 'src/app/@shared/constants/site.constants';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as dayjs from 'dayjs';
import { CloneType } from '../../models/clone-type.model';

const log = new Logger('CloneEventComponent');

interface DialogData {
  eventId: string;
}

@Component({
  selector: 'app-clone-event',
  templateUrl: './clone-event.component.html',
  styleUrls: ['./clone-event.component.scss']
})

export class CloneEventComponent<TEvent extends Event, TDivision extends Division, TOfferPromo extends OfferPromo, TPromoDomain extends PromoDomain<OfferPromo>, TVersion extends Version> implements OnInit {

  CloneType = CloneType;
  viewModel$ = combineLatest([
    this.eventService.events$,
    this.eventService.isLoading$,
    this.eventService.totalRecords$,
    this.eventService.page$,
    this.divisionService.divisionsList$,
    this.offerPromoService.cloneEventId$
  ]).pipe(
    map(([events, isLoading, totalRecords, page, divisions, cloneEventId]) => {
      if (events.length == 0 && page.pageIndex > 0) {
        page.previousPageIndex = 0
        page.pageIndex = 0
        this.eventService.page(page);
        this.eventService.reload();
      }
      this.eventDomain$ = this.eventService.getEventDomain(cloneEventId).pipe(
        tap((eventDomain: any) => {
          this.formGroup.patchValue(eventDomain);
          this.formGroup.patchValue({
            SourceEventId: cloneEventId,
            DestinationEvent: {
              SourceEventName: eventDomain.Detail.EventName,
              EventName: eventDomain.Detail.EventName + '_Copy',
              StartDate: new Date(eventDomain.Detail.StartDate),
              EndDate: new Date(eventDomain.Detail.EndDate)
            }
          })
        })
      );
      return { events, isLoading, totalRecords, page, divisions, cloneEventId }
    }),
  );
  eventDomain$: Observable<EventDomain<TEvent>> = of({} as EventDomain<TEvent>);
  flexMediaWatcher!: Subscription;
  formGroup = new FormGroup({
      SourceEventId: new FormControl<string | null>(null),
      DestinationEvent: new FormGroup({
        SourceEventName: new FormControl<string | null>(null),
        Id: new FormControl<null>(null),
        ClientKey: new FormControl<string>('', [Validators.required, Validators.minLength(1), Validators.maxLength(100)]),
        EventName: new FormControl<string>('', [Validators.required, Validators.minLength(1), Validators.maxLength(100)]),
        StartDate: new FormControl<Date | string | null>(null, [Validators.required]),
        EndDate: new FormControl<Date | string | null>(null, [Validators.required]),
        DivisionIds: new FormControl<string[]>([], [Validators.required]),
      }),
      VersionIds: new FormControl<string[]>([]),
      IncludeVersions: new FormControl<true>(true),
      KeepCurrentStatus: new FormControl<true>(true)
  });

  sourceEventId: string = '';
  versionList: TVersion[] = [];

  constructor(private mediaObserver: MediaObserver,
    private eventService: EventService<TEvent>,
    private divisionService: DivisionService<TDivision>,
    private router: Router,
    public dialogRef: MatDialogRef<CloneEventComponent<TEvent, TDivision, TOfferPromo, TPromoDomain, TVersion>>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private matSnackBar: MatSnackBar,
    private route: ActivatedRoute,
    private offerPromoService: OfferPromoService<TOfferPromo, TPromoDomain>,
    private organizationService: OrganizationService,
    private dialog: MatDialog,
    private versionService: VersionService<TVersion>
  ) {
      this.sourceEventId = this.data.eventId;
      this.offerPromoService.cloneEventId = this.data.eventId;
      this.getVersionsList(this.data.eventId);
      this.formGroup.controls['VersionIds'].valueChanges.subscribe((res: any) => {
        if(res?.length >= this.versionList.length && !res?.includes('allVersions')) {
          this.formGroup.controls['VersionIds'].setValue([...res, 'allVersions']);
        } else if(res?.length <= this.versionList.length && res?.includes('allVersions')) {
          const values = [...res.filter((val: any) => val!=='allVersions')];
          this.formGroup.controls['VersionIds'].setValue(values);
        }
      })
   }

  ngOnInit(): void {
    log.debug('init');
  }

  cloneForm = new FormGroup({
    search: new FormControl(),
  });


  closeDialog() {
    this.dialogRef.close();
    this.offerPromoService.cloneEntity(CloneType.EVENT);
  }

  openSourceEventsDialog() {
    const dialogRef = this.dialog.open(UploadAssetsEventsComponent, {
      width: '60%',
      height: '90%',
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        this.formGroup.controls.DestinationEvent.controls['SourceEventName'].patchValue(
          res.Detail?.EventName
        );
        this.sourceEventId = res.Detail?.Id;
        this.offerPromoService.cloneEventId = res.Detail?.Id;
        this.getVersionsList(res.Detail?.Id);
      }
    });
  }

  cloneEvent() {
    let VersionIds: any = []
    if(this.formGroup.controls['VersionIds'].value) {
      VersionIds = [...this.formGroup.controls['VersionIds'].value.filter((version: string) => version !== 'allVersions')];
    }
    const startDate = this.formatDate(this.formGroup.controls.DestinationEvent.controls.StartDate.value);
    const endDate = this.formatDate(this.formGroup.controls.DestinationEvent.controls.EndDate.value);
    const record = this.formGroup.getRawValue();
    record.DestinationEvent.StartDate = startDate;
    record.DestinationEvent.EndDate = endDate;
    const request: any = {...record, DivisionIds: record.DestinationEvent.DivisionIds, VersionIds };
    this.eventService.cloneEvent(request).subscribe({
      next: (res) => {
        this.matSnackBar.open(`Cloned Successfully!`, 'OK', DEFAULT_SNACKBAR_CONFIG);
        this.dialogRef.close(res);
        this.offerPromoService.cloneEntity(CloneType.EVENT);
        const url = (`${this.organizationService.organization?.apiPath}`).toLowerCase();
        let currentUrl = `${url}/events/${res.Id}/offers`;
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
          this.router.navigate([currentUrl]);
        });
      }, error: (error) => {
        log.error('Error in cloning event', error);

        if (error.error.value) {
          throw new Error(error.error.value);
        } else {
          throw new Error(error.message);
        }
      }
    })
  }

  formatDate(date: Date | string | null) {
    if (date) {
      const newDate = dayjs(date).format('MM/DD/YYYY');
      return newDate;
    } else {
      return '';
    }
  }

  getVersionsList(eventId: string) {
    this.versionService.getVersionsForVariants(eventId).subscribe((res) => {
      this.versionList = res;
      this.formGroup.controls['VersionIds'].setValue(this.versionList.map(version => version.Id));
    });
  }

  toggleSelectAllVersions(event: any) {
    if(this.formGroup.controls['VersionIds'].value && this.formGroup.controls['VersionIds'].value.length >= this.versionList.length) {
      this.formGroup.controls['VersionIds'].setValue(null);
    } else {
      this.formGroup.controls['VersionIds'].setValue([...this.versionList.map(version => version.Id), 'allVersions']);
    }
  }
}
