import {
  AfterViewChecked,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { AddWorkflowComponent, EventService, EventType } from '../..';
import { EventWorkflowService } from '../../services/event-workflow.service';
import { MatDialog } from '@angular/material/dialog';
import { Subscription, combineLatest, map, tap } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { SelectionModel } from '@angular/cdk/collections';
import {
  ConfirmDialogComponent,
  NotificationService,
  OrganizationService,
} from 'src/app/@shared';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-event-workflow',
  templateUrl: './event-workflow.component.html',
  styleUrls: ['./event-workflow.component.scss'],
})
export class EventWorkflowComponent<
  TEvent extends Event,
  TEventType extends EventType
> implements OnInit, OnDestroy, AfterViewChecked
{
  editWorkflow = false;
  dataSource: MatTableDataSource<any> | undefined;
  displayedColumns: string[] = [
    'select',
    'templateName',
    'templateGroup',
    'templateCategory',
    'workflowStatus',
    'actions',
  ];

  public subscription$ = new Subscription();
  eventId: any;
  showActions = false;
  selection = new SelectionModel<any>(true, []);
  selectedTemplateId: any;
  @ViewChild(MatPaginator) paginator?: MatPaginator;
  eventIfFromRoute = '';
  allWorkflowGroups: any;
  allSelected: any;
  selectedTemplates: any;
  selectedGroupId: any = undefined;
  selectTemplateClicked: boolean = false;

  constructor(
    public ewService: EventWorkflowService,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private eventService: EventService<any>,
    private orgService: OrganizationService,
    private _route: ActivatedRoute,
    private notificationService: NotificationService
  ) {
    this.ewService.selectedEventId = _route.snapshot.params['eventId'];

    const getOrgsInterval = setInterval(() => {
      if (this.orgService.accessTokenGotSilently.length > 0) {
        this.initialSetup();
        clearInterval(getOrgsInterval);
      }
    }, 1000);
  }

  ngOnInit(): void {}

  initialSetup() {
    if (this.ewService.selectedEventId.length > 0) {
      this.getControlsRefData();

      this.getEventName();
      this.subscription$.add(
        this.ewService.reload$.subscribe(() => {
          this.ewService.loadingBehaviorSubject.next(true);
          this.getWorkflowsForEvent();
        })
      );
    }
  }

  ngAfterViewChecked() {
    if (this.dataSource && this.paginator && !this.dataSource.paginator) {
      setInterval(() => {
        this.dataSource!.paginator = this.paginator!;
      }, 3000);
    }
  }

  getControlsRefData() {
    this.subscription$.add(
      this.ewService
        .getRefDataForEditWorkflowControls()
        .subscribe((data: any) => {
          this.allWorkflowGroups = data.workflowGroups;
        })
    );
  }

  getWorkflowsForEvent() {
    this.subscription$.add(
      this.ewService
        .getWorkflowsForFeature(
          this.ewService.selectedEventId,
          this.orgService.silentAuthToken
        )
        .subscribe((resp: any) => {
          resp = resp.map((r: any) => {
            return {
              ...r,
              WorkflowGroup: this.getGroupNameFromGroupId(r.WorkflowGroup),
            };
          });

          this.dataSource = new MatTableDataSource(resp);
          this.ewService.loadingBehaviorSubject.next(false);
        })
    );
  }

  getGroupNameFromGroupId(groupId: string) {
    const i = this.allWorkflowGroups?.findIndex((g: any) => g.Id == groupId);
    if (i > -1) {
      return this.allWorkflowGroups[i]?.Name;
    }
    return groupId;
  }

  getEventName() {
    // get the event id from the parent route
    this.subscription$.add(
      this.route.params.subscribe((params) => {
        this.eventId = params['eventId'];

        if (this.eventId !== '0') {
          this.subscription$.add(
            this.eventService
              .getEventDomain(this.eventId)
              .pipe(
                tap((eventDomain) => {
                  this.ewService.eventName = eventDomain?.Detail?.EventName;
                  // this.ewService.selectedEventId = this.eventId;
                })
              )
              .subscribe()
          );
        }
      })
    );
  }

  openAddTemplateViewDialog() {
    this.selectTemplateClicked = true;

    // if (this.selectedGroupId == undefined) {
    //   return;
    // }

    // Pass in view model data & filter data
    const dialogRef = this.dialog.open(AddWorkflowComponent, {
      data: {
        filterData: this.dataSource?.data
          .filter((d) => d.WorkflowStatus != 'completed')
          .map((d: any) => {
            return d.WorkflowTemplateId;
          }),
      },
    });

    // Subscirve to dialog result
    this.subscription$.add(
      dialogRef.afterClosed().subscribe((result) => {
        console.log(`Dialog result: ${result}`);
        this.selectTemplateClicked = false;
      })
    );
  }

  onTemplatesSelected(selectedTemplates: any) {
    this.dataSource = new MatTableDataSource(selectedTemplates);
  }
  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource!.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  // toggleAllRows() {
  //   if (this.isAllSelected()) {
  //     this.selection.clear();
  //     return;
  //   }

  //   this.selection.select(...this.dataSource!.data);
  //   this.handleShowActions();
  // }

  toggleAllRows(e: any, dataSource: any) {
    this.allSelected = e.checked;
    if (e.checked == false) {
      this.selectedTemplates = [];
      this.selection.clear();
    } else {
      const selectedIndexes = [];
      for (let i = 0; i < dataSource.length; i++) {
        selectedIndexes.push(dataSource[i].id);
      }
      this.selectedTemplates = selectedIndexes;
      this.selection.select(...dataSource);
    }

    this.showActions = this.selection.selected.length > 0;
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${
      row.position + 1
    }`;
  }

  // selectionToggle(row: any) {
  //   this.selection.toggle(row);
  //   this.handleShowActions();
  // }

  selectionToggle(e: any, rowIndex: number, dataSource: any) {
    const u = dataSource[rowIndex];

    if (this.selection.isSelected(u)) {
      this.selection.deselect(u);
    } else {
      this.selection.select(u);
    }
    this.showActions = this.selection.selected.length > 0;
  }

  handleShowActions() {
    if (this.selection.selected.length > 0) {
      this.showActions = true;
    } else {
      this.showActions = false;
    }
  }

  closeEditWorkflow(e: any) {
    this.editWorkflow = false;
    this.getWorkflowsForEvent();
  }

  onEditWorkflow(selectedWorkflow: any) {
    this.selectedTemplateId = selectedWorkflow.Id;
    this.editWorkflow = true;
  }

  getCategoryNameFromId(id: string) {
    const i = this.ewService.workflowGroupsRefData?.findIndex(
      (g: any) => g?.Id == id
    );
    if (i > -1) {
      return this.ewService.workflowGroupsRefData[i]?.Name;
    }
    return id;
  }

  onDeleteWorkflow() {
    const message =
      this.selection.selected.length > 1
        ? `Are you sure you wish to delete these workflows?`
        : `Are you sure you wish to delete ${this.selection.selected[0]?.Name}?`;

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Confirm Delete',
        message: message,
        confirmText: 'Confirm',
        cancelText: 'Cancel',
      },
    });
    this.subscription$.add(
      dialogRef.afterClosed().subscribe((result) => {
        if (result == true) {
          const wIds = this.selection.selected.map((s) => s.Id);
          this.selection.clear();
          this.showActions = false;
          const deleteSubscriptions = new Subscription();
          for (let i = 0; i < wIds.length; i++) {
            deleteSubscriptions.add(
              this.ewService
                .deleteWorkflow(wIds[i], this.orgService.silentAuthToken)
                .subscribe((resp: any) => {
                  if (i == wIds.length - 1) {
                    deleteSubscriptions.unsubscribe();
                    this.notificationService.showSuccess(resp?.value);
                    this.ewService.reload();
                  }
                })
            );
          }
        }
      })
    );
  }

  onGroupSelect(e: any) {
    const selectedGroupId = e?.value;
    this.selectedGroupId = e;
    this.ewService.workflowGroup(selectedGroupId);
  }

  // getDataForAddWorkflowComponent() {
  //   return combineLatest([
  //     this.ewService.Template$!,
  //     this.ewService.isLoading$!,
  //     this.ewService.totalRecords$!,
  //     this.ewService.page$!,
  //   ]).pipe(
  //     map(([templates, isLoading, totalRecords, page]) => {
  //       console.log('STILL CALLING GETDATA FOR ADD WORKFLOW');
  //       if (templates.length == 0 && page.pageIndex > 0) {
  //         page.previousPageIndex = 0;
  //         page.pageIndex = 0;
  //         this.ewService.page(page);
  //         this.ewService.reload();
  //       }
  //       return { templates, isLoading, totalRecords, page };
  //     })
  //   );
  // }

  ngOnDestroy(): void {
    this.subscription$.unsubscribe();
  }
}
