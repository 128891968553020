<!-- page title -->
<h1>Product Categories</h1>

<form [formGroup]="filtersForm" novalidate>
    <!-- actions bar -->
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px" fxLayout.lt-md="column"
        fxLayoutAlign.lt-md="start start" fxLayoutGap.lt-md="4px" class="actions-bar">
        <button type="button" mat-raised-button color="accent" matTooltip="Add new record" [routerLink]="[0]">
            <fa-icon [icon]="['fas', 'plus']"></fa-icon>New Category
        </button>
        <button type="button" mat-raised-button [matMenuTriggerFor]="menu" matTooltip="Actions">
            <mat-icon>more_vert</mat-icon>Actions
          </button>
          <mat-menu #menu="matMenu">
            <button type="button" mat-menu-item [matMenuTriggerFor]="Download"><mat-icon>arrow_circle_down</mat-icon>Download</button>         
          </mat-menu>
          <mat-menu #Download="matMenu">
            <button mat-menu-item (click)="downloadJSON()">JSON</button>
            <button mat-menu-item (click)="downloadCSV()">CSV</button>
          </mat-menu>
        <mat-form-field appearance="outline" class="mat-form-field-no-padding">
            <mat-icon matPrefix>search</mat-icon>
            <mat-label>Search</mat-label>
            <input matInput formControlName="search" type="text" (input)="onSearch($event)" placeholder="Search" #searchInput/>
            <button type="button" matSuffix mat-icon-button aria-label="Clear" *ngIf="searchInput.value" (click)="clearSearch()">
              <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
    </div>

    <mat-sidenav-container>
        <!--filter sidenav-->
        <mat-sidenav #filterSidenav mode="over" position="end">
            <mat-toolbar>
                Filter
                <span class="menu-spacer"></span>
                <button type="button" mat-icon-button aria-label="Close filter menu" matTooltip="Close filter menu"
                    (click)="filterSidenav.toggle()">
                    <mat-icon>close</mat-icon>
                </button>
            </mat-toolbar>
            <div style="margin: 16px">
                <div fxLayout="column">

                    <mat-form-field appearance="outline">
                        <mat-label>Date Range</mat-label>
                        <mat-date-range-input [rangePicker]="dateRangePicker">
                            <input matStartDate formControlName="startDate" placeholder="Start date"
                                (dateChange)="onDateRangeChange()" />
                            <input matEndDate formControlName="endDate" placeholder="End date"
                                (dateChange)="onDateRangeChange()" />
                        </mat-date-range-input>
                        <mat-datepicker-toggle matSuffix [for]="dateRangePicker"></mat-datepicker-toggle>
                        <mat-date-range-picker #dateRangePicker></mat-date-range-picker>
                        <mat-error *ngIf="filtersForm.get('startDate')?.hasError('required')">
                            Start date is required
                        </mat-error>
                        <mat-error *ngIf="filtersForm.get('startDate')?.hasError('matStartDateInvalid')">
                            Invalid start date
                        </mat-error>
                        <mat-error *ngIf="filtersForm.get('endDate')?.hasError('required')">
                            End date is required
                        </mat-error>
                        <mat-error *ngIf="filtersForm.get('endDate')?.hasError('matEndDateInvalid')">
                            Invalid end date
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field appearance="outline">
                        <mat-label>Divisions</mat-label>
                        <mat-select multiple>
                            <mat-option value="1" selected>Division 1</mat-option>
                            <mat-option value="1">Division 2</mat-option>
                            <mat-option value="3">Division 3</mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field appearance="outline">
                        <mat-label>Event type</mat-label>
                        <mat-select formControlName="eventType">
                            <mat-option value="">All</mat-option>
                            <mat-option value="1">Event Type 1</mat-option>
                            <mat-option value="1">Event Type 2</mat-option>
                            <mat-option value="3">Event Type 3</mat-option>
                        </mat-select>
                    </mat-form-field>

                </div>
                <div fxLayout="row" fxLayoutAlign="end start" fxLayoutGap="16px">
                    <button type="reset" mat-raised-button matTooltip="Reset filters" (click)="resetFilters()">
                        Reset
                    </button>
                    <button type="button" mat-raised-button matTooltip="Apply filters" color="accent" (click)="filterSidenav.toggle()"
                        [disabled]="filtersForm.invalid">
                        Apply
                    </button>
                </div>
            </div>
        </mat-sidenav>

        <!--table view-->
        <app-product-category-list-table></app-product-category-list-table>

    </mat-sidenav-container>
</form>