import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  combineLatest,
  debounceTime,
  distinctUntilChanged,
  map,
  Observable,
  shareReplay,
  switchMap,
  tap,
} from 'rxjs';
import { environment } from 'src/environments/environment';
import { v4 as uuidv4 } from 'uuid';
import { Division } from '..';
import { OrganizationService } from 'src/app/@shared/services/organization.service';
import { DEFAULT_PAGING } from 'src/app/@shared/constants/site.constants';

@Injectable({
  providedIn: 'root'
})

export class DivisionService<Tdivision extends Division> {

  // initialize behavior subjects
  private pageBehaviorSubject = new BehaviorSubject(DEFAULT_PAGING);
  private loadingBehaviorSubject = new BehaviorSubject<boolean>(false);
  private searchBehaviorSubject = new BehaviorSubject<string>('');
  private sortBehaviorSubject = new BehaviorSubject({ active: 'DivisionName', direction: 'asc', });
  private reloadBehaviorSubject = new BehaviorSubject<string>('');

  public page$ = this.pageBehaviorSubject.asObservable();
  public search$ = this.searchBehaviorSubject.asObservable();
  public sort$ = this.sortBehaviorSubject.asObservable();
  public isLoading$ = this.loadingBehaviorSubject.asObservable();

  constructor(private httpClient: HttpClient, private organizationService: OrganizationService) { }

  // create the parameters observable that looks for changes in page, startDate, endDate, etc
  public params$ = combineLatest([
    this.pageBehaviorSubject, // add debounce if we need to wait for user input ex: .pipe(debounceTime(300)), 
    this.sortBehaviorSubject,
    this.searchBehaviorSubject.pipe(debounceTime(300)),
    this.reloadBehaviorSubject,
  ]).pipe(
    distinctUntilChanged((previous, current) => {
      // if the values coming down this pipe are the same, don't continue the pipe
      return JSON.stringify(previous) === JSON.stringify(current);
    }),
    map(([page, sort, search, reload]) => {

      // set the query string odata parameters
      let params: HttpParams = new HttpParams({
        fromObject: {
        //mode: viewMode,
        $skip: page.pageIndex * page.pageSize,
        $top: page.pageSize,
        $orderby: `${sort.active} ${sort.direction}`,
        $count: true,
      }
    });

      // if there is a search, add the search to the parameters
      if (search.length) {
        params = params.append('$search', `"${search}"`);
      }

      return params;
    })
  );

  // create the divisions observable that calls http get when any of our parameters change
  private divisionsResponse$ = this.params$.pipe(
    tap(() => this.loadingBehaviorSubject.next(true)), // set isLoading to true
    switchMap((_params) =>
      this.httpClient.get(
        `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/Divisions`,
        { params: _params })
    ),
    tap(() => this.loadingBehaviorSubject.next(false)), // set isLoading to false
    shareReplay(1) // make sure all subscriptions share the same http call (otherwise there will be a http call for each subscription)
  );

  // division listing
  public divisions$: Observable<Tdivision[]> = this.divisionsResponse$.pipe(
    map((res: any) => res.value)
  );

  private allDivisionsListResponse$ = this.params$.pipe(
    tap(() => this.loadingBehaviorSubject.next(true)), // set isLoading to true
    switchMap((_params) =>
      this.httpClient.get(`${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/Divisions/GetAllDivisions`)
    ),
    tap(() => this.loadingBehaviorSubject.next(false)), // set isLoading to false
    shareReplay(1) // make sure all subscriptions share the same http call (otherwise there will be a http call for each subscription)
  );

  // division listing
  public divisionsList$: Observable<Tdivision[]> = this.allDivisionsListResponse$.pipe(
    map((res: any) => res.value)
  )

  // create the divisions observable that calls http get when any of our parameters change
  private allDivisionsResponse$ = this.params$.pipe(
    tap(() => this.loadingBehaviorSubject.next(true)), // set isLoading to true
    switchMap((_params) =>
      this.httpClient.get(`${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/Divisions`)
    ),
    tap(() => this.loadingBehaviorSubject.next(false)), // set isLoading to false
    shareReplay(1) // make sure all subscriptions share the same http call (otherwise there will be a http call for each subscription)
  );

  // division listing
  public allDivisions$: Observable<Tdivision[]> = this.allDivisionsResponse$.pipe(
    map((res: any) => {
      return res.value.sort((a: Tdivision, b: Tdivision) => a.DivisionName.localeCompare(b.DivisionName))
    })
  );

  // total number of Division records based on filtering
  public totalRecords$: Observable<number> = this.divisionsResponse$.pipe(
    map((res: any) => res['@odata.count'])
  );

  // set the current page
  page(page: any) {
    this.pageBehaviorSubject.next(page);
  }

  // gets an division by id
  getDivision(divisionId: string): Observable<Tdivision> {
    const url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/Divisions/${divisionId}`;
    return this.httpClient.get<Tdivision>(url);
  }

  // sets the sort property and order
  sort(sort: any) {
    this.sortBehaviorSubject.next(sort);
  }

  // sets the search phrase
  search(search: string) {
    const page = this.pageBehaviorSubject.value;
    page.pageIndex = 0;
    page.previousPageIndex = 0;
    this.searchBehaviorSubject.next(search);
    this.pageBehaviorSubject.next(page);
  }

  // reloads/refreshes the divison listing
  reload() {
    // reload the divison data
    this.reloadBehaviorSubject.next(uuidv4());
  }

  // up-serts divisons
  saveDivison(divison: Tdivision) {
    let url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/Divisions/`;
    if (!divison.Id || divison.Id === '0') {
      // create new record
      return this.httpClient.post(url, divison);
    } else {
      // edit existing record
      url += `${divison.Id}`;
      return this.httpClient.put(url, divison);
    }
  }

  // deletes an divison by id
  deleteDivison(id: string) {
    const url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/Divisions/${id}`
    return this.httpClient.delete(url);
  }


  deleteDivisions(divisions: any) {
    const url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/Divisions/DeleteDivisions?${this.prepareDivisionStringtoDelete(divisions)}`;
    return this.httpClient.post(url, divisions);
  }

  prepareDivisionStringtoDelete(divisions: any) {
    let urlParams = '';
    if (divisions && divisions.length > 0) {
      for (let index = 0; index <= divisions.length - 1; index++) {
        urlParams += `divisionIds=${divisions[index]?.Id}`;
        if (index != divisions.length - 1) {
          urlParams += '&'
        }
      }
    }
    return urlParams;
  }

  downloadDivisionsJSON() {
    const url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/Divisions/EtlExport`;
    window.open(url,'_blank');
  }

  downloadDivisionsCSV() {
    const url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/Divisions/Export`;
    window.open(url,'_blank');
  }
}
