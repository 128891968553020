import { NgModule } from '@angular/core';
import { SharedModule } from 'src/app/@shared';
import { UnfiV1RoutingModule } from './unfi.v1.routing.module';

@NgModule({
  declarations: [
  ],
  imports: [
    SharedModule,
    UnfiV1RoutingModule
  ]
})

export class UnfiV1Module { }
