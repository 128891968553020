<ng-container *ngIf="viewModel$ | async as viewModel">
    <table *ngIf="viewModel.products.length" mat-table [dataSource]="viewModel.products" matSort
        (matSortChange)="onSort($event)" matSortDisableClear [matSortStart]="'desc'">

         <!-- Checkbox Column -->
      <ng-container matColumnDef="select">
        <th class="no-ellipsis" mat-header-cell *matHeaderCellDef>
            <mat-checkbox (change)="$event ? masterToggle() : null"
                [checked]="selection.hasValue() && isAllSelected()"
                [indeterminate]="selection.hasValue() && !isAllSelected()">
            </mat-checkbox>
        </th>
        <td class="no-ellipsis" mat-cell *matCellDef="let row">
            <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
                [checked]="selection.isSelected(row)">
            </mat-checkbox>
        </td>
    </ng-container>

        <!-- Id Column -->
        <ng-container matColumnDef="Id">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Id" [resizeColumn]="true">
                Id
            </th>
            <td mat-cell *matCellDef="let record">{{ record.Detail.Id }}</td>
        </ng-container>

        <!-- ProductName Column -->
        <ng-container matColumnDef="ProductName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Name" [resizeColumn]="true">
                Product Name
            </th>
            <td mat-cell *matCellDef="let record"> {{ record.Detail.ProductName }}</td>
        </ng-container>

        <!-- ProductImage Column -->
        <ng-container matColumnDef="ProductImage">
            <th mat-header-cell *matHeaderCellDef [resizeColumn]="true">
                Preview
            </th>
            <td mat-cell *matCellDef="let record">
                <!-- <img *ngIf="record.FileName" src="{{viewModel.assetsUrl}}/{{record.FileName}}" alt="product image">
                <img *ngIf="!record.FileName" src="./assets/images/default-image.png"
                    alt="product image"> -->
                    <ng-container *ngIf="record.FileName">
                        <img mat-card-md-image [src]="getImageSrc(record)" alt="product image">
                    </ng-container>
                    <img mat-card-md-image *ngIf="!record.FileName"
                        src="./assets/images/default-image.png" alt="product image">
            </td>
        </ng-container>

        <!-- ClientKey Column -->
        <ng-container matColumnDef="ClientKey">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by ClientKey" [resizeColumn]="true">
                Client Key
            </th>
            <td mat-cell *matCellDef="let record">{{ record.Detail.ClientKey }}</td>
        </ng-container>

        <!-- SKU Column -->
        <ng-container matColumnDef="SKU">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by SKU" [resizeColumn]="true">
                SKU
            </th>
            <td mat-cell *matCellDef="let record">{{ record.Detail.SKU }}</td>
        </ng-container>

        <!-- UPC Column -->
        <ng-container matColumnDef="UPC">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by UPC" [resizeColumn]="true">
                UPC
            </th>
            <td mat-cell *matCellDef="let record">{{ record.Detail.UPC }}</td>
        </ng-container>

        <!-- Actions Column -->
        <ng-container matColumnDef="Actions">
            <th mat-header-cell *matHeaderCellDef>Actions</th>
            <td mat-cell *matCellDef="let record">
                <button type="button" mat-button color="accent"
                    [routerLink]="[record.Detail.Id, 'details']">Edit</button>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>

    <table *ngIf="!viewModel.products.length">
        <tr>
            <td class="no-records" [attr.colspan]="displayedColumns.length">
                <h2>No Records Found</h2>
            </td>
        </tr>
    </table>

    <!-- pager -->
    <mat-paginator [length]="viewModel.totalRecords" [pageSize]="viewModel.page.pageSize"
        [pageSizeOptions]="[5, 10, 20, 50, 100,500]" (page)="onPage($event)" *ngIf="viewModel.products.length"
        showFirstLastButtons [pageIndex]="viewModel.page.pageIndex">
    </mat-paginator>

</ng-container>
