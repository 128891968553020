import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DEFAULT_SNACKBAR_CONFIG } from '..';

@Injectable({
    providedIn: 'root'
})

export class NotificationService {

    constructor(public snackBar: MatSnackBar) { }

    showSuccess(message: string): void {
        this.snackBar.open(message, 'OK', DEFAULT_SNACKBAR_CONFIG);
    }

    showError(message: string, onDismiss?: Function) {
        let defaultConfig = DEFAULT_SNACKBAR_CONFIG;
        delete defaultConfig.duration
        const snabarref = this.snackBar.open(message, 'OK', defaultConfig);
        if(onDismiss) {
            snabarref.afterDismissed().subscribe(() => { onDismiss() });
        }
    }
}