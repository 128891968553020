<ng-container *ngIf="viewModel$ | async as viewModel">
    <mat-card appearance="outlined" [class.mat-elevation-z0]="true">
        <mat-card-content fxLayout="column">

            <!-- title -->
            <h2 *ngIf="!dialogData">Versions for {{viewModel.event.EventName}}</h2>

            <!-- actions bar -->
            <form [formGroup]="filtersForm" novalidate>
                <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px" fxLayout.lt-md="column"
                    fxLayoutAlign.lt-md="start start" fxLayoutGap.lt-md="4px" class="actions-bar">
                    <button *ngIf="!dialogData" type="button" mat-raised-button color="accent" matTooltip="Add new record" [routerLink]="[0, 'details']">
                        <fa-icon [icon]="['fas', 'plus']"></fa-icon>New Version
                      </button>

                      <button type="button"
                      *ngIf="!dialogData && getSelectedSectionRecords() && getSelectedSectionRecords().length > 0"
                      mat-raised-button [matMenuTriggerFor]="menu" matTooltip="Actions">
                      <mat-icon>more_vert</mat-icon>Actions
                    </button>
                    <mat-menu #menu="matMenu">
                        <button type="button" class="download" mat-menu-item [matMenuTriggerFor]="Download"><mat-icon class="ml-2">arrow_circle_down</mat-icon>Download</button>
                        <!-- <button type="button" mat-menu-item><mat-icon>file_copy</mat-icon>Copy</button>  -->
                        <button type="button" mat-menu-item (click)="deleteSelectedRecords()"><mat-icon class="ml-23">delete</mat-icon>Delete</button>                         
                        <button type="button" mat-menu-item>
                                {{getSelectedSectionRecords().length}} Selected                                
                        </button>           
                      </mat-menu>
                      <mat-menu #Download="matMenu">
                        <button mat-menu-item (click)="downloadVersions()">Versions</button>
                        <button mat-menu-item (click)="downloadVersionStores()">Stores</button>
                      </mat-menu>
                    
                    <h2 *ngIf="dialogData">Add Versions</h2>
                    <mat-form-field appearance="outline" class="mat-form-field-no-padding">
                        <mat-icon matPrefix>search</mat-icon>
                        <mat-label>Search</mat-label>
                        <input matInput type="text" placeholder="Search" (input)="onSearch($event)" formControlName="search" #searchInput/>
                        <button type="button" matSuffix mat-icon-button aria-label="Clear" *ngIf="searchInput.value" (click)="clearSearch()">
                          <mat-icon>close</mat-icon>
                      </button>
                    </mat-form-field>
                </div>
            </form>

            <!-- record listing table -->
            <table mat-table [dataSource]="viewModel.versions" matSort (matSortChange)="onSort($event)" matSortDisableClear [matSortStart]="'desc'" *ngIf="viewModel.versions.length">
                 <!-- Checkbox Column -->
            <ng-container matColumnDef="select">
                <th class="no-ellipsis" mat-header-cell *matHeaderCellDef>
                    <mat-checkbox (change)="$event ? masterToggle() : null"
                        [checked]="selection.hasValue() && isAllSelected()"
                        [indeterminate]="selection.hasValue() && !isAllSelected()">
                    </mat-checkbox>
                </th>
                <td class="no-ellipsis" mat-cell *matCellDef="let row">
                    <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
                        [checked]="selection.isSelected(row)">
                    </mat-checkbox>
                </td>
            </ng-container>
                <!-- Id Column -->
                <ng-container matColumnDef="VersionName">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Version" [resizeColumn]="true">
                        Version
                    </th>
                    <td mat-cell *matCellDef="let record">{{ record.VersionName }}</td>
                </ng-container>

                <!-- ClientKey Column -->
                <ng-container matColumnDef="ClientKey">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by ClientKey" [resizeColumn]="true">
                        Client Key
                    </th>
                    <td mat-cell *matCellDef="let record">{{ record.ClientKey }}</td>
                </ng-container>

                <!-- Actions Column -->
                <ng-container matColumnDef="Actions">
                    <th mat-header-cell *matHeaderCellDef>Actions</th>
                    <td mat-cell *matCellDef="let record">
                        <button *ngIf="!dialogData" type="button" mat-button color="accent" [routerLink]="[record.Id, 'details']">Edit</button>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns" (dblclick)="onRowDoubleClick(row.Id)"></tr>
            </table>

            <table *ngIf="!viewModel.versions.length">
                <tr>
                    <td class="no-records" [attr.colspan]="displayedColumns.length">
                        <h2>No Records Found</h2>
                    </td>
                </tr>
            </table>

            <!-- pager -->
            <mat-paginator [length]="viewModel.totalRecords" [pageSize]="viewModel.page.pageSize"
                [pageSizeOptions]="[5, 10, 20, 50, 100,500]" (page)="onPage($event)" *ngIf="viewModel.versions.length"
                showFirstLastButtons [pageIndex]="viewModel.page.pageIndex">
            </mat-paginator>
        </mat-card-content>

        <ng-container *ngIf="dialogData?.selectedRecordsDetails?.length">
            <mat-card-footer align="end">
                <button mat-flat-button (click)="cancel()">Cancel</button>
                <button mat-raised-button color="accent" (click)="selectVersions()" [disabled]="!selection.selected.length">Select</button>
            </mat-card-footer>
        </ng-container>

    </mat-card>

</ng-container>
