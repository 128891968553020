import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { combineLatest, distinctUntilChanged, map, Subscription } from 'rxjs';
import { MediaChange, MediaObserver } from '@angular/flex-layout';
import { ConfirmDialogComponent, DEFAULT_SNACKBAR_CONFIG, Logger } from 'src/app/@shared';
import { Sort } from '@angular/material/sort';
import { PageEvent } from '@angular/material/paginator';
import { EventType, EventTypeService } from '../..';
import { ActivatedRoute } from '@angular/router';
import { SelectionModel } from '@angular/cdk/collections';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';

const log = new Logger('EventTypesComponent');

@Component({
  selector: 'app-event-types',
  templateUrl: './event-types.component.html',
  styleUrls: ['./event-types.component.scss']
})
export class EventTypesComponent<T extends EventType> implements OnInit {

  public get eventTypeService(): EventTypeService<T> {
    return this._eventTypeService;
  }
  public set eventTypeService(value: EventTypeService<T>) {
    this._eventTypeService = value;
  }

  viewModel$ = combineLatest([
    this.eventTypeService.eventTypes$,
    this.eventTypeService.isLoading$,
    this.eventTypeService.totalRecords$,
    this.eventTypeService.page$,
  ]).pipe(
    map(([eventTypes, isLoading, totalRecords, page]) => {
      if (eventTypes.length == 0 && page.pageIndex > 0) {
        page.previousPageIndex = 0
        page.pageIndex = 0
        this.eventTypeService.page(page);
        this.eventTypeService.reload();
      }
      return { eventTypes, isLoading, totalRecords, page }
    }),
  );
  flexMediaWatcher!: Subscription;
  displayedColumns = ['select', 'EventTypeName', 'ClientKey', 'Actions'];
  eventTypeList: EventType[] = [];
  selection = new SelectionModel<EventType>(true, []);


  constructor(private _eventTypeService: EventTypeService<T>, private mediaObserver: MediaObserver, private route: ActivatedRoute, private dialog: MatDialog,
    private matSnackBar: MatSnackBar) { }

  ngOnInit(): void {
    log.debug('init');
    this.eventTypeService.eventTypes$.subscribe((data) => {
      this.eventTypeList = data;
    })
    this.clearSearch();
    this.eventTypeService.reload();

    this.route.queryParams.subscribe(queryParams => {
      let searchKey = queryParams['search'];

      if (searchKey) {
        this.eventTypeService.search(searchKey)
        this.filtersForm.controls.search.setValue(searchKey);
      } else {
        this.eventTypeService.search('');
        this.filtersForm.controls.search.setValue('');
      }
    });

    // detect changes in viewport size to handle show/hide of table columns
    const getAlias = (MediaChange: MediaChange[]) => {
      return MediaChange[0].mqAlias;
    };

    this.flexMediaWatcher = this.mediaObserver
      .asObservable()
      .pipe(
        distinctUntilChanged(
          (x: MediaChange[], y: MediaChange[]) => getAlias(x) === getAlias(y)
        ))
      .subscribe((change) => {
        if (change.some(x => x.mqAlias === 'xs')) {
          this.displayedColumns = ['select', 'EventTypeName', 'Actions'];
        }
        else {
          this.displayedColumns = ['select', 'EventTypeName', 'ClientKey', 'Actions'];
        }
      });
  }

  onSearch(event: any) {
    this.eventTypeService.search(event.target.value);
  }

  clearSearch() {
    this.filtersForm.controls.search.setValue('');
    this.eventTypeService.search('');
  }

  filtersForm = new FormGroup({
    search: new FormControl<string | null>(null)
  });

  onSort(sortState: Sort): void {
    this.eventTypeService.sort(sortState);
    this.selection.clear();
  }

  onPage(pageEvent: PageEvent): void {
    this.selection.clear();
    this.eventTypeService.page(pageEvent);
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.eventTypeList.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.eventTypeList.forEach(row => this.selection.select(row));
  }

  public getSelectedSectionRecords() {
    return this.selection.selected;
  }

  public clearSelection() {
    this.selection.clear();
  }

  deleteSelectedRecords() {
    let selectedRecords: any;   
    selectedRecords = this.getSelectedSectionRecords();    
    if (selectedRecords && selectedRecords.length > 0) {
      const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
        data: {
          title: 'Confirm Delete',
          message: `Do you want to remove Event Type(s)?`,
        },
        disableClose: true,
      });

      confirmDialog.afterClosed().subscribe(
        confirmResult => {
          if (confirmResult) {
            this.eventTypeService.deleteEventTypes(selectedRecords).subscribe({
              next: () => {
                this.matSnackBar.open(`Event Type(s) deleted`, 'OK', DEFAULT_SNACKBAR_CONFIG);
                this.eventTypeService.reload();               
                  this.clearSelection();                
              },
              error: (error) => {
                log.error('Error in deleting Event Type', error);

                if (error.error.value) {
                  throw new Error(error.error.value);
                } else {
                  throw new Error(error.message);
                }
              }
            });
          }
        });
    }
  }

  downloadJSON(){
    this.eventTypeService.downloadEventTypesJSON();
  }

  downloadCSV(){
    this.eventTypeService.downloadEventTypesCSV();
  }

}
