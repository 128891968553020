import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Logger } from 'src/app/@shared';
import { Offer, OfferDomain, OfferService } from '../..';

const log = new Logger('PromoComponent');

@Component({
  selector: 'app-promo',
  templateUrl: './promo.component.html',
  styleUrls: ['./promo.component.scss']
})

export class PromoComponent <TOfferDomain extends OfferDomain<Offer>> implements OnInit {
  promoId: string = '0';

  constructor(private route: ActivatedRoute, private offerserv: OfferService<any, TOfferDomain>) { }

  ngOnInit(): void {
    log.debug('init');
    this.promoId = this.route.snapshot.params['offerId'];
  }

  onSave() {
    this.offerserv.setSaveorCancel('1');
  }

  onCancel() {
    this.offerserv.setSaveorCancel('0');
  }

}
