import { Status } from "./offer-promo-status";

export interface OfferTagsResponseModel {
    TagId?: string;
    TagName?: string;
    Offers?: Offers[];
    showLess?: boolean
}

export interface Offers {
    Id?: string;
    Headline?: string;
    OfferName?: string;
    Label?: string
}