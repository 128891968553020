<div class="event-workflow-container">
  <div
    *ngIf="!editWorkflow"
    fxLayout="column wrap"
    fxLayoutGap.gt-sm="16px"
    fxLayout.lt-md="column"
  >
    <div fxLayout="row wrap" fxLayoutGap.gt-sm="16px">
      <h2 class="event-name-header">{{ ewService.eventName }}</h2>

      <button
        type="button"
        mat-raised-button
        color="accent"
        matTooltip="Add Workflow"
        style="top: 10px"
        (click)="openAddTemplateViewDialog()"
      >
        <fa-icon [icon]="['fas', 'plus']"></fa-icon>Add Template
      </button>

      <!-- Actions drop down -->
      <button
        mat-raised-button
        [matMenuTriggerFor]="actionsMenu"
        style="top: 10px"
        *ngIf="showActions == true"
      >
        <mat-icon>more_vert</mat-icon>
        Actions
      </button>
      <mat-menu class="actions-mat-menu" #actionsMenu="matMenu">
        <button mat-menu-item (click)="onDeleteWorkflow()">
          <mat-icon>delete</mat-icon>
          <span>Delete</span>
        </button>

        <button mat-menu-item style="margin-top: 2px">
          <span>{{ selection.selected.length }} Selected</span>
        </button>
      </mat-menu>
    </div>

    <!-- <div fxLayout="row wrap" fxLayoutGap.gt-sm="16px">
      <div>
        <mat-form-field>
          <mat-label>Group</mat-label>
          <mat-select
            style="width: 188px"
            data-testid="Workflow Groups"
            (selectionChange)="onGroupSelect($event)"
          >
            <mat-option>None</mat-option>
            <mat-option
              *ngFor="let group of allWorkflowGroups"
              [value]="group.Id"
            >
              <span data-testid="Workflow Groups">{{ group.Name }}</span>
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div fxLayout="row wrap" fxLayoutGap.gt-sm="16px" fxLayout.lt-md="column">
        <button
          type="button"
          mat-raised-button
          color="accent"
          matTooltip="Add Workflow"
          style="top: 10px"
          (click)="openAddTemplateViewDialog()"
        >
          <fa-icon [icon]="['fas', 'plus']"></fa-icon>Select Template
        </button>
      </div>
      <div fxLayout="row wrap" fxLayoutGap.gt-sm="16px" fxLayout.lt-md="column">
        
        <button
          mat-raised-button
          [matMenuTriggerFor]="actionsMenu"
          style="top: 10px"
          *ngIf="showActions == true"
        >
          <mat-icon>more_vert</mat-icon>
          Actions
        </button>
        <mat-menu class="actions-mat-menu" #actionsMenu="matMenu">
          <button mat-menu-item (click)="onDeleteWorkflow()">
            <mat-icon>delete</mat-icon>
            <span>Delete</span>
          </button>
          <button mat-menu-item style="margin-top: 2px">
            <span>{{ selection.selected.length }} Selected</span>
          </button>
        </mat-menu>
      </div>
    </div> -->

    <div
      id="exisiting-templates"
      fxLayout="row wrap"
      fxLayoutGap.gt-sm="16px"
      fxLayout.lt-md="column"
      *ngIf="dataSource && dataSource.data"
    >
      <table mat-table [dataSource]="dataSource!.data" class="mat-elevation-z8">
        <!--- Note that these columns can be defined in any order.
            The actual rendered columns are set as a property on the row definition" -->

        <!-- Checkbox Column -->
        <ng-container matColumnDef="select">
          <th class="no-ellipsis" mat-header-cell *matHeaderCellDef>
            <mat-checkbox
              *ngIf="dataSource.data.length > 0"
              (change)="$event ? toggleAllRows($event, dataSource!.data) : null"
              [checked]="selection.hasValue() && isAllSelected()"
              [indeterminate]="selection.hasValue() && !isAllSelected()"
              [aria-label]="checkboxLabel()"
            >
            </mat-checkbox>
          </th>
          <td class="no-ellipsis" mat-cell *matCellDef="let row; let i = index">
            <!-- <mat-checkbox
              (click)="$event.stopPropagation()"
              (change)="$event ? selectionToggle(row) : null"
              [checked]="selection.isSelected(row)"
              [aria-label]="checkboxLabel(row)"
            >
            </mat-checkbox> -->

            <mat-checkbox
              (click)="$event.stopPropagation()"
              (change)="
                $event ? selectionToggle($event, i, dataSource.data) : null
              "
              [checked]="selection.isSelected(row)"
              [aria-label]="checkboxLabel(row)"
            >
            </mat-checkbox>
          </td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="templateName">
          <th mat-header-cell *matHeaderCellDef>Template Name</th>
          <td mat-cell *matCellDef="let element">
            {{ element.Name }}
          </td>
        </ng-container>

        <!-- Weight Column -->
        <ng-container matColumnDef="templateGroup">
          <th mat-header-cell *matHeaderCellDef>Group</th>
          <td mat-cell *matCellDef="let element">
            <!-- {{ getCategoryNameFromId(element.WorkflowGroup) }} -->
            {{ element.WorkflowGroup }}
          </td>
        </ng-container>

        <ng-container matColumnDef="templateCategory">
          <th mat-header-cell *matHeaderCellDef>Category</th>
          <td mat-cell *matCellDef="let element">
            {{ element.WorkflowCategory }}
          </td>
        </ng-container>

        <ng-container matColumnDef="workflowStatus">
          <th mat-header-cell *matHeaderCellDef>Workflow Status</th>
          <td mat-cell *matCellDef="let element">
            <div
              class="indicator-container"
              *ngIf="element.WorkflowStatus == 'in_progress'"
            >
              <div class="indicator workflow-active"></div>
              <span>In Progress</span>
            </div>

            <div class="indicator-container" *ngIf="element.WorkflowStatus == 'paused'">
              <div class="indicator workflow-paused"></div>
              <span>Paused</span>
            </div>

            <div
              class="indicator-container"
              *ngIf="!element.WorkflowStatus || element.WorkflowStatus == null"
            >
              <div class="indicator workflow-inactive"></div>
              <span>Inactive</span>
            </div>

            <div class="indicator-container" *ngIf="element.WorkflowStatus == 'completed'">
              <div class="indicator workflow-completed"></div>
              <span>Completed</span>
            </div>

          </td>
        </ng-container>

        <!-- Symbol Column -->
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef>Actions</th>
          <td mat-cell *matCellDef="let element">
            <a (click)="onEditWorkflow(element)">Edit</a>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        <tr>
          <td *ngIf="!dataSource || dataSource.data.length == 0">
            <p><b>No Templates Added Yet</b></p>
          </td>
        </tr>
      </table>
      <div
        fxLayout="row"
        fxLayoutAlign="center center"
        *ngIf="!dataSource || dataSource.data.length == 0"
        style="width: 100%; padding-top: 1rem"
      >
        <p><b>No Templates Added Yet</b></p>
      </div>

      <mat-paginator
        style="width: 100%"
        [length]="dataSource!.data.length"
        [pageSize]="50"
        [pageSizeOptions]="[5, 10, 25, 100]"
        aria-label="Select page"
      >
      </mat-paginator>
    </div>
  </div>

  <app-edit-workflow
    *ngIf="editWorkflow == true"
    [templateId]="selectedTemplateId"
    (onClose)="closeEditWorkflow($event)"
  ></app-edit-workflow>
</div>
