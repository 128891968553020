import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, from, lastValueFrom } from 'rxjs';
import { environment as env } from 'src/environments/environment';
import { createApp } from "@shopify/app-bridge";
import { getSessionToken } from "@shopify/app-bridge-utils";


@Injectable()
export class ShopifyJwtInterceptor implements HttpInterceptor {
    constructor() { }

    intercept(req: HttpRequest<any>, next: HttpHandler) {
        // convert promise to observable using 'from' operator
        return from(this.handle(req, next))
    }

    async handle(req: HttpRequest<any>, next: HttpHandler) {
        const app = createApp({
            apiKey: env.shopifyAuth.apiKey,
            host: env.shopifyAuth.host,
        });
        
        const token = await getSessionToken(app);

        req = req.clone({
            setHeaders: {
                Authorization: 'Bearer ' + token
            }
        })

        return await lastValueFrom(next.handle(req));
    }
}
