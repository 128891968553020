<ng-container *ngIf="viewModel$ | async as viewModel; else loading">
  <div class="bg-white">
  <form [formGroup]="filtersForm" novalidate>
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px" fxLayout.lt-md="column"
         fxLayoutAlign.lt-md="start start" fxLayoutGap.lt-md="4px" class="actions-bar" style="padding-top: 20px;">
      <button mat-raised-button color="accent" matTooltip="Add new record" (click)="openDialog()">
        <fa-icon [icon]="['fas', 'plus']"></fa-icon>Add Assets
      </button>

      <mat-form-field appearance="outline" class="mat-form-field-no-padding">
        <mat-icon matPrefix>search</mat-icon>
        <mat-label>Search Assets</mat-label>
        <input matInput formControlName="search" type="text" (input)="onSearch($event)" placeholder="Search"
               #searchInput />
        <button type="button" matSuffix mat-icon-button aria-label="Clear" *ngIf="searchInput.value"
                (click)="clearSearch()">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
      <span class="menu-spacer"></span>

      <mat-button-toggle-group #group="matButtonToggleGroup" value="cards">
          <mat-button-toggle value="cards" matTooltip="Card view" aria-label="Card view"
              (click)="toggleView('CARDS')">
              <mat-icon>grid_view</mat-icon>
          </mat-button-toggle>
          <mat-button-toggle value="table" matTooltip="Table view" aria-label="Table view"
              (click)="toggleView('TABLE')">
              <mat-icon>table_view</mat-icon>
          </mat-button-toggle>
      </mat-button-toggle-group>

    </div>

    <mat-sidenav-container>

      <!--main content-->
      <!--table view-->
      <app-event-asset-cards #assetCards *ngIf="viewModel.viewMode === 'CARDS'"></app-event-asset-cards>
      <!--expansion view-->
      <app-event-assets-table #assetTable *ngIf="viewModel.viewMode === 'TABLE'"></app-event-assets-table>

      <!-- pager -->
      <!-- <h2>Pager goes here</h2> -->
      <!-- <mat-paginator [length]="viewModel.totalRecords" [pageSize]="viewModel.page.pageSize"
          [pageSizeOptions]="[5, 10, 20, 50, 100]" (page)="onPage($event)" [hidden]="!viewModel.events.length"
          showFirstLastButtons [pageIndex]="viewModel.page.pageIndex">
      </mat-paginator> -->
  </mat-sidenav-container>

    <mat-card-actions>
      <div fxLayout="row" fxLayoutAlign="space-between" class="mat-card-actions-details">
        <button mat-raised-button type="button" color="warn"
        [disabled]="!((viewModel.viewMode === 'TABLE' && assetTable?.getSelectedSectionRecords()?.length > 0) || (viewModel.viewMode === 'CARDS' && assetCards?.getSelectedSectionRecords()?.length > 0))"
                (click)="diassociateAssets()">
          Delete
        </button>
        <button mat-stroked-button type="reset" color="accent" (click)="cancel()">
          Cancel
        </button>
      </div>
    </mat-card-actions>
  </form>
  </div>

</ng-container>



<ng-template #loading>
    <mat-progress-bar mode="indeterminate" color="accent"></mat-progress-bar>
</ng-template>
