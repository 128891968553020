<ng-container *ngIf="viewModel$ | async as viewModel; else loading">
    <div class="bg-white">
    <form [formGroup]="filtersForm" novalidate>
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px" fxLayout.lt-md="column"
            fxLayoutAlign.lt-md="start start" fxLayoutGap.lt-md="4px" class="actions-bar" style="padding-top: 20px;">
            <button mat-raised-button color="accent" matTooltip="Add new record" (click)="openDialog()">
                <fa-icon [icon]="['fas', 'plus']"></fa-icon>Add Assets
            </button>

            <mat-form-field appearance="outline" class="mat-form-field-no-padding">
                <mat-icon matPrefix>search</mat-icon>
                <mat-label>Search Assets</mat-label>
                <input matInput formControlName="search" type="text" (input)="onSearch($event)" placeholder="Search"
                    #searchInput />
                <button type="button" matSuffix mat-icon-button aria-label="Clear" *ngIf="searchInput.value"
                    (click)="clearSearch()">
                    <mat-icon>close</mat-icon>
                </button>
            </mat-form-field>
        </div>
        <div class="grid" style="padding-top: 20px;">
            <mat-card appearance="outlined" *ngFor="let assets of viewModel.assets">
                <mat-card-content>
                    <div class="grid-col-span-2 offer-name" title="{{ assets.AssetName }}" fxLayout="row wrap">
                        <mat-checkbox class="card-checkbox" fxFlex="30px" (click)="$event.stopPropagation()"
                            (change)="$event ? selection.toggle(assets) : null"
                            [checked]="selection.isSelected(assets)">
                        </mat-checkbox>

                    </div>
                    <div fxLayoutAlign="center center" class="offer-image">
                        <img mat-card-md-image *ngIf="assets?.Detail?.FileName" src="{{viewModel.assetsUrl}}/{{assets?.Detail?.FileName}}"
                            alt="offer image">
                        <img mat-card-md-image *ngIf="!assets?.Detail?.FileName" src="./assets/images/default-image.png"
                            alt="offer image">
                    </div>
                    <div class="offer-content">
                        <div class="offer-headline" title="{{ assets.DateAdded }}" *ngIf="assets.DateAdded">
                            <div class="ellipsis offer-headline-bold">{{assets?.AssetName }}</div>
                            <span class="ellipsis">{{assets?.DateAdded }}</span>
                        </div>
                        <div class="offer-bodycopy" *ngIf="assets.AssetGroupName">
                            <span class="ellipsis-3-line">{{ assets?.AssetGroupName }}</span>
                        </div>
                        <div class="offer-bodycopy" fxLayout="row wrap" style="margin:10px 0px ;">
                            <div fxFlex fxLayoutAlign="center center">
                                <a [routerLink]="[ assets.Detail?.Id, 'rank']">Rank</a>
                            </div>
                            <div fxFlex fxLayoutAlign="center center">
                                <span class="ellipsis-3-line">{{ assets?.Type }}</span>
                            </div>
                        </div>
                        <div class="offer-button">
                            <button type="button" matSuffix mat-icon-button aria-label="delete" (click)="diassociateAssets()">
                                <mat-icon>delete</mat-icon>
                            </button>
                        </div>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>
        <h2 *ngIf="!viewModel.assets.length">No Records Found</h2>
    </form>
    </div>

    <mat-paginator [length]="viewModel.totalRecords" [pageSize]="viewModel.page.pageSize"
[pageSizeOptions]="[5, 10, 20, 50, 100,500]" (page)="onPage($event)" *ngIf="viewModel.assets.length"
showFirstLastButtons [pageIndex]="viewModel.page.pageIndex">
</mat-paginator>

</ng-container>



<ng-template #loading>
    <mat-progress-bar mode="indeterminate" color="accent"></mat-progress-bar>
</ng-template>
