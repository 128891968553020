<ng-container *ngIf="viewModel$ | async as viewModel; else loading">
    <div class="grid">
        <mat-card appearance="outlined" *ngFor="let assets of viewModel.assets">
          <mat-card-content>
            <div class="grid-col-span-2 offer-name" title="{{ assets.AssetName }}" fxLayout="row wrap">
              <mat-checkbox class="card-checkbox" fxFlex="30px" (click)="$event.stopPropagation()"
                            (change)="$event ? selection.toggle(assets) : null"
                            [checked]="selection.isSelected(assets)">
              </mat-checkbox>

            </div>
            <div fxLayoutAlign="center center" class="offer-image">
              <ng-container *ngIf="assets.Detail.FileName">
                <img mat-card-md-image [src]="getImageSrc(assets)" alt="Offer image">
              </ng-container>
              <img mat-card-md-image *ngIf="!assets.Detail.FileName"
                   src="./assets/images/default-image.png" alt="Offer image">
            </div>
            <div class="offer-content">

              <div class="offer-headline" title="{{ assets.DateAdded }}" *ngIf="assets.DateAdded">
                <div class="ellipsis name offer-headline-bold">{{assets?.AssetName }}</div>
                <span class="ellipsis">{{assets?.DateAdded }}</span>
              </div>
              <div class="offer-bodycopy" *ngIf="assets.AssetGroupName">
                <span class="ellipsis-3-line">{{ assets?.AssetGroupName }}</span>
              </div>
              <div class="offer-bodycopy" fxLayout="row wrap" style="margin:10px 0px ;">
                <div fxFlex fxLayoutAlign="center center">
                  <span class="ellipsis-3-line">{{ assets?.Type }}</span>
                </div>
                <div fxFlex fxLayoutAlign="center center">
                  <a [routerLink]="[ assets.Detail?.Id, 'rank']">Rank</a>
                </div>

              </div>

            </div>
          </mat-card-content>
        </mat-card>
      </div>
      <mat-paginator [length]="viewModel.totalRecords" [pageSize]="viewModel.page.pageSize"
      [pageSizeOptions]="[5, 10, 20, 50, 100,500]" (page)="onPage($event)" *ngIf="viewModel.assets.length"
      showFirstLastButtons [pageIndex]="viewModel.page.pageIndex">
</mat-paginator>

    <table *ngIf="!viewModel.assets.length">
        <tr>
            <td class="no-records">
                <h2>No Records Found</h2>
            </td>
        </tr>
    </table>
</ng-container>

<ng-template #loading>
    <mat-progress-bar mode="indeterminate" color="accent"></mat-progress-bar>
</ng-template>
