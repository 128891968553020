import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { map, combineLatest } from 'rxjs';
import { ConfirmDialogComponent, Logger } from 'src/app/@shared';
import { DEFAULT_SNACKBAR_CONFIG } from 'src/app/@shared/constants/site.constants';
import { OfferAsset, Asset, PromoAssetsService, PromoAddAssetComponent, OfferPromo, PromoDomain, OfferPromoService, AssetsService, OfferService, Offer, OfferDomain } from '../..';
import { AuthorizeControlService } from 'pr1-ui-components';

const log = new Logger('PromoAssetsComponent');

@Component({
  selector: 'app-promo-assets',
  templateUrl: './promo-assets.component.html',
  styleUrls: ['./promo-assets.component.scss'],
})

export class PromoAssetsComponent<T extends Offer, TOfferDomain extends OfferDomain<Offer>> implements OnInit, OnDestroy {

  viewModel$ = combineLatest([
    this.promoAssetService.isLoading$,
    this.promoAssetService.viewMode$,
    this.promoAssetService.promoId$,
    this.offerPromoService.offerPromos$
  ]).pipe(
    map(([isLoading, viewMode, promoId, promos]) => {
      return { isLoading, viewMode, promoId, promos }
    }),
  );

  filtersForm = new FormGroup({
    search: new FormControl(),
  });
  @ViewChild('assetTable', { static: false }) assetTable: any;
  @ViewChild('assetCards', { static: false }) assetCards: any;
  public assetsList: any;
  constructor(private promoAssetService: PromoAssetsService<OfferAsset>,
    private offerPromoService: OfferPromoService<OfferPromo, PromoDomain<OfferPromo>>,
    private assetsService: AssetsService<Asset>,
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private matSnackBar: MatSnackBar,
    private authorizationService: AuthorizeControlService,
    private offerService: OfferService<T, TOfferDomain>,) { }

  ngOnInit(): void {
    log.debug('init');
    this.clearSearch();
    this.promoAssetService.promoId = this.route.snapshot.params['offerId'];    
    this.assetsService.promoId = this.route.snapshot.params['offerId'];
    this.promoAssetService.assets$.subscribe((data) => {
      this.assetsList = data;
    });
  }

  ngOnDestroy() { }


  onSearch(event: any) {
    this.promoAssetService.search(event.target.value);
  }


  openDialog(): void {
    const dialogRef = this.dialog.open(PromoAddAssetComponent, {
      width: '80%',
      height: '90%',
      data: { promoId: this.route.snapshot.params['offerId'] }
    });
    dialogRef.afterClosed().subscribe(() => {
      this.clearSearch();
    })
  }

  clearSearch() {
    this.filtersForm.controls.search.setValue('');
    this.promoAssetService.search('');
  }

  toggleView(mode: string) {
    this.promoAssetService.toggleViewMode(mode);
  }

  cancel(): void {
    this.promoAssetService.reload();
    this.router.navigate([`../../../`], { relativeTo: this.route, queryParamsHandling: 'preserve' });
  }


  deleteSelectedRecords() {
    let selectedRecords: any;
    if (this.assetTable) {
      selectedRecords = this.assetTable.getSelectedSectionRecords();
    } else if (this.assetCards) {
      selectedRecords = this.assetCards.getSelectedSectionRecords();
    }
    if (selectedRecords && selectedRecords.length > 0) {
      const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
        data: {
          title: 'Confirm Delete',
          message: `Do you want to remove Asset?`,
        },
        disableClose: true,
      });


      confirmDialog.afterClosed().subscribe(
        confirmResult => {
          if (confirmResult) {
            const idstoPass = selectedRecords.map((x: any) => x.Detail.Id);
            const offers = (this.offerService.selection.selected && this.offerService.selection.selected.length > 0) ? this.offerService.selection.selected.map(x => x.Id) : [this.promoAssetService.promoId];
            this.promoAssetService.disassociateAssetToPromo(offers, idstoPass).subscribe({
              next: () => {
                this.matSnackBar.open(`Asset(s) deleted`, 'OK', DEFAULT_SNACKBAR_CONFIG);
                this.clearSearch();
                this.promoAssetService.assetsReload();
                this.router.navigate([`../`, 'assets'], { relativeTo: this.route, queryParamsHandling: 'preserve' });
              },
              error: (error) => {
                log.error('Error deleting asset from offer', error);

                if (error.error.value) {
                  throw new Error(error.error.value);
                } else {
                  throw new Error(error.message);
                }
              }
            });
          }
        });
    }

  }

  getPromoName(promos: any) {
    if (promos && promos.length > 0) {
      const promoDetail = promos.find((x: PromoDomain<OfferPromo>) => x.DomainId === this.route.snapshot.params['offerId']);
      if (promoDetail && promoDetail.Detail) {
        return promoDetail.Detail.Name;
      } else {
        return '';
      }
    }
    return '';
  }
  
  canEdit(): boolean {
    const hasAccess = this.authorizationService.checkAccess('edit||offermang');
    return hasAccess;
  }
}
