import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, debounceTime, distinctUntilChanged, map, Observable, of, shareReplay, switchMap, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { v4 as uuidv4 } from 'uuid';
import { OrganizationService } from 'src/app/@shared/services/organization.service';
import { Store } from '..';
import { DEFAULT_PAGING } from 'src/app/@shared/constants/site.constants';

@Injectable({
  providedIn: 'root',
})

export class VersionStoreService<TStore extends Store> {

  // initialize behavior subjects
  private versionIdBehaviorSubject = new BehaviorSubject<string>('');
  private loadingBehaviorSubject = new BehaviorSubject<boolean>(false);
  private pageBehaviorSubject = new BehaviorSubject(DEFAULT_PAGING);
  private storeDialogPageBehaviorSubject = new BehaviorSubject(DEFAULT_PAGING);
  private sortBehaviorSubject = new BehaviorSubject({ active: 'StoreName', direction: 'asc', });
  private searchBehaviorSubject = new BehaviorSubject<string>('');
  private dialogSearchBehaviorSubject = new BehaviorSubject<string>('');
  private reloadBehaviorSubject = new BehaviorSubject<string>('');

  // we do not wish to expose our behavior subjects.  create public observables
  public isLoading$ = this.loadingBehaviorSubject.asObservable();
  public sort$ = this.sortBehaviorSubject.asObservable();
  public page$ = this.pageBehaviorSubject.asObservable();
  public storeDialogPage$ = this.storeDialogPageBehaviorSubject.asObservable();
  public search$ = this.searchBehaviorSubject.asObservable();
  public dialogSearch$ = this.dialogSearchBehaviorSubject.asObservable();

  // create the parameters observable that looks for changes in page, startDate, endDate, etc
  public params$ = combineLatest([
    this.versionIdBehaviorSubject,
    this.pageBehaviorSubject, // add debounce if we need to wait for user input ex: .pipe(debounceTime(300)),
    this.sortBehaviorSubject,
    this.searchBehaviorSubject.pipe(debounceTime(300)),
    this.reloadBehaviorSubject,

  ]).pipe(
    distinctUntilChanged((previous, current) => {
      // if the values coming down this pipe are the same, don't continue the pipe
      return JSON.stringify(previous) === JSON.stringify(current);
    }),
    map(([versionId, page, sort, search, reload]) => {

      // set the query string odata parameters
      let params: HttpParams = new HttpParams({
        fromObject: {
        versionId: this.versionIdBehaviorSubject.value,
        $skip: page.pageIndex * page.pageSize,
        $top: page.pageSize,
        $orderby: `${sort.active} ${sort.direction}`,
        $count: true,
      }
    });

      // if there is a search, add the search to the parameters
      if (search.length) {
        params = params.append('$search', `"${search}"`);
      }

      return params;
    })
  );

  public dialogParams$ = combineLatest([
    this.versionIdBehaviorSubject,
    this.storeDialogPageBehaviorSubject, // add debounce if we need to wait for user input ex: .pipe(debounceTime(300)),
    this.sortBehaviorSubject,
    this.dialogSearchBehaviorSubject.pipe(debounceTime(300)),
    this.reloadBehaviorSubject,
  ]).pipe(
    distinctUntilChanged((previous, current) => {
      // if the values coming down this pipe are the same, don't continue the pipe
      return JSON.stringify(previous) === JSON.stringify(current);
    }),
    map(([versionId, page, sort, search, reload]) => {

      // set the query string odata parameters
      let params: HttpParams = new HttpParams({
        fromObject: {
        versionId: this.versionIdBehaviorSubject.value,
        $skip: page.pageIndex * page.pageSize,
        $top: page.pageSize,
        $orderby: `${sort.active} ${sort.direction}`,
        $count: true,
      }
    });

      // if there is a search, add the search to the parameters
      if (search.length) {
        params = params.append('$search', `"${search}"`);
      }

      return params;
    })
  );

  // create the versions observable that calls http get when any of our parameters change
  private storeResponse$ = this.params$.pipe(
    tap(() => this.loadingBehaviorSubject.next(true)), // set isLoading to true
    switchMap((_params) => {
      const url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/Stores/ByVersionId`;
      return this.httpClient.get(url, { params: _params });
      return of();
    }),
    tap(() => this.loadingBehaviorSubject.next(false)), // set isLoading to false
    shareReplay(1) // make sure all subscriptions share the same http call (otherwise there will be a http call for each subscription)
  );

  // create the versions observable that calls http get when any of our parameters change
  private dialogstoreResponse$ = this.dialogParams$.pipe(
    tap(() => this.loadingBehaviorSubject.next(true)), // set isLoading to true
    switchMap((_params) => {
      const url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/Stores/ByNotVersionId`;
      return this.httpClient.get(url, { params: _params });
      return of();
    }),
    tap(() => this.loadingBehaviorSubject.next(false)), // set isLoading to false
    shareReplay(1) // make sure all subscriptions share the same http call (otherwise there will be a http call for each subscription)
  );

  // version listing
  public stores$: Observable<TStore[]> = this.storeResponse$.pipe(
    map((res: any) => res.value)
  );

  // total number of version records based on filtering
  public totalRecords$: Observable<number> = this.storeResponse$.pipe(
    map((res: any) => res['@odata.count'])
  );

  // version listing
  public dialogstore$: Observable<TStore[]> = this.dialogstoreResponse$.pipe(
    map((res: any) => res.value)
  );

  // total number of version records based on filtering
  public dialogtotalRecords$: Observable<number> = this.dialogstoreResponse$.pipe(
    map((res: any) => res['@odata.count'])
  );
  constructor(private httpClient: HttpClient, private organizationService: OrganizationService) { }

  set versionId(versionId: string) {
    this.versionIdBehaviorSubject.next(versionId);
  }

  // set the current page
  page(page: any) {
    this.pageBehaviorSubject.next(page);
  }

  storeDialogPage(page: any){
    this.storeDialogPageBehaviorSubject.next(page);
  }

  // reset the current page
  resetpage() {
    const page = this.pageBehaviorSubject.value;
    page.pageIndex = 0;
    page.previousPageIndex = 0;
    this.pageBehaviorSubject.next(page);
  }

  // reset the current page
  storeDialogresetpage() {
    const page = this.storeDialogPageBehaviorSubject.value;
    page.pageIndex = 0;
    page.previousPageIndex = 0;
    this.storeDialogPageBehaviorSubject.next(DEFAULT_PAGING);
  }

  // sets the sort property and order
  sort(sort: any) {
    this.sortBehaviorSubject.next(sort);
  }

  // sets the search phrase
  search(search: string) {
    const page = this.pageBehaviorSubject.value;
    page.pageIndex = 0;
    page.previousPageIndex = 0;
    this.searchBehaviorSubject.next(search);
    this.pageBehaviorSubject.next(page);
  }

  // sets the search phrase
  dialogSearch(search: string) {
    const page = this.pageBehaviorSubject.value;
    page.pageIndex = 0;
    page.previousPageIndex = 0;
    this.dialogSearchBehaviorSubject.next(search);
    this.pageBehaviorSubject.next(page);
  }

  // reloads/refreshes the version listing
  reload() {
    // reload the event data
    this.reloadBehaviorSubject.next(uuidv4());
  }

  // add edge version to store  associateStore
  associateVersionToStore(versionId: string, store: Store[]) {
    const url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/Stores/AddToVersion?versionId=${versionId}`;
    return this.httpClient.post(url, store);
  }

  disassociateVersionToStore(versionId: string, store: Store[]) {
    const url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/Stores/RemoveEdgeVersionHasStores?versionId=${versionId}`;
    return this.httpClient.post(url, store);
  }

  // deletes an version by id
  deleteVersion(id: string) {
    const url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/Stores/${id}`;
    return this.httpClient.delete(url);
  }

}
