import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Logger } from 'src/app/@shared';
import {
  EventService,
  Offer,
  Event,
  OfferDomain,
  OfferPromo,
  OfferPromoService,
  PromoDomain,
  CloneOfferComponent,
} from '../..';
import { OfferService } from 'src/app/modules/standard/v1';
import { combineLatest, distinctUntilChanged, map, take } from 'rxjs';

import { DEFAULT_PAGING } from 'src/app/@shared/constants/site.constants';
import { MatDialog } from '@angular/material/dialog';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { SelectionModel } from '@angular/cdk/collections';
const log = new Logger('OfferComponent');

@Component({
  selector: 'app-offer',
  templateUrl: './offer.component.html',
  styleUrls: ['./offer.component.scss'],
})
export class OfferComponent<
  TOfferDomain extends OfferDomain<Offer>,
  TOfferPromo extends OfferPromo,
  TEvent extends Event,
  TPromoDomain extends PromoDomain<OfferPromo>
> implements OnInit, OnDestroy {
  offerId: string = '0';
  versionNames: string[] = [];
  versionsList: any[] = [];
  private totalRecordsobj: any;
  private offerPromos: any;
  newVersionName = new FormControl<any>(null);
  VersionCtrl = new FormControl<string>('');
  applyChanges = new FormControl<boolean>(false);
  private offersList?: TPromoDomain[] = [];
  public isPromo: boolean = false;
  public currentOfferIndex: number = -1;
  public offerPromosCount: number = 0;
  public offersListPage = DEFAULT_PAGING;
  private currentRecord: any;
  public offerlistModelSub: any = null;
  public offersListRes: TPromoDomain[] = [];
  private offerList: any;
  public disableNewVersion: boolean = false;
  public versionsCount: number = 0;
  public activeNavItem: string = '';
  offerlistModel$ = combineLatest([
    this.offerpromoService.getoffersPromos$,
    this.offerpromoService.totalRecords$,
    this.offerpromoService.page$,
  ]).pipe(
    map(([offers, totalRecords, page]) => {
      this.offerList = offers;
      return { offers, totalRecords, page };
    })
  );
  viewModel$ = combineLatest([
    this.eventService.getEvent(this.route.snapshot.params['eventId']),
  ]).pipe(
    map(([event]) => {
      return { event };
    })
  );

  readOnlyMode: any = null;

  constructor(
    public route: ActivatedRoute,
    public offerserv: OfferService<any, TOfferDomain>,
    private offerpromoService: OfferPromoService<TOfferPromo, TPromoDomain>,
    private router: Router,
    private eventService: EventService<TEvent>,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    log.debug('init');
    this.offerserv.newVersion = '';
    this.offerId = this.route.snapshot.params['offerId'];

    if (
      this.route.snapshot.routeConfig &&
      this.route.snapshot.routeConfig.path === 'promos'
    ) {
      this.isPromo = true;
    } else {
      this.isPromo = false;
    }
    this.loadOfferListPagingData();
    this.getVersions('');
    this.checkIfReadOnlyMode();
    this.offerserv.triggerNewVariant$
      .pipe(
        distinctUntilChanged(
          (x: any, y: any) => x === y
        ))
      .subscribe((change) => {
        if (change && !this.offerserv.dialogueOpened) {
          this.openDialog();
          this.offerserv.addNewVariant = false;
        }
      });
  }

  checkIfReadOnlyMode() {
    this.readOnlyMode = this.offerpromoService.getOfferDetailsReadOnlyValue();
    this.offerpromoService.offerDetailsReadOnlyMode$.subscribe(
      (res) => (this.readOnlyMode = res)
    );
  }

  getVersions(versionName: string) {
    if (this.offerId != '0') {
      if (this.isPromo == true) {
        this.offerpromoService
          .getPromoVersions(this.offerId)
          .subscribe((res: any) => {
            this.updateVersionValues(res, versionName);
          });
      } else {
        this.offerserv.getOfferVersions(this.offerId).subscribe((res: any) => {
          this.updateVersionValues(res, versionName);
        });
      }
    }
  }

  updateVersionValues(res: any, versionName: string) {
    if (res) {
      this.offerserv.selection.clear();
      this.versionsList = res.value;
      this.versionsCount = res.value.length;
      this.offerserv.versionCount = res.value.length;
      if (versionName) {
        const versiontoAdd = { Id: new Date(), OfferVariantName: versionName };
        this.versionsList.push(versiontoAdd);
        this.newVersionName.patchValue(versiontoAdd);
      } else {
        const offer = this.versionsList.find((x: any) => x.Id === this.offerId);
        if (offer) {
          this.newVersionName.patchValue(offer);
          this.offerserv.selection.select(offer);
        }
      }
    }
  }

  isAllSelected() {
    const numSelected = this.offerserv.selection.selected.length;
    const numRows = this.versionsList.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected()
      ? this.offerserv.selection.clear()
      : this.versionsList.forEach((row) =>
        this.offerserv.selection.select(row)
      );

    if (!this.isAllSelected()) {
      const version = this.versionsList.find(
        (x) => x.Id === this.newVersionName.value.Id
      );
      this.offerserv.selection.select(version);
    }
  }

  removeTag(tag: any): void {
    const rows = this.offerserv.selection.selected.filter(
      (x) => x.Id != tag.Id
    );
    this.offerserv.selection.clear();
    rows.forEach((row) => this.offerserv.selection.select(row));
  }

  loadOfferListPagingData(
    pageIndex?: number,
    pageSize?: number,
    copyValue?: string
  ) {
    if (pageIndex != undefined && pageSize != undefined)
      this.offerpromoService.page({ pageIndex, pageSize });

    this.offerlistModelSub = this.offerlistModel$.pipe(take(1)).subscribe(
      ({ offers, totalRecords, page }) => {
        if (this.offersListRes != offers) {
          this.offerPromosCount = totalRecords;
          this.offersListRes = offers;
          this.offersList = this.getOfferShellArray(page, offers);
          this.offersListPage = page;

          //  if(this.currentOfferIndex != -1 && gotoIndex == undefined) gotoIndex = this.currentOfferIndex;
          if (
            this.currentOfferIndex != -1 &&
            this.offersList[this.currentOfferIndex]
          ) {
            this.goToOfferPromo(this.offersList[this.currentOfferIndex]);
            //  this.currentOfferIndex = gotoIndex;
          } else {
            if (this.currentOfferIndex == -1) this.setNavigation();
          }
        }
      }
    );
  }

  openDialog() {
    const offer = this.offerserv.getCurrentRecord;
    const selectedVersions = this.offerserv.SelectedOfferVersionsList;
    console.log(selectedVersions);
    if (offer) {
      const dialogRef = this.dialog.open(CloneOfferComponent, {
        width: '30%',
        height: '40%',
        data: { offerName: offer?.Detail?.Name, eventId: this.route.snapshot.params['eventId'], selectedVersionsList: selectedVersions },
      });
      this.offerserv.dialogueOpened = true;
      dialogRef.afterClosed().subscribe((res) => {
        console.log('Response', res);
        this.offerserv.setSelectedOfferVersionsList = [];
        this.offerserv.dialogueOpened = false;
        if (res && res.formvalues && res.formvalues.Detail && res.formvalues.Detail.Name) {
          this.offerserv.newVersion = res.formvalues.Detail.Name;
          this.offerserv.selectedVersionsIds = res.selectedIds.map((x: any) => x.Id);
          this.versionNames.push(res.formvalues.Detail.Name);
          this.newVersionName.patchValue(res.formvalues.Detail.Name);
          this.disableNewVersion = true;
          this.goToOfferPromo(offer, res.formvalues.Detail.Name);
          //this.offerId = '0';
        }
      });
    }
  }

  getOfferShellArray(
    currentPage: {
      previousPageIndex: number;
      pageIndex: number;
      pageSize: number;
      length: number;
    },
    currentPageOffers: TPromoDomain[]
  ): TPromoDomain[] {
    let previousPageList = new Array<TPromoDomain>(
      currentPage.pageIndex * currentPage.pageSize
    );
    let nextPageList: TPromoDomain[] = [];

    if (this.offerPromosCount > previousPageList.length + currentPage.pageSize)
      nextPageList = new Array<TPromoDomain>(
        this.offerPromosCount - (previousPageList.length + currentPage.pageSize)
      );

    return [...previousPageList, ...currentPageOffers, ...nextPageList];
  }

  ngOnDestroy(): void {
    if (this.offerlistModelSub) this.offerlistModelSub.unsubscribe();

    this.offerserv.currentRecord = {};
    this.offerserv.newVersion = '';
    this.offerserv.addNewVariant = null;
    this.offerserv.dialogueOpened = false;
  }

  setNavigation(): void {
    if (this.offersList && this.offersList.length) {
      this.offerId = this.route.snapshot.params['offerId'];
      if (this.offersList) {
        this.currentOfferIndex = this.offersList.findIndex(
          (x) => x && x.DomainId === this.offerId
        );
      }
    }
    if (this.offerlistModelSub) this.offerlistModelSub.unsubscribe();
  }

  onSave() {
    this.offerserv.setSaveorCancel('1');
    this.disableNewVersion = false;
    this.offerserv.newVersion = '';
  }

  onCancel() {
    this.offerserv.setSaveorCancel('0');
    this.offerserv.newVersion = '';
  }

  reloadCurrentRoute() {
    let currentUrl = this.router.url;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([currentUrl]);
    });
    this.getVersions('');
  }

  tagSelected(event: MatAutocompleteSelectedEvent) { }

  navigatetoNewOffer(previous: boolean) {
    let idtoOpen;

    if (!this.offersList || this.offersList.length <= 0) return;

    //let currentIndex = this.currentOfferIndex; //this.offersList.findIndex(x => x.Detail.Id === this.offerId);

    if (previous) {
      this.currentOfferIndex--;
      if (this.offersList[this.currentOfferIndex]) {
        idtoOpen = this.offersList[this.currentOfferIndex];
      } else {
        let pIndex = this.currentOfferIndex + 1 - this.offersListPage.pageSize;
        this.loadOfferListPagingData(pIndex, this.offersListPage.pageSize);
      }
    } else {
      this.currentOfferIndex++;
      if (this.offersList[this.currentOfferIndex]) {
        idtoOpen = this.offersList[this.currentOfferIndex];
      } else {
        this.loadOfferListPagingData(
          this.currentOfferIndex / this.offersListPage.pageSize,
          this.offersListPage.pageSize
        );
      }
    }
    this.offerId = idtoOpen?.DomainId as string;
    this.goToOfferPromo(idtoOpen);

    // if (idtoOpen) {
    //   let urltoOpen = idtoOpen.EventType === 'PROMO' ? `../${idtoOpen.Detail.Id}/promos/promodetails` : `../${idtoOpen.Detail.Id}/details`
    //   if (this.route.snapshot.routeConfig && this.route.snapshot.routeConfig.path === 'promos') {
    //     urltoOpen = '../' + urltoOpen;
    //   }
    //   this.router.navigate([urltoOpen], { relativeTo: this.route }).then(x => {
    //     this.reloadCurrentRoute();
    //   });

    // }
  }

  goToOfferPromo(OfferPromo: any, versionName?: string) {
    if (OfferPromo) {
      this.isPromo = OfferPromo.EventType === 'PROMO';
      this.getVersions(versionName as string);
      let urltoOpen =
        OfferPromo.EventType === 'PROMO'
          ? `../${OfferPromo.DomainId}/promos/promodetails`
          : `../${OfferPromo.DomainId}/details`;
      if (
        this.route.snapshot.routeConfig &&
        this.route.snapshot.routeConfig.path === 'promos'
      ) {
        urltoOpen = '../' + urltoOpen;
      }
      console.log(urltoOpen);
      this.router
        .navigate([urltoOpen], { relativeTo: this.route })
        .then((x) => {
          // this.reloadCurrentRoute();
        });
    }
  }

  // enableButton(previous: boolean) {
  //   let idtoOpen;
  //   if (!this.offersList || this.offersList.length <= 0) return false;
  //   const currentIndex = this.offersList.findIndex(x => x.Detail.Id === this.offerId);
  //   if (previous) {
  //     if (this.offersList[currentIndex - 1]) {
  //       idtoOpen = this.offersList[currentIndex - 1];
  //     }
  //   } else {
  //     if (this.offersList[currentIndex + 1]) {
  //       idtoOpen = this.offersList[currentIndex + 1];
  //     }
  //   }
  //   if (idtoOpen) {
  //     return true;
  //   }
  //   return false;
  // }

  getSelectedVersionDetail(event: any) {
    if (this.versionsList && this.versionsList.length > 0) {
      this.offerserv.newVersion = '';
      const versionName = this.versionsList.find(
        (x) => x.Id === event.value.Id
      );
      if (versionName && versionName.Id) {
        this.offerId = versionName.Id;
        let idtoOpen = {
          DomainId: versionName.Id,
          EventType: this.isPromo ? 'PROMO' : 'OFFER',
        };
        this.goToOfferPromo(idtoOpen);
      }
    }
    this.disableNewVersion = false;
  }

  setCurrentActiveItem(item: string) {
    this.activeNavItem = item;
  }
}
