<ng-container *ngIf="entityType$ | async as entityType else loading">
    <form action="" [formGroup]="cloneForm">
        <div fxLayout="row" fxLayoutGap="30px">
            <mat-form-field appearance="outline" fxFlex="50" (click)="openSourceEventsDialog()">
                <mat-label data-testid="sourceEvent">Source Event</mat-label>
                <input matInput readonly formControlName="sourceEvent" placeholder="Choose Source Event" />
                <mat-error *ngIf="cloneForm.controls['sourceEvent'].hasError('required')">
                    Source event is required
                </mat-error>
                <mat-error *ngIf="cloneForm.controls['sourceEvent'].hasError('invalid')">
                    {{cloneForm.controls['sourceEvent'].getError('invalid')}}
                </mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline" fxFlex="50" (click)="openDestinationEventsDialog()">
                <mat-label data-testid="destinationEvent">Destination Event</mat-label>
                <input matInput readonly formControlName="destinationEvent" placeholder="Choose Destination Event" />
                <mat-error *ngIf="cloneForm.controls['destinationEvent'].hasError('required')">
                    Destination Event is required
                </mat-error>
                <mat-error *ngIf="cloneForm.controls['destinationEvent'].hasError('invalid')">
                    {{cloneForm.controls['destinationEvent'].getError('invalid')}}
                </mat-error>
            </mat-form-field>
        </div>

        <div fxLayout="row" fxLayoutGap="30px">
            <mat-form-field appearance="outline" fxFlex="50">
                <mat-label data-testid="Versions">Versions</mat-label>
                <mat-select multiple formControlName="versions">
                    <mat-option *ngIf="versionList.length" [value]="'allVersions'" (click)="toggleSelectAllVersions($event)"> All Versions </mat-option>
                    <mat-option *ngFor="let version of versionList" [value]="version.Id">
                        {{version?.VersionName}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <div fxLayout="column" fxFlex="50" class="checkbox-group">
                <mat-checkbox *ngIf="destinationEventId === sourceEventId" formControlName="IncludeVersions">Include Versions Assigned</mat-checkbox>
                <mat-checkbox formControlName="KeepCurrentStatus">Keep Current Offer Status</mat-checkbox>
            </div>
        </div>


        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px" fxLayout.lt-md="column"
            fxLayoutAlign.lt-md="start start" fxLayoutGap.lt-md="4px" class="actions-bar">
            <h2>Select {{entityType === CloneType.OFFER ? 'Offers' : 'Promos' }} to Clone</h2>
            <mat-form-field appearance="outline" class="mat-form-field-no-padding">
                <mat-icon matPrefix>search</mat-icon>
                <mat-label>Search</mat-label>
                <input matInput type="text" (input)="onSearch($event)" placeholder="Search" #searchInput />
                <button type="button" matSuffix mat-icon-button aria-label="Clear" *ngIf="searchInput.value"
                    (click)="clearSearch()">
                    <mat-icon>close</mat-icon>
                </button>
            </mat-form-field>

            <span class="menu-spacer"></span>
        </div>
        <div class="seleted-offers">
            <span>({{selectedRecords.length}} {{entityType === CloneType.OFFER ? 'Offer(s)' : 'Promo(s)' }} Selected )</span>
        </div>
    </form>

    <app-clone-offers-list-table #offerPromoCloneTable *ngIf="viewMode === 'TABLE'"></app-clone-offers-list-table>

    <mat-card-actions fxLayout="row" fxLayoutAlign="end">
        <div>
            <button mat-stroked-button type="button" (click)="closeDialog()" style="margin-right: 10px;">
                Cancel
            </button>
            <button mat-raised-button type="button" color="accent" [disabled]="cloneForm.invalid || !selectedRecords.length"
                (click)="cloneEvent()">
                Clone {{ entityType === CloneType.OFFER ? 'Offers' : 'Promos' }}
            </button>
        </div>
    </mat-card-actions>
</ng-container>

<ng-template #loading>
    <mat-progress-bar mode="indeterminate" color="accent"></mat-progress-bar>
</ng-template>