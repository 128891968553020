import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, of, tap } from 'rxjs';
import { Logger } from 'src/app/@shared/services/logger.service';
import { ApiValidation, DEFAULT_SNACKBAR_CONFIG } from 'src/app/@shared';
import { AuthorizationService } from 'src/app/@shared/services/authorization.service';
import { CouponService } from '../../services/coupon.service';
import { Coupon } from '../../models/coupon.model';
import { Asset } from '../../models/asset.model';
import { AssetDomain } from '../../models/asset-domain.model';
import { CouponAssetsService } from '../../services/coupon-assets.service';

const log = new Logger('CouponAssetUpdateRankComponent');

@Component({
  selector: 'app-coupon-asset-update-rank',
  templateUrl: './coupon-asset-update-rank.component.html',
  styleUrls: ['./coupon-asset-update-rank.component.scss']
})
export class CouponAssetUpdateRankComponent<T extends Asset, TAssetDomain> implements OnInit {
  asset$: Observable<any> = of({} as any);
  formGroup = new FormGroup({
    Rank: new FormControl<number | null | undefined>(null, [Validators.min(1), Validators.max(99)]),
    AssetName: new FormControl<string | null>(null)
  });

  public asset: any;
  private couponId: string = '';
  private assetId: string = '';
  constructor(private couponAssetService: CouponAssetsService<Asset>, private router: Router,
    private route: ActivatedRoute,
    private matSnackBar: MatSnackBar

  ) { }

  ngOnInit(): void {
    log.debug('init');
    this.couponId = this.route.snapshot.params['couponId'];
    this.assetId = this.route.snapshot.params['assetId'];
    this.couponAssetService.couponId = this.couponId;
    this.couponAssetService.assets$.subscribe(assets => {
      if(assets && assets.length) {
        this.asset = assets.find(asset => asset.Detail.Id === this.assetId);
        this.formGroup.patchValue({
          AssetName: this.asset.Detail.AssetName,
          Rank: this.asset.Rank
        })
      }
    })
  }
  
  saveData() { 
    if(this.formGroup.controls.Rank.value){   
      this.couponAssetService.UpdateCouponAssociationRank(this.couponId, this.assetId, this.formGroup.controls.Rank.value).subscribe(res => {
        this.matSnackBar.open(
          ` ${this.formGroup.controls.AssetName.value} saved`, 'OK', DEFAULT_SNACKBAR_CONFIG
        );
        this.couponAssetService.reload();
        this.router.navigate([`../`], { relativeTo: this.route, queryParamsHandling: 'preserve' });
      })
    }
  }
  

  cancel(): void {
    this.formGroup.reset();
    this.router.navigate([`../`], { relativeTo: this.route })

  }
}
