<!-- page title -->
<div fxLayout="row" fxLayoutAlign="space-between" fxLayout.lt-md="column" fxLayoutAlign.lt-md="start start">
    <h1 class="mt-18">Variants Offer Management</h1>
</div>


<ng-container *ngIf="viewModel$ | async as viewModel; else loading">

    <h2 class="event-name">{{viewModel.event.EventName}}</h2>
    <form [formGroup]="filtersForm" novalidate>

        <!-- actions bar -->
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px" fxLayout.lt-md="column"
            fxLayoutAlign.lt-md="start start" fxLayoutGap.lt-md="4px" class="actions-bar">
            <button (click)="backOffers()" appAuthorizeControl="edit||offermang" type="button" mat-raised-button color="accent">
                Back to All Offers
            </button>

            <button type="button"
                *ngIf="(viewModel.viewMode === 'TABLE' && offerPromoTable?.getSelectedSectionRecords()?.length > 0) || (viewModel.viewMode === 'CARDS' && offerPromoCards?.getSelectedSectionRecords()?.length > 0)"
                mat-raised-button [matMenuTriggerFor]="menu" matTooltip="Actions">
                <mat-icon>more_vert</mat-icon>Actions
            </button>
            <mat-menu #menu="matMenu">
                <!-- <button type="button" mat-menu-item
                    [matMenuTriggerFor]="Download"><mat-icon>arrow_circle_down</mat-icon>Download</button> -->
                <!-- <button type="button" mat-menu-item><mat-icon>file_copy</mat-icon>Copy</button> -->
                <button type="button" mat-menu-item appAuthorizeControl="edit||offermang"
                    (click)="deleteSelectedRecords()"><mat-icon>delete</mat-icon>Delete</button>
                <button type="button" mat-menu-item
                    *ngIf="(viewModel.viewMode === 'TABLE' && offerPromoTable?.getSelectedSectionRecords()?.length > 0) || (viewModel.viewMode === 'CARDS' && offerPromoCards?.getSelectedSectionRecords()?.length > 0)">
                    {{ viewModel.viewMode === 'TABLE' ? offerPromoTable?.getSelectedSectionRecords()?.length :
                    offerPromoCards?.getSelectedSectionRecords()?.length}} Selected
                </button>
                <button appAuthorizeControl="edit||offermang" type="button" mat-menu-item [matMenuTriggerFor]="Select">Status</button>
            </mat-menu>
            <mat-menu #Download="matMenu">
                
            </mat-menu>
            <mat-menu #Select="matMenu">
                <button mat-menu-item (click)="updateStatus(1)">Pending</button>
                <button mat-menu-item (click)="updateStatus(2)">Approve</button>
                <button mat-menu-item (click)="updateStatus(3)">Suppress</button>
            </mat-menu>

            <span class="menu-spacer"></span>
           

            <mat-button-toggle-group #group="matButtonToggleGroup" value="cards">
                <mat-button-toggle value="cards" matTooltip="Card view" aria-label="Card view"
                    (click)="toggleView('CARDS')">
                    <mat-icon>grid_view</mat-icon>
                </mat-button-toggle>
                <mat-button-toggle value="table" matTooltip="Table view" aria-label="Table view"
                    (click)="toggleView('TABLE')">
                    <mat-icon>table_view</mat-icon>
                </mat-button-toggle>
            </mat-button-toggle-group>
        </div>

        <mat-sidenav-container>

            <!--main content-->
            <!--table view-->
            <app-version-promo-cards #offerPromoCards *ngIf="viewModel.viewMode === 'CARDS'"></app-version-promo-cards>
            <!--expansion view-->
            <app-version-promo-table #offerPromoTable *ngIf="viewModel.viewMode === 'TABLE'"></app-version-promo-table>

        </mat-sidenav-container>

    </form>

</ng-container>

<ng-template #loading>
    <mat-progress-bar mode="indeterminate" color="accent"></mat-progress-bar>
</ng-template>