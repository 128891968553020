<h2 mat-dialog-title>{{ data.title ? data.title : "Confirm Action" }}</h2>
<mat-dialog-content>
  {{ data.message ? data.message : "Are you sure you wish to continue?" }}
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button
    type="button"
    mat-button
    mat-dialog-close
    cdkFocusInitial
    *ngIf="hideCancel != true"
  >
    {{ data.cancelText ? data.cancelText : "Cancel" }}
  </button>
  <button type="button" mat-button [mat-dialog-close]="true">
    {{ data.confirmText ? data.confirmText : "Confirm" }}
  </button>
  <button
    *ngIf="data.saveText"
    type="button"
    mat-button
    [mat-dialog-close]="'save'"
  >
    {{ data.saveText ? data.saveText : "Save" }}
  </button>
</mat-dialog-actions>
