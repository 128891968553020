import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import * as dayjs from 'dayjs';
import { combineLatest, map, Observable, of, tap } from 'rxjs';
import { Logger, ConfirmDialogComponent } from 'src/app/@shared';
import { DEFAULT_SNACKBAR_CONFIG } from 'src/app/@shared/constants/site.constants';
import { Division, DivisionService, Event, EventDomain, EventService, EventType, EventTypeService } from '../..';

const log = new Logger('EventDetailsComponent');

@Component({
  selector: 'app-event-details',
  templateUrl: './event-details.component.html',
  styleUrls: ['./event-details.component.scss'],
})

export class EventDetailsComponent
  <TEvent extends Event, TDivision extends Division, TEventType extends EventType>
  implements OnInit, OnDestroy {

  viewModel$ = combineLatest([
    this.divisionService.divisionsList$,
    this.eventTypeService.eventTypesList$
  ]).pipe(
    map(([divisions, eventTypes]) => {
      return {
        divisions,
        eventTypes
      }
    }),
  );

  formGroup = new FormGroup({
    EventTypeId: new FormControl<string | null>(null),
    DivisionIds: new FormControl<string[]>([]),
    Detail: new FormGroup({
      ClientKey: new FormControl<string>('', [Validators.required, Validators.minLength(1), Validators.maxLength(100)]),
      EndDate: new FormControl<Date | string | null>(null, [Validators.required]),
      Id: new FormControl<string>('0', { nonNullable: true }),
      EventName: new FormControl<string>('', [Validators.required, Validators.minLength(1), Validators.maxLength(100)]),
      StartDate: new FormControl<Date | string | null>(null, [Validators.required]),
      Disclaimer: new FormControl<string>(''),
    })
  });

  eventDomain$: Observable<EventDomain<TEvent>> = of({} as EventDomain<TEvent>);
  eventId = '0';

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private matSnackBar: MatSnackBar,
    private eventService: EventService<TEvent>,
    private divisionService: DivisionService<TDivision>,
    private eventTypeService: EventTypeService<TEventType>,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    log.debug('init');

    // get the event id from the parent route
    this.route.params.subscribe(params => {
      this.eventId = params['eventId'];
    });

    // if we are editing a record, get the record data
    if (this.eventId !== '0') {
      this.eventDomain$ = this.eventService.getEventDomain(this.eventId).pipe(
        tap((eventDomain) => {
          if(eventDomain && eventDomain.Detail && eventDomain.Detail.StartDate) {
            eventDomain.Detail.StartDate = new Date(eventDomain.Detail.StartDate);
          }
          if(eventDomain && eventDomain.Detail && eventDomain.Detail.EndDate) {
            eventDomain.Detail.EndDate = new Date(eventDomain.Detail.EndDate);
          }
          this.formGroup.patchValue(eventDomain);
          this.formGroup.markAllAsTouched();
        })
      );
    }

  }

  trimControlValues(formGroup: FormGroup): void {
    Object.keys(formGroup.controls).forEach((key: string) => {
      const abstractControl = formGroup.get(key);
      if (abstractControl instanceof FormGroup) {
        this.trimControlValues(abstractControl);
      } else {
        if (typeof abstractControl?.value == 'string') {
          abstractControl.setValue(abstractControl?.value.trim());
        }
      }
    })
  }

  save(): void {
    this.trimControlValues(this.formGroup);

    if (this.formGroup.valid) {

      const startDate = this.formatDate(this.formGroup.controls.Detail.controls.StartDate.value);
      const endDate = this.formatDate(this.formGroup.controls.Detail.controls.EndDate.value);
      const request = this.formGroup.getRawValue() as EventDomain<TEvent>;
      request.Detail.StartDate = startDate;
      request.Detail.EndDate = endDate;
      this.eventService.saveEventDomain(request).subscribe({
        next: (response) => {
          this.matSnackBar.open(
            `${this.formGroup.controls.Detail.controls.EventName.value} saved`, 'OK', DEFAULT_SNACKBAR_CONFIG
          );
          this.eventService.reload();
          this.router.navigate([`../../`], { relativeTo: this.route, queryParamsHandling: 'preserve' });
        },
        error: (error) => {
          if (error.status === 500) {
            log.error('500 Error saving event', error);
          }
          if (error.status === 400) {
            const apiValidations: any = error.error;
            if (Array.isArray(apiValidations)) {
              apiValidations.forEach((validation: any) => {
                if (this.formGroup?.get(validation.PropertyName)) {
                  const control = this.formGroup?.get(validation.PropertyName);
                  if (control) {
                    control.markAsTouched();
                    control.setErrors({ invalid: validation.ErrorMessage });
                    this.matSnackBar.open(validation.ErrorMessage, 'OK', { verticalPosition: 'top', panelClass: ['snackbar-error'] });
                  }
                } else {
                  ///TODO: if we have cross field validation then show the validation error at the top of the screen
                  // if we have cross field validation then show the validation error at the top of the screen
                  // push general error messages to array this is displayed in a toast or dialog
                }
              });
            } else {
              this.matSnackBar.open(apiValidations, 'Error', { verticalPosition: 'top', panelClass: ['snackbar-error'] });
            }


          }
        }
      });
    }
  }

  cancel(): void {
    this.formGroup.reset();
    this.router.navigate([`../../`], { relativeTo: this.route, queryParamsHandling: 'preserve' });
  }

  deleteRecord() {
    const record = this.formGroup.getRawValue();
    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Confirm Delete',
        message: `Are you sure you wish to delete ${record.Detail.EventName}?`,
      },
      disableClose: true,
    });

    confirmDialog.afterClosed().subscribe(
      confirmResult => {
        if (confirmResult) {
          this.eventService.deleteEvent(record.Detail.Id).subscribe({
            next: () => {
              this.matSnackBar.open(`${record.Detail.EventName} deleted`, 'OK', DEFAULT_SNACKBAR_CONFIG);
              this.eventService.reload();
              this.router.navigate([`../../`], { relativeTo: this.route, queryParamsHandling: 'preserve' });
            },
            error: (error) => {
              log.error('Error deleting event', error);

              if (error.error.value) {
                throw new Error(error.error.value);
              } else {
                throw new Error(error.message);
              }
            }
          });
        }
      });
  }

  formatDate(date: Date | string | null) {
    if(date) {
      const newDate = dayjs(date).format('MM/DD/YYYY');
      return newDate;
    } else {
      return '';
    }
    
  }

  ngOnDestroy() { }
}
