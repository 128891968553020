<ng-container *ngIf="viewModel$ | async as viewModel">
    <form [formGroup]="form">
      <div formArrayName="versions">
        <table *ngIf="viewModel.coupons.length" mat-table [dataSource]="viewModel.coupons" matSort
          (matSortChange)="onSort($event)" matSortDisableClear [matSortStart]="'desc'">

          <!-- Checkbox Column -->
          <ng-container matColumnDef="select">
            <th class="no-ellipsis" mat-header-cell *matHeaderCellDef>
              <mat-checkbox [checked]="selection.hasValue() && isAllSelected()" (change)="$event ? masterToggle() : null"
                [indeterminate]="selection.hasValue() && !isAllSelected()">
              </mat-checkbox>
            </th>
            <td class="no-ellipsis" mat-cell *matCellDef="let row">
              <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
                [checked]="selection.isSelected(row)">
              </mat-checkbox>
            </td>
          </ng-container>

          <!--  Image Column -->
          <ng-container matColumnDef="Image">
            <th mat-header-cell *matHeaderCellDef [resizeColumn]="true">
              Preview
            </th>
            <td mat-cell *matCellDef="let record">
              <!-- <img src="{{record?.FileName }}"> -->
              <!-- <img *ngIf="record.FileName" src="{{viewModel.assetsUrl}}/{{record.FileName}}" alt="Offer image">
          <img *ngIf="!record.FileName" src="./assets/images/default-image.png" alt="Offer image"> -->
              <!-- <ng-container *ngIf="record.FileName">
                <img mat-card-md-image [src]="getImageSrc(record)" alt="Offer image">
              </ng-container> -->
              <img mat-card-md-image *ngIf="!record.PreviewImage" src="/assets/images/da_default_asset_error_icon.png"
                alt="Offer image">
              <img mat-card-md-image *ngIf="record.PreviewImage" [src]="record?.PreviewImage"
                alt="Offer image">
            </td>
          </ng-container>

          <!-- Summary Column -->
          <ng-container matColumnDef="Summary">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Name" [resizeColumn]="true">
              Summary
            </th>
            <td mat-cell *matCellDef="let record"> {{ record.Detail.Summary }}</td>
          </ng-container>

          <!-- Description Column -->
          <ng-container matColumnDef="Description">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Headline" [resizeColumn]="true">
              Description
            </th>
            <td mat-cell *matCellDef="let record">{{ record.Detail.Description }}</td>
          </ng-container>

          <!-- Redemption Start Date Column -->
          <ng-container matColumnDef="RedemptionStartDate">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by BodyCopy" [resizeColumn]="true">
              Redemption Start Date
            </th>
            <td mat-cell *matCellDef="let record">{{ record.Detail.RedemptionStartDate }}</td>
          </ng-container>

          <!-- Expiry Datey Column -->
          <ng-container matColumnDef="ExpiryDate">
            <th mat-header-cell *matHeaderCellDef [resizeColumn]="true">
              Expiry Date
            </th>
            <td mat-cell *matCellDef="let record">{{ record.Detail.ExpiryDate }}</td>
          </ng-container>

          <!-- Status Column -->
          <ng-container matColumnDef="Status">
            <th style="width:120px" mat-header-cell *matHeaderCellDef mat-sort-header
              sortActionDescription="Sort by Status" [resizeColumn]="true">
              Status
            </th>
            <td [matMenuTriggerFor]="menu" mat-cell *matCellDef="let record">
              <div class="offer-status column-status">
                <span class="{{record &&
                  record.Detail &&
                  record.Detail.Status &&
                  record.Detail.Status.toString() === 'Pending'
                      ? 'statusPending'
                      : 'status' + record.Detail.Status.toString()
                  }}">
                  {{ record.Detail.Status === 'Pending' ? 'Pending' :
                  record.Detail.Status}}
                  <mat-icon class="icon">arrow_drop_down</mat-icon>
                </span>

              </div>
              <mat-menu #menu="matMenu">
                <button type="button" class="statusApproved" mat-menu-item [disabled]="record?.Detail?.Status === 'Approved'"
                  (click)="updateTableStatus('Approved', record.Detail)">APPROVED</button>
                <button type="button" mat-menu-item class="statusPending" [disabled]="record?.Detail?.Status === 'Pending'"
                  (click)="updateTableStatus('Pending', record.Detail)">PENDING</button>
                <button type="button" class="statusSuppressed" mat-menu-item [disabled]="record?.Detail?.Status === 'Suppressed'"
                  (click)="updateTableStatus('Suppressed', record.Detail)">SUPPRESSED</button>
              </mat-menu>
            </td>
          </ng-container>

          <!-- Actions Column -->
          <ng-container matColumnDef="Actions">
            <th mat-header-cell *matHeaderCellDef style="padding-left: 20px;">Actions</th>
            <td mat-cell *matCellDef="let record">
              <button class="offer-button" type="button" mat-button color="accent"
                [routerLink]="[record.Detail.Id, 'details']">
                <span >Edit</span>
              </button>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
      </div>
    </form>


    <table *ngIf="!viewModel.coupons.length">
      <tr>
        <td class="no-records" [attr.colspan]="displayedColumns.length">
          <h2>No Records Found</h2>
        </td>
      </tr>
    </table>

    <!-- pager -->
    <mat-paginator [length]="viewModel.totalRecords" [pageSize]="viewModel.page.pageSize"
      [pageSizeOptions]="[5, 10, 20, 50, 100,500]" (page)="onPage($event)" *ngIf="viewModel.coupons.length"
      showFirstLastButtons [pageIndex]="viewModel.page.pageIndex">
    </mat-paginator>

  </ng-container>
