import { SelectionModel } from '@angular/cdk/collections';
import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MediaChange, MediaObserver } from '@angular/flex-layout';
import { FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Sort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest, distinctUntilChanged, map, Subscription } from 'rxjs';
import { ConfirmDialogComponent, DEFAULT_SNACKBAR_CONFIG, Logger } from 'src/app/@shared';
import { EventService, Version, VersionService, Event, OfferPromoService, OfferPromo, PromoDomain } from '../..';
import * as dayjs from 'dayjs';

const log = new Logger('VersionListComponent');

@Component({
  selector: 'app-version-list',
  templateUrl: './version-list.component.html',
  styleUrls: ['./version-list.component.scss']
})

export class VersionListComponent<TVersion extends Version, TEvent extends Event, TOfferPromo extends OfferPromo, TPromoDomain extends PromoDomain<OfferPromo>> implements OnInit {

  viewModel$ = combineLatest([
    this.versionService.versions$,
    this.versionService.isLoading$,
    this.versionService.totalRecords$,
    this.versionService.page$,
    this.eventService.getEvent(this.route.snapshot.parent?.parent?.params['eventId'] || this.versionService.eventId)
  ]).pipe(
    map(([versions, isLoading, totalRecords, page, event]) => {
      if (versions.length == 0 && page.pageIndex > 0) {
        page.previousPageIndex = 0
        page.pageIndex = 0
        this.versionService.page(page);
        this.versionService.reload();
      }
      return { versions, isLoading, totalRecords, page, event }
    }),
  );

  flexMediaWatcher!: Subscription;
  displayedColumns = ['select', 'VersionName', 'ClientKey', 'Actions'];
  versionList: Version[] = [];
  eventId!: string;
  selection = new SelectionModel<Version>(true, []);


  constructor(
    private eventService: EventService<TEvent>,
    private versionService: VersionService<TVersion>,
    private mediaObserver: MediaObserver,
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private offerpromoService: OfferPromoService<TOfferPromo, TPromoDomain>,
    @Optional() @Inject(MAT_DIALOG_DATA) public dialogData: any,
    private matSnackBar: MatSnackBar) { }

  ngOnInit(): void {
    log.debug('init');
    // get the event id from the parent route
    this.route.params.subscribe(params => {
      this.eventId = params['eventId'];
    }); 
    this.versionService.versions$.subscribe((data) => {
      this.versionList = data;
    })
    this.clearSearch();
    // detect changes in viewport size to handle show/hide of table columns
    const getAlias = (MediaChange: MediaChange[]) => {
      return MediaChange[0].mqAlias;
    };

    this.flexMediaWatcher = this.mediaObserver
      .asObservable()
      .pipe(
        distinctUntilChanged(
          (x: MediaChange[], y: MediaChange[]) => getAlias(x) === getAlias(y)
        ))
      .subscribe((change) => {
        if (change.some(x => x.mqAlias === 'xs')) {
          this.displayedColumns = ['select', 'VersionName', 'Actions'];
        }
        else {
          this.displayedColumns = ['select', 'VersionName', 'ClientKey', 'Actions'];
        }
        if(this.dialogData) {
          this.displayedColumns.pop();
        }
      });

    // get and set the event id in the version service
    if (this.route.snapshot.parent && this.route.snapshot.parent.parent) {
      //this.versionService.eventId = this.route.snapshot.parent.params['eventId'];
      this.versionService.eventId = this.route.snapshot.parent.parent.params['eventId'];
    }
  }

  filtersForm = new FormGroup({
    search: new FormControl(),
  });

  onSearch(event: any) {
    this.versionService.search(event.target.value);
  }

  onPage(pageEvent: PageEvent): void {
    this.selection.clear();
    this.versionService.page(pageEvent);
  }

  clearSearch() {
    this.filtersForm.controls.search.setValue('');
    this.versionService.search('');
  }

  onSort(sortState: Sort): void {
    this.versionService.sort(sortState);
    this.selection.clear();
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.versionList.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.versionList.forEach(row => this.selection.select(row));
  }

  public getSelectedSectionRecords() {
    return this.selection.selected;
  }

  public clearSelection() {
    this.selection.clear();
  }

  onRowDoubleClick(id: string) {
    this.router.navigate([`${id}/details`], { relativeTo: this.route });
  }

  deleteSelectedRecords() {
    let selectedRecords: any;   
    selectedRecords = this.getSelectedSectionRecords();    
    if (selectedRecords && selectedRecords.length > 0) {
      const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
        data: {
          title: 'Confirm Delete',
          message: `Do you want to remove Version(s)?`,
        },
        disableClose: true,
      });

      confirmDialog.afterClosed().subscribe(
        confirmResult => {
          if (confirmResult) {
            this.versionService.deleteVersions(selectedRecords).subscribe({
              next: () => {
                this.matSnackBar.open(`Version(s) deleted`, 'OK', DEFAULT_SNACKBAR_CONFIG);
                this.versionService.reload();               
                  this.clearSelection();                
              },
              error: (error) => {
                log.error('Error in deleting Version', error);

                if (error.error.value) {
                  throw new Error(error.error.value);
                } else {
                  throw new Error(error.message);
                }
              }
            });
          }
        });
    }
  }

  downloadVersions() {
    this.versionService.downloadVersions(this.eventId);
  }

  downloadVersionStores() {
    this.versionService.downloadVersionStores(this.eventId);
  }

  selectVersions() {
    let addVersionCodes: any = [];
      for (let index = 0; index <= this.dialogData.selectedRecordsDetails.length - 1; index++) {
        const activeVersion = this.dialogData.selectedRecordsDetails[index].OfferVariants.find((x: any) => x.activeItem == true);
        const x = activeVersion ? activeVersion : this.dialogData.selectedRecordsDetails[index].OfferVariants[0];
          addVersionCodes.push({
            Id: x.Id, ClientKey: x.ClientKey, UserId: x.UserId, Name: x.Name, Headline: x.Headline, BodyCopy: x.BodyCopy,
            Disclaimer: x.Disclaimer, ClientComment: x.ClientComment, StartDate: dayjs(x.StartDate).format('MM/DD/YYYY'),
            EndDate: dayjs(x.EndDate).format('MM/DD/YYYY'), Rank: x.Rank, TargetURL: x.TargetURL, Status: x.Status, Version: x.Version,
            Label: x.Label, Versions: x.Versions
          });
       

      }

    this.versionService.applyVersionCodes(this.selection.selected, addVersionCodes).subscribe(res => {
      this.matSnackBar.open(`Applied versions to all offers and promos`, 'OK', DEFAULT_SNACKBAR_CONFIG);
      this.dialog.closeAll();
      this.selection.clear();
      this.offerpromoService.reload();
    })
  }

  cancel() {
    this.dialog.closeAll();
  }

  ngOnDestroy(): void {
    this.flexMediaWatcher.unsubscribe();
  }

}
