import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DialogData, ErrorDialogComponent } from '..';

@Injectable({
  providedIn: 'root',
})

export class ErrorDialogService {
  private isOpened = false;

  constructor(private dialog: MatDialog) { }

  openDialog(dialogData: DialogData): void {
    if (!this.isOpened) {
      this.isOpened = true;
      const dialogRef = this.dialog.open(ErrorDialogComponent, {
        data: dialogData,
        maxHeight: '100%',
        width: '540px',
        maxWidth: '100%',
        disableClose: true,
        hasBackdrop: true,
      });

      dialogRef.afterClosed().subscribe(() => {
        this.isOpened = false;
      });
    }
  }
}
