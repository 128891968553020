<ng-container *ngIf="viewModel$ | async as viewModel">
  <h1>Workflow Management</h1>
  <div
    class="actions-bar"
    fxLayout="row wrap"
    fxLayoutGap.gt-sm="16px"
    fxLayout.lt-md="column"
    style="align-items: center !important"
    *ngIf="!editWorkflow"
  >
    <div fxLayout="row wrap" fxLayoutGap.gt-sm="16px" fxLayout.lt-md="column">
      <button
        type="button"
        mat-raised-button
        color="accent"
        matTooltip="New Template"
        (click)="navigateToNewTemplate()"
      >
        <fa-icon [icon]="['fas', 'plus']"></fa-icon>New Template
      </button>
    </div>
    <div fxLayout="row wrap" fxLayoutGap.gt-sm="16px" fxLayout.lt-md="column" style="align-items: center;">
      <!-- Actions drop down -->
      <button
        class="actions-menu-button"
        mat-raised-button
        [matMenuTriggerFor]="actionsMenu"
        *ngIf="showActions == true"
      >
        <mat-icon>more_vert</mat-icon>
        Actions
      </button>
      <mat-menu class="actions-mat-menu" #actionsMenu="matMenu">
        <button mat-menu-item (click)="deleteTemplates()">
          <mat-icon>delete</mat-icon>
          <span>Delete</span>
        </button>
        <button mat-menu-item (click)="cloneTemplates()">
          <mat-icon>content_copy</mat-icon>
          <span>Clone</span>
        </button>

        <!-- <button mat-menu-item (click)="activateUserToggle()">
              {{
                selectedUser?.selectedUserIds[0]?.isActive == true
                  ? 'De-activate'
                  : 'Activate'
              }}
            </button> -->
        <button mat-menu-item style="margin-top: 2px">
          <span>{{ selection.selected.length }} Selected</span>
        </button>
      </mat-menu>

      <!-- Search Bar -->
      <mat-form-field appearance="outline" class="mat-form-field-no-padding">
        <mat-icon matPrefix>search</mat-icon>
        <mat-label>Search</mat-label>
        <input
          matInput
          [(ngModel)]="searchText"
          name="searchText"
          type="text"
          placeholder="Search"
          (input)="onSearch($event)"
          #searchInput
        />
        <button
          type="button"
          matSuffix
          mat-icon-button
          aria-label="Clear"
          *ngIf="searchText.length > 0"
          (click)="clearSearch()"
        >
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
    </div>
  </div>
  <div [ngClass]="{ 'event-workflow-container': !editWorkflow }">
    <div
      *ngIf="!editWorkflow"
      fxLayout="row wrap"
      fxLayoutGap.gt-sm="16px"
      fxLayout.lt-md="column"
    >
      <div
        id="exisiting-templates"
        fxLayout="row wrap"
        fxLayoutGap.gt-sm="16px"
        fxLayout.lt-md="column"
      >
        <table
          mat-table
          [dataSource]="viewModel.templates"
          class="mat-elevation-z8"
        >
          <!--- Note that these columns can be defined in any order.
              The actual rendered columns are set as a property on the row definition" -->

          <!-- Checkbox Column -->
          <ng-container matColumnDef="select">
            <th class="no-ellipsis" mat-header-cell *matHeaderCellDef>
              <mat-checkbox
                (change)="toggleAllRows($event, viewModel.templates)"
                [checked]="selection.hasValue() && isAllSelected()"
                [indeterminate]="selection.hasValue() && !isAllSelected()"
                [aria-label]="checkboxLabel()"
              >
              </mat-checkbox>
            </th>
            <td
              class="no-ellipsis"
              mat-cell
              *matCellDef="let row; let i = index"
            >
              <mat-checkbox
                (click)="$event.stopPropagation()"
                (change)="
                  $event ? selectionToggle(row, i, viewModel.templates) : null
                "
                [checked]="selection.isSelected(row)"
                [aria-label]="checkboxLabel(row)"
              >
              </mat-checkbox>
            </td>
          </ng-container>

          <!-- Name Column -->
          <ng-container matColumnDef="template-name">
            <th mat-header-cell *matHeaderCellDef>Template Name</th>
            <td mat-cell *matCellDef="let element">
              {{ element.Name }}
            </td>
          </ng-container>

          <!-- Weight Column -->
          <ng-container matColumnDef="template-group">
            <th mat-header-cell *matHeaderCellDef>Group</th>
            <td mat-cell *matCellDef="let element">
              {{ getCategoryNameFromId(element.WorkflowGroup) }}
            </td>
          </ng-container>

          <ng-container matColumnDef="category">
            <th mat-header-cell *matHeaderCellDef>Category</th>
            <td mat-cell *matCellDef="let element">
              {{ element.WorkflowCategory }}
            </td>
          </ng-container>

          <!-- Symbol Column -->
          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef>Actions</th>
            <td mat-cell *matCellDef="let element">
              <a (click)="onEditWorkflow(element)">Edit</a>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
          <tr>
            <td *ngIf="!viewModel.templates || viewModel.templates.length == 0">
              <p><b>No Templates Added Yet</b></p>
            </td>
          </tr>
        </table>

        <table
          *ngIf="!viewModel.templates.length"
        >
          <tr>
            <td class="no-records" [attr.colspan]="displayedColumns.length">
              <h2>No Records Found</h2>
            </td>
          </tr>
        </table>
        <mat-paginator
          *ngIf="viewModel.templates.length"
          style="width: 100%"
          showFirstLastButtons
          [length]="viewModel.totalRecords"
          [pageSize]="viewModel.page.pageSize"
          [pageSizeOptions]="[5, 10, 20, 50, 100, 500]"
          [pageIndex]="viewModel.page.pageIndex"
          (page)="onPage($event)"
          aria-label="Select page"
        >
        </mat-paginator>
      </div>
    </div>

    <app-admin-edit-workflow
      *ngIf="editWorkflow == true"
      [templateId]="selectedTemplateId"
      (onClose)="closeEditWorkflow($event)"
    ></app-admin-edit-workflow>
  </div>
</ng-container>
