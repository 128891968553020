<form [formGroup]="form!">
  <mat-accordion style="margin-top: 16px" *ngIf="viewModel$ | async as viewModel">
    <mat-expansion-panel [expanded]="true">
      <mat-expansion-panel-header>
        <mat-panel-title><span style="font-size: 20px">{{form!.controls['organization'].value?.name ||
            'Organization'}}</span></mat-panel-title>
      </mat-expansion-panel-header>

      <!-- Expansion panel body starts here -->
      <div fxLayout="row wrap" fxLayoutGap.gt-sm="16px" fxLayout.lt-md="column">
        <!-- Organization dropdown -->
        <mat-form-field appearance="outline" fxFlex="24">
          <mat-label>Organization</mat-label>
          <mat-select required formControlName="organization" (selectionChange)="getSelectedOrganizationDetail($event)">
            <mat-option *ngFor="let org of viewModel.organizations" [value]="org">
              {{ org.name | titlecase }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="
                  form!.controls['organization'].touched &&
                  form!.controls['organization'].invalid
                ">Organization is required</mat-error>
        </mat-form-field>

        <!-- Division dropdown -->

        <ng-container *ngIf="form!.controls['organization'].value">
          <mat-form-field appearance="outline" fxFlex="24" *ngIf="divisionsList">
            <mat-label>Divisions</mat-label>
            <mat-select formControlName="divisions" [disabled]="true" multiple>
              <mat-option *ngFor="let division of divisionsList" [value]="division.Id">
                {{ division.DivisionName | titlecase }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="
                    form!.controls['divisions'].touched &&
                    form!.controls['divisions'].invalid
                  ">Division is required</mat-error>
          </mat-form-field>

          <!-- User Role dropdown -->
          <mat-form-field appearance="outline" fxFlex="24">
            <mat-label>User Role</mat-label>
            <mat-select required formControlName="user_role">
              <mat-option *ngFor="let role of userRoles" [value]="role.Id">
                {{ role.Name | titlecase }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="
                    form!.controls['user_role'].touched &&
                    form!.controls['user_role'].invalid
                  ">User Role is required</mat-error>
          </mat-form-field>

          <!-- User Status -->
          <mat-form-field appearance="outline" fxFlex="24">
            <mat-label>User Status</mat-label>
            <mat-select required formControlName="user_status">
              <mat-option *ngFor="let status of userStatus" [value]="status.value">
                {{ status.viewValue }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="
                    form!.controls['user_status'].touched &&
                    form!.controls['user_status'].invalid
                  ">User Status is required</mat-error>
          </mat-form-field>

          
          <!-- Categories -->
          <mat-form-field appearance="outline" fxFlex="24">
            <mat-label>Categories</mat-label>
            <mat-select formControlName="product_categories" multiple>
              <mat-option *ngFor="let category of productCategoriesList" [value]="category.Id">
                {{ category.ProductCategoryName }}
              </mat-option>
            </mat-select>
            <!-- <mat-error *ngIf="
                    form!.controls['product_categories'].touched &&
                    form!.controls['product_categories'].invalid
                  ">Categories is required</mat-error> -->
          </mat-form-field>
        </ng-container>

      </div>



      <!-- Second line in second section -->
      <div fxLayout="row wrap" fxLayoutGap.gt-sm="16px" *ngIf="form!.controls['organization'].value"
        fxLayout.lt-md="column">
        <div fxLayout="column wrap" fxLayoutGap.gt-sm="16px">
          <section class="list-types-section">
            <section>
              <mat-label>Event Type</mat-label>
            </section>
            <span class="list-types">
              <mat-checkbox [name]="'Event Type'" class="example-margin" [checked]="selectAllEvents"
                (change)="setAllEvents($event.checked)">
                Select All
              </mat-checkbox>
            </span>
            <span class="list-types" *ngIf="eventTypes && eventTypes.length > 1">
              <div *ngFor="let eventType of eventTypes; let i = index">
                <mat-checkbox [checked]="eventType.selected" type="checkbox"
                  (change)="eventTypeSelected($event, eventType)">{{eventType.EventTypeName}}</mat-checkbox>
              </div>
            </span>
          </section>
        </div>

        <div fxLayout="column wrap" fxLayoutGap.gt-sm="16px">
          <section class="example-section">
            <section *ngIf="subscribedChannels && subscribedChannels.length > 0">
              <mat-label>Channels</mat-label>
            </section>
            <span class="list-types">
              <mat-checkbox [name]="'EventType'" class="example-margin" [checked]="selectAllChannels"
                (change)="setAllChannels($event.checked)">
                Select All
              </mat-checkbox>
            </span>
            <span class="list-types" *ngIf="subscribedChannels && subscribedChannels.length > 0">
              <ul>
                <li *ngFor="let channel of subscribedChannels">
                  <mat-checkbox class="example-margin" [checked]="channel.selected"
                    (change)="channelSelected($event, channel)">{{
                    channel?.ChannelName }}</mat-checkbox>
                </li>
              </ul>
            </span>
          </section>

          <mat-error *ngIf="
                  form!.controls['channels'].touched &&
                  form!.controls['channels'].invalid &&
                  subscribedChannels &&
                  subscribedChannels.length > 0
                ">
            <span class="mat-err-text">Choose atleast one channel</span></mat-error>
        </div>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</form>