import { Injectable } from '@angular/core';
import { User, UserDomain } from '..';
import { HttpClient, HttpParams } from '@angular/common/http';
import {
  BehaviorSubject,
  combineLatest,
  debounceTime,
  distinctUntilChanged,
  map,
  Observable,
  shareReplay,
  switchMap,
  tap,
} from 'rxjs';
import { environment } from 'src/environments/environment';
import { OrganizationService } from 'src/app/@shared';
import { v4 as uuidv4 } from 'uuid';
import { DEFAULT_PAGING } from 'src/app/@shared/constants/site.constants';
import { Filter } from 'src/app/@shared/models/filter.model';

@Injectable({
  providedIn: 'root',
})
export class UserService<TUser extends User> {
  constructor(
    private httpClient: HttpClient,
    private organizationService: OrganizationService
  ) {}

  private loadingBehaviorSubject = new BehaviorSubject<boolean>(false);
  private pageBehaviorSubject = new BehaviorSubject(DEFAULT_PAGING);
  private dialogPageBehaviorSubject = new BehaviorSubject(DEFAULT_PAGING);
  private sortBehaviorSubject = new BehaviorSubject({
    active: 'FirstName',
    direction: 'desc',
  });
  private searchBehaviorSubject = new BehaviorSubject<string>('');
  private dialogSearchBehaviorSubject = new BehaviorSubject<string>('');
  private reloadBehaviorSubject = new BehaviorSubject<string>('');
  private viewModeBehaviorSubject = new BehaviorSubject<string>('TABLE');
  private filterBehaviorSubject = new BehaviorSubject<Filter[]>([]);
  private selectedUserForAdBehaviorSubject = new BehaviorSubject<
    UserDomain<TUser>[]
  >([]);

  public isLoading$ = this.loadingBehaviorSubject.asObservable();
  public sort$ = this.sortBehaviorSubject.asObservable();
  public page$ = this.pageBehaviorSubject.asObservable();
  public dialogPage$ = this.dialogPageBehaviorSubject.asObservable();
  public search$ = this.searchBehaviorSubject.asObservable();
  public dialogSearch$ = this.dialogSearchBehaviorSubject.asObservable();
  public viewMode$ = this.viewModeBehaviorSubject.asObservable();
  public filters$ = this.filterBehaviorSubject.asObservable();
  public selectedUserForAd$ =
    this.selectedUserForAdBehaviorSubject.asObservable();

  // create the parameters observable that looks for changes in page, startDate, endDate, etc
  public params$ = combineLatest([
    this.pageBehaviorSubject, // add debounce if we need to wait for user input ex: .pipe(debounceTime(300)),
    this.sortBehaviorSubject,
    this.searchBehaviorSubject.pipe(debounceTime(300)),
    this.filterBehaviorSubject.pipe(debounceTime(50)),
    this.reloadBehaviorSubject,
  ]).pipe(
    distinctUntilChanged((previous, current) => {
      // if the values coming down this pipe are the same, don't continue the pipe
      return JSON.stringify(previous) === JSON.stringify(current);
    }),
    map(([page, sort, search, filters, reload]) => {
      let _orderby = `Detail/${sort.active} ${sort.direction}`;
      if (sort.active == 'DivisionNames' || sort.active == 'UserTypeName') {
        _orderby = `${sort.active} ${sort.direction}`;
      }

      // set the query string parameters
      let params: HttpParams = new HttpParams({
        fromObject: {
          $expand: 'Detail',
          $skip: page.pageIndex * page.pageSize,
          $top: page.pageSize,
          $orderby: _orderby,
          $count: true,
        },
      });

      // if there is a search, add the search to the parameters
      if (search.length) {
        params = params.append('$search', `"${search}"`);
      }

      // if there are filters, add the filters to the parameters
      if (filters.length > 0) {
        params = this.buildFilterParams(filters, params);
      }

      return params;
    })
  );

  // create the parameters observable that looks for changes in page, startDate, endDate, etc
  public dialogParams$ = combineLatest([
    this.dialogPageBehaviorSubject, // add debounce if we need to wait for user input ex: .pipe(debounceTime(300)),
    this.sortBehaviorSubject,
    this.dialogSearchBehaviorSubject.pipe(debounceTime(300)),
    this.reloadBehaviorSubject,
  ]).pipe(
    distinctUntilChanged((previous, current) => {
      // if the values coming down this pipe are the same, don't continue the pipe
      return JSON.stringify(previous) === JSON.stringify(current);
    }),
    map(([page, sort, search, reload]) => {
      let _orderby = `Detail/${sort.active} ${sort.direction}`;
      if (sort.active == 'DivisionNames' || sort.active == 'UserTypeName') {
        _orderby = `${sort.active} ${sort.direction}`;
      }

      // set the query string parameters
      let params: HttpParams = new HttpParams({
        fromObject: {
          $expand: 'Detail',
          $skip: page.pageIndex * page.pageSize,
          $top: page.pageSize,
          $orderby: _orderby,
          $count: true,
        },
      });

      // if there is a search, add the search to the parameters
      if (search.length) {
        params = params.append('$search', `"${search}"`);
      }

      return params;
    })
  );

  // create the users observable that calls http get when any of our parameters change
  private dialogUsersResponse$ = this.dialogParams$.pipe(
    tap(() => this.loadingBehaviorSubject.next(true)), // set isLoading to true
    switchMap((_params) =>
      this.httpClient.get(
        `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/AuthUserDomains/GetUserWithNoAd`,
        { params: _params }
      )
    ),
    tap(() => this.loadingBehaviorSubject.next(false)), // set isLoading to false
    shareReplay(1) // make sure all subscriptions share the same http call (otherwise there will be a http call for each subscription)
  );

  // user listing
  public dialogUsers$: Observable<UserDomain<TUser>[]> =
    this.dialogUsersResponse$.pipe(map((res: any) => res.value));

  // total number of user records based on filtering
  public dialogTotalRecords$: Observable<number> =
    this.dialogUsersResponse$.pipe(map((res: any) => res['@odata.count']));

  // set the current page
  dialogPage(page: any) {
    this.dialogPageBehaviorSubject.next(page);
  }

  // sets the search phrase
  dialogSearch(search: string) {
    this.dialogSearchBehaviorSubject.next(search);
    this.dialogPageBehaviorSubject.next(DEFAULT_PAGING);
  }

  clearUserForAd() {
    this.selectedUserForAdBehaviorSubject.next([]);
  }

  setUserForAd(user: UserDomain<TUser>[]) {
    this.selectedUserForAdBehaviorSubject.next(user);
  }

  public uploadparams$ = combineLatest([
    this.pageBehaviorSubject, // add debounce if we need to wait for user input ex: .pipe(debounceTime(300)),
    this.sortBehaviorSubject,
    this.searchBehaviorSubject.pipe(debounceTime(300)),
    this.filterBehaviorSubject.pipe(debounceTime(50)),
    this.reloadBehaviorSubject,
  ]).pipe(
    distinctUntilChanged((previous, current) => {
      // if the values coming down this pipe are the same, don't continue the pipe
      return JSON.stringify(previous) === JSON.stringify(current);
    }),
    map(([page, sort, search, filters, reload]) => {
      // set the query string parameters
      let params: HttpParams = new HttpParams({
        fromObject: {
          $expand: 'Detail',
          $skip: page.pageIndex * page.pageSize,
          $top: page.pageSize,
          $orderby: `Detail/${sort.active} ${sort.direction}`,
          $count: true,
        },
      });

      // if there is a search, add the search to the parameters
      if (search.length) {
        params = params.append('$search', `"${search}"`);
      }

      // if there are filters, add the filters to the parameters
      if (filters.length > 0) {
        params = this.buildFilterParams(filters, params);
      }

      return params;
    })
  );

  // create the users observable that calls http get when any of our parameters change
  private usersResponse$ = this.params$.pipe(
    tap(() => this.loadingBehaviorSubject.next(true)), // set isLoading to true
    switchMap((_params) =>
      this.httpClient.get(
        `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/AuthUserDomains`,
        { params: _params }
      )
    ),
    tap(() => this.loadingBehaviorSubject.next(false)), // set isLoading to false
    shareReplay(1) // make sure all subscriptions share the same http call (otherwise there will be a http call for each subscription)
  );

  // user listing
  public users$: Observable<UserDomain<TUser>[]> = this.usersResponse$.pipe(
    map((res: any) => res.value)
  );

  ///TODO: remove this observable
  private allusersResponse$ = this.params$.pipe(
    tap(() => this.loadingBehaviorSubject.next(true)), // set isLoading to true
    switchMap((_params) =>
      this.httpClient.get(
        `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/AuthUserDomains/GetAll`,
        { params: _params }
      )
    ),
    tap(() => this.loadingBehaviorSubject.next(false)), // set isLoading to false
    shareReplay(1) // make sure all subscriptions share the same http call (otherwise there will be a http call for each subscription)
  );

  private uploadUsersResponse$ = this.uploadparams$.pipe(
    tap(() => this.loadingBehaviorSubject.next(true)), // set isLoading to true
    switchMap((_params) =>
      this.httpClient.get(
        `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/AuthUserDomains`,
        { params: _params }
      )
    ),
    tap(() => this.loadingBehaviorSubject.next(false)), // set isLoading to false
    shareReplay(1) // make sure all subscriptions share the same http call (otherwise there will be a http call for each subscription)
  );

  // user listing
  public uploadusers$: Observable<UserDomain<TUser>[]> =
    this.uploadUsersResponse$.pipe(map((res: any) => res.value));

  // user listing
  public allusers$: Observable<UserDomain<TUser>[]> = this.usersResponse$.pipe(
    map((res: any) => res.value)
  );

  // user listing
  public usersList$: Observable<UserDomain<TUser>[]> =
    this.allusersResponse$.pipe(map((res: any) => res.value));

  // total number of user records based on filtering
  public totalRecords$: Observable<number> = this.usersResponse$.pipe(
    map((res: any) => res['@odata.count'])
  );

  // total number of user records
  public totalUsersRecords$: Observable<number> = this.allusersResponse$.pipe(
    map((res: any) => res['@odata.count'])
  );

  // total number of user records
  public uploadUsersRecords$: Observable<number> =
    this.uploadUsersResponse$.pipe(map((res: any) => res['@odata.count']));

  // set the current page
  page(page: any) {
    this.pageBehaviorSubject.next(page);
  }

  // sets the sort property and order
  sort(sort: any) {
    this.sortBehaviorSubject.next(sort);
  }

  // sets the search phrase
  search(search: string) {
    const page = this.pageBehaviorSubject.value;
    page.pageIndex = 0;
    page.previousPageIndex = 0;
    this.searchBehaviorSubject.next(search);
    this.pageBehaviorSubject.next(page);
  }

  // reloads/refreshes the user listing
  reload() {
    // reload the user data
    this.reloadBehaviorSubject.next(uuidv4());
  }

  // changes the view mode of the user listing
  toggleViewMode(mode: string) {
    this.viewModeBehaviorSubject.next(mode);
    if (mode.toUpperCase() !== 'EXPANSION') {
      this.pageBehaviorSubject.next(DEFAULT_PAGING);
    }
  }

  // gets an user by id
  getUser(userId: string): Observable<TUser> {
    const url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/AuthUserDomains/${userId}?$expand=Detail`;
    return this.httpClient.get<TUser>(url);
  }

  // an user domain by id
  getUserDomain(userId: string): Observable<UserDomain<TUser>> {
    const url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/AuthUserDomains/${userId}?$expand=Detail`;
    return this.httpClient.get<UserDomain<TUser>>(url);
  }

  // up-serts user
  saveUser(user: UserDomain<TUser>, pathName: string) {
    let url = `${environment.pr1ApiUrl}/${pathName}/${this.organizationService.organization?.version}/AuthUserDomains/`;
    if (!user.Detail.Id || user.Detail.Id === '0') {
      // create new record
      return this.httpClient.post(url, user);
    } else {
      // edit existing record
      url += `${user.Detail.Id}`;
      return this.httpClient.put(url, user);
    }
  }

  // up-serts user domain
  saveUserDomain(userDomain: UserDomain<TUser>) {
    let url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/AuthUserDomains/`;

    if (!userDomain.Detail.Id || userDomain.Detail.Id === '0') {
      // clear the Detail Id
      userDomain.Detail.Id = '';
      // create new record
      return this.httpClient.post(url, userDomain);
    } else {
      // edit existing record
      url += `${userDomain.Detail.Id}`;
      return this.httpClient.put(url, userDomain);
    }
  }

  // deletes an user by id
  deleteUser(id: string) {
    const url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/AuthUserDomains/${id}`;
    return this.httpClient.delete(url);
  }

  deleteUsers(users: any) {
    const url = `${environment.pr1ApiUrl}/${
      this.organizationService.organization?.apiPath
    }/${
      this.organizationService.organization?.version
    }/AuthUserDomains/DeleteUsers?${this.prepareAssetStringtoDelete(users)}`;
    return this.httpClient.post(url, users);
  }

  prepareAssetStringtoDelete(users: any) {
    let urlParams = '';
    if (users && users.length > 0) {
      for (let index = 0; index <= users.length - 1; index++) {
        urlParams += `keys=${users[index]?.Detail?.Id}`;
        if (index != users.length - 1) {
          urlParams += '&';
        }
      }
    }
    return urlParams;
  }

  // adds filters to the user listing
  addFilters(newFilters: Filter[]) {
    const filters = this.filterBehaviorSubject.value;

    newFilters.forEach((filter) => {
      if (
        filters.findIndex(
          (item) =>
            item.fieldName.toLowerCase() === filter.fieldName.toLowerCase() &&
            item.value.toLowerCase() === filter.value.toLowerCase()
        ) === -1
      ) {
        filters.push(filter);
      }
    });

    this.filterBehaviorSubject.next(filters);
  }

  // removes a filter from the user listing
  removeFilter(filter: Filter) {
    const filters = this.filterBehaviorSubject.value.filter(
      (item) => item !== filter
    );
    this.filterBehaviorSubject.next(filters);
  }

  // removes a filter from the user listing
  removeFilterByFieldName(fieldName: string) {
    const filters = this.filterBehaviorSubject.value.filter(
      (item) => item.fieldName.toLowerCase() !== fieldName.toLowerCase()
    );
    this.filterBehaviorSubject.next(filters);
  }

  // removes all filters for the user listing
  clearFilters() {
    this.filterBehaviorSubject.next([]);
  }

  // build the list of filter parameters
  private buildFilterParams(filters: Filter[], params: HttpParams): HttpParams {
    // get the division id filters
    const divisionIdFilters = filters.filter(
      (item) => item.fieldName.toLowerCase() === 'divisionid'
    );

    // loop through the division id filters and add filter statement to param
    divisionIdFilters.forEach((filter) => {
      params = params.append('divisionIds', filter.value);
    });

    // get the user type id filter
    const userTypeIdFilter = filters.filter(
      (item) => item.fieldName.toLowerCase() === 'usertypeid'
    );

    // loop through the user type id filter and add filter statement to param
    if (userTypeIdFilter.length > 0) {
      const userType: any = filters.filter(
        (item) => item.fieldName.toLowerCase() === 'usertypeid'
      )[0].value;
      params = params.append('userTypeId', userType.Id);
    }

    // return the params
    return params;
  }

  downloadUsersJSON() {
    const url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/AuthUserDomains/EtlExport`;
    window.open(url, '_blank');
  }

  downloadUsersCSV() {
    const url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/AuthUserDomains/Export`;
    window.open(url, '_blank');
  }

  cloneUser(
    sourceId: string,
    includeVersions: boolean,
    keepStatus: boolean,
    currentObj: any
  ) {
    const url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/AuthUserDomains/CloneUser`;
    return this.httpClient.post<any>(url, currentObj, {
      params: {
        sourceId: sourceId,
        includeVersions: includeVersions,
        keepStatus: keepStatus,
      },
    });
  }

  deactivateUser(AuthUsers: any) {
    const url = `${environment.pr1ApiUrl}/${
      this.organizationService.organization?.apiPath
    }/${
      this.organizationService.organization?.version
    }/AuthUserDomains/DeActivateUsers?${this.prepareAssetStringtoDelete(
      AuthUsers
    )}`;
    return this.httpClient.post(url, AuthUsers);
  }

  activateUser(AuthUsers: any) {
    const url = `${environment.pr1ApiUrl}/${
      this.organizationService.organization?.apiPath
    }/${
      this.organizationService.organization?.version
    }/AuthUserDomains/ActivateUsers?${this.prepareAssetStringtoDelete(
      AuthUsers
    )}`;
    return this.httpClient.post(url, AuthUsers);
  }

  getUserRoles(orgPath: string): Observable<any> {
    const url = `${environment.pr1ApiUrl}/${orgPath}/${this.organizationService.organization?.version}/AuthUserDomains/GetRolesList`;
    return this.httpClient.get<any>(url);
  }

  getDivisions(orgPath: string): Observable<any> {
    const url = `${environment.pr1ApiUrl}/${orgPath}/${this.organizationService.organization?.version}/Divisions`;
    return this.httpClient.get<any>(url);
  }

  getEventTypes(orgPath: string): Observable<any> {
    const url = `${environment.pr1ApiUrl}/${orgPath}/${this.organizationService.organization?.version}/EventTypes`;
    return this.httpClient.get<any>(url);
  }

  getChannels(orgPath: string): Observable<any> {
    const url = `${environment.pr1ApiUrl}/${orgPath}/${this.organizationService.organization?.version}/Channels`;
    return this.httpClient.get<any>(url);
  }

  getProductCategoriesList(orgPath: string): Observable<any> {
    const url = `${environment.pr1ApiUrl}/${orgPath}/${this.organizationService.organization?.version}/ProductCategories`;
    return this.httpClient.get<any>(url);
  }

  getAuth0Orgs(): Observable<any> {
    const url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/AuthUserDomains/GetAuth0Orgs`;
    return this.httpClient.get<any>(url);
  }

  public getParentLevelCategories() {
    return this.httpClient.get(
      `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/ProductCategoryDomains`,
      {
        params: {
          $expand: 'Detail',
          $skip: 0,
          $top: 1000,
          $orderby: 'ProductCategoryName asc',
          $count: true,
        },
      }
    );
  }
}
