<h1>User Management</h1>

<form *ngIf="user$ | async as T; else loading" [formGroup]="formGroup" novalidate>
    <mat-card appearance="outlined">
        <mat-card-content fxLayout="column">

            <h2>{{formGroup.controls.Detail.controls.FirstName.value == '' ? "User" :
                formGroup.controls.Detail.controls.FirstName.value}}</h2>

            <div formGroupName="Detail" fxLayout="row wrap" fxLayoutGap.gt-sm="16px" fxLayout.lt-md="column">

                <mat-form-field appearance="outline" fxFlex="24" fxFlex.lt-md="auto">
                    <mat-label>First Name</mat-label>
                    <input matInput type="text" formControlName="FirstName" />
                    <mat-error *ngIf="formGroup.controls.Detail.controls.FirstName.hasError('required')">
                        First Name is required
                    </mat-error>
                    <mat-error *ngIf="formGroup.controls.Detail.controls.FirstName.hasError('invalid')">
                        {{formGroup.controls.Detail.controls.FirstName.getError('invalid')}}
                    </mat-error>
                    <mat-error *ngIf="formGroup.controls.Detail.controls.FirstName.status=='INVALID'">
                        {{formGroup.controls.Detail.controls.FirstName.errors!['maxlength'] && 'Maximum length can be' +
                        formGroup.controls.Detail.controls.FirstName.errors!['maxlength']['requiredLength'] + '.'}}
                    </mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline" fxFlex="24" fxFlex.lt-md="auto">
                    <mat-label>Last Name</mat-label>
                    <input matInput type="text" formControlName="LastName" />
                    <mat-error *ngIf="formGroup.controls.Detail.controls.LastName.hasError('required')">
                        Last Name is required
                    </mat-error>
                    <mat-error *ngIf="formGroup.controls.Detail.controls.LastName.hasError('invalid')">
                        {{formGroup.controls.Detail.controls.LastName.getError('invalid')}}
                    </mat-error>
                    <mat-error *ngIf="formGroup.controls.Detail.controls.LastName.status=='INVALID'">
                        {{formGroup.controls.Detail.controls.LastName.errors!['maxlength'] && 'Maximum length can be' +
                        formGroup.controls.Detail.controls.LastName.errors!['maxlength']['requiredLength'] + '.'}}
                    </mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline" fxFlex="24" fxFlex.lt-md="auto">
                    <mat-label>Email</mat-label>
                    <input matInput type="text" formControlName="Email" />
                    <mat-error *ngIf="formGroup.controls.Detail.controls.Email.hasError('required')">
                        Email is required
                    </mat-error>
                    <mat-error
                        *ngIf="!formGroup.controls.Detail.controls.Email.hasError('required') && formGroup.controls.Detail.controls.Email.status=='INVALID'">
                        Please enter valid email
                    </mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline" fxFlex="24" fxFlex.lt-md="auto">
                    <mat-label>Phone Number</mat-label>
                    <input matInput type="text" formControlName="PhoneNumber" />
                    <mat-error *ngIf="formGroup.controls.Detail.controls.PhoneNumber.hasError('invalid')">
                        {{formGroup.controls.Detail.controls.PhoneNumber.getError('invalid')}}
                    </mat-error>
                    <mat-error *ngIf="formGroup.controls.Detail.controls.PhoneNumber.status=='INVALID'">
                        {{formGroup.controls.Detail.controls.PhoneNumber.errors!['maxlength'] && 'Maximum length can be'
                        +
                        formGroup.controls.Detail.controls.PhoneNumber.errors!['maxlength']['requiredLength'] + '.'}}
                        {{formGroup.controls.Detail.controls.PhoneNumber.errors!['pattern'] && 'PhoneNumber may only
                        contain
                        alphanumeric characters.'}}
                    </mat-error>
                </mat-form-field>
            </div>


        </mat-card-content>

    </mat-card>
    <div formArrayName="associated_orgs" class="add-user-form-container">
        <app-add-user-organization-details *ngFor="let org of associated_orgs.controls"
            [formGroup]="org"></app-add-user-organization-details>
    </div>
    <mat-card-actions class="card-actions">
        <div fxLayout="row" [fxLayoutAlign]="userId != '0'?'space-between':'end'" class="mat-card-actions-details">
            <button mat-raised-button type="button" color="warn" (click)="deleteRecord()" *ngIf="userId != '0'">
                Delete
            </button>
            <div>
                <button mat-stroked-button type="reset" (click)="cancel()">
                    Cancel
                </button>
                <button mat-raised-button type="button" (click)="save() " [disabled]="formGroup.invalid" color="accent">
                    Save
                </button>
            </div>
        </div>
    </mat-card-actions>
</form>

<ng-template #loading>
    <mat-progress-bar mode="indeterminate" color="accent"></mat-progress-bar>
</ng-template>