import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthorizationService {
  // initialize behavior subjects
  private permissionsBehaviorSubject = new BehaviorSubject(
    null as string[] | null | undefined
  );
  private pemissionsAssigned: string[] | undefined | null;

  constructor() {}

  get permissions(): string[] | null {
    return this.pemissionsAssigned ? this.pemissionsAssigned.slice() : null;
  }

  setPermissions(permissions: string[]): void {
    this.pemissionsAssigned = permissions;
  }

  checkAccess(appAuthorizeControl: any): boolean {
    const permissions: string[] = this.permissions as string[];
    if (permissions && permissions.length > 0) {
      let projectPermissions: string[] = [];
      permissions.filter((x: string) => {
        const filterdValue = this.filterPermissions(x, appAuthorizeControl);
        if (filterdValue) {
          projectPermissions.push(filterdValue);
        }
      });
      if (projectPermissions && projectPermissions.length > 0) {
        return true;
      }
    }
    return false;
  }

  filterPermissions(x: string, appAuthorizeControl: string): string {
    const currentValue = x.split(':');
    const currentControlValue = appAuthorizeControl?.split('||');
    if (
      currentValue &&
      currentValue.length > 2 &&
      currentControlValue.length > 1 &&
      currentValue[2] === environment.appKey &&
      currentValue[0] === currentControlValue[0] &&
      currentValue[1] === currentControlValue[1]
    ) {
      return x;
    }
    return '';
  }

  /** Code used for restrict control directive */
  /**
  public checkAccess(authorizationString: string): boolean {
    const permissions: string[] = this.permissions as string[];
    if (permissions && permissions.length > 0) {
      let projectPermissions: string[] = [];
      permissions.forEach((x: string) => {
        const filterdValue = this.filterPermissions(x, authorizationString);
        if (filterdValue) {
          projectPermissions.push(filterdValue);
        }
      });
      if (
        !projectPermissions ||
        (projectPermissions && projectPermissions.length == 0)
      ) {
        return false;
      } else {
        return true;
      }
    }
    return false;
  }

  filterPermissions(
    x: string,
    authorizationString: string | undefined
  ): string {
    const currentValue = x.split(':');
    let currentControlValue;
    currentControlValue = authorizationString!.split('||');
    if (this.hasOnlyViewPermission(currentControlValue, currentValue)) {
      return '';
    }

    if (this.hasEditPermission(currentControlValue, currentValue)) {
      return x;
    }

    if (this.hasAdminPermissions(currentControlValue, currentValue)) {
      return x;
    }

    return '';
  }

  hasOnlyViewPermission(currentControlValue: string[], currentValue: string[]) {
    if (!currentValue || !currentControlValue) {
      return false;
    }
    if (
      currentValue[0] == 'view' &&
      currentControlValue[0] == 'view' &&
      currentValue?.includes(currentControlValue[1])
    ) {
      return true;
    }
    return false;
  }

  hasEditPermission(currentControlValue: string[], currentValue: string[]) {
    if (!currentValue || !currentControlValue) {
      return false;
    }
    if (
      currentValue[0] == 'edit' &&
      (currentControlValue[0] == 'edit' || currentControlValue[0] == 'view') &&
      currentValue?.includes(currentControlValue[1])
    ) {
      return true;
    }
    return false;
  }

  hasAdminPermissions(currentControlValue: string[], currentValue: string[]) {
    if (!currentValue || !currentControlValue) {
      return false;
    }
    if (
      currentValue[0] == 'admin' &&
      currentValue?.includes(currentControlValue[1])
    ) {
      return true;
    }
    return false;
  }*/
}
