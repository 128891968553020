<ng-container *ngIf="viewModel$ | async as viewModel; else loading">
    <div class="bg-white">
        <h2>{{getOfferName(viewModel?.coupons)}} </h2>
        <form [formGroup]="filtersForm" novalidate>
    
            <!-- actions bar -->
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px" fxLayout.lt-md="column"
                fxLayoutAlign.lt-md="start start" fxLayoutGap.lt-md="4px" class="actions-bar">
                <button appAuthorizeControl="edit||offermang" type="button" mat-raised-button color="accent" matTooltip="Add new record" (click)="openDialog()">
                    <fa-icon [icon]="['fas', 'plus']"></fa-icon>Add Product
                </button>
    
                <button type="button"
                *ngIf="(viewModel.viewMode === 'TABLE' && productsTable?.getSelectedSectionRecords()?.length > 0) || (viewModel.viewMode === 'CARDS' && productsCards?.getSelectedSectionRecords()?.length > 0) && canEdit()"
                mat-raised-button [matMenuTriggerFor]="menu" matTooltip="Actions">
                <mat-icon>more_vert</mat-icon>Actions
            </button>
            <mat-menu #menu="matMenu">
                <button type="button" mat-menu-item
                    *ngIf="(viewModel.viewMode === 'TABLE' && productsTable?.getSelectedSectionRecords()?.length > 0) || (viewModel.viewMode === 'CARDS' && productsCards?.getSelectedSectionRecords()?.length > 0)">
                    {{ viewModel.viewMode === 'TABLE' ? productsTable?.getSelectedSectionRecords()?.length :
                    productsCards?.getSelectedSectionRecords()?.length}}
                    Selected</button>
                <button appAuthorizeControl="edit||offermang" type="button" mat-menu-item (click)="deleteSelectedRecords()"><mat-icon>delete</mat-icon>Delete</button>        
              </mat-menu>
    
                <mat-form-field appearance="outline" class="mat-form-field-no-padding">
                    <mat-icon matPrefix>search</mat-icon>
                    <mat-label>Search Products</mat-label>
                    <input matInput formControlName="search" type="text" (input)="onSearch($event)" placeholder="Search"
                        #searchInput />
                    <button type="button" matSuffix mat-icon-button aria-label="Clear" *ngIf="searchInput.value"
                        (click)="clearSearch()">
                        <mat-icon>close</mat-icon>
                    </button>
                </mat-form-field>
                <span class="menu-spacer"></span>
    
                <mat-button-toggle-group #group="matButtonToggleGroup" value="cards">
                    <mat-button-toggle value="cards" matTooltip="Card view" aria-label="Card view"
                        (click)="toggleView('CARDS')">
                        <mat-icon>grid_view</mat-icon>
                    </mat-button-toggle>
                    <mat-button-toggle value="table" matTooltip="Table view" aria-label="Table view"
                        (click)="toggleView('TABLE')">
                        <mat-icon>table_view</mat-icon>
                    </mat-button-toggle>
                </mat-button-toggle-group>
    
            </div>
    
            <mat-sidenav-container>
    
                <!--main content-->
                <!--cards view-->
                <app-coupon-products-cards #productsCards *ngIf="viewModel.viewMode === 'CARDS'"></app-coupon-products-cards>
                <!--table view-->
                <app-coupon-products-table #productsTable *ngIf="viewModel.viewMode === 'TABLE'"></app-coupon-products-table>

            </mat-sidenav-container>
            <mat-card-actions>
                <div fxLayout="row" fxLayoutAlign="end" class="mat-card-actions-details" [ngClass]="{'flex-row-reverse': canEdit() == false}">
                    <button mat-stroked-button type="reset" color="accent" (click)="cancel()">
                        <span *ngIf="canEdit()">Cancel</span>
                        <span *ngIf="!canEdit()">Close</span>
                    </button>
                </div>
            </mat-card-actions>
        </form>
    </div>
    </ng-container>
    
    <ng-template #loading>
        <mat-progress-bar mode="indeterminate" color="accent"></mat-progress-bar>
    </ng-template>
    <router-outlet></router-outlet>
