import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogData } from '../..';

@Component({
  selector: 'app-error-dialog',
  templateUrl: './error-dialog.component.html',
  styleUrls: ['./error-dialog.component.scss'],
})
export class ErrorDialogComponent {
  showErrorContext = true;
  constructor(@Inject(MAT_DIALOG_DATA) public dialogData: DialogData) {
    if (dialogData?.error?.hideErrorContext == true) {
      this.showErrorContext = false;
    }
  }
}
