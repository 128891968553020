import { User } from "..";


export interface UserDomain<TUser extends User> {
  Detail: TUser;
  EventTypeIds: string[];
  EventTypeNames?: string[];
  ChannelIds: string[];
  ChannelNames?: string[];
  Division: string;
  DomainId: string;
}
