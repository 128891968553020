import { Component, OnInit } from '@angular/core';
import { SpinnerOverlayService } from '../..';

@Component({
  selector: 'app-spinner-overlay',
  templateUrl: './spinner-overlay.component.html',
  styleUrls: ['./spinner-overlay.component.scss'],
})
export class SpinnerOverlayComponent implements OnInit {
  isLoading$ = this.spinnerOverlayService.isLoading$;

  constructor(private spinnerOverlayService: SpinnerOverlayService) {}

  ngOnInit(): void {}
}
