import { Injectable } from '@angular/core';
import * as dayjs from 'dayjs';

@Injectable({
  providedIn: 'root'
})
export class DateConversionService {

  constructor() { }

  encodeDate(date: Date | null) {
    const formatDate = dayjs(date).format('YYYY-MM-DD 00:00:00');
    let encodedDate: Date;
    let _date: number = new Date(formatDate).getTime();
    let offsetValue: any = new Date().getTimezoneOffset() * 60 * 1000;

    _date = _date - offsetValue

    encodedDate = new Date(_date);
    // console.log(date, encodedDate)
    return encodedDate
  }

  decodeDate(date: string) {
    let decodedDate: Date;
    let _date: number = new Date(date).getTime();
    let offsetValue: any = new Date().getTimezoneOffset() * 60 * 1000;

    _date = _date + offsetValue

    decodedDate = new Date(_date);
    // console.log(date, decodedDate)
    return decodedDate
  }
}
