<form [formGroup]="sortForm" (ngSubmit)="onSubmit()" novalidate>
    <mat-dialog-content>

        <mat-radio-group formControlName="sortBy" class="sort-radio-group" aria-label="Sorting By">
            <mat-radio-button class="sort-radio-button" *ngFor="let sort of sortBy" [value]="sort.value"
                [labelPosition]="'before'" [aria-label]="sort.name">
                {{sort.name}}
            </mat-radio-button>
        </mat-radio-group>

        <mat-button-toggle-group formControlName="direction" aria-label="direction">
            <mat-button-toggle *ngFor="let direction of sortDirection" [value]="direction.value"
                [aria-label]="direction.name">
                <fa-icon [icon]='direction.icon'></fa-icon>{{direction.name}}
            </mat-button-toggle>
        </mat-button-toggle-group>

    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <button mat-raised-button type="submit" [disabled]="sortForm.invalid" color="accent">Apply</button>
    </mat-dialog-actions>
</form>