import { Logger, OrganizationService } from 'src/app/@shared';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { MatDialog } from '@angular/material/dialog';
import { ActivityLogDetailsDialogComponent } from 'src/app/@shared/components/activity-log-details-dialog/activity-log-details-dialog.component';
import {
  Offer,
  OfferDomain,
  OfferPromo,
  OfferPromoService,
  OfferService,
  OfferTag,
  PromoDomain,
} from '../..';
import { Observable, Subscription, of, tap } from 'rxjs';
import { AuthorizationService } from 'src/app/@shared/services/authorization.service';
import { Status } from '../../models/offer-promo-status';
import * as dayjs from 'dayjs';

const log = new Logger('OfferAssetsComponent');

@Component({
  selector: 'app-offer-activitylog',
  templateUrl: './offer-activity-log.component.html',
  styleUrls: ['./offer-activity-log.component.scss'],
})
export class OfferActivityLogComponent<
  T extends Offer,
  Tag extends OfferTag,
  TOfferDomain extends OfferDomain<Offer>,
  TOfferPromo extends OfferPromo,
  TEvent extends Event,
  TPromoDomain extends PromoDomain<OfferPromo>
> implements OnInit, OnDestroy
{
  assetsUrl$ = this.organizationService.assetsUrl$;
  offer$: Observable<OfferDomain<T>> = of({} as OfferDomain<T>);

  displayedColumns: string[] = ['activity', 'user', 'time', 'details'];
  public activityDataSource: any = [];
  public offerId: string = '';
  subscription$ = new Subscription();
  readOnlyMode: boolean = false;
  offerDetails: any;

  constructor(
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private offerService: OfferService<Offer, OfferDomain<Offer>>,
    private organizationService: OrganizationService,
    private authorizationService: AuthorizationService,
    private offerpromoService: OfferPromoService<TOfferPromo, TPromoDomain>
  ) {}

  ngOnInit(): void {
    this.offerId = this.route.snapshot.params['offerId'];
    this.offerpromoService.eventId = this.route.snapshot.params['eventId'];
    this.subscription$.add(
      this.offerService.getOfferLogs(this.offerId).subscribe((logs: any) => {
        this.activityDataSource = (logs as any[]).map((l) => ({
          Activity: l.Activity,
          User: l.UserName,
          Time: l.ModifiedDate,
          Details: l.OldNewPropertyValue,
        }));
      })
    );

    this.getDataById();

    this.subscription$.add(
      this.offerService.newVersion$.subscribe((res) => {
        if (res) {
          this.getDataById();
        }
      })
    );
  }

  viewDetails(i: number) {
    this.dialog.open(ActivityLogDetailsDialogComponent, {
      data: {
        details: this.activityDataSource[i]?.Details,
      },
      disableClose: true,
    });
  }

  public getImageSrc(assetsUrl: any, assets: any) {
    let imagesrc = '';
    if (assets.Type === 'application/pdf') {
      imagesrc = './assets/images/pdf.png';
    } else if (assets.Type.indexOf('audio') > -1) {
      imagesrc = './assets/images/mp3.jpg';
    } else if (assets.Type.indexOf('video') > -1) {
      imagesrc = './assets/images/mp4.png';
    } else if (assets.Type.indexOf('text') > -1) {
      imagesrc = './assets/images/text.png';
    } else {
      imagesrc = assetsUrl + '/' + assets.FileName;
    }
    return imagesrc;
  }

  getDataById() {
    if (this.offerId && this.offerId !== '0') {
      this.offer$ = this.offerService.getOffer(this.offerId).pipe(
        tap((offer: any) => {
          this.offerDetails = offer;
          this.offerService.currentRecord = JSON.parse(JSON.stringify(offer));
          if (offer && offer.Detail && offer.Detail.StartDate) {
            offer.Detail.StartDate = new Date(offer.Detail.StartDate);
          }
          if (offer && offer.Detail && offer.Detail.EndDate) {
            offer.Detail.EndDate = new Date(offer.Detail.EndDate);
          }
          const newVersionName = this.offerService.getNewVersion;
          if (newVersionName) {
            offer.Detail.ClientKey = '';
            // this.offerId = '0';
            offer.Detail.OfferVariantName = newVersionName;
          }
        })
      );
    }
  }

  canEdit(): boolean {
    const hasAccess =
      this.authorizationService.checkAccess('edit||offermang') &&
      this.readOnlyMode != true;

    return hasAccess;
  }

  updateTableStatus(status: Status) {
    let selectedRecordsDetails: any;
    // selectedRecordsDetails = [JSON.parse(JSON.stringify(this.offerResponse))];
    const responseOffers =
      this.offerService.selection.selected &&
      this.offerService.selection.selected.length > 0
        ? this.offerService.selection.selected
        : [this.offerDetails];
    selectedRecordsDetails = responseOffers;
    this.updateRecordsStatus(selectedRecordsDetails, status);
  }

  updateRecordsStatus(selectedRecordsDetails: Array<any>, status: any) {
    if (selectedRecordsDetails && selectedRecordsDetails.length > 0) {
      const recordstoupdate = selectedRecordsDetails.map((x: any) => {
        return {
          Id: x.Id,
          ClientKey: x.ClientKey,
          UserId: x.UserId,
          Name: x.Name,
          Headline: x.Headline,
          BodyCopy: x.BodyCopy,
          Disclaimer: x.Disclaimer,
          ClientComment: x.ClientComment,
          StartDate: dayjs(x.StartDate).format('MM/DD/YYYY'),
          EndDate: dayjs(x.EndDate).format('MM/DD/YYYY'),
          Rank: x.Rank,
          TargetURL: x.TargetURL,
          Status: status,
          Version: x.Version,
          Label: 'OFFER',
        };
      });

      this.subscription$.add(
        this.offerpromoService.updateStatus(recordstoupdate).subscribe({
          next: () => {
            this.getDataById();
          },
        })
      );
    }
  }

  ngOnDestroy() {
    this.subscription$.unsubscribe();
  }
}
