export interface OfferAsset {
  DomainId?: string;
  AssetTagIds?: string[];
  ProductId?: string;
  EventId?: null;
  OfferId?: string;
  ProductBrandId?:string;
  AssetName?: string;
  DateAdded?: string;
  Type?: string;
  AssetGroupName?: string;
  File?: any;
  PromoId?: any;
  Rank?: number | null;
  Detail?: any;
  }
