import { Component } from '@angular/core';

@Component({
  selector: 'app-coupon',
  templateUrl: 'coupon.component.html',
  styleUrls: ['coupon.component.scss']
})
export class CouponComponent {
  activeNavItem: string = 'details';

  constructor() {
  }

  setCurrentActiveItem(item: string) {
    this.activeNavItem = item;
  }
}
