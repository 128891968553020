<mat-dialog-content class="mat-typography">
  <div
    fxLayout="row wrap"
    fxLayoutGap.gt-sm="16px"
    fxLayout.lt-md="column"
    class="add-workflow-container"
  >
    <h2 style="padding-top: 0.8rem">Add Template</h2>
    <!-- <div fxLayout="row wrap" fxLayoutGap.gt-sm="16px" fxLayout.lt-md="column">
      <mat-form-field appearance="outline">
        <mat-icon matPrefix>search</mat-icon>
        <mat-label>Search Workflow Templates</mat-label>
        <input
          matInput
          type="text"
          (input)="onSearch($event)"
          placeholder="Search"
          #searchInput
        />
        <button
          type="button"
          matSuffix
          mat-icon-button
          aria-label="Clear"
          *ngIf="searchInput.value"
          (click)="clearSearch()"
        >
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
    </div> -->

    <div
      id="new-templates"
      fxLayout="row wrap"
      fxLayoutGap.gt-sm="16px"
      fxLayout.lt-md="column"
      *ngIf="viewModel$ | async as viewModel"
    >
      <table
        mat-table
        [dataSource]="viewModel.templates"
        class="mat-elevation-z8"
      >
        <!--- Note that these columns can be defined in any order.
          The actual rendered columns are set as a property on the row definition" -->

        <!-- Checkbox Column -->
        <ng-container matColumnDef="select">
          <th class="no-ellipsis" mat-header-cell *matHeaderCellDef>
            <mat-checkbox
              *ngIf="
                viewModel.templates.length > 0 &&
                !areAllTemplatesAdded(viewModel.templates)
              "
              (change)="
                $event ? toggleAllRows($event, viewModel.templates) : null
              "
              [checked]="selection.hasValue() && isAllSelected()"
              [indeterminate]="selection.hasValue() && !isAllSelected()"
              [aria-label]="checkboxLabel()"
            >
            </mat-checkbox>
          </th>
          <td class="no-ellipsis" mat-cell *matCellDef="let row">
            <mat-checkbox
              *ngIf="!checkIfTemplateAlreadyAdded(row)"
              (click)="$event.stopPropagation()"
              (change)="$event ? selection.toggle(row) : null"
              [checked]="selection.isSelected(row)"
              [aria-label]="checkboxLabel(row)"
            >
            </mat-checkbox>
          </td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="templateName">
          <th mat-header-cell *matHeaderCellDef>Template Name</th>
          <td mat-cell *matCellDef="let element">
            {{ element.Name }}
          </td>
        </ng-container>

        <!-- Weight Column -->
        <ng-container matColumnDef="templateGroup">
          <th mat-header-cell *matHeaderCellDef>Group</th>
          <td mat-cell *matCellDef="let element">
            {{ getCategoryNameFromId(element.WorkflowGroup) }}
          </td>
        </ng-container>

        <ng-container matColumnDef="templateCategory">
          <th mat-header-cell *matHeaderCellDef>Category</th>
          <td mat-cell *matCellDef="let element">
            {{ element.WorkflowCategory }}
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: displayedColumns"
          [ngClass]="{
            'already-added-template': checkIfTemplateAlreadyAdded(row)
          }"
        ></tr>
        <tr>
          <td *ngIf="!dataSource || dataSource.length == 0">
            <p><b>No Template Found</b></p>
          </td>
        </tr>
      </table>
      <div
        fxLayout="row"
        fxLayoutAlign="center center"
        *ngIf="!dataSource || dataSource.length == 0"
        style="width: 100%; padding-top: 1rem"
      >
        <p><b>No Template Found</b></p>
      </div>

      <mat-paginator
        *ngIf="viewModel.templates.length"
        style="width: 100%"
        showFirstLastButtons
        [length]="viewModel.totalRecords"
        [pageSize]="viewModel.page.pageSize"
        [pageSizeOptions]="[5, 10, 20, 50, 100, 500]"
        [pageIndex]="viewModel.page.pageIndex"
        (page)="onPage($event)"
        aria-label="Select page"
      >
      </mat-paginator>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close (click)="dialogRef.close()">
    Cancel
  </button>
  <button
    *ngIf="!allTemplatesAdded"
    mat-raised-button
    color="accent"
    [mat-dialog-close]="true"
    cdkFocusInitial
    (click)="onSave()"
  >
    Select
  </button>
</mat-dialog-actions>
