<ng-container *ngIf="viewModel$ | async as viewModel">
  <table
    mat-table
    [dataSource]="viewModel.users"
    class="mat-elevation-z8 user-management-table"
  >
    <!-- Checkbox Column -->
    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef>
        <mat-checkbox
          *ngIf="
            viewModel.users.length > 0 && !allUsersAlreadyAdded(viewModel.users)
          "
          (change)="$event ? toggleAllRows() : null"
          [checked]="selection.hasValue() && isAllSelected()"
          [indeterminate]="selection.hasValue() && !isAllSelected()"
          [aria-label]="checkboxLabel()"
        >
        </mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let row">
        <mat-checkbox
          *ngIf="!checkIfUserAlreadyAdded(row)"
          (click)="$event.stopPropagation()"
          (change)="$event ? onCheckboxClick(row) : null"
          [checked]="selection.isSelected(row)"
          [aria-label]="checkboxLabel(row)"
        >
        </mat-checkbox>
      </td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="first_name">
      <th mat-header-cell *matHeaderCellDef>First Name</th>
      <td mat-cell *matCellDef="let element">{{ element.Detail.FirstName }}</td>
    </ng-container>

    <ng-container matColumnDef="last_name">
      <th mat-header-cell *matHeaderCellDef>Last Name</th>
      <td mat-cell *matCellDef="let element">{{ element.Detail.LastName }}</td>
    </ng-container>
    
    <!-- User Role Column -->
    <ng-container matColumnDef="user_role" *ngIf="usedForWorkflow">
      <th mat-header-cell *matHeaderCellDef>User Role</th>
      <td mat-cell *matCellDef="let element">{{ element.Detail.Roles }}</td>
    </ng-container>

    <!-- Email Column -->
    <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef>Email</th>
      <td mat-cell *matCellDef="let element">{{ element.Detail.Email }}</td>
    </ng-container>

    <!-- User Status Column -->
    <ng-container matColumnDef="user_status" *ngIf="!usedForWorkflow">
      <th mat-header-cell *matHeaderCellDef>User Status</th>
      <td mat-cell *matCellDef="let element">
        {{ element.user_status }}

        <!-- Actions drop down -->
        <button
          class="user-status-toggle-button"
          mat-raised-button
          [matMenuTriggerFor]="statusMenu"
          [class]="
            element.Detail.Active ? 'active-dropdown' : 'notactive-dropdown'
          "
        >
          {{ element.Detail.Active == true ? 'Active' : 'Not Active' }}
          <mat-icon>expand_more</mat-icon>
        </button>
        <mat-menu #statusMenu="matMenu" name="userStatus" ngDefaultControl>
          <button
            mat-menu-item
            (click)="
              element.Detail.Active != true
                ? activateUserToggle(element.DomainId, element.Detail.Active)
                : null
            "
          >
            Active
          </button>
          <button
            mat-menu-item
            (click)="
              element.Detail.Active == true
                ? activateUserToggle(element.DomainId, element.Detail.Active)
                : null
            "
          >
            Not Active
          </button>
        </mat-menu>
      </td>
    </ng-container>

    <!-- Edit Link Column -->
    <ng-container matColumnDef="edit_user" *ngIf="!usedForWorkflow">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element">
        <div
          fxLayout="row"
          fxLayoutAlign="start center"
          fxLayoutGap="32px"
          class="user-table-actions"
        >
          <!-- Edit Link -->
          <div>
            <a [routerLink]="['edit', element.DomainId]">Edit</a>
          </div>

          <!-- Activate User toggle -->
          <!-- <div
            (click)="activateUserToggle(element.user_id, element.Detail.Active)"
            style="cursor: pointer"
          >
            <mat-icon *ngIf="element.Detail.Active != true" class="active-person"
              >person</mat-icon
            >
            <mat-icon *ngIf="element.Detail.Active == true" class="inactive-person"
              >person_off</mat-icon
            >
          </div> -->

          <!-- Delete user -->
          <!-- <div (click)="deleteUser(element)">
            <mat-icon style="color: #b00020; cursor: pointer">delete</mat-icon>
          </div> -->
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: displayedColumns"
      [ngClass]="{ 'already-added-user': checkIfUserAlreadyAdded(row) }"
    ></tr>
    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" [attr.colspan]="displayedColumns.length">
        No users available.
      </td>
    </tr>
  </table>

  <mat-paginator
    [ngClass]="{ 'mt-2rem': usedForWorkflow }"
    [length]="viewModel.totalRecords"
    [pageSize]="viewModel.page.pageSize"
    [pageSizeOptions]="[5, 10, 20, 50, 100, 500]"
    (page)="onPage($event)"
    *ngIf="viewModel.users.length"
    showFirstLastButtons
    [pageIndex]="viewModel.page.pageIndex"
  >
  </mat-paginator>

  <!-- <mat-paginator #paginator [pageSizeOptions]="[5, 10, 20, 50, 100,500]" (page)="onPage($event)" showFirstLastButtons>
  </mat-paginator> -->
</ng-container>
