<ng-container *ngIf="viewModel$ | async as viewModel">
    <form *ngIf="eventDomain$ | async as TEvent; else loading" [formGroup]="formGroup" novalidate>
        <div fxLayout="column" formGroupName="DestinationEvent">
            <div fxLayout="row" fxLayoutGap="30px">
                <mat-form-field appearance="outline" fxFlex="50">
                    <mat-label data-testid="EventName">Source Event</mat-label>
                    <input matInput type="text" formControlName="SourceEventName" (click)="openSourceEventsDialog()"/>
                    <mat-error *ngIf="formGroup.controls.DestinationEvent.controls.SourceEventName.hasError('required')">
                        Name is required
                    </mat-error>
                    <mat-error *ngIf="formGroup.controls.DestinationEvent.controls.SourceEventName.hasError('invalid')">
                        {{formGroup.controls.DestinationEvent.controls.SourceEventName.getError('invalid')}}
                    </mat-error>
                    <mat-error *ngIf="formGroup.controls.DestinationEvent.controls.SourceEventName.status=='INVALID'">
                        {{formGroup.controls.DestinationEvent.controls.SourceEventName.errors!['maxlength'] && 'Maximum length can be' +
                        formGroup.controls.DestinationEvent.controls.SourceEventName.errors!['maxlength']['requiredLength'] + '.'}}
                    </mat-error>
                </mat-form-field>
    
                <mat-form-field appearance="outline" fxFlex="50">
                    <mat-label data-testid="EventName">New Event Name</mat-label>
                    <input matInput type="text" formControlName="EventName" />
                    <mat-error *ngIf="formGroup.controls.DestinationEvent.controls.EventName.hasError('required')">
                        Name is required
                    </mat-error>
                    <mat-error *ngIf="formGroup.controls.DestinationEvent.controls.EventName.hasError('invalid')">
                        {{formGroup.controls.DestinationEvent.controls.EventName.getError('invalid')}}
                    </mat-error>
                    <mat-error *ngIf="formGroup.controls.DestinationEvent.controls.EventName.status=='INVALID'">
                        {{formGroup.controls.DestinationEvent.controls.EventName.errors!['maxlength'] && 'Maximum length can be' +
                        formGroup.controls.DestinationEvent.controls.EventName.errors!['maxlength']['requiredLength'] + '.'}}
                    </mat-error>
                </mat-form-field>
            </div>

            <div fxLayout="row" fxLayoutGap="30px">
                <mat-form-field appearance="outline" fxFlex="50">
                    <mat-label data-testid="StartDate">Start Date</mat-label>
                    <input matInput [matDatepicker]="startDatePicker" formControlName="StartDate">
                    <mat-error *ngIf="formGroup.controls.DestinationEvent.controls.StartDate.hasError('required')">
                        Start date is required
                    </mat-error>
                    <mat-error *ngIf="formGroup.controls.DestinationEvent.controls.StartDate.hasError('invalid')">
                        {{formGroup.controls.DestinationEvent.controls.StartDate.getError('invalid')}}
                    </mat-error>
                    <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
                    <mat-datepicker #startDatePicker></mat-datepicker>
                </mat-form-field>

                <mat-form-field appearance="outline" fxFlex="50">
                    <mat-label data-testid="EndDate">End Date</mat-label>
                    <input matInput [matDatepicker]="endDatePicker" formControlName="EndDate">
                    <mat-error *ngIf="formGroup.controls.DestinationEvent.controls.EndDate.hasError('required')">
                        End date is required
                    </mat-error>
                    <mat-error *ngIf="formGroup.controls.DestinationEvent.controls.EndDate.hasError('invalid')">
                        {{formGroup.controls.DestinationEvent.controls.EndDate.getError('invalid')}}
                    </mat-error>
                    <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
                    <mat-datepicker #endDatePicker></mat-datepicker>
                </mat-form-field>
            </div>

            <div fxLayout="row" fxLayoutGap="30px">
                <mat-form-field appearance="outline" fxFlex="50">
                    <mat-label>Client Key</mat-label>
                    <input matInput type="text" formControlName="ClientKey" />
                    <mat-error>
                        Client key is required
                    </mat-error>
                    <mat-error *ngIf="formGroup.controls.DestinationEvent.controls.ClientKey.hasError('invalid')">
                        {{formGroup.controls.DestinationEvent.controls.ClientKey.getError('invalid')}}
                    </mat-error>
                    <mat-error *ngIf="formGroup.controls.DestinationEvent.controls.ClientKey.status=='INVALID'">
                        {{formGroup.controls.DestinationEvent.controls.ClientKey.errors!['maxlength'] && 'Maximum length can be' +
                        formGroup.controls.DestinationEvent.controls.ClientKey.errors!['maxlength']['requiredLength'] + '.'}}
                    </mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline" fxFlex="50">
                    <mat-label data-testid="DivisionName">Divisions</mat-label>
                    <mat-select multiple formControlName="DivisionIds">
                    <mat-option *ngFor="let division of viewModel.divisions" [value]="division.Id">
                        {{division.DivisionName}}
                    </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
       
        <div fxLayout="row" fxLayoutGap="30px">
            <mat-form-field appearance="outline" fxFlex="50">
                <mat-label data-testid="Versions">Versions</mat-label>
                <mat-select multiple formControlName="VersionIds">
                    <mat-option *ngIf="versionList.length" [value]="'allVersions'" (click)="toggleSelectAllVersions($event)"> All Versions </mat-option>
                    <mat-option *ngFor="let version of versionList" [value]="version.Id">
                        {{version?.VersionName}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <div fxLayout="column" fxFlex="50" class="checkbox-group" >
                <mat-checkbox class="check-box" (click)="$event.stopPropagation()" formControlName="IncludeVersions"> Include
                    Versions Assigned
                </mat-checkbox>
                <mat-checkbox class="check-box" (click)="$event.stopPropagation()" formControlName="KeepCurrentStatus"> Keep Current
                    Offer Status
                </mat-checkbox>
            </div>            
        </div>
        


        <mat-card-actions fxLayout="row" fxLayoutAlign="end">

            <div>
                <button mat-stroked-button type="button" (click)="closeDialog()" style="margin-right: 10px;">
                    Cancel
                </button>
                <button mat-raised-button type="button" color="accent" [disabled]="formGroup.invalid" (click)="cloneEvent()">
                    Clone
                </button>
            </div>
        </mat-card-actions>
    </form>
</ng-container>

<ng-template #loading>
    <mat-progress-bar mode="indeterminate" color="accent"></mat-progress-bar>
</ng-template>