import { NgModule } from '@angular/core';
import { EventDetailsComponent } from '.';
import { SharedModule } from 'src/app/@shared';
import { RiteaidV1RoutingModule } from './riteaid.v1.routing.module';

@NgModule({
  declarations: [
    EventDetailsComponent,
  ],
  imports: [
    SharedModule,
    RiteaidV1RoutingModule
  ]
})

export class RiteaidV1Module { }
