import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { finalize, Observable } from 'rxjs';
import { SpinnerOverlayService } from '..';

@Injectable({
  providedIn: 'root',
})

export class SpinnerOverlayInterceptor implements HttpInterceptor {
  private totalRequests = 0;

  constructor(public spinnerOverlayService: SpinnerOverlayService) { }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const timer = setTimeout(() => {
      this.spinnerOverlayService.show();
    }, 300);

    this.totalRequests++;

    return next.handle(request).pipe(
      finalize(() => {
        this.totalRequests--;

        if (timer) {
          clearTimeout(timer);
        }

        if (this.totalRequests === 0) {
          this.spinnerOverlayService.hide();
        }
      })
    );
  }
}
