<ng-container *ngIf="viewModel$ | async as viewModel">
  <mat-accordion multi>
    <mat-expansion-panel *ngFor="let adWeek of viewModel.adWeeks">
      <mat-expansion-panel-header>
        <mat-panel-title>{{adWeek.title}}</mat-panel-title>
      </mat-expansion-panel-header>
      <mat-list role="list">
        <mat-list-item *ngFor="let record of adWeek.eventDomains" fxflex="row">
          <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(record) : null"
                        [checked]="selection.isSelected(record)">
          </mat-checkbox>
          <mat-icon mat-list-icon>event</mat-icon><a [routerLink]="[record.Detail.Id, 'offers']">{{record.Detail.EventName}}</a>
          <span class="menu-spacer"></span>
          <button appAuthorizeControl="edit||offermang" type="button" mat-button color="accent" (click)="openDialog(record)">Clone</button>
          <button appAuthorizeControl="edit||eventmang" type="button" mat-button color="accent" [routerLink]="[record.Detail.Id, 'details']">Edit</button>
          <mat-divider></mat-divider>
        </mat-list-item>
      </mat-list>
    </mat-expansion-panel>
  </mat-accordion>
  <table>
    <tr *ngIf="!viewModel.events.length">
      <td class="no-records">
        <h2>No Records Found</h2>
      </td>
    </tr>
  </table>
</ng-container>
