import { Component, Inject, OnInit } from '@angular/core';
import { combineLatest, distinctUntilChanged, map, Subscription } from 'rxjs';
import { MediaChange, MediaObserver } from '@angular/flex-layout';
import { Logger } from 'src/app/@shared';
import { Sort } from '@angular/material/sort';
import { PageEvent } from '@angular/material/paginator';
import { OfferPromo, PromoDomain, OfferPromoService } from '../..';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl } from '@angular/forms';

const log = new Logger('UploadAssetsPromosComponent');

@Component({
  selector: 'app-upload-assets-promos',
  templateUrl: './upload-assets-promos.component.html',
  styleUrls: ['./upload-assets-promos.component.scss']
})

export class UploadAssetsPromosComponent<TOfferPromo extends OfferPromo, TPromoDomain extends PromoDomain<OfferPromo>> implements OnInit {
  selectedPromo: any;
  search = new FormControl<string>('');
  viewModel$ = combineLatest([
    this.offerpromoService.uploadPromos$,
    this.offerpromoService.isLoading$,
    this.offerpromoService.totalRecords$,
    this.offerpromoService.uploadPromosPage$,
  ]).pipe(
    map(([offers, isLoading, totalRecords, page]) => {
      return { offers, isLoading, totalRecords, page }
    }),
  );
  flexMediaWatcher!: Subscription;
  displayedColumns = ['Name', 'Headline', 'BodyCopy', 'Actions'];
  constructor(private offerpromoService: OfferPromoService<TOfferPromo, TPromoDomain>, private mediaObserver: MediaObserver,
    @Inject(MAT_DIALOG_DATA) private dialogData: any,
    public dialogRef: MatDialogRef<UploadAssetsPromosComponent<TOfferPromo, TPromoDomain>>) { }

  ngOnInit(): void {
    log.debug('init');
    this.offerpromoService.eventId = this.dialogData?.event?.Detail?.Id;
    // detect changes in viewport size to handle show/hide of table columns
    const getAlias = (MediaChange: MediaChange[]) => {
      return MediaChange[0].mqAlias;
    };

    this.flexMediaWatcher = this.mediaObserver
      .asObservable()
      .pipe(
        distinctUntilChanged(
          (x: MediaChange[], y: MediaChange[]) => getAlias(x) === getAlias(y)
        ))
      .subscribe((change) => {
        if (change.some(x => x.mqAlias === 'xs')) {
          this.displayedColumns = ['Name'];
        }
        else if (change.some(x => x.mqAlias === 'sm')) {
          this.displayedColumns = ['Name', 'Headline'];
        }
        else {
          this.displayedColumns = ['Name', 'Headline', 'BodyCopy', 'Actions'];
        }
      });
  }

  updateSelectedPromo(promo: TOfferPromo) {
    this.selectedPromo = promo;
  }

  saveData() {
    if (this.selectedPromo) {
      this.dialogRef.close(this.selectedPromo);
      this.offerpromoService.uploadPromosSearch('');
    }
  }
  onSearch(event: any) {
    this.offerpromoService.uploadPromosSearch(event.target.value);
  }

  clearSearch() {
    this.search.setValue('');
    this.offerpromoService.uploadPromosSearch('');
   }


  closeDialog() {
    this.dialogRef.close();
    this.offerpromoService.uploadPromosSearch('');
  }

  onSort(sortState: Sort): void {
    this.offerpromoService.uploadPromosSort(sortState);
  }

  onPage(pageEvent: PageEvent): void {
    this.offerpromoService.uploadPromosPage(pageEvent);
  }

}
