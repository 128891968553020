import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Subscription } from 'rxjs';
import { OfferPromo, OfferPromoService, PromoDomain } from '../..';

interface SortDirection {
  name: string;
  value: string;
  icon: IconProp;
}

@Component({
  selector: 'app-offer-promo-sort-dialog',
  templateUrl: './offer-promo-sort-dialog.component.html',
  styleUrls: ['./offer-promo-sort-dialog.component.scss']
})
export class OfferPromoSortDialogComponent<TOfferPromo extends OfferPromo, TPromoDomain extends PromoDomain<OfferPromo>> implements OnInit {
  sortBy = [
    { name: 'Name', value: 'Name' },
    { name: 'Start Date', value: 'StartDate' },
    { name: 'Rank', value: 'Rank' },
    { name: 'Status', value: 'Status' },
  ];
  sortDirection: SortDirection[] = [
    { name: 'Ascending', value: 'asc', icon: ['fas', 'arrow-up'] },
    { name: 'Descending', value: 'desc', icon: ['fas', 'arrow-down'] },
  ];
  sortForm = new FormGroup({
    sortBy: new FormControl<string>(this.sortBy[0].value),
    direction: new FormControl<string>(this.sortDirection[0].value),
  });
  subscription!: Subscription;

  constructor(
    private matDialogRef: MatDialogRef<OfferPromoSortDialogComponent<TOfferPromo, TPromoDomain>>,
    private offerpromoService: OfferPromoService<TOfferPromo, TPromoDomain>
  ) { }

  ngOnInit(): void {
    this.subscription = this.offerpromoService.sort$.subscribe(res => {
      this.sortForm.controls.sortBy.patchValue(res.active);
      this.sortForm.controls.direction.patchValue(res.direction);
      this.sortForm.markAllAsTouched();
    })
  }

  onSubmit() {
    if (this.sortForm.valid) {
      if (this.sortForm.controls.sortBy.value && this.sortForm.controls.direction.value) {
        const sortAction = { active: this.sortForm.controls.sortBy.value, direction: this.sortForm.controls.direction.value };
        this.offerpromoService.sort(sortAction);
      }

      this.matDialogRef.close();
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
