<ng-container *ngIf="viewModel$ | async as viewModel; else loading">
    <div class="grid">
        <mat-card appearance="outlined" *ngFor="let asset of viewModel.assets">
            <mat-card-content>
                <div class="grid-col-span-2 offer-name" title="{{ asset.AssetName }}" fxLayout="row wrap">
                    <mat-checkbox class="card-checkbox" appAuthorizeControl="edit||offermang" (click)="$event.stopPropagation()" fxFlex="30px"
                        (change)="$event ? selection.toggle(asset) : null" [checked]="selection.isSelected(asset)">
                    </mat-checkbox>
                    <div class="ellipsis-3-line" fxFlex>{{ asset?.AssetName }}</div>
                    <span class="rank" *ngIf="asset?.Rank">Rank : {{ asset?.Rank}}</span>
                </div>
                <div fxLayoutAlign="center center" class="offer-image">
                    <!-- <img mat-card-md-image *ngIf="assets?.Detail?.FileName" src="{{viewModel.assetsUrl}}/{{assets?.Detail?.FileName}}" alt="offer image">
                    <img mat-card-md-image *ngIf="!assets?.Detail?.FileName" src="./assets/images/default-image.png" alt="offer image"> -->
                    <ng-container *ngIf="asset?.Detail?.FileName && assetsUrl$ | async as assetsUrl">
                        <img mat-card-md-image [src]="getImageSrc(assetsUrl, asset)" alt="Coupon image">
                    </ng-container>
                    <img mat-card-md-image *ngIf="!asset.Detail.FileName"
                        src="./assets/images/default-image.png" alt="Coupon image">
                </div>
                <div class="offer-content" fxLayout="column">
                    <div class="offer-headline" title="{{ asset.DateAdded }}" *ngIf="asset?.DateAdded">
                        <span class="ellipsis-3-line">{{ asset?.DateAdded }}</span>
                    </div>
                    <div *ngIf="asset?.AssetGroupName">
                        <span class="ellipsis-3-line"> {{ asset?.AssetGroupName }}</span>
                    </div>
                    <div fxFlex fxLayout="row" fxLayoutAlign="end end">
                        <span class="footer-text">{{ asset?.Type }}</span>
                        <a class="footer-text" [routerLink]="[asset.Detail?.Id]">Edit Rank </a>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
    <mat-paginator [length]="viewModel.totalRecords" [pageSize]="viewModel.page.pageSize"
        [pageSizeOptions]="[5, 10, 20, 50, 100,500]" (page)="onPage($event)" *ngIf="viewModel.assets.length"
        showFirstLastButtons [pageIndex]="viewModel.page.pageIndex">
    </mat-paginator>

    <table *ngIf="!viewModel.assets.length">
        <tr>
            <td class="no-records">
                <h2>No Records Found</h2>
            </td>
        </tr>
    </table>
</ng-container>

<ng-template #loading>
    <mat-progress-bar mode="indeterminate" color="accent"></mat-progress-bar>
</ng-template>
