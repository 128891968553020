import { AssetDetails, AssetGroup } from "..";

export interface Asset {
    File: any;
    AssetTagIds: string[];
    AssetGroup: AssetGroup;
    ProductId?: string;
    EventId?: string;
    OfferId?: string;
    DomainId: string; 
    AssetName: string;
    DateAdded: string;
    Type: string;
    AssetGroupName: string; 
    Detail: AssetDetails;
  }