<mat-dialog-content class="mat-typography">
  <div fxLayout="row wrap" fxLayoutGap.gt-sm="16px" fxLayout.lt-md="column" class="add-users-container">
    <h2 style="padding-top: 0.8rem">Add Users</h2>
    <div fxLayout="row wrap" fxLayoutGap.gt-sm="16px" fxLayout.lt-md="column">
      <mat-form-field appearance="outline">
        <mat-icon matPrefix>search</mat-icon>
        <mat-label>Search User List</mat-label>
        <input matInput type="text" (input)="onSearch($event)" placeholder="Search" #searchInput />
        <button type="button" matSuffix mat-icon-button aria-label="Clear" *ngIf="searchInput.value"
          (click)="clearSearch()">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
    </div>

    <div id="new-templates" fxLayout="row wrap" fxLayoutGap.gt-sm="16px" fxLayout.lt-md="column">
      <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
        <!--- Note that these columns can be defined in any order.
            The actual rendered columns are set as a property on the row definition" -->

        <!-- Checkbox Column -->
        <ng-container matColumnDef="select">
          <th class="no-ellipsis" mat-header-cell *matHeaderCellDef>
            <mat-checkbox (change)="$event ? toggleAllRows() : null" [checked]="selection.hasValue() && isAllSelected()"
              [indeterminate]="selection.hasValue() && !isAllSelected()" [aria-label]="checkboxLabel()">
            </mat-checkbox>
          </th>
          <td class="no-ellipsis" mat-cell *matCellDef="let row">
            <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
              [checked]="selection.isSelected(row)" [aria-label]="checkboxLabel(row)">
            </mat-checkbox>
          </td>
        </ng-container>

        <!-- First Name Column -->
        <ng-container matColumnDef="first_name">
          <th mat-header-cell *matHeaderCellDef [resizeColumn]="true">First Name</th>
          <td mat-cell *matCellDef="let element">{{ element.first_name }}</td>
        </ng-container>

        <!-- Last Name Column -->
        <ng-container matColumnDef="last_name">
          <th mat-header-cell *matHeaderCellDef [resizeColumn]="true">Last Name</th>
          <td mat-cell *matCellDef="let element">{{ element.last_name }}</td>
        </ng-container>

        <!-- User Column -->
        <ng-container matColumnDef="user_role">
          <th mat-header-cell *matHeaderCellDef [resizeColumn]="true">User Role</th>
          <td mat-cell *matCellDef="let element">{{ element.user_role }}</td>
        </ng-container>

        <!-- User Status Column -->
        <!-- <ng-container matColumnDef="user_status">
          <th mat-header-cell *matHeaderCellDef [resizeColumn]="true">User Status</th>
          <td mat-cell *matCellDef="let element">{{ element.user_status }}</td>
        </ng-container> -->

        <!-- Email Column -->
        <ng-container matColumnDef="email">
          <th mat-header-cell *matHeaderCellDef>Email</th>
          <td mat-cell *matCellDef="let element">{{ element.email }}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        <tr>
          <td *ngIf="!dataSource || dataSource?.length == 0">
            <p><b>No User Found</b></p>
          </td>
        </tr>
      </table>
      <div fxLayout="row" fxLayoutAlign="center center" *ngIf="!dataSource || dataSource?.length == 0"
        style="width: 100%; padding-top: 1rem">
        <p><b>No User Found</b></p>
      </div>

      <mat-paginator style="width: 100%" [length]="dataSource?.length" [pageSize]="10"
        [pageSizeOptions]="[5, 10, 25, 100]" aria-label="Select page">
      </mat-paginator>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions [align]="'end'">
  <button mat-button (click)="dialogRef.close()">Cancel</button>
  <button mat-raised-button color="accent" cdkFocusInitial (click)="onSave()">
    Select
  </button>
</mat-dialog-actions>